<template>
<b-container>
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Reports</router-link>
        </li>
        
        <li
          class="breadcrumb-item active"
          style="font-size: 0.875rem"
          aria-current="page"
        >
          Conditional Pass Physical/Ground Report
        </li>
      </ol>
    </nav>
    <hr />
    <Medicalreport
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      @submit="onSubmit"
    ></Medicalreport
    >
    <div class="row" style="margin-top: 20px">
      <b-col md="11"></b-col>
    </div>

    <b-row class="mb-2">
    <!-- <b-col md="3"  >
    <p>Total Applications: {{ totalRows }}</p>
    </b-col> -->
    <b-col>
   
        <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
          @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
        <label class="float-left mx-3" for="" style="margin-top: 0.5%;">Total Applications: {{ totalRows }}</label>
    </b-col>
    </b-row>
    
     <loading
      :active.sync="isLoading"
      color="#5dbbda"
      loader="spinner"
      :is-full-page="fullPage"
      width:2
      height:2
    ></loading>
     <b-table
      id="tblApplication"
      show-empty
      select-mode="multi"
      head-variant="dark"
      empty-text="No Data available."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      style="font-size: 12px"
    >
      <template v-slot:cell(sr_no)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
    </b-table>
      <b-row>
      <b-col md="8" class="my-1">
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
          @change="ChangeEvent"
        />
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group
          horizontal
          label="Per page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
    </b-row>
    
    
</b-container>
    <!-- <div>
        test
    </div> -->
</template>
<script>
import apis from "@/apis";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Medicalreport from '../components/Medicalreport.vue';
export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType"],
    components: { Spinner, DatePicker, Loading, Medicalreport },
    data(){
      return {
      showBtn: false,
      boards: this.$store.getters.getBoards,
      perPage: 5,
      BatchNumber:"",
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      EventID: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      applications: [],
      bscReport: "",
      bscReportPath: "",
      appCount: 0,
      appDate: null,
  
      attendence_applications: [],
      fields: [
        { key: "sr_no", label: "SrNo" },
        { key: "PoolType", label: "Pool Type" },
         { key: "ApplicationNo", label: "Application No", sortable: true },
         { key: "CompanyName", label: "Employer Name", sortable: true },
          { key: "FullName", label: "Guard Name", sortable: true },
        
        {
          key: "AllotmentNo",
          label: "Registration No",
          sortable: true,
        },

        {
          key: "UpdatedDate",
          label: "Registration Date",
          sortable: true,
          formatter: "convertDateFormat",
        },
     
       
      ],
      marks_applications: [],
      
      batchno :"",
      
      selected: [],
      form: {
        BoardID: "",
      },
      showbtn: false,
      selectedApplication: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      fullPage: false,
      isLoadingFile: false,
      isAdmin: false,
    };
  
    },
      computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
    methods: {
       onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
     // this.totalRows = filteredItems.length;
      this.perPage=10
      this.currentPage = 1;
    },
     ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      //this.getApplicationsSub();
    },
        getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getApplicationsSub();
    },
       ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },
      onReset(){

      },
    //   onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length;
    //   this.currentPage = 1;
    // },
      convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
       convertToPdf() {
      var totalRows = this.totalRows?this.totalRows.toString():'0';
      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "PoolType", title: "Pool Type " },
        {
          dataKey: "ApplicationNo",
          title: "Application No",
        },
         {
          dataKey: "CompanyName",
          title: "Employer Name",
        },
        { dataKey: "FullName", title: "Guard Name" },
        { dataKey: "AllotmentNo", title: "Registration No" },
        { dataKey: "UpdatedDate", title: "Registration Date",Formatter:"convertDateFormat" },
       
    
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchForm.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      // var event = "";
      // this.$store.getters.getEvents.forEach((e) => {
      //   if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
      //     event = e.EventTitle;
      //     return;
      //   }
      // });
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        // pdf.text(
        //   "Guard Applications Of " + event,
        //   pdf.internal.pageSize.getWidth() / 2,
        //   50,
        //   "center"
        // );
        pdf.setFontSize(12);
        pdf.text("Date :", 40, 70);
        pdf.text("Total Applications :", 610, 70);
        pdf.text(ref.convertDateFormat(new Date()), 72, 70);
        pdf.text(totalRows,720, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
      
      );
      pdf.save("Scroll.pdf");
    },
     async downloadExcel() {
      var columns = [
       
        // "Application No",
        // "Guard Name",
        // "Registration No",
        // "Registration Date",
     
      
      ];

      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {
           "Sr No": "",
            "Pool Type ": "",
           "Application No": "",
           "Employer Name": "",
          "Guard Name": "",
          "Registration No": "",
          "Registration Date": "",
         
   
        
        };
        obj["Sr No"] = element.sr_no;
       obj["Pool Type "] = element.PoolType;
        obj["Application No"] = element.ApplicationNo;
        obj["Employer Name"] = element.CompanyName;
        obj["Guard Name"] = element.FullName;
        obj["Registration No"] = element.AllotmentNo;
        obj["Registration Date"] = this.convertDateFormat(element.UpdatedDate);
         

       
        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
    onSubmit(evt) { 
      this.currentPage = 1;
      // this.form.Limit = this.perPage;
      // this.form.OffSet = 0;
      this.getApplicationsSub();
      
         },
     getApplicationsSub() {
  
      this.isLoading = true;
      this.showDismissibleAlert = false;
      this.$store.dispatch("setSearchForm", this.form);
      let promise = apis.GetGroundList(this.form);

      promise
        .then((response) => {
          //console.log(response);
          this.isLoading = false;
          if (response.status == 200) {
            this.applications = response.data;
            for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
            }
           this.totalRows = response.data.length;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    
    },
      mounted() {
    this.form.BoardID = this.userBoard;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    let today_date = dd + "/" + mm + "/" + yyyy+1;
    this.current_date = today_date;

    
    let frm = this.$store.getters.getAppSearchFormPvt;

    if (frm != {} && frm.ApplicationStatus != undefined) {
      this.form = frm;
      this.getApplications();
    }
  },
    
    
    
};
</script>
<style scoped>

.autowidth {
  width: 300px !important;
  text-align: left;
}
</style>
