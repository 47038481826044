<template>
  <b-container>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Guard Registration</a></li>
        <li class="breadcrumb-item active" aria-current="page">
          IntimatePolice Medical List
        </li>
      </ol>
    </nav>
    <Intimatefilter
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      :name="IntimatePoliceMedical"
      @submit="onSubmit"
    ></Intimatefilter>
    <hr />
    <b-row class="mb-1">
      <div class="col-md-2" style="text-align: left">
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="selectAllRows"
        >
          {{ allSelected ? "Un-select All" : "Select All" }}
        </b-form-checkbox>
      </div>

      <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="filter"
            placeholder="Type to Search"
            size="sm"
          />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''" size="sm"
              >Clear</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="3">
        <p>
          Total Applications: {{ totalRows }} / Selected
          {{ selected.length }}
        </p>
      </b-col>

      <b-col>
        <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
          @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="mx-2"
          @click="sendLetter"
          style="float: right"
          >Send Intimation Latter</b-button
        >
      </b-col>
    </b-row>
    <loading :active.sync="isLoading" 
        color="#5dbbda"
        loader="spinner"
        :is-full-page="fullPage"
        width:2
        height:2
        ></loading>
    <b-table
      id="tblApplication"
      show-empty
      ref="tblApplication"
      head-variant="dark"
      empty-text="No Applications available."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :current-page="currentPage"
      :per-page="perPage"
      @filtered="onFiltered"
      style="font-size: 12px"
    >
      <template v-slot:cell(selected)="row">
        <b-form-checkbox
          v-model="row.item.selected"
          @change="onRowSelected(row.index, row.item)"
        >
        </b-form-checkbox>
      </template>

      <template v-slot:cell(Sr)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
      <template v-slot:cell(action)="row">
        <b-button
          size="sm"
          @click.stop="viewProfile(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
          v-b-tooltip.hover
          title="View Details"
          >View</b-button
        >
      </template>
      <template v-slot:cell(Gender)="row">
        <template v-if="row.item.Gender">
          <span>Male</span>
        </template>
        <template v-else>
          <span>Female</span>
        </template>
      </template>
      <template v-slot:cell(EvaluationStatus)="row">
        <template v-if="row.item.EvaluationStatus">
          <span>Yes</span>
        </template>
        <template v-else>
          <span>No</span>
        </template>
      </template>
      <!-- <template v-slot:cell(marks)="row">
          <b-button
            size="sm"
            @click.stop="enterMarks(row.item, row.index, $event.target)"
            class="mr-1 pt-0 pb-0"
            variant="warning"
            v-b-tooltip.hover
            title="Edit Doc"
          
            >Marks</b-button
          >
        </template> -->
    </b-table>
    <b-row>
      <b-col md="8" class="my-1">
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
        />
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group
          horizontal
          label="Per page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-modal id="confiramation_model" title="Confirmation" hide-footer>
        <p class="my-4">Are You Sure, you want to Send letter?</p>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="sendLetterConfirm"
          >
            OK
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancel
          </button>
        </div>
      </b-modal>

      <!-- <b-modal id="mdlMarks" size="xl">
        <marks-entry :applicationID="selectedApplication"></marks-entry>
      </b-modal> -->
    </div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from "vuex";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";

import Intimatefilter from "@/components/Intimatefilter.vue";
import Policemedicalfilter from "@/components/Policemedicalfilter.vue";

export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType"],
  components: { Spinner, Policemedicalfilter, Intimatefilter, Loading },
  data() {
    return {
      showBtn: false,
      isLoading:false,
      fullpage:false,
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      receiver_type: "",
      App: "App",
      print_shortlisting_letter_for_selected: [],
      selectMode: "multi",
      download_applications: [],
      test_type: "",
      name: "",
      UserID:  "",
      fullPage: "",
      modalShow: false,
      pendingWithList: [
        "All",
        "DM",
        "DBSC",
        "AGM Project",
        "GM",
        "MD",
        "LOI",
        "Lapsed",
        "Disbursed",
        "Hold",
        "Rejected",
      ],
      approvalStatus: this.$store.getters.getApprovalStatusPurpose,
      applications: [],
      IntimatePoliceMedical: [{ name: "IntimatePoliceMedical" }],
      bscReport: "",
      bscReportPath: "",
      appCount: 0,
      appDate: null,
      fields: [
        { key: "selected", label: "Selected" },
        { key: "Sr", label: "SrNo" },
        { key: "ApplicationNo", label: "Application No", sortable: true },

        { key: "FullName", label: "Applicant Name", sortable: true },
        { key: "Gender", label: "Gender", sortable: true },

        { key: "ApplicantAge", label: "Age", sortable: true },

        { key: "Height", label: "Height (cm)" },
        { key: "Weight", label: "Weight (kg)" },
        { key: "EducationalMark", label: "Education Marks" },
        { key: "ChestDeflated", label: "Chest D (cm)" },
        { key: "ChestInflated", label: "Chest I (cm)" },
        { key: "RunningTime", label: "Running Time" },
        { key: "RunningMark", label: "Runnning Marks" },
        { key: "PullUps", label: "PullUps" },
        { key: "PullupsMark", label: "PullUp Marks" },
        { key: "SitUps", label: "Situps" },
        { key: "SitupsMark", label: "Situp Marks" },
        { key: "GroundMarks", label: "Ground Marks" },
        { key: "TotalMarks", label: "Total Marks" },
        { key: "ResultStatus", label: "Result" },
      ],
      selected: [],
      selected_all: [],
      allSelected: false,
      form: {
        BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: [],
        Gender: "",
        MobileNo: "",
        ApplicationNo: "",
        ExServiceMan: 3,
        PinCode: null,
        OffSet: 0,
        // Limit: 500,
        Purpose: 8,
        selectValue:[]
      },
      formBatch: {
        BatchDate: null,
        BatchTime: null,
        ApplicationIDs: [],
        IsActive: true,
      },
      showbtn: false,
      selectedApplication: "",
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      selected_candidate: [],
      isAdmin: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  watch: {
    selected(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.applications.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  methods: {
    sendLetterConfirm() {
      this.$root.$emit("bv::hide::modal", "confiramation_model");

      let applications = "[";
      for (var i = 0; i < this.selected.length; i++) {
        if (i == 0) {
          applications += '"' + this.selected[i]["ApplicationNo"] + '"' ;
        } else {
          applications += ', "' + this.selected[i]["ApplicationNo"] + '"' ;
        }
      }

      applications += "]";

      let payload = {
        EventID: this.form.EventID,
        UserID: this.UserID,
        Gender: this.form.Gender,
        ApplicationNumberList: applications,
      };

      let Promise = apis.SendIntimationLetter(payload);

      Promise.then((response) => {
        if (response.status == 200) {
          this.$dialog.alert("Emails Are Sheduled Successfully ? Please Check After Some Time");
          this.selected=[];
        }
      }).catch((err) => {
        this.$dialog.alert("ERROR!!");
        
      });
      this.getApplicationsSub();
    },
    sendLetter() {
      this.appCount = this.selected.length;
      if (this.appCount == 0) {
        this.$dialog.alert("Please Select Applications ");
        return;
      }

      this.$root.$emit("bv::show::modal", "confiramation_model");
    },

   

    
    
   

    onRowSelected(index, item) {
      if (item.selected) {
        this.selected.push(item);
      } else {
        this.selected = _.filter(this.selected, function (x) {
          return x.sr_no != item.sr_no;
        });
      }
    },

    selectAllRows(checked) {
      for (var i = 0; i < this.applications.length; i++) {
        this.applications[i].selected = checked;
      }
      this.selected = checked ? this.applications.slice() : [];
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
   
    viewProfile(item) {
      this.$router.push({
        name: "ViewProfile",
        params: {
          ApplicationID: item.UserAppllicationID,
          ApplicationStatus: item.ApplicationStatusID,
        },
      });
    },

    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },

    enterMarks(item, index, button, n) {
      this.selectedApplication = item.UserAppllicationID;
      this.$root.$emit("bv::show::modal", "mdlMarks");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onSubmit() {
      if (this.appDate != null) {
        this.form.StartDate = Formatter.convertDateFormatYMD(this.appDate[0]);
        this.form.EndDate = Formatter.convertDateFormatYMD(this.appDate[1]);
      } else {
        this.form.StartDate = "0001-01-01";
        this.form.EndDate = "0001-01-01";
      }
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.Limit = 5;
      this.form.OffSet = 0;

      this.getApplicationsSub();
    },

    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      //this.perPage = 500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getApplicationsSub();
    },

    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getApplicationsSub();
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },

    getApplicationsSub() {
      this.isLoading = true;
      this.showDismissibleAlert = false;

      if (this.form.ApplicationStatus == 2) {
        this.showsts = true;
      } else {
        this.showsts = false;
      }

      if (this.form.ApplicationStatus == 1) {
        this.showbtn = true;
      } else {
        this.showbtn = false;
      }

      this.$store.dispatch("setSearchFormIntimation", this.form);
      //let promise = apis.readApplicationList(this.form);
      let data = {
        EventID: this.form.EventID,
        // or 0 for Female , 1 for Male
      };
      let promise = apis.GetIntimationList(this.form);
      promise
        .then((response) => {
          if (response.status == 200) {
            this.applications = response.body;
            for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
              this.applications[i].selected = false;
            }
            this.totalRows = this.applications.length;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    async getApplicationsSub1() {
      this.isLoading = true;
      this.showDismissibleAlert = false;

      if (this.form.ApplicationStatus == 2) {
        this.showsts = true;
      } else {
        this.showsts = false;
      }

      if (this.form.ApplicationStatus == 1) {
        this.showbtn = true;
      } else {
        this.showbtn = false;
      }

      this.$store.dispatch("setSearchFormIntimation", this.form);
      var data = this.form;
      data.Limit = this.totalRows;
      let promise = apis.readApplicationList(data);

      await promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.selected = response.body.Table1;
            //this.totalRows = response.body.Table[0].TotalRecords;
            //var userinfo = this.$store.getters.loggedUser;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    async getApplicationsSub2(limit, offset) {
      this.isLoading = true;
      this.showDismissibleAlert = false;

      if (this.form.ApplicationStatus == 2) {
        this.showsts = true;
      } else {
        this.showsts = false;
      }

      if (this.form.ApplicationStatus == 1) {
        this.showbtn = true;
      } else {
        this.showbtn = false;
      }

      this.$store.dispatch("setSearchFormIntimation", this.form);
      var data = this.form;
      data.Limit = limit;
      data.offset = offset;
      let promise = apis.readApplicationList(data);

      await promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.selected.concat(response.body.Table1);
            //this.totalRows = response.body.Table[0].TotalRecords;
            //var userinfo = this.$store.getters.loggedUser;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: "",
        MobileNo: "",
        ApplicationNo: "",
        StartDate: null,
        EndDate: null,
      };
    },

    convertToPdf() {
      //alert(0);
      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "ApplicationNo", title: "Application No" },
        {
          dataKey: "Createdate",
          title: "Application Date",
        },
        { dataKey: "FullName", title: "Applicant Name" },
        { dataKey: "Gender", title: "Gender" },
        { dataKey: "Mobile", title: "Mobile No" },
        { dataKey: "ApplicantAge", title: "Age" },
        { dataKey: "ExServiceMan", title: "Ex-Serv Man" },
        { dataKey: "", title: "Employer" },
        { dataKey: "ApplicationStatus", title: "Status" },
        { dataKey: "", title: "Total Calls" },
        { dataKey: "DeceasedGuard", title: "Decreased Guard" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchForm.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      var event = "";
      this.$store.getters.getEvents.forEach((e) => {
        if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
          event = e.EventTitle;
          return;
        }
      });
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Guard Applications Of " + event,
          pdf.internal.pageSize.getWidth() / 2,
          50,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            if (data.cell.section != "head") {
              switch (data.column.dataKey) {
                case "Createdate":
                  data.cell.text = vc.convertDateFormat(data.cell.text);
                  break;
                case "Gender":
                  if (data.cell.text == "male") {
                    data.cell.text = "Female";
                  } else {
                    data.cell.text = "Male";
                  }
                  break;
                case "ExServiceMan":
                  if (data.cell.text == "false") {
                    data.cell.text = "No";
                  } else {
                    data.cell.text = "Yes";
                  }
                  break;
              }
            }
          },
        }
      );
      pdf.save("Applications.pdf");
    },
    async downloadExcel() {
      var columns = [
        "Sr No",
        "Application No",
        "Application Date",
        "Applicant Name",
        // "Gender",
        // "Mobile No.",
        "Age",
        "Ex-Serv Man",
        "Employer",
        "Status",
        "Total Calls",
        "Decreased Guard",
      ];

      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {
          "Application No": "",
          "Application Date": null,
          "Applicant Name": null,
          // "Gender": "",
          // "Mobile No.": "",
          Age: "",
          "Ex-Serv Man": "",
          Employer: "",
          Status: "",
          "Total Calls": "",
          "Decreased Guard": "",
        };

        obj["Application No"] = element.ApplicationNo;
        obj["Application Date"] = this.convertDateFormat(element.Createdate);
        obj["Applicant Name"] = this.titleCase(element.FullName);
        // obj["Gender"] = this.gender_h(element.Gender);
        obj["Mobile No."] = element.Mobile;
        obj["Age"] = element.ApplicantAge;
        obj["Ex-Serv Man"] = this.service_man(element.ExServiceMan);
        obj["Employer"] = "";
        obj["Status"] = element.ApplicationStatus;
        obj["Total Calls"] = "";
        obj["Decreased Guard"] = element.DeceasedGuard;

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },

  mounted() {
    this.form.BoardID = this.userBoard;
    var userinfo = this.$store.getters.loggedUser;
    this.UserID  = userinfo.UserId;
    let frm = this.$store.getters.getAppSearchFormIntimation;
    this.selectValue = [];
    if (frm != {} && frm.ApplicationStatus != undefined) {
      this.form = frm;
      this.getApplications();
      
    }
  },
};
</script>

<style>
</style>
