import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import changePassword from '@/components/ChangePassword'
import Applications from '../views/GuardRegistration/Applications.vue'
import Applicationstest from '../views/GuardRegistration/Applicationstest.vue'
import Registration from '../views/GuardRegistration/Registration.vue'
import EditDueDate from '../views/GuardRegistration/EditDueDate.vue'
import RegistrationList from '../views/GuardRegistration/RegistrationList.vue'
import ApplicantWiseList from '../views/GuardRegistration/ApplicantWiseList.vue'

import Viewsignup from '../views/GuardRegistration/Viewsignup.vue'
import Profiles from '../views/GuardRegistration/Profiles.vue'
import ViewProfile from '../views/GuardRegistration/ViewProfile'
import BatchSchedule from '../views/GuardRegistration/BatchSchedule'
import BatchScheduleList from '../views/GuardRegistration/BatchScheduleList'
import NewPool from '../views/NewPool.vue'
import Eventlist from '../views/Eventlist.vue'
import GenerateResult from '../views/GuardRegistration/GenerateResult.vue'
import MeritList from '../views/GuardRegistration/MeritList.vue'
import uploadFile  from '../views/GuardRegistration/uploadFile .vue'
import IntimatePoliceMedical from '../views/GuardRegistration/IntimatePoliceMedical.vue'
import Exemption from '../views/Exemption/Exemption.vue'
import scrollreport from '../views/scrollreport.vue'
import PoliceMedicalExemption from '../views/PoliceMedicalExemption.vue'
import GroundExemption from '../views/GroundExemption.vue'

import ExemptionViewProfile from '../views/Exemption/ExemptionViewProfile'
// import ApprovalProcess from '../views/Exemption/ApprovalProcess'

import GuardList from '../views/Exemption/GuardList.vue'
import PrivateRegistration from '../views/PrivateRegistration/PrivateRegistration.vue'
import EmployerProfile from '../views/PrivateRegistration/EmployerProfile.vue'
import Applicationprofile from '../views/Exemption/Applicationprofile.vue'
import AgencyProfile from '../views/PrivateRegistration/AgencyProfile.vue'
import GuardProfile from '../views/PrivateRegistration/GuardProfile.vue'
import ViewProposal from '../views/Exemption/ViewProposal'
import ApplicationList from '../views/Exemption/EmployerRegistration/ApplicationList.vue'

 
Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/newpool',
    name: 'NewPool',
    component: NewPool
  },
  {
    path: '/applications',
    name: 'Applications',
    component: Applications
  },
  {
    path: '/applicationstest',
    name: 'Applicationstest',
    component: Applicationstest
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/editduedate',
    name: 'EditDueDate',
    component: EditDueDate
  },
  
  {
    path: '/registrationlist',
    name: 'RegistrationList',
    component: RegistrationList
  },
  {
    path: '/applicantwiselist',
    name: 'ApplicantWiseList',
    component: ApplicantWiseList
  },
  {
    path: '/viewsignup',
    name: 'Viewsignup',
    component: Viewsignup,
    props:true
  },

  
  {
    path: '/profiles',
    name: 'Profiles',
    component: Profiles
  },
  {
    path: '/generateresult',
    name: 'GenerateResult',
    component: GenerateResult
  },
  {
    path: '/eventlist',
    name: 'Eventlist',
    component: Eventlist
  },
  {
    path: '/exemption',
    name: 'Exemption',
    component: Exemption
  },
  {
    path: '/scrollreport',
    name: 'scrollreport',
    component: scrollreport
  },
  {
    path: '/ApplicationList',
    name: 'ApplicationList',
    component: ApplicationList
  },
  {
    path: '/PrivateRegistration',
    name: 'PrivateRegistration',
    component: PrivateRegistration
  },
  {
    path: '/employerprofile',
    name: 'EmployerProfile',
    component: EmployerProfile,
    props:true
  },
  {
    path: '/applicationprofile',
    name: 'Applicationprofile',
    component: Applicationprofile,
    props:true
  },
  
  {
    path: '/agencyprofile',
    name: 'AgencyProfile',
    component: AgencyProfile,
    props:true
  },
  {
    path: '/guardprofile',
    name: 'GuardProfile',
    component: GuardProfile,
    props:true
  },
  {
    path: '/guardlist',
    name: 'GuardList',
    component: GuardList
  },
  {
    path: '/viewprofile',
    name: 'ViewProfile',
    component: ViewProfile,
    props:true
  },
  {
    path: '/exemptionviewprofile',
    name: 'ExemptionViewProfile',
    component: ExemptionViewProfile,
    props:true
  },
  // {
  //   path: '/approvalprocess',
  //   name: 'ApprovalProcess',
  //   component: ApprovalProcess,
  //   props:true
  // },
  {
    path: '/batchschedule',
    name: 'BatchSchedule',
    component: BatchSchedule
  },
  {
    path: '/batchschedulelist',
    name: 'BatchScheduleList',
    component: BatchScheduleList
  },
  {
    path: '/Meritlist',
    name: 'MeritList',
    component: MeritList
  },
  {
    path: '/uploadfile',
    name: 'uploadfile',
    component: uploadFile
  },
  {
    path: '/IntimatePoliceMedical',
    name: 'IntimatePoliceMedical',
    component: IntimatePoliceMedical
  },
  {
    path: '/ViewProposal',
    name: 'ViewProposal',
    component: ViewProposal,
    props:true
  },
  {
    path:'/changePassword',
    name:'changePassword',
    component:changePassword,

  },
  {
    path: '/PoliceMedicalExemption',
    name: 'PoliceMedicalExemption',
    component: PoliceMedicalExemption
  },
  {
    path: '/GroundExemption',
    name: 'GroundExemption',
    component: GroundExemption
  },
 
  
]

const router = new VueRouter({
  mode:'history',
  base: process.env.BASE_URL,
  routes
})

export default router
