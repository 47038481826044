<template>
  <b-container>
    <!-- <div id="app container">
    <Breadcrumb class="row justify-content-center mt-4" :crumbs="crumbs" @selected="selected" />
  </div> -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Guard Registration</router-link>
        </li>
        <li
          class="breadcrumb-item active"
          style="font-size: 0.875rem"
          aria-current="page"
        >
          Application List Test
        </li>
      </ol>
    </nav>

    <application-filter
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      :name="Applications_name"
       @submit="onSubmit"
    ></application-filter>

    <hr />
    <b-row class="mb-2">
      <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="filter"
            placeholder="Type to Search"
            size="sm"
          />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''" size="sm"
              >Clear</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="3">
        <!-- <b-form-group horizontal label="Sort" class="mb-0" label-size="sm" label-cols="1">
              <b-input-group>
                <b-form-select v-model="sortBy" :options="sortOptions" size="sm">
                  <option slot="first" :value="null">-- none --</option>
                </b-form-select>
                <b-input-group-append>
                  <b-form-select :disabled="!sortBy" v-model="sortDesc" size="sm">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group-append>
              </b-input-group>
        </b-form-group>-->
        <p>Total Applications: {{ totalRows }}</p>
      </b-col>
      <b-col>
        <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
          @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
      </b-col>
    </b-row>
    <loading
      :active.sync="isLoading"
      color="#5dbbda"
      loader="spinner"
      :is-full-page="fullPage"
      width:2
      height:2
    ></loading>
    <b-table
      id="tblApplication"
      show-empty
      
      select-mode="multi"
      head-variant="dark"
      empty-text="No Applications available."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
      style="font-size: 12px"
    >
      <template v-slot:cell(selected)="{ rowSelected }">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not Selected</span>
        </template>
      </template>
      <template v-slot:cell(sr_no)="row">
      <div v-if="perPage=='show all'">{{
        (currentPage - 1) * totalRows + row.index + 1
      }}</div>
      <div v-else>
      {{(currentPage - 1) * perPage + row.index + 1}}
      </div>
      
      </template>
      <!-- <template v-slot:cell(action)="row">
        <b-button
          size="sm"
          @click.stop="viewProfile(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
          v-b-tooltip.hover
          title="View Details"
          >View</b-button
        >
      </template> -->
      <template v-slot:cell(employer_test)="row" >
                <span @click="details_data(row.item.VerificationCallCount)">
                  {{row.item.VerificationCallCount}}
                </span>
              </template>
      <template v-slot:cell(Gender)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.Gender">
          <span>Male</span>
        </template>
        <template v-else>
          <span>Female</span>
        </template>
      </template>
      <template v-slot:cell(ExServiceMan)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.ExServiceMan">
          <span>Yes</span>
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>
      
      <template v-slot:cell(editapp)="row" 
      >
        <b-button 
          size="sm"
          @click.stop="editApplication(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="info"
        
         
        >
          <i class="fas fa-edit"></i>
        </b-button>
      </template>
      <template v-slot:cell(editDoc)="row">
        <b-button
          size="sm"
          @click.stop="editDocument(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="warning"
          v-b-tooltip.hover
          title="Edit Doc"
          v-if="isAdmin"
          >Docs</b-button
        >
      </template>
      <template v-slot:cell(marks)="row">
        <b-button
          size="sm"
          @click.stop="enterMarks(row.item, row.index, $event.target)"
          class="mr-1 pt-0 pb-0"
          variant="warning"
          v-b-tooltip.hover
          title="Edit Doc"
          v-if="
            row.item.ApplicationStatusID == 6 ||
            row.item.ApplicationStatusID == 28 ||
            row.item.ApplicationStatusID == 29
          "
          >Marks</b-button
        >
      </template>
    </b-table>
    <b-row>
      <b-col md="8" class="my-1">
        <b-pagination  
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
          @change="ChangeEvent"
        />
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group
          horizontal
          label="Per page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
    </b-row>
    
    <div>
      <b-modal id="modalConfirm2" title="Schedule Batch" size="sm" hide-footer>
        <pre>Total Applications: {{ appCount }}</pre>
        <b-form @submit="onSubmit3" @reset="onReset3">
          <b-form-group
            id="iGpDt"
            horizontal
            label="Batch Date*"
            label-cols="4"
            label-size="sm"
            label-for="inpBatchDt"
          >
            <b-form-input
              id="inpBatchDt"
              type="date"
              v-model="formBatch.BatchDate"
              required
              size="sm"
              placeholder="Batch Date"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="iGpTm"
            horizontal
            label="Batch Time*"
            label-cols="4"
            label-size="sm"
            label-for="inpBatchTime"
          >
            <b-form-input
              id="inpBatchTime"
              type="time"
              v-model="formBatch.BatchTime"
              required
              size="sm"
              placeholder="Batch Time"
            ></b-form-input>
          </b-form-group>
          <div slot="modal-footer">
            <b-button variant="danger" type="submit" style="margin-left: 5px"
              >Create Batch</b-button
            >
            <b-button variant="info" type="reset" style="margin-left: 5px"
              >Reset</b-button
            >
          </div>
        </b-form>
      </b-modal>
      <b-modal id="mdlMarks" size="xl" :title="title" hide-footer>
        <marks-entry
          :applicationID="selectedApplication"
          :gender="selectedGender"
          :age="selectedAge"
        ></marks-entry>
      </b-modal>
      <b-modal id="mdlApp" size="xl" type="Submit" title="Edit Application" hide-footer>
    <div class="body-content ">    
     <!-- {{eventid}} -->
     <form @submit.prevent="Submit_profile">
      <table
        class="table table-bordered"
        style="text-align: left; horizantal-align: top"
      >
        <thead>
        <thead>
          <tr>
            <th>Field</th>
            <th>Old Value</th>
            <th>New Value</th>
            
          </tr>
        </thead>
        <tr v-if="eventid=='862ba3c1-7636-4e03-8a1a-485365f577ca'|| eventid=='bb033037-189c-4181-beac-7b3d60aa2bae'">
            <th style="width: 300px">Name of the Employer</th>
             <td >{{old_form.PrincipalEmployerName}}</td>  
            
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum" 
              placeholder="Name of the Employer"
              v-model="PrincipalEmployerName"
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false" 
              required  
            /></td>
          </tr>
           <tr v-if="eventid=='862ba3c1-7636-4e03-8a1a-485365f577ca'|| eventid=='bb033037-189c-4181-beac-7b3d60aa2bae'">
            <th style="width: 300px"> Registration No. of the Employer</th>
            <td >{{old_form.EmployerRegNumber}}</td>  
             <td>
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"  
              placeholder="Registration No. of the Employer" 
              v-model="EmployerRegNumber"  
              onkeypress="return event.charCode >= 48 && event.charCode <= 57  "
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
         <tr >
            <th style="width: 300px"> First Name</th>
            <td >{{old_form.FirstName}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum" 
               placeholder="First Name" 
              v-model="FirstName"  
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false" 
              required
            /></td>
          </tr>
           <tr>
            <th style="width: 300px"> Middle Name</th>
            <td >{{old_form.MiddleInitial}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Middle Name"   
              v-model="MiddleInitial"
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required 
            /></td>
          </tr>
           <tr>
            <th style="width: 300px"> Last Name</th>
            <td >{{old_form.LastName}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Last Name"  
              v-model="LastName"
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
                required  
            /></td>
          </tr>
           <tr >
            <th style="width: 300px">DOB</th>
            <td >{{this.convertDateFormatYMD1(old_form.DOB)}}</td>
          <td>  
             <date-picker 
              placeholder="DOB" 
              v-model="DOB"
              @change="validateAge"
             :first-day-of-week="1"
              format="DD-MM-YYYY" 
              lang="en"
             required
             ></date-picker>
             
            </td>
          </tr>
          <tr>
            <th style="width: 300px"> Mobile </th>
            <td >{{old_form.Mobile}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Mobile"  
              v-model="Mobile" 
              onkeypress="return event.charCode >= 48 && event.charCode <= 57  "
              maxlength="10"
              minlength="10"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
          <tr>
            <th style="width: 300px"> Email  </th>
            <td >{{old_form.EmailID}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Email" 
              v-model="EmailID"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || 
              event.charCode >= 65 && event.charCode <= 90 || 
              event.charCode >= 97 && event.charCode <= 122 ||
               event.charCode== 64 ||event.charCode== 46 || 
               event.charCode== 95 || event.charCode== 45"
              onpaste="return false"
              ondrop="return false"
              required   
            /></td>
          </tr>
          <tr>
            <th style="width: 300px"> Aadhar No  </th>
            <td >{{old_form.AdharNo}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Aadhar No" 
              v-model="AdharNo"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57  "
              maxlength="12"
              minlength="12"
              onpaste="return false"
              ondrop="return false"
              required   
            /></td>
          </tr>
          <tr >
          <th>Gender</th>
       <td >{{this.gender_h(old_form.Gender)}}</td>
       <td> 
            <b-form-select  
            placeholder="Gender" 
            v-model="Gender" size="sm" required>
                   <option :value="true">Male</option>
                    <option :value="false">Female</option>
            </b-form-select>
            </td>
          </tr>
          <tr >
            <th>Nationality</th>
         <td >{{this.nationality_h(old_form.Nationality)}}</td>     
          <td>  
            <b-form-radio-group 
            placeholder="Nationality"
            v-model="Nationality"
            required
             name="nationality">
            <b-form-radio value=true>Indian</b-form-radio>
            <b-form-radio value=false>Nepali</b-form-radio>    
      </b-form-radio-group>
           
           </td>
          </tr>
     <!-- ---------------------------------------affec---------------------------------- -->
         
          <!-- -------------------------------------start comp---------------------- -->
          <tr v-if="eventid=='48071941-70D9-4044-85A4-DFB45C8478CA'">
          
            <th style="width: 300px"> Reg. No of Deceased Guard </th>
            <td >{{old_form.DeceasedRegNum}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Reg. No of Deceased Guard" 
               onkeypress="return event.charCode >= 65 && event.charCode <= 90"
               onpaste="return false"
              ondrop="return false"
              v-model="DeceasedRegNum"
              required   
            /></td>
          </tr>
          <tr v-if="eventid=='48071941-70D9-4044-85A4-DFB45C8478CA'">
            <th style="width: 300px">  Name of Deceased Guard   </th>
               <td >{{old_form.DeceasedName}}</td> 
            
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Name of Deceased Guard"    
              v-model="DeceasedName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='48071941-70D9-4044-85A4-DFB45C8478CA'">
            <th style="width: 300px">  Relationship of the Applicant with Deceased Guard  </th>
             <td >{{old_form.DeceasedRelation}}</td> 
             <td>
              <b-select
              id="ipgndr"
              class="form-control"
              :options="list"
              value-field="RelationID"
              text-field="DeceasedRelation"
              size="sm"
              placeholder="Relationship of the Applicant with Deceased Guard"
              v-model="DeceasedRelation"
              required
            ></b-select>
             </td>
             <!-- <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum" 
              v-model="old_form.DeceasedRelation"   
            /></td> -->
          </tr>
           <tr v-if="eventid=='48071941-70D9-4044-85A4-DFB45C8478CA'">
            <th style="width: 300px">   Legal Heir First Name  </th>
             <td >{{old_form.HeirFirstName}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Legal Heir First Name"  
              v-model="HeirFirstName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required  
            /></td>
          </tr>
           <tr v-if="eventid=='48071941-70D9-4044-85A4-DFB45C8478CA'">
            <th style="width: 300px">  Legal Heir Middle Name  </th>
               <td >{{old_form.HeirMiddleName}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
               placeholder="Legal Heir Middle Name"    
              v-model="HeirMiddleName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='48071941-70D9-4044-85A4-DFB45C8478CA'">
            <th style="width: 300px">  Legal Heir Last Name  </th>
               <td >{{old_form.HeirLastName}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Legal Heir Last Name"
              v-model="HeirLastName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required    
            /></td>
          </tr>
          <!-- - -------------------------------------end comp---------------------- -- -->
          <!-- ------------------------court start--------------------------- -->
          
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px"> Court Case No</th>
            <td >{{old_form.OrderNum}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Court Case No"   
              v-model="OrderNum"
               onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||
               event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px"> Court Name </th>
             <td >{{old_form.CourtName}}</td> 
            
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Court Name"  
              v-model="CourtName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px">Court Order Date</th>
            <td >{{this.convertDateFormatYMD1(old_form.OrderDate)}}</td> 
        
          <td>  
             <date-picker
                      format="DD-MM-YYYY"
                      id="ipBtDt"
                      lang="en"
                      size="small"
                      style=""
                      placeholder="Court Order Date"
                      v-model="OrderDate"
                      required
                      
                 
                ></date-picker>
            </td>
          </tr>
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px"> Party Name </th>
               <td >{{old_form.UnionName}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Party Name"    
              v-model="UnionName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
        
          <!-- ------------------------court end--------------------------- -- -->  
        
      </table>
   
      <b-col>
        <b-button
          class="hide-print mt-3 ml-1 float-right"
          size="lg"
          variant="success"
          type="submit"
          >Submit</b-button
        >

      </b-col>
     </form>
    </div>
      </b-modal>
      
    </div>
     <b-modal ref="details_modal" size="xl" hide-footer title="Batch Details">
      <div class="modal-body">
            <div class="row">
              <div class="col-md-1"></div>
              <div class=" col-md-10">
                <b-table 
                    show-empty
                    selectable
                    select-mode="multi"
                    head-variant="dark"
                    empty-text="No Applications available."
                    stacked="md"
                    bordered
                    striped
                    :items="batchitems"
                    :fields="batchfields"
                >

                </b-table>
              </div>
              <div class="col-md-1"></div> 
            </div>
          </div>
    
    </b-modal>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import ApplicationFilter from "@/components/ApplicationFilter.vue";
import MarksEntry from "@/components/MarksEntry.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType"],
  components: { Spinner, ApplicationFilter, MarksEntry, Loading ,DatePicker},
  data() {
    return {
      PrincipalEmployerName:"",
      LoginUserID:"",
      user_id: "",
      EmployerRegNumber:"",
      FirstName:"",
      EmailID:"",
      EmployerName:"",
      gender:"",
      nationality: null,
      petitiondate:"",
      PetitionNum:null,
      Orderdate:"",
      PetitionDate:null,
      partyName:"",
      AdharNo:"",
      CourtName:"",
      registrationno:"",
      employername:"",
      courtname:"",
      DeceasedName:"",
      DeceasedRegNum:"",
      DeceasedRelation:"",
      FirstLoginBeforeDate:"",
      FirstName:"",
      Gender:"",
      UnionName:"",
      
      HeirFirstName:"",
      HeirLastName:"",
      HeirMiddleName:"",
      LastName:"",
      MiddleInitial:"",
      ApplicationStatusID:"",
      Mobile:"",
      Nationality:"",
      OrderDate:'',
      OrderNum:"",
      ordernum:"",
      partyname:"",
      OtherRelationName:null,
      Mobile: "",
      email_id:"",
      adhar:"",
      eventid:[],
      PrincipalEmployerName:"",
      Fullname:"",
      date:"",
      birthdate:"",
      showBtn: false,
      BatchNumber:"",
      fullPage: false,
      Gender: "",
      RegistrationNo: "",
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      crumbs: ["Home", "Category", "Sub category"],
      App: "App",
      Gen: "Gen",
      name: "",
      download_applications: [],
      batchitems:[],
      old_form:{},
      DOB: null,
      genderList: this.$store.getters.getGenderList,
      GenderData:[
         "Male",
        "Female",
        "Both",
      ],
      batchfields:[
          // { key: "BatchNumber", label: "Batch No" },
           { key: "BatchName", label: "Batch Name" },
            { key: "BatchDate", label: "Batch Date" },
      
      ],
      list:[
        "Sun",
        "Daughter",
        "Wife",
        "Husband",
        "Brother",
        "Sister",
        "Other(describe)"
      

      ],
      pendingWithList: [
        "All",
        "DM",
        "DBSC",
        "AGM Project",
        "GM",
        "MD",
        "LOI",
        "Lapsed",
        "Disbursed",
        "Hold",
        "Rejected",
      ],
      approvalStatus: this.$store.getters.getApprovalStatus,
      applications: [],
      bscReport: "",
      Applications_name:[{name:"Applications"}],
      bscReportPath: "",
      appCount: 0,
      appDate: null,
      Qualification:"",
      batchDetails:[],
      fields: [
        // { key: "selected", label: "Selected" },
        { key: "sr_no", label: "SrNo" },
        { key: "EventTitle", label: "Pool Type" },

        { key: "ApplicationNo", label: "Application No", sortable: true },
      
        {
          key: "Createdate",
          label: "Application Date",
          sortable: true,
          formatter: "convertDateFormat",
        },

        { key: "FullName", label: "Applicant Name", sortable: true },
        { key: "Gender", label: "Gender", sortable: true },
        { key: "Mobile", label: "Mobile No.", sortable: true },
        { key: "ApplicantAge", label: "Age", sortable: true },
        { key: "ExServiceMan", label: "Ex-Srv Man", sortable: true },
        { key: "PrincipalEmployerName", label: "Employer", sortable: true },
        { key: "ApplicationStatus", label: "Status", sortable: true },
        { key: "RegistrationNo", label: "Reg No", sortable: true },
         { key: "Qualification", label: "Qualification", sortable: true },
        { key: "RegistrationDate", label: "Reg Date", sortable: true },
        {
          key: "employer_test",
          label: "Total Calls",
          sortable: true,
        },
        { key: "DeceasedGuard", label: "Deceased Guard", sortable: true },
        // { key: "action", label: "View" },
        { key: "editapp", label: "Application Edit" },
        // { key: "editDoc", label: "Doc Edit" },
        // { key: "marks", label: "Marks" },
      ],
      selected: [],
      form: {
        BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: [],
        selectValue: [],
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        RegistrationNo: "",
        StartDate: "01-01-1970",
        EndDate: "01-01-1970",
        ExServiceMan: 3,
        PinCode: null,
        OffSet: 0,
        Limit: 500,
        Purpose: 0,
        EmployerName: "",
        ApplicationNumberList: "",
        value: "",
      },
      formBatch: {
        BatchDate: null,
        BatchTime: null,
        ApplicationIDs: [],
        IsActive: true,
      },
      showbtn: false,
      formBSC: {
        BSCID: 0,
        BoardID: "",
        MeetingDate: null,
        ApprovalStatus: 13,
        ApprovalDoc: "",
        UserID: "",
        ApplicationIDs: [],
      },
      selectedApplication: "",
      selectedGender: "",
      selectedAge: 0,
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
      title: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    calculateAge(birthDate) {
    
      birthDate = new Date(birthDate);
      let incrementDate = new Date(birthDate);
      
      let today = new Date();
      let n = 0;
      while (incrementDate < today) {
        n++;
        incrementDate.setYear(incrementDate.getFullYear() + 1);
      }
      return n - 1;

    },
  
     validateAge() {
       let age = this.calculateAge(this.DOB);
         if (age < 18 || age > 58) {
          alert("Age Criteria not fulfilled");
          this.DOB = '';
          return false;
      }
},
     convertDateFormatYMD(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD(inDate);
      }
    },
     convertDateFormatYMD1(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD1(inDate);
      }
    },
      convertDateFormatYMD_edit(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD_edit(inDate);
      }
    },
      convertDateFormatYMDY(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMDY(inDate);
      }
    },
    
    Submit_profile(){  
       if (!this.DOB) {
        alert("Please Select DOB");
        return;
      }

       if (!this.OrderDate) {
        alert("Please Select Order Date");
        return;
      }
      
     var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (this.EmailID !== "" && this.EmailID != null) {
        if (reg.test(this.EmailID) == false) {
          alert("please Enter Valid Email ID");
          return false;
        }
      }
      var data = {
        UserID: this.LoginID,
        LoginUserID: this.user_id,
        FirstName: this.FirstName,
        MiddleInitial: this.MiddleInitial,
        LastName: this.LastName,
        PrincipalEmployerName: this.PrincipalEmployerName,
        EmployerRegNumber: this.EmployerRegNumber,
        CourtName: this.CourtName,
        DeceasedName: this.DeceasedName,
        DeceasedRegNum: this.DeceasedRegNum,
        DeceasedRelation: this.DeceasedRelation,
        DOB: this.convertDateFormatYMDY(this.DOB),
        Mobile: this.Mobile,
        EmailID: this.EmailID,
        AdharNo: this.AdharNo,
        Gender: this.Gender,
        HeirFirstName: this.HeirFirstName,
        HeirMiddleName: this.HeirMiddleName,
        HeirLastName: this.HeirLastName,
        Nationality:  this.Nationality,
        OrderDate: this.convertDateFormatYMDY(this.OrderDate),
        OrderNum: this.OrderNum, 
        UnionName: this.UnionName,
        OtherRelationName: this.OtherRelationName,
        PetitionDate: this.convertDateFormatYMDY(this.PetitionDate),
        PetitionNum: this.PetitionNum
      };
      this.isLoading=true;
      let promise = apis.updateUserDetails(data);
      promise
        .then((response) => {
          this.isLoading=false;
          if (response.status == 200) {
          this.$dialog.alert("Save Succesfully..");
          } else {
            this.$dialog.alert(" UnSuccesfully..");
          }
          this.$root.$emit("bv::hide::modal", "mdlApp");
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
      details_close(){
        this.$refs["details_modal"].hide();
    },
     details_data(count){
      if (count>0) {
        this.$refs["details_modal"].show();
      }
   
  
    },
    convertDateFormat(inDate) {
      if (!inDate) {
        return ''
      }else{
      return Formatter.convertDateFormat(inDate);
      }
    },
    onSubmit3(evt) {
      evt.preventDefault();

      this.showDismissibleAlert = false;
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      this.formBatch.LoginID = LoginID;

      //console.log("SELECTED", this.selected);
      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array

        this.formBatch.ApplicationIDs.push(this.selected[i].LoanApplicationID);
      }

      // this.selected.forEach(function(data, index) {
      //   this.formBatch.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });
      let promise = apis.createBatch(this.formBatch);

      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "Batch Created Successfully";
            this.variant = "success";
            this.showDismissibleAlert = true;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    onReset3(evt) {},
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    onSubmitBSC(evt) {
      evt.preventDefault();
      this.showBtn = false;
      this.showDismissibleAlert = false;
      if (this.formBSC.ApprovalDoc == "") {
        this.alertText =
          "Approval File is not yet Uploaded. Please check the file icon before submit.";
        this.showDismissibleAlert = true;
        return;
      }
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      this.formBSC.UserID = LoginID;
      this.formBSC.BoardID = this.form.BoardID;

      //console.log("SELECTED", this.selected);

      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array

        this.formBSC.ApplicationIDs.push(this.selected[i].LoanApplicationID);
      }

      //console.log(this.formBSC);
      // this.selected.forEach(function(data, index) {
      //   this.form.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });

      let promise = apis.UpdateBSCDetails(this.formBSC);

      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "Data Saved Successfully";
            this.variant = "success";
            this.showDismissibleAlert = true;
            this.ResetBSCForm();
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
            this.showBtn = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
          this.showBtn = false;
        });
    },
    onReset2(evt) {
      evt.preventDefault();
    },
    ResetBSCForm() {
      this.$refs.finp.reset();
      this.bscReportPath = "";
      this.formBSC = {
        BSCID: 0,
        BoardID: "",
        MeetingDate: null,
        ApprovalStatus: 13,
        ApprovalDoc: "",
        UserID: "",
        ApplicationIDs: [],
      };
    },
    deleteDocument(item, index, button) {
      //console.log(item);
      this.selecteObj.Document = item.Document;
      this.selecteObj.Path = item.Path;

      let info = "You want to delete record for:" + "\n" + item.Document;
      this.modalInfo = info;
      this.$root.$emit("bv::show::modal", "modalConfirm");
    },

    modalCancel() {
      this.selecteObj.Document = "";
      this.selecteObj.Path = "";
      this.$root.$emit("bv::hide::modal", "modalConfirm");
    },

    modalConfirm() {
      this.form.UserLoanDocuments[this.selecteObj.Document] = "";

      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      //   var filename =   this.Photo.name;
      this.form.LoginID = LoginID;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      this.isLoadingFile = true;

      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1].toLowerCase();
      }

      if (extn != "jpeg" && extn != "png" && extn != "jpg" && extn != "pdf") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.finp.reset();
        this.isLoadingFile = false;
        return;
      }

      let dt = Date.now();
      var filetype = files[0].type;
      var filepath = "bsc_" + dt + "." + extn;
      let promisefoto = lambda.getSignedURL("msobcfdc_bsc", filepath, filetype);

      promisefoto
        .then((response) => {
          let signedUrl = response;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": files[0].type,
            },
          };
          return axios.put(signedUrl, files[0], options);
        })
        .then((res) => {
          if (res.status == 200) {
            this.formBSC.ApprovalDoc = filepath;
            this.showBtn = true;
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoadingFile = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoadingFile = false;
        });
    },
    viewProfile(item) {
      this.$router.push({
        name: "ViewProfile",
        params: {
          ApplicationID: item.UserAppllicationID,
          ApplicationStatus: item.ApplicationStatusID,
        },
      });
    },

    editDocument(item) {
      this.$router.push({
        name: "DocEdit",
        params: {
          UserID: item.UserID,
          ApplicationStatus: this.form.ApplicationStatus,
        },
      });
    },
   
  
    editApplication(item){
  
      this.LoginID = item.LoginID;
      let promiseU = apis.readappprofile({UserId:item.LoginID}); 
      this.eventid = item.EventID;
      promiseU
      .then((response) => {
         this.isLoading=false;
        if (response.status == 200) {
         if (response.body.length>0) {
            this.old_form = response.body[0];
            this.PrincipalEmployerName = response.body[0].PrincipalEmployerName  ;
            this.EmployerRegNumber = response.body[0].EmployerRegNumber;
            this.FirstName = response.body[0].FirstName;
            this.MiddleInitial = response.body[0].MiddleInitial;
            this.LastName = response.body[0].LastName;
            this.DOB = new Date(response.body[0].DOB);
            this.Mobile = response.body[0].Mobile;
            this.EmailID = response.body[0].EmailID;
            this.AdharNo = response.body[0].AdharNo;
            this.Gender = response.body[0].Gender;
            //  if (response.body[0].Gender==true) {
            //   this.Gender='Male';
            // }
            // else
            // {
            //    this.Gender='female';
            // }
            // if (response.body[0].Nationality==true) {
            //   this.Nationality=1;
            // }else{
            //    this.Nationality=0;
            // }
            this.Nationality = response.body[0].Nationality
            this.DeceasedRegNum = response.body[0].DeceasedRegNum;
            this.DeceasedName = response.body[0].DeceasedName;
            this.DeceasedRelation = response.body[0].DeceasedRelation;
            this.HeirFirstName = response.body[0].HeirFirstName;
            this.HeirMiddleName = response.body[0].HeirMiddleName;
            this.HeirLastName = response.body[0].HeirLastName;
            this.OrderNum = response.body[0].OrderNum;
            this.CourtName = response.body[0].CourtName;
            this.OrderDate = new Date(response.body[0].OrderDate);
            this.PetitionDate = new Date(response.body[0].PetitionDate);
            this.UnionName = response.body[0].UnionName;
            
            
            this.$root.$emit("bv::show::modal", "mdlApp");
          }
          else
          {
            alert('Data Not Found');
          }
         
        } else {
          
          alert(response.body)
        }
      })
      
     
    },
    enterMarks(item, index, button, n) {
      this.selectedApplication = item.UserAppllicationID;
      this.selectedGender = item.Gender;
      this.selectedAge = item.ApplicantAge;
      this.title = item.FullName + " (" + item.ApplicationNo + ")";
      this.$root.$emit("bv::show::modal", "mdlMarks");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onSubmit() {
      if (this.appDate != null) {
        this.form.StartDate = Formatter.convertDateFormatYMD(this.appDate[0]);
        this.form.EndDate = Formatter.convertDateFormatYMD(this.appDate[1]);
      } else {
        this.form.StartDate = "0001-01-01";
        this.form.EndDate = "0001-01-01";
      }
      this.currentPage = 1;
      this.form.Limit = this.perPage=='show all'?500:this.perPage;
      // this.form.Limit = 500;
      this.form.OffSet = 0;

      this.getApplicationsSub();
    },

    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getApplicationsSub();
    },

    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getApplicationsSub();
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      if (this.perPage=='show all') {
        this.form.Limit=this.totalRows;
        
      }else{
         this.form.Limit = this.perPage;
      }
     
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },

    getApplicationsSub() {
      this.isLoading = true;
      this.form.ApplicationStatus = JSON.parse(
        JSON.stringify(this.form.selectValue)
      );
      this.showDismissibleAlert = false;

      //console.log(this.form.ApplicationStatus.length);
      if (
        this.form.ApplicationStatus &&
        this.form.ApplicationStatus.length == 0
      ) {
        for (let x = 0; x < this.approvalStatus.length; x++) {
          this.form.ApplicationStatus.push(this.approvalStatus[x]);
        }
      }
      //console.log(this.form.ApplicationStatus);

      // this.form.selectvalue=JSON.parse(this.form.selectvalue)
      this.$store.dispatch("setSearchForm", this.form);
      let promise = apis.readApplicationList(this.form);

      promise
        .then((response) => {
          //console.log(response);
          this.isLoading = false;
          if (response.status == 200) {
            this.applications = response.body.Table1;
            this.batchitems =  this.applications[0].BatchDetails?JSON.parse(this.applications[0].BatchDetails):[];
            
            for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
            }
            this.totalRows = response.body.Table[0].TotalRecords;
            var userinfo = this.$store.getters.loggedUser;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        RegistrationNo: "",
        StartDate: null,
        EndDate: null,
      };
    },

    convertToPdf() {
      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "sr_no", title: "Sr No" },
        { dataKey: "EventTitle", title: "Pool Type" },
        { dataKey: "ApplicationNo", title: "Application No" },
        {
          dataKey: "Createdate",
          title: "Application Date",
        },
        { dataKey: "RegistrationNo", title: "Registration Number" },
         { dataKey: "RegistrationDate", title: "Registration Date" },
        { dataKey: "FullName", title: "Applicant Name" },
        { dataKey: "Qualification", title: "Education" },

        { dataKey: "Gender", title: "Gender" },
        { dataKey: "Mobile", title: "Mobile No" },
        { dataKey: "ApplicantAge", title: "Age" },
        { dataKey: "ExServiceMan", title: "Ex-Serv Man" },
        { dataKey: "", title: "Employer" },
        { dataKey: "ApplicationStatus", title: "Status" },
        { dataKey: "VerificationCallCount", title: "Total Calls" },
        { dataKey: "DeceasedGuard", title: "Deceased Guard" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchForm.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      var event = "";
      this.$store.getters.getEvents.forEach((e) => {
        if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
          event = e.EventTitle;
          return;
        }
      });
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Guard Applications Of " + event,
          pdf.internal.pageSize.getWidth() / 2,
          50,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      var data = [];
      for (var i = 0; i < vc.applications.length; i++) {
        vc.applications[i]["Gender"] =
          vc.applications[i]["Gender"] == "false" ? "Female" : "Male";
        vc.applications[i]["ExServiceMan"] =
          vc.applications[i]["ExServiceMan"] == "false" ? "No" : "Yes";
        vc.applications[i]["Createdate"] = vc.convertDateFormat(
          vc.applications[i]["Createdate"]
        );
      }
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            if (data.cell.section != "head") {
              switch (data.column.dataKey) {
                case "Createdate":
                  data.cell.text = vc.convertDateFormat(data.cell.text);
                  break;
                case "Gender":
                  if (data.cell.text == "false") {
                    data.cell.text = "Female";
                  } else {
                    data.cell.text = "Male";
                  }
                  break;
                case "ExServiceMan":
                  if (data.cell.text == "false") {
                    data.cell.text = "No";
                  } else {
                    data.cell.text = "Yes";
                  }
                  break;
              }
            }
          },
        }
      );
      pdf.save("Applications.pdf");
    },
    async downloadExcel() {
      var columns = [
        "Sr No",
        "Pool Type",
        "Application No",
        "Application Date",
        "Registation Number",
        "Registration Date",
        "Applicant Name",
        "Education",
        "Gender",
        "Mobile No.",
        "Age",
        "Ex-Serv Man",
        "Employer",
        "Status",
        "Total Calls",
        "Decreased Guard",
      ];

      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {
          "Sr No": "",
          "Pool Type": "",
          "Application No": "",
          "Application Date": null,
          "Registation Number": "",
          "Registration Date":"",
          "Applicant Name": null,
          Education: "",
          Gender: "",
          "Mobile No.": "",
          Age: "",
          "Ex-Serv Man": "",
          Employer: "",
          Status: "",
          "Total Calls": "",
          "Decreased Guard": "",
        };
        obj["Sr No"] = element.sr_no;
        obj["Pool Type"] = element.EventTitle;

        obj["Application No"] = element.ApplicationNo;
        obj["Application Date"] = this.convertDateFormat(element.Createdate);
        obj["Registation Number"] = this.titleCase(element.RegistrationNo);
        obj["Registration Date"] = this.convertDateFormat(element.RegistrationDate);
        
        
        obj["Applicant Name"] = this.titleCase(element.FullName);
        obj["Education"] = element.Qualification;
        obj["Gender"] = this.gender_h(element.Gender);
        obj["Mobile No."] = element.Mobile;
        obj["Age"] = element.ApplicantAge;
        obj["Ex-Serv Man"] = this.service_man(element.ExServiceMan);
        obj["Employer"] = element.PrincipalEmployerName;
        obj["Status"] = element.ApplicationStatus;
        obj["Total Calls"] = element.VerificationCallCount;
        obj["Decreased Guard"] = element.DeceasedGuard;

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
      nationality_h(Nationality) {
      if (Nationality) {
        return "Indian";
      } else {
        return "Nepali";
      }
    },
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },

  mounted() {
     this.user_id = this.$store.getters.loggedUser.UserId;
 
   
    
    // this.approvalStatus = this.$store.getters.getApprovalStatus;
    // this.boards = this.$store.getters.getBoards;
    this.form.BoardID = this.userBoard;
    // this.events = this.$store.getters.getEvents;
    let frm = this.$store.getters.getAppSearchForm;
    this.selectValue=[];

    if (frm != {} && frm.ApplicationStatus != undefined) {
      this.form = frm;
      this.getApplications();
    }
  },
};
</script>

<style scoped>
#tj-datetime-input {
  width: 250px !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
