<template>
  <div class="container">
    <br />
    <b-row>
      <b-col md="8" offset="3"
        v-if="
          RoleID == 1 ||
          RoleID == 2 ||
          RoleID == 3 ||
          RoleID == 4 ||
          RoleID == 7
        "
      >
        <div  class="small table-striped table-bordered b-table-stacked-md"
          style="font-size: 15px">
          <div class="card-header" style="background-color: white">
            <b>New Registration</b>
          </div>

          <div class="card-body" style="margin-top: -2px;">
            <br />
            <div :class="{ overflow_cls: isOver }">
              <table
                class="table table-bordered border-primary small table-striped"
                style="background-color: skyblue"
              >
                <thead>
                  <th style="text-align: left">Application Status</th>
                  <th>Compassionate</th>
                  <th>Recommended</th>
                  <th>Court-Case</th>                  
                  <th>Affected</th>
                  <th>New</th>
                  <th>Total</th>
                </thead>
                <tbody>
                  <tr
                    style="height: 5px !important"
                    v-for="(ol, index) in rows"
                    :key="index"
                  >
                    <td style="text-align: left">
                      <a
                        style="color: black"
                        @click="link_page('applications')"
                        >{{ ol.ApplicationStatus }}</a
                      >
                    </td>
                    <td>
                      <a
                        style="color: blue"
                        @click="link_page('applications', 3)"
                        >{{ ol.Compassionate }}</a
                      >
                    </td>
                    
                    <td>
                      <a
                        style="color: blue"
                        @click="link_page('applications', 2)"
                        >{{ ol.Recommended }}</a
                      >
                    </td>
                    <td>
                      <a
                        style="color: blue"
                        @click="link_page('applications', 4)"
                        >{{ ol["Court Cases"] }}</a
                      >
                    </td>
                    
                    <td>
                      <a
                        style="color: blue"
                        @click="link_page('applications', 1)"
                        >{{ ol.Affected }}</a
                      >
                    </td>
                    
                    <td>
                      <a
                        style="color: blue"
                        @click="link_page('applications', 0)"
                      >
                        {{ ol.New }}</a
                      >
                    </td>
                    <td>
                      <a
                        style="color: blue"
                        @click="link_page('applications', 0)"
                      >
                        {{ ol.Total }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-col>

      <b-col md="6" offset="3"
        v-if="RoleID >= 2"
      >
        <div
           class="small table-striped table-bordered b-table-stacked-md"
          style="font-size: 15px"
        >
          <div class="card-header" style="background-color: white">
            <b>Exemption Application</b>
          </div>
          <div class="card-body style=margin-top: 2px">
            <br />
            <div>
              <table class="table small">
                <thead>
                  <th style="text-align: left">Application Status</th>
                  <th>Count</th>
                </thead>
                <tbody>
                  <tr
                    style="height: 5px !important"
                    v-for="(ol, index) in rows1"
                    :key="index"
                  >
                    <td style="text-align: left">
                      <a style="color: black" @click="link_page('exemption')">{{
                        ol.ApplicationStatus
                      }}</a>
                    </td>
                    <td>
                      <a
                        style="color: blue"
                        @click="link_page('exemption', 5)"
                        >{{ ol.ApplicationCount }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apis from "@/apis";
export default {
  name: "dashboard",
  data: function () {
    return {
      isOver: true,
      BoardID: "",
      Applicationstatus:"",
      Applicationcount:"",
      rows: [],
      rows1: [],
      isLoading: "true",
      isLoading: "false",
      RoleID: "",
    };
  },
  methods: {
    get_class1() {
      if (this.RoleID == 1) {
        return "col-md-8";
      } else if (this.RoleID > 4) {
        return "";
      } else {
        return "col-md-12";
      }
    },
    get_class2() {
      //  alert(this.RoleID);
      if (this.RoleID > 4) {
        return "col-md-12";
      } else if (this.RoleID == 1) {
        return "col-md-4";
      } else {
        return "col-md-4";
      }
    },

    get_dashboard() {
      this.isLoading = true;
      let data = {
        BoardID: this.$store.getters.getUserBoard,//"A1BFDFD8-DB30-4D39-87F7-5F2EDDD7F810",
        value: "",
      
      };

      var promise = apis.getdashboard(data);
      promise
        .then((response) => {
          this.isLoading = false;
          console.log(response);
          this.rows = response.data.Table;
           this.rows1 = response.data.Table1;
          //this. = response.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    link_page(page, value) {
      //alert(value);
      this.$router.push(page);
      // this.$router.push({
      //   name: 'applications',
      //   params: {
      //     value: value,

      //   },
      // });
    },
  },
  mounted() {
    var nme = this.$store.getters.loggedUser;
    this.RoleID = nme.RoleID;
    this.get_dashboard();
  },

  components: {},
};
</script>

<style scoped>
/* .overflow_cls {
  height: 380px;
  overflow: scroll;
  overflow-x: hidden;
} */
.table > thead {
  background-color: #2e4b90;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  color: white;
}
.table td {
  padding: 0.5em !important;
}
.card {
  background-color: transparent;
}
</style>