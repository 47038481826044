<template>
  <b-container>
    <div id="printme">
       <b-row class="mb-3"> 
         <b-col>
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          @click.prevent="goback"
          variant="danger"
          size="sm"
          >BACK</b-button
        >
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          size="sm"
          variant="info"
          @click="print"
          >Print Profile</b-button
        >
      </b-col>
        <hr />
       </b-row>
       <b-row>

      <b-col style="text-align:center">
          <!-- <img src="@/assets/images/logo_trans.png" class="float-left" width="80px" /> -->
          <h2>Security Guards Board</h2>
          <h4>Registration of Employer Agency</h4>
          
        </b-col>

     

     </b-row> 
      

    
      <b-row class="my-2">
        <b-col md="1"></b-col>
        <b-col>
          <strong>Registration Number:</strong>
          {{ RegistrationNumber }}
        </b-col>
        <b-col></b-col>
        <b-col class="float-right">
          <strong>Date:</strong>
          {{ PaymentDate }}
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col md="1"></b-col>
        <b-col md="1">
          <strong>Board</strong>
        </b-col>
        <b-col md="6">{{ BoardName }}</b-col>
      </b-row>
      <div class="my-2" style="border-style: solid">
        <h5>1.AgencyDetails</h5>
        <div style="margin-left: 3%">
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Name of Employer Agency</b-col>
            <b-col md="5">{{ form.AgencyName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Address of the Agency</b-col>
            <b-col md="5">{{ form.AgencyAddress }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">City</b-col>
            <b-col md="5">{{ DistrictName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Pin Code</b-col>
            <b-col md="5">{{ form.PinCode }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Contact Number</b-col>
            <b-col md="5">{{ form.ContactNumber }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Email ID</b-col>
            <b-col md="5">{{ form.EmailID }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Type of Company</b-col>
            <b-col md="5">{{ form.CompanyType }}</b-col>
          </b-row>
        </div>
      </div>

      <div class="table4 mb-2" style="border-style: solid">
        <h5>2.Owner/Partner/Director Details</h5>
        <br />
        <div style="margin-left: 20px">
          <table class="my-2">
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Number</th>
              <th>Email ID</th>
            </tr>
            <tr v-for="owner in form.OwnerDetails" :key="owner.OwnerName">
              <td>{{ owner.OwnerName }}</td>
              <td>{{ owner.OwnerAddress }}</td>
              <td>{{ owner.OwnerMobile }}</td>
              <td>{{ owner.OwnerEmail }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="table3" style="border-style: solid">
        <h5>3.License Details</h5>
        <div style="margin-left: 4%">
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Year of Establishment</b-col>
            <b-col md="5">{{ form.EstablishmentYear }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4"
              >Registration Number as Per Bombay Shop & Commercial Establishment
              Act</b-col
            >
            <b-col md="5">{{ form.ShopActRegNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Male Employees</b-col>
            <b-col md="5">{{ form.MaleEmployeeCount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Female Employees</b-col>
            <b-col md="5">{{ form.FemaleEmployeeCount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">PSRA License Number</b-col>
            <b-col md="5">{{ form.PSRA }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">License Date</b-col>
            <b-col md="5">{{ form.PsraLicenseDate }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">License Validity Date</b-col>
            <b-col md="5">{{ form.PsaraDueDate }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">PAN</b-col>
            <b-col md="5">{{ form.PAN }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">GST No</b-col>
            <b-col md="5">{{ form.GST }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Professional Tax Reg No</b-col>
            <b-col md="5">{{ form.PTNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Employee Welfare Board Reg No</b-col>
            <b-col md="5">{{ form.EmployeeWelfare }}</b-col>
          </b-row>
        </div>
      </div>
      <div class="table4 my-2" style="border-style: solid">
        <h5>4.GuardDetails</h5>
        <br />
        <div
          class="subtable1 mb-5"
          style="margin-left: 20px; margin-right: 20px"
        >
          <table class="my-2">
            <tr>
              <th>Group</th>
              <th>Toltal Ex-Serviceman</th>
              <th>Maharashtra Domiciled</th>
              <th>Citizens of Kashmir</th>
              <th>Citizens of Other State</th>
              <th>Basic</th>
              <th>D.A.</th>
              <th>Allowance</th>
              <th>Total Salary</th>
            </tr>

            <tr>
              <td>Unarmed Security Guard</td>

              <td>{{ form.SecurityGuardNonWeapon.XSvcMan }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Domiciled }}</td>
              <td>{{ form.SecurityGuardNonWeapon.KasmirCtznext }}</td>
              <td>{{ form.SecurityGuardNonWeapon.OtherCtzn }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Basic }}</td>
              <td>{{ form.SecurityGuardNonWeapon.DA }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Allowance }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Total }}</td>
            </tr>

            <tr>
              <td>Armed Security Guard</td>

              <td>{{ form.SecurityGuardsWeapon.XSvcMan }}</td>
              <td>{{ form.SecurityGuardsWeapon.Domiciled }}</td>
              <td>{{ form.SecurityGuardsWeapon.KasmirCtznext }}</td>
              <td>{{ form.SecurityGuardsWeapon.OtherCtzn }}</td>
              <td>{{ form.SecurityGuardsWeapon.Basic }}</td>
              <td>{{ form.SecurityGuardsWeapon.DA }}</td>
              <td>{{ form.SecurityGuardsWeapon.Allowance }}</td>
              <td>{{ form.SecurityGuardsWeapon.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Head Security Guards</td>

              <td>{{ form.HeadSGNW.XSvcMan }}</td>
              <td>{{ form.HeadSGNW.Domiciled }}</td>
              <td>{{ form.HeadSGNW.KasmirCtznext }}</td>
              <td>{{ form.HeadSGNW.OtherCtzn }}</td>
              <td>{{ form.HeadSGNW.Basic }}</td>
              <td>{{ form.HeadSGNW.DA }}</td>
              <td>{{ form.HeadSGNW.Allowance }}</td>
              <td>{{ form.HeadSGNW.Total }}</td>
            </tr>

            <tr>
              <td>Armed Head Security Guards</td>

              <td>{{ form.HeadSGW.XSvcMan }}</td>
              <td>{{ form.HeadSGW.Domiciled }}</td>
              <td>{{ form.HeadSGW.KasmirCtznext }}</td>
              <td>{{ form.HeadSGW.OtherCtzn }}</td>
              <td>{{ form.HeadSGW.Basic }}</td>
              <td>{{ form.HeadSGW.DA }}</td>
              <td>{{ form.HeadSGW.Allowance }}</td>
              <td>{{ form.HeadSGW.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Security Supervisor</td>

              <td>{{ form.SSNW.XSvcMan }}</td>
              <td>{{ form.SSNW.Domiciled }}</td>
              <td>{{ form.SSNW.KasmirCtznext }}</td>
              <td>{{ form.SSNW.OtherCtzn }}</td>
              <td>{{ form.SSNW.Basic }}</td>
              <td>{{ form.SSNW.DA }}</td>
              <td>{{ form.SSNW.Allowance }}</td>
              <td>{{ form.SSNW.Total }}</td>
            </tr>

            <tr>
              <td>Armed Security Supervisor</td>

              <td>{{ form.SSW.XSvcMan }}</td>
              <td>{{ form.SSW.Domiciled }}</td>
              <td>{{ form.SSW.KasmirCtznext }}</td>
              <td>{{ form.SSW.OtherCtzn }}</td>
              <td>{{ form.SSW.Basic }}</td>
              <td>{{ form.SSW.DA }}</td>
              <td>{{ form.SSW.Allowance }}</td>
              <td>{{ form.SSW.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Asst Security Officer</td>

              <td>{{ form.ASONW.XSvcMan }}</td>
              <td>{{ form.ASONW.Domiciled }}</td>
              <td>{{ form.ASONW.KasmirCtznext }}</td>
              <td>{{ form.ASONW.OtherCtzn }}</td>
              <td>{{ form.ASONW.Basic }}</td>
              <td>{{ form.ASONW.DA }}</td>
              <td>{{ form.ASONW.Allowance }}</td>
              <td>{{ form.ASONW.Total }}</td>
            </tr>

            <tr>
              <td>Armed Asst Security Officer</td>

              <td>{{ form.ASOW.XSvcMan }}</td>
              <td>{{ form.ASOW.Domiciled }}</td>
              <td>{{ form.ASOW.KasmirCtznext }}</td>
              <td>{{ form.ASOW.OtherCtzn }}</td>
              <td>{{ form.ASOW.Basic }}</td>
              <td>{{ form.ASOW.DA }}</td>
              <td>{{ form.ASOW.Allowance }}</td>
              <td>{{ form.ASOW.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Security Officer</td>

              <td>{{ form.SQNW.XSvcMan }}</td>
              <td>{{ form.SQNW.Domiciled }}</td>
              <td>{{ form.SQNW.KasmirCtznext }}</td>
              <td>{{ form.SQNW.OtherCtzn }}</td>
              <td>{{ form.SQNW.Basic }}</td>
              <td>{{ form.SQNW.DA }}</td>
              <td>{{ form.SQNW.Allowance }}</td>
              <td>{{ form.SQNW.Total }}</td>
            </tr>

            <tr>
              <td>Armec Security Officer</td>

              <td>{{ form.SOW.XSvcMan }}</td>
              <td>{{ form.SOW.Domiciled }}</td>
              <td>{{ form.SOW.KasmirCtznext }}</td>
              <td>{{ form.SOW.OtherCtzn }}</td>
              <td>{{ form.SOW.Basic }}</td>
              <td>{{ form.SOW.DA }}</td>
              <td>{{ form.SOW.Allowance }}</td>
              <td>{{ form.SOW.Total }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="table4 mb-2" style="border-style: solid">
        <h5>5.Principal Employer Details</h5>
        <br />
        <div style="margin-left: 20px">
          <table class="my-2">
            <tr>
              <th>SGB Registration Number</th>
              <th>Name of Principal Employer</th>
              <th>Address</th>
              <th>Guards Employed</th>
              <th>Period of Work (months)</th>
            </tr>
            <tr
              v-for="employer in form.PrincipalEmployers"
              :key="employer.EmployerName"
            >
              <td>{{ employer.EmployerRegistrationNum }}</td>
              <td>{{ employer.EmployerName }}</td>
              <td>{{ employer.EmployerAddress }}</td>
              <td>{{ employer.GuardsEmployed }}</td>
              <td>{{ employer.EmployementPeriod }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="table6 mb-2" style="border-style: solid">
        <h5>6.Employment Hours, Leaves and Amenities Details</h5>
        <br />
        <div style="margin-left: 3%">
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Daily Working Hours</b-col>
            <b-col md="5">{{ form.WorkingHours }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Overtime Charges</b-col>
            <b-col md="5">{{ form.OTRate }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Weekly Off</b-col>
            <b-col md="5">{{ form.Weeklyoff }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Paid Leave</b-col>
            <b-col md="5">{{ form.PaidLeave }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Paid Leaves</b-col>
            <b-col md="5">{{ form.PaidLeavesNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Paid Holidays</b-col>
            <b-col md="5">{{ form.PaidHolidays }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Paid Holidays</b-col>
            <b-col md="5">{{ form.PaidHolidayNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Accidental Insurance</b-col>
            <b-col md="5">{{ form.AccidentInsurance }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Travel Allowance</b-col>
            <b-col md="5">{{ form.LTA }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Travel Allowance Amount</b-col>
            <b-col md="5">{{ form.LTAAmount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">HRA</b-col>
            <b-col md="5">{{ form.HRA }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">HRA Amount</b-col>
            <b-col md="5">{{ form.HRAAmount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Bonus%</b-col>
            <b-col md="5">{{ form.Bonus }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Bonus Distribution</b-col>
            <b-col md="5">{{ form.BonusDistribution }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Medical Facilities</b-col>
            <b-col md="5">{{ form.MedicalFacilities }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Provident Fund</b-col>
            <b-col md="5">{{ form.EPF }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">PF Registration Number</b-col>
            <b-col md="5">{{ form.EPFRegNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Service Years for Gratuity</b-col>
            <b-col md="5">{{ form.GratuityYears }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">LIC Group Gratuity Scheme Number</b-col>
            <b-col md="5">{{ form.LICGratuityNo }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Uniform Given to Guard</b-col>
            <b-col md="5">{{ form.Uniform }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">ESIC Registration No</b-col>
            <b-col md="5">{{ form.ESIC }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Social /Other Benefits</b-col>
            <b-col md="5">{{ form.SocialBenefits }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Salry Paid By</b-col>
            <b-col md="5">{{ form.SalryPayer }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Salary Mode</b-col>
            <b-col md="5">{{ form.SalaryMode }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Salary Payment Day</b-col>
            <b-col md="5">{{ form.SalaryDay }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Name of Training Provider</b-col>
            <b-col md="5">{{ form.TrainerName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Training Days</b-col>
            <b-col md="5">{{ form.TrainingDays }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Union of Which Your Employees are Member</b-col>
            <b-col md="5">{{ form.UnionName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Reason for Non Police Verification</b-col>
            <b-col md="5">{{ form.ReasonNonVerification }}</b-col>
          </b-row>
        </div>
      </div>

      <div class="table4" style="border-style: solid">
        <h5>7.Bank Account Details of the Agency</h5>
        <br />
        <div class="mb-5" style="margin-left: 20px">
          <table class="my-2">
            <tr>
              <th>Bank Name</th>
              <th>Branch Name</th>
              <th>Bank Account Number</th>
            </tr>
            <tr v-for="bank in form.BankDetails1" :key="bank.BankAccountNumber">
              <td>{{ bank.BankName }}</td>
              <td>{{ bank.BranchName }}</td>
              <td>{{ bank.BankAccountNumber }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="my-2" style="border-style: solid">
        <h5>8.Documents</h5>
        <br />
        <div style="margin-left: 3%">
          <table class="table table-sm">
            <tr>
              <th>Document Type</th>
              <th>Link</th>
            </tr>
            <tr>
              <td>PSARA License</td>
              <td>
                <a target="_blank" :href="form.PSARALicenseCopy[0].content"
                  >View</a
                >
              </td>
            </tr>
            <tr>
              <td>Company Incorporation</td>
              <td>
                <a target="_blank" :href="form.CompanyIncorporation[0].content"
                  >View</a
                >
              </td>
            </tr>
            <tr>
              <td>PAN Card</td>
              <td>
                <a target="_blank" :href="form.PANCard[0].content">View</a>
              </td>
            </tr>
            <tr>
              <td>Provident Fund Registration Certificate</td>
              <td>
                <a target="_blank" :href="form.PFRegistration[0].content"
                  >View</a
                >
              </td>
            </tr>
            <tr>
              <td>ESIC Registration Certificate</td>
              <td>
                <a target="_blank" :href="form.ESICRegistration[0].content"
                  >View</a
                >
              </td>
            </tr>
            <tr>
              <td>Affidavit</td>
              <td>
                <a target="_blank" :href="form.BalanceSheets[0].content"
                  >View</a
                >
              </td>
            </tr>
            <tr>
              <td>Professional Tax Registration Certificate</td>
              <td>
                <a target="_blank" :href="form.PTRegistration[0].content"
                  >View</a
                >
              </td>
            </tr>
            <tr>
              <td>ST/GST Returns of Last 3 years</td>
              <td>
                <a target="_blank" :href="form.GSTReturns[0].content">View</a>
              </td>
            </tr>
            <tr v-if="form.EmployeeWelfare != '0'">
              <td>Employee Welfare Board Registration</td>
              <td>
                <a
                  target="_blank"
                  :href="form.EmpWelfareRegistration[0].content"
                  >View</a
                >
              </td>
            </tr>
            <tr>
              <td>
                Bank Account Proof (Cheque Book/Account Statement with Account
                and Name Details/Passbook)
              </td>
              <td>
                <a target="_blank" :href="form.BankAccountProof[0].content"
                  >View</a
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="table4" style="border-style: solid">
        <h5>9. Guard Mapping</h5>
        <br />
        <div class="mb-5" style="margin-left: 20px">
          <table class="my-2">
            <tr>
              <th>Registration Number</th>
              <th>Guard Name</th>
              <th>Employer Name</th>
            </tr>
            <tr v-for="guard in GuardMap" :key="guard.RegistrationNumber">
              <td>{{ guard.RegistrationNumber }}</td>
              <td>{{ guard.GuardName }}</td>
              <td>{{ guard.EmployerName }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import vueHtmlToPaper from "vue-html-to-paper";
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
export default {
  props: ["id"],
  data() {
    return {
      show: true,
      BoardName: "",
      DistrictName: "",
      RegistrationNumber: "",
      RegistrationNum: "",
      GuardName: "",
      EmployerName: "",
      PaymentDate: "",
      GuardMap: [
        {
          RegistrationNumber: "",
          GuardName: "",
          EmployerName: "",
        },
      ],

      form: {
        Board: "9424b61f-63d9-4390-8e99-a7db2f9a3339",
        AgencyName: "",
        AgencyAddress: "",
        City: "4194a540-b02b-43c2-91d6-2a7c34a2b98e",
        PinCode: 0,
        ContactNumber: "",
        EmailID: "",
        CompanyType: "",
        OwnerName: "",
        OwnerAddress: "",
        OwnerMobile: "",
        OwnerEmail: "",
        EstablishmentYear: null,
        ShopActRegNum: "",
        MaleEmployeeCount: 0,
        FemaleEmployeeCount: 0,
        PSRA: "",
        PsraLicenseDate: null,
        PsaraDueDate: null,
        PAN: "",
        GST: "",
        PTNum: "",
        EmployeeWelfare: "",

        SecurityGuardNonWeapon: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        SecurityGuardsWeapon: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        HeadSGNW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        HeadSGW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        SSNW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        SSW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        ASONW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        ASOW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        SQNW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        SOW: {
          XSvcMan: "0",
          Domiciled: "0",
          KasmirCtznext: "0",
          OtherCtzn: "0",
          Basic: "0",
          DA: "0",
          Allowance: "0",
          Total: "0",
        },
        PrincipalEmployers: [
          {
            EmployerRegistrationNum: "",
            EmployerName: "",
            EmployerAddress: "",
            GuardsEmployed: 1,
            EmployementPeriod: 1,
          },
        ],

        WorkingHours: 0,
        OTRate: "",
        Weeklyoff: "",
        PaidLeave: "",
        PaidLeavesNum: 0,
        PaidHolidays: "",
        PaidHolidayNum: 0,
        AccidentInsurance: "",
        LTA: "",
        LTAAmount: "",
        HRA: "",
        HRAAmount: 0,
        Bonus: 0,
        BonusDistribution: "",
        MedicalFacilities: "",
        EPF: "",
        EPFRegNum: "",
        GratuityYears: 0,
        Uniform: "",
        ESIC: "",
        SocialBenefits: "",
        MaternityLeave: false,
        SalryPayer: "",
        SalaryMode: "",
        SalaryDay: 0,
        Training: false,
        PoliceVerification: true,
        UnionName: "",
        BankName: "",
        BranchName: "",
        BankAccountNumber: "",
        PSARALicenseCopy: [
          {
            name: "",
            type: "application/pdf",
            content: "#",
          },
        ],
        CompanyIncorporation: [
          {
            name: "",
            type: "application/pdf",
            content: "#",
          },
        ],
        PANCard: [
          {
            name: "",
            type: "image/jpeg",
            content: "#",
          },
        ],
        PFRegistration: [
          {
            name: "",
            type: "image/jpeg",
            content: "#",
          },
        ],
        ESICRegistration: [
          {
            name: "",
            type: "image/jpeg",
            content: "",
          },
        ],
        BalanceSheets: [
          {
            name: "",
            type: "image/jpeg",
            content: "#",
          },
        ],
        PTRegistration: [
          {
            name: "",
            type: "image/jpeg",
            content: "#",
          },
        ],
        GSTReturns: [
          {
            name: "",
            type: "image/jpeg",
            content: "#",
          },
        ],
        EmpWelfareRegistration: [
          {
            name: "",
            type: "image/jpeg",
            content: "#",
          },
        ],
        BankAccountProof: [
          {
            name: "",
            type: "image/jpeg",
            content: "#",
          },
        ],
      },
    };
  },
  methods: {
    print() {
      window.print();
    },

    goback() {
      //this.$router.back();
      //alert(1);
      this.$router.push({
        name: "PrivateRegistration",
        params: {
          userDistrictID: this.$store.getters.getUserDistrict,
          userApprovalStatus: this.$store.getters.getAppStatus,
          userLoanType: 1,
        },
      });
    },
  },
  mounted() {
    let promise = apis.ReadRegistrationDetails(this.id, 13);

    promise
      .then((response) => {
        if (
          response.status == 200 &&
          response.body != "Error in Registration"
        ) {
          this.$store.dispatch("setRegID", response.body.RegistrationID);
          this.form = JSON.parse(response.body.FormDetails);

          if (!this.form.hasOwnProperty("EmployeeWelfare")) {
            this.form.EmployeeWelfare = "";
          }

          this.BoardName = response.body.BoardName;
          let current_datetime = new Date(response.body.PaymentDate);
          let formatted_date =
            current_datetime.getDate() +
            "-" +
            months[current_datetime.getMonth()] +
            "-" +
            current_datetime.getFullYear();
          this.PaymentDate = formatted_date;
          this.RegistrationNumber = response.body.RegistrationNumber;
          this.DistrictName = response.body.DistrictName;
          this.StateName = response.body.StateName;
        } else {
          this.form = {};
        }
      })
      .catch(err => {
        this.$dialog.alert("Error In Loading previous Details.");
      });

    let promisemap = apis.getAgencyGuardMapping(this.id);

    promisemap
      .then((response) => {
        if (response.status == 200) {
          this.GuardMap = response.body;
          this.isLoading = false;
        } else {
          this.alertText = response.body;

          this.variant = "warning";
          this.showDismissibleAlert = true;
        }
      })
      .catch((err) => {
        this.alertText = err.message;
        this.showDismissibleAlert = true;
        this.variant = "danger";
      });
  },
};
</script>
<style scoped>
table {
  font-family: arial, sans-serif;
  /* border-collapse: collapse; */
  width: 80%;
  margin-left: 10%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
}
.newfont {
  font-size: 0.875rem;
}
</style>