<template>
  <div class="container-fluid">
    <div class="panel-body">
      <b-container>
        <b-form @submit="onSubmit1" @reset="onReset">
          <div class="form-row">
            <b-col class="mt-3" md="12">
              <b-form-group
                style="width: 60%"
                id="grp2"
                label-cols="3"
                label="Select District Board *"
                label-size="sm"
                label-for="ipBoard"
              >
                <b-select
                  id="ipBoard"
                  :options="boards"
                  value-field="SecurityBoardID"
                  text-field="SecurityBoardName"
                  required
                  size="sm"
                  disabled
                  v-model="BoardID"
                  class="form-control"
                ></b-select>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-3">
              <b-form-group
                style="width: 60%"
                id="grp1"
                label-cols="3"
                label="Event Title *"
                label-size="sm"
                label-for="ipPoolType"
              >
                <b-form-input
                  id="ipPoolType"
                  class="form-control"
                  size="sm"
                  v-model="EventTitle"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </div>
          <div class="form-row">
            <b-col md="12" class="mt-3">
              <b-form-group
                style="width: 60%"
                id="grp1"
                label-cols="3"
                label="Description *"
                label-size="sm"
                label-for="ipPoolType"
              >
                <b-form-input
                  id="ipPoolType"
                  class="form-control"
                  size="sm"
                  v-model="discriptions"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                style="width: 60%"
                id="grp4"
                label-cols="3"
                label="No. of Posts *"
                label-size="sm"
                label-for="ipAppNum"
              >
                <b-form-input
                  type="number"
                  size="sm"
                  id="ipAppNum"
                  v-model="no_of_posts"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                style="width: 60%"
                id="grp4"
                label-cols="3"
                label="No. of Posts for Male *"
                label-size="sm"
                label-for="ipAppNum"
              >
                <b-form-input
                  type="number"
                  size="sm"
                  id="ipAppNum"
                  v-model="no_of_posts_for_male"
                />
              </b-form-group>
            </b-col>
          </div>
          <b-row>
            <b-col md="12" class="mt-1">
              <b-form-group
                style="width: 60%"
                id="grp4"
                label-cols="3"
                label="No. of Posts for Female *"
                label-size="sm"
                label-for="ipAppNum"
              >
                <b-form-input
                  type="number"
                  size="sm"
                  id="ipAppNum"
                  v-model="no_of_posts_for_female"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                style="width: 60%"
                id="grp4"
                label-cols="3"
                label="Application Start date *"
                label-size="sm"
                label-for="ipAppNum"
              >
                <!-- <b-form-datepicker v-model="value1" :min="min" :max="max" locale="en"></b-form-datepicker> -->
                <date-picker
                  format="DD-MM-YYYY hh:mm A"
                  type="datetime"
                  width="300px"
                  v-model="value1"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group
                style="width: 60%"
                id="grp4"
                label-cols="3"
                label="Application End date *"
                label-size="sm"
                label-for="ipAppNum"
              >
                <date-picker
                  format="DD-MM-YYYY hh:mm A"
                  type="datetime"
                  width="300px"
                  v-model="value2"
                ></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="form-row">
            <b-col md="12">
              <b-form-group
                style="width: 60%"
                id="grp1"
                label="Active *"
                label-cols="3"
                label-size="sm"
                label-for="ipPoolType"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="status"
                  name="checkbox-1"
                  style="align-right"
                  value="Active"
                  unchecked-value="In-active"
                >
                </b-form-checkbox>
              </b-form-group>
              <b-col md="3" style="width: 30%"></b-col>
              <b-col md="9"> </b-col>
            </b-col>
          </div>
          <div>
            <b-col md="12" class="mt-1">
              <b-form-group
                style="width: 60%"
                id="grp1"
                label-cols="3"
                label="Upload Job Description*"
                label-size="sm"
                label-for="ipPoolType"
              >
                <input
                  type="file"
                  class="form-control"
                  id="rfp_file_document"
                  ref="rfp_file_document"
                  v-on:change="handleFileUpload()"
                  placeholder
                  tabindex="7"
                />
              </b-form-group>
            </b-col>
          </div>
          <div class="container">
            <div class="vertical-center">
              <b-col class="mt-1" md="2">
                <b-button
                  type="submit"
                  size="xs"
                  variant="primary"
                  style="float: centre"
                  >Submit</b-button
                >
                &nbsp;
                <router-link
                  class="btn btn-primary btn-xs"
                  :to="{ name: 'Eventlist' }"
                  title="Back "
                  >Back</router-link
                >
              </b-col>
            </div>
          </div>
        </b-form>       
      </b-container>
    </div>
  </div>
</template>

<script>
import apis from "@/apis";
import lambda from "@/apis/lambda";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";

import DatePicker from "vue2-datepicker";

//import ApplicationFilter from "../components/ApplicationFilter.vue";
//import MarksEntry from "../components/MarksEntry.vue";
//alert(1);
export default {
  props: ["userBoardID", "EventTitle"],
  components: { DatePicker, Spinner, Loading },
  data() {
    return {
      isLoading: false,
      fullpage: false,
      showBtn: false,
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      pendingWithList: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      appDate: null,
      // EventTitle:"",
      value2: "",
      value1: "",
      status: true,
      no_of_posts_for_male: "",
      no_of_posts_for_female: "",
      no_of_posts: "",
      discriptions: "",
      rfp_file_document: "",
      BoardID: "",
      appStatus: "",
      genderList: "",
      // Board:"Amravati District Security Guards ",
      fields: [
        { key: "selected", label: "Selected" },
        { key: "Sr", label: "SrNo" },
        { key: "EventTitle", label: "Event Title", sortable: true },
        {
          key: "Createdate",
          label: "Application Date",
          sortable: true,
          formatter: "convertDateFormat",
        },
        { key: "EventTitle", label: "Event Title", sortable: true },
        { key: "EventTotalPosts", label: "Total Posts", sortable: true },
        { key: "EventMalePost", label: "Posts for Male", sortable: true },
        { key: "EventFemalePosts", label: "Posts for Female", sortable: true },
        {
          key: "ApplicationStartDt",
          label: "Application Start Date",
          sortable: true,
        },
        {
          key: "ApplicationEndDt",
          label: "Application End Date",
          sortable: true,
        },
        { key: "action", label: "View" },
      ],
      selected: [],
      form: {
        BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        EventTitle: "",
        EventDescription: "",
        EventTotalPosts: "",
        EventMalePosts: "",
        EventFemalePosts: "",
        ApplicationStartDt: "",
        ApplicationEndDt: "",
        IsActive: "",
        EventDetailsFile: "",
      },
      showbtn: false,
      formBSC: {
        BSCID: 0,
        BoardID: "",
        MeetingDate: null,
        ApprovalStatus: 13,
        ApprovalDoc: "",
        UserID: "",
        ApplicationIDs: [],
      },
      selectedApplication: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
      store_id: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    handleFileUpload() {
      this.rfp_file_document = this.$refs.rfp_file_document.files[0];
      // alert(this.rfp_file_document.name)
    },

    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
      convertDateString(inputDateStr) {
  const inputDate = new Date(inputDateStr);

  const datePart = inputDate.getDate().toString().padStart(2, '0');
  const monthPart = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const yearPart = inputDate.getFullYear().toString();
  let hoursPart = inputDate.getHours();
  let minutesPart = inputDate.getMinutes();
  let secondsPart = inputDate.getSeconds();
  let millisecondsPart = inputDate.getSeconds();
  
  // const ampm = hoursPart >= 12 ? 'PM' : 'AM';

  if (hoursPart === 0) {
    hoursPart = 12; // Adjust midnight to 12:00 AM
  } else if (hoursPart > 12) {
    hoursPart -= 12; // Convert hoursPart to 12-hour format
  }

  hoursPart = hoursPart.toString().padStart(2, '0');
  minutesPart = minutesPart.toString().padStart(2, '0');
  secondsPart = secondsPart.toString().padStart(2, '0');
  millisecondsPart = secondsPart.toString().padStart(3, '0');

    const formattedDate = `${yearPart}-${monthPart}-${datePart}T${hoursPart}:${minutesPart}:${secondsPart}.${millisecondsPart}Z`;

  return formattedDate;
},
    onSubmit1(evt) {
      //console.log();
      // var postfile = this.rfp_file_document;
      // var v1 = this.value1.split(" ");
      // var v2 = this.value2.split(" ");
      // var date1 = v1[0].split("-");
      // var date1 = date1[2] + "/" + date1[1] + "/" + date1[0] + " " + v1[1];
      // var date2 = v2[0].split("-");
      // var date2 = date2[2] + "/" + date2[1] + "/" + date2[0] + " " + v2[1];

    evt.preventDefault();
      this.isLoading=true;
      var postfile = this.rfp_file_document;

      var v1 = this.convertDateString(this.value1);
      var v2 = this.convertDateString(this.value2);

      //alert(value1);
      // alert(date2);
      var data = {
        EventID: "00000000-0000-0000-0000-000000000000",
        EventTitle: this.EventTitle,
        EventDescription: this.discriptions,
        EventTotalPosts: this.no_of_posts,
        EventMalePosts: this.no_of_posts_for_male,
        EventFemalePosts: this.no_of_posts_for_female,
        ApplicationStartDt: v1,
        ApplicationEndDt: v2,
        IsActive: false,
        BoardID: this.userBoard,
        EventDetailsFile: this.rfp_file_document.name,
      };
      this.isLoading=true;
      let promise = apis.neweventsubmit(data);
      promise
       .then((response) => {
              if (response.status == 200) {
                this.$dialog.alert("Pool Create Successfully..");
                // this.$router.push("/RegistrationList");
                 this.$router.push({
                  name: "Eventlist",
                  params: {
                  BoardID: this.BoardID, 
                  },
                });       
              }
            })
            .catch((err) => {
              this.$dialog.alert("Error while creating the record.");
            });
        // .then((response) => {
        //   this.isLoading=false;
        //   //console.log(response);
        //   if (response.status == 200) {
        //    this.$dialog.alert("Pool Create Succesfully..");
        //     this.$router.push({
        //           name: "eventlist",
        //           params: {
        //           BoardID : this.BoardID,
        //           },
        //         });
        //   } else {
        //     this.alertText = response.body;
        //     this.variant = "warning";
        //     this.showDismissibleAlert = true;
        //   }
        //   this.isLoading = false;
        // })
        // .catch((err) => {
        //   this.alertText = err.message;
        //   this.showDismissibleAlert = true;
        //   this.variant = "danger";
        //   this.isLoading = false;
        // });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.showDismissibleAlert = false;
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      this.formBatch.LoginID = LoginID;
      //console.log("SELECTED", this.selected);
      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array
        this.formBatch.EventID.push(this.selected[i].LoanApplicationID);
      }
      // this.selected.forEach(function(data, index) {
      //   this.formBatch.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });
      let promise = apis.createBatch(this.formBatch);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "";
            this.variant = "success";
            this.showDismissibleAlert = true;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      //this.getApplicationsSub();
    },
    onReset(evt) {},
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.showBtn = false;
      this.showDismissibleAlert = false;
      if (this.formBSC.ApprovalDoc == "") {
        this.alertText =
          "Approval File is not yet Uploaded. Please check the file icon before submit.";
        this.showDismissibleAlert = true;
        return;
      }
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      // this.formBSC.UserID = LoginID;
      // this.formBSC.BoardID = this.form.BoardID;
      //console.log("SELECTED", this.selected);
      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array
        this.formBSC.ApplicationIDs.push(this.selected[i].LoanApplicationID);
      }
      //console.log(this.formBSC);
      // this.selected.forEach(function(data, index) {
      //   this.form.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });
      this.isLoading=true;
      let promise = apis.UpdateBSCDetails(this.formBSC);
      promise
        .then((response) => {
          this.isLoading=false;
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "Data Saved Successfully";
            this.variant = "success";
            this.showDismissibleAlert = true;
            //this.ResetBSCForm();
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
            this.showBtn = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
          this.showBtn = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();
    },
    ResetBSCForm() {
      this.$refs.finp.reset();
      this.bscReportPath = "";
      // this.formBSC = {
      //   BSCID: 0,
      //   BoardID: "",
      //   MeetingDate: null,
      //   ApprovalStatus: 13,
      //   ApprovalDoc: "",
      //   UserID: "",
      //   ApplicationIDs: [],
    },
    deleteDocument(item, index, button) {
      //console.log(item);
      this.selecteObj.Document = item.Document;
      this.selecteObj.Path = item.Path;
      let info = "You want to delete record for:" + "\n" + item.Document;
      this.modalInfo = info;
      this.$root.$emit("bv::show::modal", "modalConfirm");
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.isLoadingFile = true;
      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1].toLowerCase();
      }
      if (extn != "jpeg" && extn != "png" && extn != "jpg" && extn != "pdf") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.finp.reset();
        this.isLoadingFile = false;
        return;
      }
      let dt = Date.now();
      var filetype = files[0].type;
      var filepath = "bsc_" + dt + "." + extn;
      let promisefoto = lambda.getSignedURL("msobcfdc_bsc", filepath, filetype);
      promisefoto
        .then((response) => {
          let signedUrl = response;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": files[0].type,
            },
          };
          return axios.put(signedUrl, files[0], options);
        })
        .then((res) => {
          if (res.status == 200) {
            //this.formBSC.ApprovalDoc = filepath;
            this.showBtn = true;
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoadingFile = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoadingFile = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        StartDate: null,
        EndDate: null,
        EventTotalPosts: null,
        EventMalePosts: "",
        EventFemalePosts: "",
      };
    },
    // props: ["userBoardID"],
    //components: { datetime, Spinner},
    mounted() {
      //alert(1);
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      // console.log(LoginID);
      // this.approvalStatus = this.$store.getters.getApprovalStatus;
      //this.boards = this.$store.getters.getBoards;
      this.BoardID = this.userBoard;

      // this.events = this.$store.getters.getEvents;
      let frm = this.$store.getters.getAppSearchForm;
      if (frm != {} && frm.ApplicationStatus != undefined) {
        this.form = frm;
        this.getApplications();
      }
    },
  },
  created() {
    //alert(1);
    var EventTitle = this.$route.params.EventTitle;
    var EventTotalPosts = this.$route.params.EventTotalPosts;
    var EventMalePosts = this.$route.params.EventMalePosts;
    var EventFemalePosts = this.$route.params.EventFemalePosts;
    var ApplicationStartDt = this.$route.params.ApplicationStartDt;
    var ApplicationEndDt = this.$route.params.ApplicationEndDt;
    var EventID = this.$route.params.EventID;
    var BoardID = this.$route.params.BoardID;
    var IsActive = this.$route.params.IsActive;
    var EventDetailsFile = this.$route.params.EventDetailsFile;
    var EventDescription = this.$route.params.EventDescription;

    this.BoardID = BoardID;
    this.EventTitle = EventTitle;
    this.discriptions = EventDescription;
    this.no_of_posts = EventTotalPosts;
    this.no_of_posts_for_male = EventMalePosts;
    this.no_of_posts_for_female = EventFemalePosts;
    this.value1 = ApplicationStartDt;
    this.value2 = ApplicationEndDt;
    this.status = IsActive;
    this.valrfp_file_documentue2 = EventDetailsFile;

    //   this.store_id = this.$route.params.id;
    //   this.EventTitle = this.$route.params.id;
    //   var boardname="";
    //    this.$store.getters.getBoards.forEach(item => {
    //      if(item.SecurityBoardID == this.$store.getters.getAppSearchForm.BoardID){
    //        boardname = item.SecurityBoardName;
    //      }
    //    });
    //    consloe.log(boardname);
    //   let frm = this.$store.getters.getAppSearchForm;
    //   if (frm != {} && frm.ApplicationStatus != undefined) {
    //     this.form = frm;
    //     this.getApplications();
    //   }
  },
};
</script>
<style>
.month-setter,
.nav-r,
.nav-l,
.year-month-wrapper,
.activePort {
  background-color: #03406c !important;
}
.days,
.okButton {
  color: #03406c !important;
}
.port:hover {
  color: #03406c !important;
}
.activePort:hover {
  color: white !important;
}
#tj-datetime-input {
  width: 250px !important;
}
</style>
