<template>
  <b-container style="font-size: 1.4rem">
    <div id="printBform">
      <img
        style="width: 140px; float: left; margin-top: 40px;margin-left:40px"
        src="@/assets/images/logo.jpg"
      />
      <b-row style="margin-left:40px">
        <b-col>
          <h5 style="margin-top: 40px; font-size: 1.4rem" align="center">
            <b>{{ form.BoardName }}</b>
          </h5>
          <P style="margin-bottom: 100px" align="center">{{
            form.BoardAddress
          }}</P>
        </b-col>
      </b-row>
      <!-- <hr style="background-color: black; color: black; border: solid 1px" /> -->
      <!-- <div style="box-sizing: border-box; border: 2px solid black">
        <b-row class="ml-2">
          <b-col sm="10"
            >Receipt.No :{{ form.AlltomentPreFixMarathi
            }}{{ form.AllotmentYear }}/{{ form.AllotmentNo }}</b-col
          >
          <b-col sm="2">Date :{{ form.PaymentDate }}</b-col>
        </b-row>

        <h6 class="mt-2" align="center" style="font-size: 1.1rem">
          <b><u>REGISTRATION RECEIPT (SECURITY GUARD)</u></b>
        </h6>
        <b-row style="text-align: center; padding: 20px">
          <b-col
            ><b
              >Registration No.:-{{ form.AlltomentPreFixMarathi
              }}{{ form.AllotmentYear }}/{{ form.AllotmentNo }}</b
            ></b-col
          >
        </b-row>
        <b-row class="ml-1" style="padding-top: 20px">
          <b-col
            >Received with thanks from<b> {{ form.FullName }}</b> a sum of
            Rs.100/ (Rupee one Hundred Only), through online payment, on account
            of Registration Fee.</b-col
          >
        </b-row>
        <div align="right" style="padding-top: 40px">
          <p style="margin-right: 10%"><b>Sd/-</b></p>
          <p style="width: 23%; text-align: center">
            <b>{{ form.BoardName }}</b>
          </p>
        </div>
      </div> -->
      <div class="my-3" style="box-sizing: border-box; border: 2px solid black; margin-left:50px;">
        <h6 class="my-3" align="center" style="font-size: 1.1rem">
          <b><u>REGISTRATION DETAILS</u></b>
        </h6>
        <b-row>
          <b-col sm="2"></b-col>
          <b-col sm="7">
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Application No.:</b-col>
              <b-col sm="3">{{ form.ApplicationNo }}</b-col>
              <b-col sm="6"
                >Application Date : {{ form.ApplicationDate }}</b-col
              >
            </b-row>
            <b-row class="ml-1" style="font-size: 1.1rem">
              <b-col sm="3"><b>Reg.No:</b></b-col>
              <b-col sm="3"
                ><b
                  >{{ form.AlltomentPreFixMarathi }}{{ form.AllotmentYear }}/{{
                    form.AllotmentNo
                  }}</b
                ></b-col
              >
              <b-col sm="6">Registration Date: {{ form.PaymentDate }}</b-col>
            </b-row>
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Name:</b-col>
              <b-col sm="9"
                ><b>{{ form.FullName }}</b></b-col
              >
            </b-row>
            <b-row class="ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Category:</b-col>
              <b-col sm="3" style="text-align: left"
                ><b>{{ form.Category }}</b></b-col
              >
            </b-row>
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Type:</b-col>
              <b-col style="text-align: left"
                ><b>{{ form.RegType }}</b>
                <span v-if="form.RegType=='PT'">&nbsp;({{ form.DeceasedName }})</span>
              <span v-else>{{ form.CompanyName }}</span> </b-col
              >
            </b-row>
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Date of Birth:</b-col>
              <b-col sm="3" style="text-align: left">{{ form.DOB }}</b-col>
            </b-row>
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Retirement Date:</b-col>
              <b-col>{{ form.RetirementDate }}</b-col>
            </b-row>
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Qualification:</b-col>
              <b-col style="text-align: left">{{ form.Qualification }}</b-col>
            </b-row>
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Identification Mark:</b-col>
              <b-col>{{form.IdentificationMark}}</b-col>
            </b-row>
            <b-row class="my-2 ml-1" style="font-size: 1.1rem">
              <b-col sm="3">Branch:</b-col>
              <b-col sm="3" style="text-align: left">{{
                form.Branch
              }}</b-col></b-row
            >
          </b-col>
          <b-col sm="3">
            <img style="width: 200px" :src="getImgUrl(form.PhotoPath)" /><br />
            <img
              style="width: 200px; height: 100px"
              :src="getImgUrl(form.SignaturePath)"
            />
            <p>Specimen Signature</p>
          </b-col>
        </b-row>
        <br />
        <b-row class="my-2 ml-1" style="font-size: 1.1rem">
          <b-col sm="2"></b-col>
          <b-col sm="4"
            ><b><u>Local Address</u></b
            >:</b-col
          >
          <b-col sm="6"
            ><b><u>Native Address</u></b
            >:</b-col
          >
        </b-row>
        <b-row class="my-2 ml-1" style="font-size: 1.1rem">
          <b-col sm="2"></b-col>
          <b-col sm="4">{{ form.CorrespondanceAddress }}</b-col>
          <b-col sm="6">{{ form.PermanentAddress }}</b-col>
        </b-row>
        <br />
        <!-- <b-row class="my-1 ml-1" style="font-size: 1.1rem">
          <b-col sm="2">State:</b-col>
          <b-col sm="4" style="text-align: left"><b>Maharashtra</b></b-col>
        </b-row> -->

        <br />

        <b-row class="my-1 ml-1" style="font-size: 1.1rem">
          <b-col sm="2"></b-col>
          <b-col sm="4"
            ><b><u>Nomination1</u></b
            >:</b-col
          >
          <b-col sm="6"
            ><b><u>Nomination2</u></b
            >:</b-col
          >
        </b-row>

        <b-row class="my-2 ml-1" style="font-size: 1.1rem">
          <b-col sm="2"></b-col>
          <b-col sm="3">{{ form.Nominee1 }}</b-col>
          <b-col sm="5">{{ form.Nominee2 }}</b-col>
        </b-row>
        <b-row class="my-2 ml-1" style="font-size: 1.1rem">
          <b-col sm="2"></b-col>
          <b-col sm="4">{{ form.Relation1 }}</b-col>
          <b-col sm="6">{{ form.Relation2 }}</b-col>
        </b-row>
        <br />
        <b-row class="mb-2 ml-1" align="center">
          <b-col>
            <qr-code :text="qrtext" :size="qrsize" error-level="H"> </qr-code>
          </b-col>
        </b-row>
      </div>

      <p class="ml-5" style="font-size: 1.2rem;margin-left:40px;">
        *This is Computer generated receipt, does not require signature
      </p>
    </div>

    <b-row>
      <b-col>
        <b-button class="float-right mt-2" variant="info" @click="print"
          >Print</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import apis from "@/apis";

export default {
  props: ["appid", "appuserid"],
  data() {
    return {
      userID: "00000000-0000-0000-0000-000000000000",
      form: {
        ApplicationID: "",
        ApplicationNo: "",
        UserID: "",
        FullName: "",
        Category: "",
        RegType: "",
        DOB: "",
        RetirementDate: "",
        Nominee1: "",
        Relation1: "",
        Nominee2: "",
        Relation2: "",
        CorrespondanceAddress: "",
        PermanentAddress: "",
        Branch: "",
        CompanyName: "",
        UserBFormID: "",
        AllotmentNo: "",
        BoardID: "",
        AllotmentPrefix: "",
        AlltomentPreFixMarathi: "",
        AllotmentYear: "",
        IsFeesPaid: "",
        EventTitle: "",
        BoardName: "",
        BoardAddress: "",
      },
      isLoading: false,
      qrtext: "",
      alertText: "",
      showDismissibleAlert: false,
      variant: "success",
      qrsize: 100,
    };
  },

  methods: {
    print() {
      this.$htmlToPaper("printBform");
    },
    getImgUrl(pic) {
      let userinfo = this.$store.getters.loggedUser;
      let userId = userinfo.UserId;
      if (pic) {
        if (pic.split(".").pop() == "pdf") {
          return require("@/assets/Pdf.jpg");
        }
        return (
          "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
          this.userID.toLowerCase() +
          "/" +
          pic
        );
      }
    },
  },
  mounted() {
    if (this.appid == undefined || this.appid == null) {
      return;
    }

    this.userID = this.appuserid;
    let promise = apis.ReadBFormDetails(this.appid);

    promise
      .then((response) => {
        //console.log(response);
        if (response.status == 200) {
          this.form = response.body[0];
          this.qrtext =
            "https://sgbregistration.in/#/summarybform/" +
            this.form.UserBFormID;
          this.isLoading = false;
        } else {
          this.alertText = response.body;

          this.variant = "warning";
          this.showDismissibleAlert = true;
        }
      })
      .catch((err) => {
        this.alertText = err.message;
        this.showDismissibleAlert = true;
        this.variant = "danger";
      });
  },
};
</script>

<style scoped>
</style>
