<template>
  <b-container>
    <div class="application-view">
      <div class="reg-form-box">
        <div class="reg-form-body">
          <b-row>
            <b-col cols="12">
              <!-- <h5 class="reg-form-title text-primary">Approval Log Details</h5> -->
              <b-row>
                <b-col cols="10" offset="1">
                  <h5 class="reg-form-title text-info">Approval History</h5>
                  <br />
                  <!-- {{approvalRemarks}} -->
                  <b-table
                    show-empty
                    selectable
                    select-mode="multi"
                    head-variant="dark"
                    empty-text="No Applications available."
                    stacked="md"
                    bordered
                    striped
                    :items="approvalRemarks"
                    :fields="approvalFields"
                    variant="dark"
                    style="font-size: 12px"
                  ></b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-col> </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="application-view">
      <b-row> </b-row>
    </div>
  </b-container>
</template>
<script>
import apis from "@/apis";
import DatePicker from "vue2-datepicker";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter.js";
import lambda from "@/apis/lambda";
import axios from "axios";

export default {
  components: { Spinner, DatePicker },
  props: ["UserID", "ApplicationStatus","Remarks"],

  data() {
    return {
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      logTbl: [],
      approvalRemarks: [],
      approvalFields: [
        "ApplicationStatus",
        "RejectionReason",
        "Remarks",
        {
          key: "LogDate",
          label: "Approve/Reject Date",
          formatter: "convertDateFormat",
        },
        "ApproverName",
      ],
      form: {
        UserID: "00000000-0000-0000-0000-000000000000",
        ApplicationID: "00000000-0000-0000-0000-000000000000",
        DocumentID: 0,
        OldPath: "",
        NewPath: "",
        Remarks: "",
      },
      isFileLoading: false,
      progressval: 0,
      DocTypes: [{ DocTypeID: 1, DocType: "Ration Card" }],
    };
  },
  methods: {
    
    onSubmit(evt) {
      evt.preventDefault();
      let promiseS = apis.SaveDocumentChangeLog(this.form);
      promiseS
        .then((response) => {
          if (response.status == 200) {
            this.logTbl = response.body;
            this.alertText = "File Changed Successfully";
            this.showDismissibleAlert = true;
            this.variant = "success";
            this.onReset(evt);
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form.DocumentID = 0;
      this.form.OldPath = "";
      this.form.NewPath = "";
      this.form.Remarks = "";
      this.$refs["fi1"].reset();
    },
  },
  mounted() {
    this.approvalRemarks=this.ApplicationStatus;
  },
};
</script>
<style scoped></style>
