<template>
  <b-container>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">EmployerRegistration</router-link>
        </li>
        <li
          class="breadcrumb-item active"
          style="font-size: 0.875rem"
          aria-current="page"
        >
           Application List
        </li>
      </ol>
    </nav>
    <hr />
    <employer-reg
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      @submit="onSubmit"
    ></employer-reg
    ><br />
    <hr />
    <br />
    <b-row class="mb-2">
      <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="filter"
            placeholder="Type to Search"
            size="sm"
          />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''" size="sm"
              >Clear</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="3">
        <p>Total Applications: {{ totalRows }}</p>
      </b-col>
      <b-col>
        <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
          @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
        <b-button
          v-if="
            showExtend &&
            (form.ApplicationStatus == 1 || form.ApplicationStatus == 2)
          "
          variant="warning"
          size="sm"
          @click="extendLoi"
          style="float: right; margin-right: 10px"
          >Payment Receipt</b-button
        >
      </b-col>
    </b-row>
      <loading :active.sync="isLoading" 
        color="#5dbbda"
        loader="spinner"
        :is-full-page="fullPage"
        width:2
        height:2
        ></loading>
    <b-table
      id="tblApplication"
      show-empty
      select-mode="multi"
      head-variant="dark"
      empty-text="No Applications available."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      style="font-size: 12px"
    >
     <template v-slot:cell(Docket)="row">
        <span>{{Docket_no(row.item.ExemptionApplicationNo)}}</span>
    </template>
      <template v-slot:cell(Sr)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click.stop="view_employer(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
        >
          <i class="fas fa-eye"></i>
        </b-button>
         <b-button v-if="
                    RoleID==9 ||
                    RoleID==7 ||
                    RoleID==8"
          size="sm"
          @click.stop="viewPrposal(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="primary"
        >
          <i class="fas fa-eye"></i>
        </b-button>
      </template>
       <!-- <template v-slot:cell(actions1)="row">
       
      </template> -->
      <!-- <template v-slot:cell(actions1)="rows1">
        <router-link
        style="margin: 6px"
          size="sm"
          :to="{name: 'ViewProposal',}"
          class="mr-1 pt-0 pb-0"
          variant="primary"
        >
          <i class="fas fa-eye"></i>
        </router-link>
      </template> -->
      <template v-slot:cell(Gender)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.Gender">
          <span>Male</span>
        </template>
        <template v-else>
          <span>Female</span>
        </template>
      </template>
      <template v-slot:cell(ExServiceMan)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.ExServiceMan">
          <span>Yes</span>
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>
      <!-- <template v-slot:cell(editDoc)="row">
        <b-button
          size="sm"
          @click.stop="editDocument(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="warning"
          v-b-tooltip.hover
          title="Edit Doc"
          v-if="isAdmin"
          >Docs</b-button
        >
      </template> -->
      <!-- <template v-slot:cell(marks)="row">
        <b-button
          size="sm"
          @click.stop="enterMarks(row.item, row.index, $event.target)"
          class="mr-1 pt-0 pb-0"
          variant="warning"
          v-b-tooltip.hover
          title="Edit Doc"
          v-if="row.item.ApplicationStatusID == 6"
          >Marks</b-button
        >
      </template> -->
    </b-table>
    <b-row>
      <b-col md="10" class="my-1">
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
          @change="ChangeEvent"
        />
      </b-col>
      <!-- <b-col></b-col> -->
      <b-col md="2" class="my-1">
        <b-form-group
          horizontal
          label="Per Page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
    </b-row>
     <b-modal
      ref="mdlEmployer"
      size="xl"
      hide-footer
      title="Principal Employer Profile"
    >
     <EmployerProfileVue :id="formEmployer.ID"></EmployerProfileVue>
      <ApprovalsVue  
        :ApprovalStatus="7" ></ApprovalsVue>
    
     
    </b-modal>

    <div></div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from "vuex";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import EmployerProfileVue from "../EmployerProfile.vue";
// import approvals from "../../components/Approvals.vue";
import ApprovalsVue from "../../../components/Approvals.vue";
import ExemptionFilter from "@/components/ExemptionFilter.vue";
import EmployerReg from "../../../components/EmployerReg.vue";



export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType"],
  components: { Spinner, ExemptionFilter, Loading, EmployerReg,EmployerProfileVue,ApprovalsVue},
  data() {
    return {
      showBtn: false,
      isLoading: false,
      fullpage: false,
      show: false,
      formEmployer:{
        ID:""
      },
      showsts: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      pendingWithList: [
        "All",
        "DM",
        "DBSC",
        "AGM Project",
        "GM",
        "MD",
        "LOI",
        "Lapsed",
        "Disbursed",
        "Hold",
        "Rejected",
      ],
      //approvalStatus: this.$store.getters.getApprovalStatus,
      approvalStatus: [],
      applications: [],
      appCount: 0,
      RoleID:"",
      appDate: null,
      fields: [
        {
          key: "ExemptionApplicationNo",
          label: "Application No",
          sortable: true,
        },
         { key: "Docket", label: "Docket No", sortable: true },
        // {
        //   key: "RegistrationNumber",
        //   label: "Registration No",
        //   sortable: true,
        // },

        // { key: "EntityName", label: "Entity Name", sortable: true },
        {
          key: "ApplicationStatus",
          label: "Application Status",
          sortable: true,
        },
        {
          key: "ExemptionApplicationDate",
          label: "Application Date",
          sortable: true,
        },
        { key: "actions", label: "view" },
        // { key: "actions1", label: "view" },
      ],
      form1: {},
      form: {
        BoardID: "",
        EventID: "bb033037-189c-4181-beac-7b3d60aa2bae",
        ApplicationStatus: 0,
        MobileNo: "",
        ApplicationNo: "",
        RegistrationNo: "",
        AgencyName: "",
        OffSet: 0,
        Limit: 10,
        Purpose: 0,
      },

      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    Docket_no(item)
    {
      //var temp=item.match(/.{1,3}/g);

      const [first, ...rest] = item.split('MUMX');
      rest[0] = Number(rest[0]).toString();
      return rest[0];
    },
    getdata() {
      this.isLoading = true;
      let promise = apis.getApprovalStatus();
      promise
        .then((response) => {
          this.isLoading = false;
          this.approvalStatus = response.body;
          this.approvalStatus.unshift({"ApplicationStatus": "ALL",
"Id": "0",
"IsReject": false,
"MISStatus": "Applied",
"NextStep": "Document Verification by Board",
"RoleType": 1,
"SMSTemplate": null,
"StepRank": 10})
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    onReset2(evt) {
      evt.preventDefault();
    },

    deleteDocument(item, index, button) {
      //console.log(item);
      this.selecteObj.Document = item.Document;
      this.selecteObj.Path = item.Path;

      let info = "You want to delete record for:" + "\n" + item.Document;
      this.modalInfo = info;
      this.$root.$emit("bv::show::modal", "modalConfirm");
    },
    modalCancel() {
      this.selecteObj.Document = "";
      this.selecteObj.Path = "";
      this.$root.$emit("bv::hide::modal", "modalConfirm");
    },

    modalConfirm() {
      this.form.UserLoanDocuments[this.selecteObj.Document] = "";

      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      //   var filename =   this.Photo.name;
      this.form.LoginID = LoginID;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      this.isLoadingFile = true;

      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1].toLowerCase();
      }

      if (extn != "jpeg" && extn != "png" && extn != "jpg" && extn != "pdf") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.finp.reset();
        this.isLoadingFile = false;
        return;
      }

      let dt = Date.now();
      var filetype = files[0].type;
      var filepath = "bsc_" + dt + "." + extn;
      let promisefoto = lambda.getSignedURL("msobcfdc_bsc", filepath, filetype);

      promisefoto
        .then((response) => {
          let signedUrl = response;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": files[0].type,
            },
          };
          return axios.put(signedUrl, files[0], options);
        })
        .then((res) => {
          if (res.status == 200) {
            this.formBSC.ApprovalDoc = filepath;
            this.showBtn = true;
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoadingFile = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoadingFile = false;
        });
    },
     view_employer(data) {
      this.formEmployer.ID = data.ExemptionApplicationID;
      this.$refs["mdlEmployer"].show();
    },
    // viewProfile(item) {
    //   this.$router.push({
    //     name: "",
    //     params: {
    //       ApplicationID: item.ExemptionApplicationID,
    //       ApplicationStatus: item.ExemptionApplicationStatus,
    //       isExemption: 1,
    //     },
    //   });
    // },
    viewPrposal(item) {
      this.$router.push({
        name: "ViewProposal",
        params: {
          ApplicationID: item.ExemptionApplicationID,
          ApplicationStatus: item.ExemptionApplicationStatus,
          EntityName: item.EntityName,
          isExemption: 1,
        },
      });
    },
    editDocument(item) {
      this.$router.push({
        name: "DocEdit",
        params: {
          UserID: item.UserID,
          ApplicationStatus: this.form.ApplicationStatus,
        },
      });
    },
    enterMarks(item, index, button, n) {
      this.$root.$emit("bv::show::modal", "mdlMarks");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onSubmit() {
      if (this.appDate != null) {
        this.form.StartDate = Formatter.convertDateFormatYMD(this.appDate[0]);
        this.form.EndDate = Formatter.convertDateFormatYMD(this.appDate[1]);
      } else {
        this.form.StartDate = "0001-01-01";
        this.form.EndDate = "0001-01-01";
      }
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      // this.form.Limit = 500;
      this.form.OffSet = 0;

      this.getApplicationsSub();
    },

    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getApplicationsSub();
    },

    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getApplicationsSub();
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },

    getApplicationsSub() {
      this.isLoading = true;
      this.showDismissibleAlert = false;
      this.$store.dispatch("setSearchFormExmpt", this.form);
      let promise = apis.ReadExemptionApplication(this.form);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.applications = response.body.Table;
            for (let i = 0; i < this.applications.length; i++) 
              {
               this.applications[i]['docket_no']=this.Docket_no(this.applications[i].RegistrationNumber);
              }
            this.totalRows = response.body.Table1[0].TotalRecords;
            // alert(response.body.Table1[0].TotalRecords);
            var userinfo = this.$store.getters.loggedUser;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: null,
        EndDate: null,
      };
    },

    convertToPdf() {
      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "ExemptionApplicationNo", title: "Application No" },
        {
          dataKey: "docket_no",
          title: "Docket No",
        },
        { dataKey: "EntityName", title: "Agency Name" },
        { dataKey: "ApplicationStatus", title: "Status" },
        { dataKey: "ExemptionApplicationDate", title: "Application Date" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchFormExmpt.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Exemption List ",
          pdf.internal.pageSize.getWidth() / 2,
          50,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            if (data.cell.section != "head") {
              switch (data.column.dataKey) {
                case "Createdate":
                  data.cell.text = vc.convertDateFormat(data.cell.text);
                  break;
                case "Gender":
                  if (data.cell.text == "false") {
                    data.cell.text = "Female";
                  } else {
                    data.cell.text = "Male";
                  }
                  break;
                case "ExServiceMan":
                  if (data.cell.text == "false") {
                    data.cell.text = "No";
                  } else {
                    data.cell.text = "Yes";
                  }
                  break;
              }
            }
          },
        }
      );
      pdf.save("Applications.pdf");
    },
    async downloadExcel() {
      var columns = [
        "Application No",
        "Docket No",
        // "Entity Name",
        "Status",
        "Application Date",
      ];

      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {
          "Application No": "",
          "Docket No": "",
          // "Entity Name": null,
          Status: "",
          "Application Date": "",
        };

        obj["Application No"] = element.ExemptionApplicationNo;
        obj["Docket No"] = this.Docket_no(element.RegistrationNumber);
        // obj["Entity Name"] = this.titleCase(element.EntityName);
        obj["Status"] = element.ApplicationStatus;
        obj["Application Date"] = element.ExemptionApplicationDate;

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  created() {
    this.getdata();
  },
  mounted() {
    this.form.BoardID = this.userBoard;
     var userinfo = this.$store.getters.loggedUser;


     
     this.RoleID = userinfo.RoleID;
    // alert(this.RoleID);
    let frm = this.$store.getters.getAppSearchFormExmpt;

    if (frm != {} && frm.ApplicationStatus != undefined) {
      this.form = frm;
      console.log( this.form)
      this.getApplications();
      this.currentPage=4;
    }
  },
};
</script>

<style></style>
