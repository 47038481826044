const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];
  
export default{
  convertDateFormat_batch(inDate) {

    let dbDateTime = new Date(inDate);
    if(dbDateTime.getFullYear()==1){
      return null;
    }
  
    let formattedDate =
      dbDateTime.getDate() +
      "-" +
      months[dbDateTime.getMonth()] +
      "-" +
      dbDateTime.getFullYear();
    return formattedDate;
  },
    convertDateFormat(inDate) {

        let dbDateTime = new Date(inDate);
        if(dbDateTime.getFullYear()==1){
          return null;
        }
      
        let formattedDate =
          dbDateTime.getDate() +
          "-" +
          months[dbDateTime.getMonth()] +
          "-" +
          dbDateTime.getFullYear();
        return formattedDate;
      },
      convertDateFormat1(inDate) {

        let dbDateTime = new Date(inDate);
        if(dbDateTime.getFullYear()==1){
          return null;
        }
      
        let formattedDate =
          dbDateTime.getDate() +
          "-" +
          dbDateTime.getMonth() +
          "-" +
          dbDateTime.getFullYear();
        return formattedDate;
      },
      convertDateFormatYMD_edit(inDate){
        let dbDateTime = new Date(inDate);
        let month =dbDateTime.getMonth();
        let date =dbDateTime.getDate();
        if(month<10)
        {
          month='0'+month;
        }
        if(date<10)
        {
          date='0'+date;
        }
        let formattedDate =  dbDateTime.getFullYear() + "-" + month + "-" + date;
        return formattedDate;
      },
      convertDateFormatYMD(inDate){
        let dbDateTime = new Date(inDate);
        let month =dbDateTime.getMonth()+1;
        if(month<10)
        {
          month='0'+month;
        }
        let formattedDate = dbDateTime.getFullYear() + "-" + month + "-" + dbDateTime.getDate();
        return formattedDate;
      },
      convertDateFormatYMDY(inDate){
        let dbDateTime = new Date(inDate);
        let month =dbDateTime.getMonth()+1;
        let date =dbDateTime.getDate();
        if(month<10)
        {
          month='0'+month;
        }
        if(date<10)
        {
          date='0'+date;
        }
        let formattedDate = dbDateTime.getFullYear() + "-" + month + "-" + date;
        return formattedDate;
      },
      convertDateFormatYMDWithTime(inDate){
        let dbDateTime = new Date(inDate);
        let month = dbDateTime.getMonth() + 1;
        let date = dbDateTime.getDate();
        let hours = dbDateTime.getHours();
        let minutes = dbDateTime.getMinutes();
        let seconds = dbDateTime.getSeconds();
    
        // Add leading zero if month, date, hours, minutes, or seconds is less than 10
        if(month < 10) month = '0' + month;
        if(date < 10) date = '0' + date;
        if(hours < 10) hours = '0' + hours;
        if(minutes < 10) minutes = '0' + minutes;
        if(seconds < 10) seconds = '0' + seconds;
    
        let formattedDate =   date + "-" + month + " " + dbDateTime.getFullYear() + "-" + hours + ":" + minutes + ":" + seconds;
        return formattedDate;
    },
      
      convertDateFormatYMD1(inDate){
        let dbDateTime = new Date(inDate);
        let month =dbDateTime.getMonth()+1;
        let date =dbDateTime.getDate();
        if(month<10)
        {
          month='0'+month;
        }
        if(date<10)
        {
          date='0'+date;
        }
        let formattedDate =  date + "-" + month + "-" + dbDateTime.getFullYear();
        return formattedDate;
      },
      convertDateFormatYM(inDate){
        let dbDateTime = new Date(inDate);
        var mm = "";
        if((dbDateTime.getMonth()+1) < 10){
          mm = "0" + (dbDateTime.getMonth()+1);
        }else{
          mm = (dbDateTime.getMonth()+1);
        }
        var dd = "";
        if(dbDateTime.getDate() < 10){
          dd = "0" +dbDateTime.getDate();
        }else{
          dd = dbDateTime.getDate();
        }
        let formattedDate = dbDateTime.getFullYear()+ "" + mm + "" + dd;
        return formattedDate;
      },
      filter_convertDateFormatYM(inDate,Type){
        if (Type==1) {
          return '20180101'
        }else{
          if (inDate) {
            
          
        
        let dbDateTime = new Date(inDate);
        var mm = "";
        if((dbDateTime.getMonth()+1) < 10){
          mm = "0" + (dbDateTime.getMonth()+1);
        }else{
          mm = (dbDateTime.getMonth()+1);
        }
        var dd = "";
        if(dbDateTime.getDate() < 10){
          dd = "0" +dbDateTime.getDate();
        }else{
          dd = dbDateTime.getDate();
        }
        let formattedDate = dbDateTime.getFullYear()+ "" + mm + "" + dd;
        return formattedDate;
      
      }else{
        let dbDateTime = new Date();
        var mm = "";
        if((dbDateTime.getMonth()+1) < 10){
          mm = "0" + (dbDateTime.getMonth()+1);
        }else{
          mm = (dbDateTime.getMonth()+1);
        }
        var dd = "";
        if(dbDateTime.getDate() < 10){
          dd = "0" +dbDateTime.getDate();
        }else{
          dd = dbDateTime.getDate();
        }
        let formattedDate = (dbDateTime.getFullYear()+1)+ "" + mm + "" + dd;
        return formattedDate;

      }
    }
    }
}