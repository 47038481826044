<template>
  <b-container>
    <Spinner size="32" message="Loading..." v-if="isLoading"></Spinner>
    <b-alert
      :show="showDismissibleAlert"
      dismissible
      @dismissed="showDismissibleAlert=false"
      :variant="variant"
    >{{alertText}}</b-alert>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-row class="justify-content-md-center">
        <b-col md="6" sm="12" class="mt-2">
          <div style="padding: 10px;border: 2px solid #c4c6be;border-radius: 15px;">
            <b-form-group
              id="igCrntPwd"
              horizontal
              label="Current Password"
              label-size="sm"
              label-for="inpCurrentPassword"
            >
              <b-form-input
                id="inpCurrentPassword"
                type="password"
                v-model="form.CurrentPassword"
                size="sm"
                placeholder="Current Password"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="igNewPwd"
              horizontal
              label="New Password"
              label-size="sm"
              label-for="inpNewPassword"
            >
              <b-form-input
                id="inpNewPassword"
                type="password"
                v-model="form.NewPassword"
                size="sm"
                placeholder="NewPassword"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="igCnfrmPwd"
              horizontal
              label="Confirm New Password"
              label-size="sm"
              label-for="inpConfirmNewPassword"
            >
              <b-form-input
                id="inpConfirmNewPassword"
                type="password"
                v-model="form.ConfirmNewPassword"
                size="sm"
                placeholder="ConfirmNewPassword"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-md-center">
        <b-col md="4" sm="12" style="text-align:center">
          <Spinner size="32" message="Loading..." v-if="isLoading"></Spinner>
          <b-alert
            :show="showDismissibleAlert"
            dismissible
            @dismissed="showDismissibleAlert=false"
            :variant="variant"
          >{{alertText}}</b-alert>
        </b-col>
        <b-col md="4" sm="12" style="text-align:right">
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import apis from "@/apis";
import * as _ from "lodash";
import Spinner from "vue-simple-spinner";
import axios from "axios";
import store from "@/store";

import lambda from "@/apis/lambda";

const _SheetJSFT = ["jpeg", "jpg", "png", "pdf"];
export default {
  components: { Spinner },
  data() {
    return {
      form: {
        ConfirmNewPassword: "",
        NewPassword: "",
        CurrentPassword: "",
        LoginID: ""
      },
      isValue: false,
      isSaved: false,
      show: true,
      alertText: "",
      showDismissibleAlert: false,
      variant: "success",
      isLoading: false
    };
  },
  methods: {
    format(value, event) {
      if (value) {
        return value.trim();
      }
    },
    onClickButton() {
      this.isSaved = false;
      this.showDismissibleAlert = false;
      this.$emit("clicked");
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.showDismissibleAlert = false;
      this.isLoading = true;
       if (
        this.form.NewPassword ===""
      ) {
        this.alertText = "Please enter new Password";
        this.variant = "warning";
        this.showDismissibleAlert = true;
        this.isLoading = false;
        return;
      }
       if (
        this.form.ConfirmNewPassword===""
      ) {
        this.alertText = "Please enter Confirm new Password";
        this.variant = "warning";
        this.showDismissibleAlert = true;
        this.isLoading = false;
        return;
      }
       if (
        this.form.CurrentPassword===""
      ) {
        this.alertText = "Please enter Current Password";
        this.variant = "warning";
        this.showDismissibleAlert = true;
        this.isLoading = false;
        return;
      }
      if (
        this.form.NewPassword !==
        this.form.ConfirmNewPassword
      ) {
        this.alertText = "New Password and Confirm Password should be same";
        this.variant = "warning";
        this.showDismissibleAlert = true;
        this.isLoading = false;
        return;
      }

      var userinfo = this.$store.getters.loggedUser;
      this.form.LoginID = userinfo.UserId;
      let promise = apis.saveConfirmPassword(this.form);

      promise
        .then(response => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "Password changed Please try login with new password";
            this.isSaved = true;
            this.variant = "success";
            this.showDismissibleAlert = true;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch(err => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    onReset() {},
  },
  computed: {
    getUserData() {
      let x = this.$store.getters.getUserData;
      let OrganizationName = "";
      let ProductfromOrganization = "";
      let OrganizationAddress = "";
      let AmountFromBank = 0;

      return {
        LockProfile: x.LockProfile
      };
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      // this.getUserBankDetails();
    }
  }
};
</script>