<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="form-row">
        <b-col class="mt-3" md="6">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              :disabled="
                value.BoardID != '00000000-0000-0000-0000-000000000000'
              "
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
       <b-col class="mt-3" md="6">
          <b-form-group
            id="grpPurpose"
            label-cols="2"
            label="Schedule For"
            label-size="sm"
            label-for="ipSched"
            v-if="value.Purpose != 0"
          >
            <b-select
              id="ipSched"
              :options="purposes"
              required
              size="sm"
              v-model="value.Purpose"
              class="form-control"
              @change="filterAppStatus"
            ></b-select>
          </b-form-group>
        </b-col>
      </div>
      <div class="form-row">
        <b-col md="6" class="mt-1">
          <b-form-group
            id="grp1"
            label-cols="2"
            label="Pool Type"
            label-size="sm"
            label-for="ipPoolType"
          >
            <b-select
              id="ipPoolType"
              class="form-control"
              value-field="EventID"
              size="sm"
              text-field="EventTitle"
              :options="events"
              v-model="value.EventID"
            ></b-select>
          </b-form-group>
        </b-col>
        <!-- <b-col class="mt-1" md="4">
          <b-form-group  
            id="grp3"
            label-cols="3"
            label="Appln. Status"
            label-size="sm"
            label-for="ipAPSts"
          >
            <b-select
              id="ipAPSts"
              class="form-control"
              :options="appStatus"
              value-field="Id"
              text-field="ApplicationStatus"
              required
              size="sm"
              v-model="value.ApplicationStatus"
            ></b-select>
          </b-form-group>
        </b-col> -->
        <!-- <b-col class="mt-1" md="4">
          <b-form-group
            id="grp3"
            label-cols="3"
            label="Appln. Status"
            label-size="sm"
            label-for="ipAPSts"
          >
            <b-select
              id="ipAPSts"
              class="form-control"
              :options="appStatus"
              value-field="Id"
              text-field="ApplicationStatus"
              required
              size="sm"
              v-model="value.ApplicationStatus"
            ></b-select>
          </b-form-group>
        </b-col> -->
        <b-col class="mt-2" md="4">
          <b-form-group
          
            id="grp3"
            label-cols="3"
            label="Appln. Status"
            label-size="sm"
            label-for="ipAPSts"
            description="To Select All options, keep this field blank."
          >
  <!-- <label class="typo__label">Appln. Status</label> -->
                      <multiselect 
                      id="ipAPSts"
                      placeholder="App status" 
                      text-field="ApplicationStatus"
                      value-field="Id"
                      size="sm" 
                      :multiple="true" 
                      :taggable="true" 
                      v-model="value.selectValue"
                      @input="filter"
                      label="ApplicationStatus"
                      track-by="Id" 
                      :options="appStatus"
                      >
  
  </multiselect>
   </b-form-group>
  <!-- <b-select class="language-json"><code>{{ value.appStatus  }}</code></b-select> -->
</b-col>

        <!-- //tushar start -->
        <!-- //<b-col class="mt-1" md="4"> -->
        <!-- <b-form-group v-show="name=='IntimatePoliceMedical'"
            id="grpGndr"
            label-cols="4"
            label="Result Published"
            label-size="sm"
            label-for="ipgndr"
          > -->
        <!-- <b-select
              id="ipgndr"
              class="form-control"
              :options="appStatus"
              value-field="GenderID"
              text-field="ApplicationStatus"
              required
              size="sm"
              v-model="value.Gender"
            ></b-select> -->
        <!-- <b-select
              id="ipgndr"
              type="radio"
              class="form-control"
              :options="ExSvcOptions"
              value-field="Id"
              text-field="EvaluationStatus"
              required
              size="sm"
              v-model="value.EvaluationStatus"
            ></b-select> -->
        <!-- {{value.Value}}
           </b-form-group>
        </b-col> -->

        <!-- tushar end -->

        <b-col class="mt-1" md="2">
          <b-form-group
            id="grpGndr"
            label-cols="4"
            label="Gender"
            label-size="sm"
            label-for="ipgndr"
          >
            <b-select
              id="ipgndr"
              class="form-control"
              :options="genderList"
              value-field="GenderID"
              text-field="Gender"
              required
              size="sm"
              v-model="value.Gender"
            ></b-select>
          </b-form-group>
        </b-col>
      </div>
      <b-row>
        <b-col md="6" class="mt-1">
          <b-form-group
            id="grpEmp"
            label-cols="2"
            label="Employer Name"
            label-size="sm"
            label-for="ipEmpNum"
            description="Please check the results thoroughly as search word 'adani' will also search for 'hiranadani'."
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              v-model="value.EmployerName"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1">
          <b-form-group
            id="grpflie"
            label-cols="5"
            label="Upload Application Number List (.xlsx file)"
            label-size="sm"
            label-for="ipappnumlist"
            description="Do not keep header and first column must be Application number."
          >
            <b-form-file
              v-model="fileAppnum"
              id="ipappnumlist"
              ref="ipappnumlist"
              size="sm"
              placeholder=".xlsx file"
              @change="onFileChange"
            ></b-form-file>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3" class="mt-1">
          <b-form-group
            id="grp4"
            label-cols="4"
            label="Application No"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.ApplicationNo"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" class="mt-1">
          <b-form-group
            id="grp4"
            label-cols="2"
            label="Mobile"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.MobileNo"
            />
          </b-form-group>
        </b-col>
        <!-- 5443{{value }}1234 -->
        <b-col md="2" class="mt-1">
          <b-form-group
            v-show="value.pagename2 != 'Gen'"
            id="grpXSvc"
            label-cols="6"
            label="Ex-Serviceman"
            label-size="sm"
            label-for="ipXSvc"
          >
            <b-select
              id="ipgndr"
              class="form-control"
              :options="ExSvcOptions"
              value-field="Id"
              text-field="Value"
              required
              size="sm"
              v-model="value.ExServiceMan"
            ></b-select>
          </b-form-group>
        </b-col>
        <b-col md="2" class="mt-1">
          <b-form-group
            id="grpPin"
            label-cols="2"
            label="PIn"
            label-size="sm"
            label-for="ipPin"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipPin"
              v-model="value.PinCode"
              min="100000"
              max="999999"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="2">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            >Search</b-button
          >
          <!-- <b-button
            variant="link"
            size="sm"
            v-b-modal.mdlAdvSearch
            style="font-size: 0.8em"
          >
            Advance Search
            <i class="fa fa-caret-square-down"></i>
          </b-button> -->
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>

        <!-- <b-col md="2" >
        <b-button
          type="submit"
          size="sm"
          variant="success"
          style="float: left"
         
          v-if="name!='Applications'"
          @click="go_next()"

        >
         Send Intimation Letter
        </b-button>
      </b-col> -->
      </b-row>
      <b-row>
         <b-col md="3" class="mt-1">
          <b-form-group
            id="grp4"
            label-cols="4"
            label="Registration No"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.RegistrationNo"
            />
          </b-form-group>
        </b-col>
           </b-row>
    </b-form>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import XLSX from "xlsx";
import _ from "lodash";
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect ,DatePicker },
  props: ["boards", "events", "approvalStatus", "value", "name"],

  data() {
    return {
      ApplicationStatus:'',
      selectValue:'',
    
      form: this.value || {},
      purposes: [
        { value: 1, text: "Document Verification" },
        { value: 2, text: "Document Verification and Physical Test" },
        { value: 3, text: "Physical Test" },
        { value: 4, text: "Physical and Ground Test" },
        { value: 5, text: "Ground Test" },
        { value: 6, text: "Character and Medical Certificate Verification" },
      ],
    
      fileAppnum:null,
      ExSvcOptions: [
        { Id: 0, Value: "No" },
        { Id: 1, Value: "Yes" },
        { Id: 3, Value: "All" },
      ],
      appDate: null,
      name_status: "",
      name_data: "",
      appStatus: [
        //{ Id: 0, Value: "All" },
        { Id: 1, Value: "Yes" },
        { Id: 0, Value: "No" },
      ],
      appStatusAll: [],

      genderList: this.$store.getters.getGenderList,
    };
  },
  mounted() {
    if (this.value.Purpose == 0) {
      //alert(this.appStatus);
      this.appStatus = this.approvalStatus;
    } else {
      this.filterAppStatus(this.value.Purpose);
    }
    if (this.events[0].EventTitle == "All") {
      this.events.shift();
    }
    if (this.name[0].name == "Applications") {
      this.name_status = true;
    }
  },

  methods: {
   filter(data){
    const statusesToCheck = ["All"];
    const allStatusesPresent = statusesToCheck.every(ApplicationStatus => data.some(item => item.ApplicationStatus === ApplicationStatus));
    //console.log(allStatusesPresent);
    if (allStatusesPresent) 
    {
      //console.log("All status values are present in the JSON array.");
      this.form.selectValue=[];
      this.form.selectValue=this.appStatus;
      
    } 
   },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    filterAppStatus(purposeid) {
      this.value.selectValue = [];
      this.appStatus = [];
    
      for (var i = 0; i < this.approvalStatus.length; i++) {
        if (
          this.approvalStatus[i].StatusPurposeID == purposeid ||
          this.approvalStatus[i].StatusPurposeID == 0
        )
          this.appStatus.push(this.approvalStatus[i]);
      }
      this.appStatusAll = this.appStatus;
      this.$emit("change");
    },


    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1];
      }

      if (extn != "xlsx") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.ipappnumlist.reset();
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(new Uint8Array(ab), { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        this.value.ApplicationNumberList = JSON.stringify(_.flattenDeep(data));

        // console.log(this.value.ApplicationList);
      };
      reader.readAsArrayBuffer(files[0]);
    },

    onSubmit(evt) {
      this.isloading=true;
      // alert(this.app_status);
      evt.preventDefault();
      //evt.preventDefault()
      this.$emit("submit");
      //this.GetResults1();

      let board = this.form.EventID;
      this.form = {
        EventID: "00000000-0000-0000-0000-000000000000",
        EvaluationStatus: 0,
        Gender: 0,
      };
       this.isloading=false;
    },

    onReset1(evt) {
     
      evt.preventDefault();

      this.$emit("reset");
      let board = this.form.EventID;
      this.form = {
        BoardID: board,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: 3,
        ExServiceMan: 3,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: null,
        EndDate: null,
        PinCode: null,
      };
    },
   onReset(evt) {
    evt.preventDefault();
    this.value.selectValue=[];
    this.value.ApplicationStatus=0;
    this.value.Gender=3;
    this.value.EmployerName="";
    this.value.ApplicationNo="";
    this.value.MobileNo="";
    this.value.ExServiceMan=3;
    this.value.PinCode=null;
    this.value.RegistrationNo="";
    this.fileAppnum="";
    //this.value.Purpose="";
    this.value.EventID="00000000-0000-0000-0000-000000000000";
   
    },
  },
  created() {
    //console.log(this.name);
    //this.name_data = this.name[0].name;
    //alert(this.name_data.name);
    // console.log(this.name[0]);
    // console.log(this.$store.getters.getGenderList);
  },
};
</script>
<style scoped>
.multi-select {
  height: auto;
  max-height: 120px; /* Set your desired maximum height here */
  overflow-y: auto;
}
</style>
