<template>
  <b-container>
    <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="#">Guard Registration</a></li>
    <li class="breadcrumb-item active" aria-current="page">Merit List</li>
  </ol>
</nav>
    <Meritfilter
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      @submit="onSubmit"
    ></Meritfilter>
    <hr />

    <div
      class="modal"
      id="add"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel1"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header"></div>
          <div class="modal-body" style="text-align: center"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-info"
              data-dismiss="modal"
              @click="close()"
            >
              Close
            </button>
          </div>
        </div>
      </div>

      <b-modal id="mdlMarks" size="xl">
        <viewshow></viewshow>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import ApplicationFilter from "@/components/ApplicationFilter.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import apis from "@/apis";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Formatter from "@/Formatter.js";
import Meritfilter from "@/components/Meritfilter.vue";
import viewshow from "@/components/viewshow.vue";

export default {
  components: { Meritfilter, viewshow, DatePicker },
  data() {
    return {
      form: {
        BoardID: "this.userBoard",
        EventID: "",
        ApplicationStatus: 0,
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: "01-01-1970",
        EndDate: "01-01-1970",
        ExServiceMan: 3,
        PinCode: null,
        OffSet: 0,
        Limit: 10,
        Purpose: 1,
        Merit: "Merit",
        value: "",
      },
      batchDate: null,
      batchTime: "11:00",
      formBatch: {
        BatchName: "",
        BatchNumber: 0,
        BatchSize: 1,
        BatchTime: "11:00",
        BatchVenue: "",
        BatchDate: null,
        JobId: "00000000-0000-0000-0000-000000000000",
        SendSMS: 0,
        SchedulePurpose: 1,
        UserID: "00000000-0000-0000-0000-000000000000",
        pagename: "Merit",
      },
      boards: this.$store.getters.getBoards,
      approvalStatus: this.$store.getters.getApprovalStatusPurpose,
      events: [],
      totalApplications: 0,
      minuteSteps: 30,
      showSeconds: false,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
    };
  },
  computed: {
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    // View_data(item, index, button, n) {
    //   //alert(JSON.stringify(item));
    //   // this.selectedApplication = item.UserAppllicationID;
    //   this.$root.$emit("bv::show::modal", "mdlMarks");
    // },
    Reset_details() {
      this.Pass_Candidate = "";
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
    onSubmitBatch(evt) {
      evt.preventDefault();
      this.isLoading = true;
      this.formBatch.BatchDate = Formatter.convertDateFormatYMD(this.batchDate);
      this.formBatch.BatchTime = this.batchTime;
      this.formBatch.JobId = this.form.EventID;
      this.formBatch.ApplicationStatus = this.form.ApplicationStatus;
      this.formBatch.Gender = this.form.Gender;
      this.formBatch.MobileNo = this.form.MobileNo;
      this.formBatch.ApplicationNo = this.form.ApplicationNo;
      this.formBatch.SchedulePurpose = this.form.Purpose;
      this.formBatch.ExServiceman = this.form.ExServiceMan;
      this.formBatch.PinCode = this.form.PinCode;

      let promise = apis.createBatch(this.formBatch);

      promise
        .then((response) => {
          if (response.status == 200) {
            var pdf = new jsPDF("p", "pt");
            var columns = [
              { dataKey: "SrNo", title: "Sr No" },
              { dataKey: "ApplicationNo", title: "Application No" },
              { dataKey: "FullName", title: "Name of Applicant" },
              { title: "Signature" },
            ];

            pdf.setFontSize(12);
            pdf.text(
              "SECURITY GUARDS BOARD".toUpperCase(),
              pdf.internal.pageSize.width / 2,
              30,
              {
                align: "center",
              }
            );

            pdf.setFontSize(10);
            pdf.text(
              "Document Verification Attendance Sheet",
              pdf.internal.pageSize.width / 2,
              45,
              {
                align: "center",
              }
            );

            pdf.text(
              "Batch Name : " + this.formBatch.BatchName,
              pdf.internal.pageSize.width / 2,
              55,
              {
                align: "center",
              }
            );
            pdf.text(
              "Batch Time : " +
                this.convertDateFormat(this.formBatch.BatchDate) +
                " " +
                this.formBatch.BatchTime,
              pdf.internal.pageSize.width / 2,
              65,
              {
                align: "center",
              }
            );
            var vc = this;
            pdf.autoTable(columns, response.body, {
              startY: 75,
              tableLineColor: [189, 195, 199],
              tableLineWidth: 0.75,
              didParseCell(data) {
                if (data.cell.section != "head") {
                  switch (data.column.dataKey) {
                    case "Createdate":
                      data.cell.text = vc.convertDateFormat(data.cell.text);
                      break;
                  }
                }
              },
            });
            pdf.save("BatchAttendance_" + vc.formBatch.BatchNumber + ".pdf");
          } else {
            this.showDismissibleAlert = true;
            this.variant = "danger";
            this.alertText = "Error while creating Batch!!";
          }
        })
        .catch((err) => {
          console.error(err);
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.alertText = "Error while creating Batch!!";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();
    },
    onSubmit() {
      //evt.preventDefault();
      this.getApplicationsSub();
    },
    getApplicationsSub() {
      this.isLoading = true;
      this.showDismissibleAlert = false;

      let promise = apis.readApplicationList(this.form);

      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.totalApplications = response.body.Table[0].TotalRecords;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
  },
  mounted() {
    //this.approvalStatus = this.$store.getters.getApprovalStatusPurpose;

    //this.boards = this.$store.getters.getBoards;
    this.form.BoardID = this.userBoard;

    for (var i = 1; i < this.$store.getters.getEvents.length; i++) {
      this.events.push(this.$store.getters.getEvents[i]);
    }
    this.Reset_details();
  },
};
</script>