<template>
  <div class="container-fluid">
   
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-info">
         
          <div class="p-3 mb-2 bg-secondary text-white"><b>MERIT LIST :</b></div>
          <div class="panel-body">
            <b-container>
              <b-form @submit="onSubmit1" @reset="onReset">
                <div class="form-row">
                  <b-col class="mt-3" md="12">
                    <b-form-group
                      style="width: 60%"
                      id="grp2"
                      label-cols="3"
                      label="Board*"
                      label-size="sm"
                      label-for="ipBoard"
                    >
                      <b-select
                        id="ipBoard"
                        :options="boards"
                        value-field="SecurityBoardID"
                        text-field="SecurityBoardName"
                        required
                        size="sm"
                        :disabled="
                          value.BoardID !=
                          '00000000-0000-0000-0000-000000000000'
                        "
                        v-model="value.BoardID"
                        class="form-control"
                      ></b-select>
                    </b-form-group>
                  </b-col>
                </div>
                <div class="form-row">
                  <b-col class="mt-2" md="12">
                    <b-form-group
                      id="grp1"
                      style="width: 60%"
                      label-cols="3"
                      label="Pool Type*"
                      label-size="sm"
                      label-for="ipPoolType"
                    >
                      <b-select
                        id="ipPoolType"
                        class="form-control"
                        value-field="BoardID"
                        size="sm"
                        text-field="EventTitle"
                        :options="events"
                        
                        :disabled="
                          value.BoardID !=
                          '00000000-0000-0000-0000-000000000000'
                        "

                        v-model="value.BoardID"
                      ></b-select>
                    </b-form-group>
                  </b-col>
                </div>
                <div class="row">
                  <div class="col-md-12"></div>
                </div>

                <div class="form-row">
                  <b-col md="12" class="mt-4">
                    <b-form-group
                      style="width: 64%"
                      id="grp1"
                      label-cols="3"
                      label="Pass Candidate *"
                      label-size="sm"
                      label-for="ipPoolType"
                      
                    >
                      <div class="row" >
                        
                        <label  for="inputWeb3" class="col-sm-1 control-label"
                        
                          >All:-</label
                        >
                         <div class="col-sm-3">
                          <!-- <input
                            type=""
                            v-model="all_candidate"
                            class="form-control"
                            disabled
                          /> -->
                        </div> 

                        <label for="inputWeb3" class="col-sm-1 control-label"
                          >Male:-</label
                        >
                        <div class="col-sm-3">
                          <!-- <input
                            type="text"
                            v-model="no_of_posts"
                            class="form-control"
                            disabled
                          /> -->
                        </div>

                        <label for="inputWeb3" class="col-sm-1 control-label"
                          >Female:-</label
                        >
                        <div class="col-sm-3">
                          <!-- <input
                            type="text"
                            v-model="no_of_posts1"
                            class="form-control"
                            disabled
                          /> -->
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                </div>

                <div class="form-row"></div>
                <div class="form-row">
                  <b-col md="12" class="mt-3">
                    <b-form-group
                      style="width: 60%"
                      id="grp1"
                      label-cols="3"
                      label="Generate Merit*"
                      label-size="sm"
                      label-for="ipPoolType"
                    >
                      <b-form-group
                        style="width: 60%"
                        id="grp1"
                        label-cols="2"
                        label="Male *"
                        label-size="sm"
                        label-for="ipPoolType"
                      >
                        <!-- <b-form-input
                  
                  size="sm"
                  id="ipAppNum"
                  v-model="no_of_posts"
                  autocomplete="off"
                /> -->
                        <b-form-group>
                          <input
                            type="radio"
                            class="check"
                            v-model="selectAll"
                            v-bind:value="'All'"
                            id="square-radio-6"
                            name="square-radio6"
                            data-radio="iradio_square-red"
                            checked
                          />
                          <label for="square-radio-6">All</label>&nbsp;
                          <input
                            type="radio"
                            class="check"
                            id="square-radio-7"
                            v-model="selectAll"
                            v-bind:value="'Select'"
                            name="square-radio6"
                            data-radio="iradio_square-red"
                          />
                          <label for="square-radio-7">Selected</label>&nbsp;

                          <input
                            type="number"
                            v-model="no_of_posts5"
                            autocomplete="off"
                            v-if="selectAll == 'Select'"
                            v-on:keyup="show_ta1()"
                          />

                          <table v-if="showtable == true">
                            <thead>
                              <th>category</th>
                              <th>Min Seats</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>OPEN</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>OBC</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>SC/ST</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>VJNT</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </b-form-group>
                      </b-form-group>
                      <b-form-group
                        style="width: 60%"
                        id="grp1"
                        label-cols="2"
                        label="Female*"
                        label-size="sm"
                        label-for="ipPoolType"
                      >
                        <!-- <b-form-input
                  
                  size="sm"
                  id="ipAppNum"
                  v-model="no_of_posts1"
                  autocomplete="off"
                /> -->
                        <b-form-group>
                          <input
                            type="radio"
                            class="check"
                            v-model="FemaleselectAll"
                            v-bind:value="'FAll'"
                            id="square-radio-1"
                            name="square-radio1"
                            data-radio="iradio_square-red"
                            checked
                          />
                          <label for="square-radio-1">All</label>&nbsp;
                          <input
                            type="radio"
                            class="check"
                            id="square-radio-2"
                            v-model="FemaleselectAll"
                            v-bind:value="'FSelect'"
                            name="square-radio2"
                            data-radio="iradio_square-red"
                          />
                          <label for="square-radio-2">Selected</label>&nbsp;

                          <input
                            type="number"
                            v-model="no_of_posts3"
                            autocomplete="off"
                            v-if="FemaleselectAll == 'FSelect'"
                            v-on:keyup="show_ta2()"
                          />
                          <table v-if="showtable1 == true">
                            <thead>
                              <th>category</th>
                              <th>Min Seats</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>OPEN</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>OBC</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>SC/ST</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>VJNT</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </b-form-group>
                      </b-form-group>
                    </b-form-group>
                  </b-col>
                  <div class="container">
                    <div class="vertical-left">
                      <b-col class="mt-1" md="3">
                        <b-button
                          type="submit"
                          @click.stop="Submit()"
                          size="xs"
                          variant="primary"
                          style="float: left"
                          >Save</b-button
                        >
                        
                &nbsp;
                <router-link
                  class="btn btn-primary btn-xs"
                  :to="{ name: 'GUARD REGISTRATION' }"
                  title="Back "
                  >Back</router-link
                >
                      </b-col>
                    </div>
                  </div>
                  <!-- <input
                  type="file"
                  class="form-control"
                  id="rfp_file_document"
                  ref="rfp_file_document"
                  v-on:change="handleFileUpload()"
                  placeholder
                  tabindex="7"
                /> -->
                  <!-- </b-form-group> -->
                </div>

                <!-- <button
            type="button"
            class="btn btn-primary"
            v-if="disable_button == true"
          >
            Generate Merit
          </button> -->
              </b-form>
            </b-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import apis from "@/apis";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";

import DatePicker from "vue2-datepicker";

//import ApplicationFilter from "../components/ApplicationFilter.vue";
//import MarksEntry from "../components/MarksEntry.vue";
//alert(1);
export default {
  // props: ["userBoardID", "EventTitle"],
  props: ["value"],
  components: { DatePicker, Spinner },
  data() {
    return {
      form: this.value || {},
      ExSvcOptions: [
        { Id: 0, Value: "All" },
        { Id: 1, Value: "Yes" },
        { Id: 3, Value: "No" },
      ],

      showBtn: false,
      selectAll: "",
      FemaleselectAll: "",
      select: "",
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      pendingWithList: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      appDate: null,
      all_candidate: "",
      showtable: false,
      showtable1: false,
      // EventTitle:"",
      value2: "",
      value1: "",
      status: true,
      no_of_posts_for_male: "",
      no_of_posts_for_female: "",
      no_of_posts: "",
      no_of_posts1: "",
      no_of_posts4: "",
      no_of_posts2: "",
      no_of_posts3: "",
      discriptions: "",
      rfp_file_document: "",
      BoardID: "",
      no_of_posts5: "",
      appStatus: "",
      disable_button: false,
      genderList: this.$store.getters.getGenderList,

      selected: [],
      form: {
        BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        EventTitle: "",
        EventDescription: "",
        EventTotalPosts: "",
        EventMalePosts: "",
        EventFemalePosts: "",
        ApplicationStartDt: "",
        ApplicationEndDt: "",
        IsActive: "",
        EventDetailsFile: "",
      },
      showbtn: false,
      formBSC: {
        BSCID: 0,
        BoardID: "",
        MeetingDate: null,
        ApprovalStatus: 13,
        ApprovalDoc: "",
        UserID: "",
        ApplicationIDs: [],
      },
      selectedApplication: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
      store_id: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    Submit() {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    },
    temp(data) {
      if (data == "Yes") {
        this.disable_button = true;
      } else {
        this.disable_button = false;
      }
    },

    handleFileUpload() {
      this.rfp_file_document = this.$refs.rfp_file_document.files[0];
      // alert(this.rfp_file_document.name)
    },
    show_ta1() {
      //alert(this.no_of_posts5);
      this.showtable = this.no_of_posts5 != "";
    },
    show_ta2() {
      //alert(this.no_of_posts3);
      this.showtable1 = this.no_of_posts3 != "";
    },

    onSubmit1(evt) {
      //console.log();
      var postfile = this.rfp_file_document;
      var v1 = this.value1.split(" ");
      var v2 = this.value2.split(" ");
      var date1 = v1[0].split("-");
      var date1 = date1[2] + "/" + date1[1] + "/" + date1[0] + " " + v1[1];
      var date2 = v2[0].split("-");
      var date2 = date2[2] + "/" + date2[1] + "/" + date2[0] + " " + v2[1];

      //alert(value1);
      // alert(date2);
      var data = {
        EventID: "00000000-0000-0000-0000-000000000000",
        EventTitle: this.EventTitle,
        EventDescription: this.discriptions,
        EventTotalPosts: this.no_of_posts,
        EventMalePosts: this.no_of_posts_for_male,
        EventFemalePosts: this.no_of_posts_for_female,
        ApplicationStartDt: date1,
        ApplicationEndDt: date2,
        IsActive: false,
        BoardID: this.userBoard,
        EventDetailsFile: this.rfp_file_document.name,
      };
      let promise = apis.neweventsubmit(data);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "";
            this.variant = "success";
            this.showDismissibleAlert = true;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.showDismissibleAlert = false;
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      this.formBatch.LoginID = LoginID;
      //console.log("SELECTED", this.selected);
      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array
        this.formBatch.EventID.push(this.selected[i].LoanApplicationID);
      }
      // this.selected.forEach(function(data, index) {
      //   this.formBatch.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });
      let promise = apis.createBatch(this.formBatch);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "";
            this.variant = "success";
            this.showDismissibleAlert = true;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      //this.getApplicationsSub();
    },
    onReset(evt) {},
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.showBtn = false;
      this.showDismissibleAlert = false;
      if (this.formBSC.ApprovalDoc == "") {
        this.alertText =
          "Approval File is not yet Uploaded. Please check the file icon before submit.";
        this.showDismissibleAlert = true;
        return;
      }
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      // this.formBSC.UserID = LoginID;
      // this.formBSC.BoardID = this.form.BoardID;
      //console.log("SELECTED", this.selected);
      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array
        this.formBSC.ApplicationIDs.push(this.selected[i].LoanApplicationID);
      }
      //console.log(this.formBSC);
      // this.selected.forEach(function(data, index) {
      //   this.form.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });
      let promise = apis.UpdateBSCDetails(this.formBSC);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "Data Saved Successfully";
            this.variant = "success";
            this.showDismissibleAlert = true;
            //this.ResetBSCForm();
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
            this.showBtn = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
          this.showBtn = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();
    },
    ResetBSCForm() {
      this.$refs.finp.reset();
      this.bscReportPath = "";
      // this.formBSC = {
      //   BSCID: 0,
      //   BoardID: "",
      //   MeetingDate: null,
      //   ApprovalStatus: 13,
      //   ApprovalDoc: "",
      //   UserID: "",
      //   ApplicationIDs: [],
    },
    deleteDocument(item, index, button) {
      //console.log(item);
      this.selecteObj.Document = item.Document;
      this.selecteObj.Path = item.Path;
      let info = "You want to delete record for:" + "\n" + item.Document;
      this.modalInfo = info;
      this.$root.$emit("bv::show::modal", "modalConfirm");
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.isLoadingFile = true;
      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1].toLowerCase();
      }
      if (extn != "jpeg" && extn != "png" && extn != "jpg" && extn != "pdf") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.finp.reset();
        this.isLoadingFile = false;
        return;
      }
      let dt = Date.now();
      var filetype = files[0].type;
      var filepath = "bsc_" + dt + "." + extn;
      let promisefoto = lambda.getSignedURL("msobcfdc_bsc", filepath, filetype);
      promisefoto
        .then((response) => {
          let signedUrl = response;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": files[0].type,
            },
          };
          return axios.put(signedUrl, files[0], options);
        })
        .then((res) => {
          if (res.status == 200) {
            //this.formBSC.ApprovalDoc = filepath;
            this.showBtn = true;
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoadingFile = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoadingFile = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        StartDate: null,
        EndDate: null,
        EventTotalPosts: null,
        EventMalePosts: "",
        EventFemalePosts: "",
      };
    },
    // props: ["userBoardID"],
    //components: { datetime, Spinner},
    mounted() {
      //alert(1);
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      // console.log(LoginID);
      // this.approvalStatus = this.$store.getters.getApprovalStatus;
      //this.boards = this.$store.getters.getBoards;
      this.BoardID = this.userBoard;

      // this.events = this.$store.getters.getEvents;
      let frm = this.$store.getters.getAppSearchForm;
      if (frm != {} && frm.ApplicationStatus != undefined) {
        this.form = frm;
        this.getApplications();
      }
    },
  },
  created() {
    //alert(1);
    var EventTitle = this.$route.params.EventTitle;
    var EventTotalPosts = this.$route.params.EventTotalPosts;
    var EventMalePosts = this.$route.params.EventMalePosts;
    var EventFemalePosts = this.$route.params.EventFemalePosts;
    var ApplicationStartDt = this.$route.params.ApplicationStartDt;
    var ApplicationEndDt = this.$route.params.ApplicationEndDt;
    var EventID = this.$route.params.EventID;
    var BoardID = this.$route.params.BoardID;
    var IsActive = this.$route.params.IsActive;
    var EventDetailsFile = this.$route.params.EventDetailsFile;
    var EventDescription = this.$route.params.EventDescription;

    this.BoardID = BoardID;
    this.EventTitle = EventTitle;
    this.discriptions = EventDescription;
    this.no_of_posts = EventTotalPosts;
    this.no_of_posts_for_male = EventMalePosts;
    this.no_of_posts_for_female = EventFemalePosts;
    this.value1 = ApplicationStartDt;
    this.value2 = ApplicationEndDt;
    this.status = IsActive;
    this.valrfp_file_documentue2 = EventDetailsFile;

    //   this.store_id = this.$route.params.id;
    //   this.EventTitle = this.$route.params.id;
    //   var boardname="";
    //    this.$store.getters.getBoards.forEach(item => {
    //      if(item.SecurityBoardID == this.$store.getters.getAppSearchForm.BoardID){
    //        boardname = item.SecurityBoardName;
    //      }
    //    });
    //    consloe.log(boardname);
    //   let frm = this.$store.getters.getAppSearchForm;
    //   if (frm != {} && frm.ApplicationStatus != undefined) {
    //     this.form = frm;
    //     this.getApplications();
    //   }
  },
};
</script>
<style>
.month-setter,
.nav-r,
.nav-l,
.year-month-wrapper,
.activePort {
  background-color: #03406c !important;
}
.days,
.okButton {
  color: #03406c !important;
}
.port:hover {
  color: #03406c !important;
}
.activePort:hover {
  color: white !important;
}
#tj-datetime-input {
  width: 250px !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
