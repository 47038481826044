import axios from 'axios'

export default {
  getSignedURL (foldername,filename, filetype) {
    //console.log(file.file);
    //let endpoint = "http://localhost:3000/sgbfileupload/v1";
    //let endpoint = "https://236ddx5aya.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1";
    //let endpoint = "https://26k167cx82.execute-api.ap-south-1.amazonaws.com/prod/sgbfileupload/v1";
    let endpoint = "https://4praudrnzg.execute-api.ap-south-1.amazonaws.com/prod/sgbfileupload/v1";
    let payload = {
      filePath: foldername +"/"+ filename,
      contentType: filetype
    }
    //console.log ("payload", payload);

    return axios.post(endpoint, payload)
      .then((res) => {
        return Promise.resolve(res.data.url || '/')
      })
      .catch((err) => {
        console.error(err)
        return Promise.reject('/')
      })
  },
}