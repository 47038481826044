<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-row>
        <b-col md="5">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              :disabled="value.BoardID != '00000000-0000-0000-0000-00000000'"
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            @click="onSubmit"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      </b-row>

    </b-form>
    <b-modal
      id="mdlAdvSearch"
      ref="mdlAdvSearch"
      size="lg"
      title="Advance Search"
      hide-footer
    >
    </b-modal>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import axios from "axios";
import apis from "@/apis";
export default {
  components: { DatePicker },
  props: ["boards", "events", "approvalStatus", "value"],

  data() {
    return {
      user_id: "",
      genderList: this.$store.getters.getGenderList,
     
    };
  },
  mounted() {
    if (this.value.Purpose == 0) {
      this.appStatus = this.approvalStatus;
    } else {
      this.filterAppStatus(this.value.Purpose);
    }
  },
  methods: {

    filterAppStatus(purposeid) {
      this.appStatus = [];
      for (var i = 0; i < this.approvalStatus.length; i++) {
        if (
          this.approvalStatus[i].StatusPurposeID == purposeid ||
          this.approvalStatus[i].StatusPurposeID == 0
        )
          this.appStatus.push(this.approvalStatus[i]);
      }
    },

    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("submit");

      let board = this.form.BoardID;
      this.form = {
        BoardID: "00000000-0000-0000-0000-000000000000",
      };
    },
    onReset() {
      evt.preventDefault();
      let board = this.value.BoardID;
      this.form = {
        BoardID: "00000000-0000-0000-0000-000000000000",
      };
    },
  },
  created() {
    this.user_id = this.$session.get("UserId");
  },
};
</script>