<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="form-row">
        <b-col class="mt-3" md="6">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              :disabled="
                value.BoardID != '00000000-0000-0000-0000-000000000000'
              "
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
        <b-col></b-col>
        <b-col class="mt-3" md="5">
          <b-form-group
            id="grp3"
            label-cols="3"
            label="Application Status"
            label-size="sm"
            label-for="ipAPSts"
          >
            <b-select
              id="ipAPSts"
              class="form-control"
              :options="approvalStatus"
              value-field="Id"
              text-field="ApplicationStatus"
              required
              size="sm"
              v-model="value.ApplicationStatus"
            ></b-select>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" md="6">
          
        </b-col>
      </div>
      <div class="form-row" style="margin-top:-10px;">
        <b-col md="4" class="">
          <b-form-group
            id="grp4"
            label-cols="3"
            label="Application No"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.ApplicationNo"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="1"></b-col> -->
        <b-col md="3" class="" style="margin-left:5%;">
          <b-form-group
            id="grp4"
            label-cols="4"
            label="Entity Name"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.AgencyName"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="2">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      </div>
      <b-row>
        <!-- <b-col class="mt-1" md="2">
          <b-form-group
            id="grpGndr"
            label-cols="4"
            label="Gender"
            label-size="sm"
            label-for="ipgndr"
          >
            <b-select
              id="ipgndr"
              class="form-control"
              :options="genderList"
              value-field="GenderID"
              text-field="Gender"
              required
              size="sm"
              v-model="value.Gender"
            ></b-select>
          </b-form-group>
        </b-col> -->

        <!-- <b-col md="3" class="mt-1">
          <b-form-group
            id="grp4"
            label-cols="2"
            label="Mobile"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.MobileNo"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-form>
    <b-modal
      id="mdlAdvSearch"
      ref="mdlAdvSearch"
      size="lg"
      title="Advance Search"
      hide-footer
    >
      <b-form @submit="onSubmit" @reset="onReset">
        <div class="form-row">
          <b-col md="6" class="mt-3">
            <b-form-group
              id="grp11"
              label-cols="2"
              label="Pool Type"
              label-size="sm"
              label-for="ipPoolType"
            >
              <b-select
                id="ipPoolType"
                class="form-control"
                value-field="EventID"
                size="sm"
                text-field="EventTitle"
                :options="events"
                v-model="value.EventID"
              ></b-select>
            </b-form-group>
          </b-col>
          <b-col class="mt-3" md="6">
            <b-form-group
              id="grp2"
              label-cols="3"
              label="District"
              label-size="sm"
              label-for="ipDistrict"
            >
              <b-select
                id="ipDistrict"
                :options="boards"
                value-field="SecurityBoardID"
                text-field="SecurityBoardName"
                required
                size="sm"
                :disabled="
                  value.BoardID != '00000000-0000-0000-0000-000000000000'
                "
                v-model="value.BoardID"
                class="form-control"
              ></b-select>
            </b-form-group>
          </b-col>
        </div>
        <b-row>
          <b-col class="mt-3" md="6">
            <b-form-group
              id="grp13"
              label-cols="2"
              label="Status"
              label-size="sm"
              label-for="ipAPSts"
            >
              <b-select
                id="ipAPSts"
                class="form-control"
                :options="appStatus"
                value-field="Id"
                text-field="ApplicationStatus"
                required
                size="sm"
                v-model="value.ApplicationStatus"
              ></b-select>
            </b-form-group>
          </b-col>
          <b-col md="6" class="mt-3">
            <b-form-group
              id="grp14"
              label-cols="4"
              label="Application No"
              label-size="sm"
              label-for="ipAppNum"
            >
              <b-form-input
                type="text"
                size="sm"
                id="ipAppNum"
                v-model="value.ApplicationNo"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-3" md="8">
            <b-form-group
              id="grp13"
              label-cols="3"
              label="Application Date"
              label-size="sm"
              label-for="ipAPDt"
            >
              <date-picker
                v-model="appDate"
                format="DD-MM-YYYY"
                id="ipAPDt"
                lang="en"
                range
                size="small"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="4" class="mt-3">
            <b-form-group
              id="grp14"
              label-cols="4"
              label="Mobile No"
              label-size="sm"
              label-for="ipMobNum"
            >
              <b-form-input
                type="text"
                size="sm"
                id="ipMobNum"
                v-model="value.MobileNo"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-3">
            <b-button
              type="submit"
              size="sm"
              variant="success"
              class="float-right mx-2"
              >Search</b-button
            >
            <b-button
              type="reset"
              size="sm"
              variant="secondary"
              style="float: right"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  props: ["boards", "events", "approvalStatus", "value"],

  data() {
    return {
      form: this.value || {},
      appDate: null,
      appStatus: [],
      genderList: this.$store.getters.getGenderList,
      purposes: [
        { value: 1, text: "Document Verification" },
        { value: 2, text: "Document Verification and Physical Test" },
        { value: 3, text: "Physical Test" },
        { value: 4, text: "Physical and Ground Test" },
        { value: 5, text: "Ground Test" },
        { value: 6, text: "Character and Medical Certificate Verification" },
      ],
    };
  },
  mounted() {
    if (this.value.Purpose == 0) {
      this.appStatus = this.approvalStatus;
    } else {
      this.filterAppStatus(this.value.Purpose);
    }
  },
  methods: {
    filterAppStatus(purposeid) {
      this.appStatus = [];
      for (var i = 0; i < this.approvalStatus.length; i++) {
        if (
          this.approvalStatus[i].StatusPurposeID == purposeid ||
          this.approvalStatus[i].StatusPurposeID == 0
        )
          this.appStatus.push(this.approvalStatus[i]);
      }
    },
    // updateForm(fieldName, value) {
    //   this.value= this.form;
    //   this.$emit("input", this.value);
    // },
    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("submit");
    },
    onReset() {
      evt.preventDefault();
      let board = this.value.BoardID;
      this.form = {
        BoardID: board,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        RegistartionNO: "",
        AgencyName: "Top",
        StartDate: null,
        EndDate: null,
      };
    },
  },
};
</script>
