<template>
  <div
    id="VueCaptcha"
    class="vue-captcha"
    v-bind:style="`border: 1px solid ${color};`"
  >
    <div class="content-captcha">
      <canvas class="vue-captcha-img" width="150" height="25"></canvas>
      <button
        type="button"
        v-on:click="picture"
        v-bind:style="`color: blue;`"
        title="Refresh"
        v-show="!auth"
      >
        <i class="fa fa-refresh"></i>
      </button>
    </div>
    <div class="content-text" v-show="!auth">
      <input
        type="text"
        v-model="text"
        style="width:125px!"
        placeholder="type above text....."
      />
      <button
        type="button"
        v-on:click="inputText"
        v-bind:style="`color: red;`"
        title="Refresh"
        v-show="!auth"
      >
        <i class="fa fa-check"></i>
      </button>
    </div>
    <div
      class="content-ok"
      v-show="auth"
      v-bind:style="`background-color: ${color};`"
    >
      <small> <i class="fa fa-thumbs-up"></i> success.!</small>
    </div>
    <div class="options" v-show="!auth"></div>
  </div>
</template>

<script>
"use strict";
import uuid from "uuid";

// import "font-awesome/css/font-awesome.css";

export default {
  name: "Vuecaptcha",
  props: {
    callSuccess: {
      type: Function,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#1D9D74",
    },
    // mode: {
    //   type: String,
    //   required: false,
    //   default: "text", // (default) 'text' -> alphanumeric | 'math' -> operations math
    // },
    resolve: {
      type: String,
      required: false,
      default: "all", // (default) 'all' -> written and digit | 'text' -> only written | 'digit' -> only digit
    },
    isAuth:{
      type:Boolean,
      required:true,
      default:false
    }
  },
  data() {
    return {
      auth:false,
      mode: "text",
      key: "",
      opc: 1,
      text: "",
      contenTextTop: 200,
      contenTextLeft: 200,
      errorSelect: 0,
      success: 0,
      preSuccess: false,
      arrayForNum: [],
    };
  },
  mounted() {
    this.picture();
    
  },
  watch: {
    errorSelect(error) {
      if (error > 0) {
        this.$dialog.alert("Please enter Valid Captcha");
      }
    },
    success(acert) {
      if (acert === 6) {
        this.callSuccess();
      }
    },
    isAuth(newval){
      this.auth = newval;
      if(!newval){
        this.picture();
        this.text="";
        this.success=0;
        this.errorSelect=0;
      }
    }
  },
  methods: {
    getKey() {
      let newKey = null;
      if (this.mode === "text") {
        do {
          newKey = uuid();
          newKey = newKey.replace("-", "");
          newKey = newKey.substr(5, 6);
        } while (this.codex(newKey));
        this.key = newKey;
      } else if (this.mode === "math") {
        newKey = this.operation();
        this.key = eval(newKey);
      }
      this.opc = 0;
      return newKey;
    },
    picture() {
      const image = this.$el.children[0].children[0];
      let ctx = image.getContext("2d");
      ctx.clearRect(0, 0, image.width, image.height);
      ctx.font = "25px Arial";
      ctx.strokeStyle = this.color;
      ctx.moveTo(0, 5);
      ctx.lineTo(150, 5);
      ctx.stroke();
      ctx.moveTo(0, 12.5);
      ctx.lineTo(150, 12.5);
      ctx.stroke();
      ctx.moveTo(0, 20);
      ctx.lineTo(150, 20);
      ctx.stroke();
      let left = this.mode === "text" ? 35 : 32;
      ctx.fillText(this.getKey(), left, 22);
    },
    inputText() {
      let text = this.mode === "math" ? Number.parseInt(this.text) : this.text;
      if (this.key === text) {
        this.auth = true;
        this.success = 6;
      } else {
        this.errorSelect = 9;
      }
    },

    codex(key) {
      let no = false;
      for (let x = 0; x < key.length; x++) {
        let count = -1;
        for (let y = 0; y < key.length; y++) {
          if (key[x] === key[y]) {
            count++;
          }
        }
        if (count > 0) {
          no = true;
          break;
        }
      }
      return no;
    },
    operation() {
      let max = 20;
      let a = Math.floor(Math.random() * max + 10);
      let b = Math.floor(Math.random() * max + 10);
      let o = Math.floor(Math.random() * 9 + 0);
      let cadena = `${a} + ${b}`;
      let result = eval(cadena);
      /*** CONTROL ARRAY FOR RANDOM SELECT  ***/
      do {
        let pre = Math.floor(Math.random() * max + 10);
        if (pre !== result) {
          this.arrayForNum.push(pre);
        }
      } while (this.arrayForNum.length < 10);
      this.arrayForNum[o] = result;
      /***   ***/
      return cadena;
    },
  },
};
</script>

<style scoped>
.vue-captcha {
  width: 230px;
  text-align: center;
  padding: 5px;
}
.vue-captcha-img {
  border: 1px solid #d3d3d3;
}
.options button {
  width: 45px;
  height: 20px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
  margin: 2px;
  outline: transparent;
}
.options button:focus,
.options button:active {
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #1d9d74;
}
div.content-text {
  padding: 5px;
}
div.content-text > input {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  font-size: 13.33px;
}
.content-ok {
  width: 150px;
  border-color: #e0f1e9;
  color: #f3faf8;
  margin-left: 10px;
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.vue-captcha-btn {
  padding: 1px 6px 1px 6px;
}
.vue-captcha-table {
  border-collapse: collapse;
}
.vue-captcha-table-item {
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #1d9d74;
}
.vue-captcha-table-item-center {
  border-left: 1px solid #1d9d74;
  border-right: 1px solid #1d9d74;
}
.vue-captcha-table-item-right {
  border-left: 1px solid #1d9d74;
}
.content-fixed {
  position: fixed;
  border: 0px solid #1d9d74;
  background-color: lightgrey;
}
.red {
  background-color: #c7254e;
}
.blue {
  background-color: #488dd8;
}
</style>