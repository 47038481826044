<template>
  <b-container class="newfont">
    <div id="printme">
      <b-row class="my-2">
        <b-col>
          <b-button
            class="hide-print mt-2 ml-2 float-right"
            @click.prevent="goback"
            variant="danger"
            size="sm"
            >BACK</b-button
          >

          <b-button
            class="hide-print float-right mt-2"
            variant="info"
            size="sm"
            @click="print"
            >Print</b-button
          >
        </b-col>
      </b-row>

      	<loading :active.sync="isLoading" 
        color="#5dbbda"
        loader="spinner"
        :is-full-page="fullPage"
        width:2
        height:2
        ></loading>	
			<b-row>
			<b-col style="text-align:center">
				<!-- <img src="@/assets/images/logo_trans.png" class="float-left" width="80px"> -->
				<h2> Security Guards Board</h2>
				<h4>Registration of Security Guards Working with Private Agency</h4>
			</b-col>
			</b-row>
			
			 
      
			

      <hr />
      <b-row>
        <b-col md="1"></b-col>
        <b-col
          ><strong>Registration Number:</strong> {{ RegistrationNumber }}</b-col
        >
        <b-col></b-col>
        <b-col class="float-right"
          ><strong>Date:</strong>{{ PaymentDate }}</b-col
        >
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Security Guards Board</b-col>
        <b-col md="6">{{ BoardName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Full Name </b-col>
        <b-col md="6">{{ form.name }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">FatherName</b-col>
        <b-col md="6">{{ form.FatherName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Address</b-col>
        <b-col md="6">{{ form.Address }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">State</b-col>
        <b-col md="6">{{ StateName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">City</b-col>
        <b-col md="6">{{ DistrictName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">PinCode</b-col>
        <b-col md="6">{{ form.PinCode }}</b-col>
      </b-row>

      <b-row>
        <b-col md="1"></b-col><b-col md="5">Designation</b-col>
        <b-col md="6">{{ form.Designation }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">BirthDate</b-col>
        <b-col md="6">{{ form.BirthDate }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Age</b-col>
        <b-col md="6">{{ form.Age }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Education</b-col>
        <b-col md="6">{{ form.Education }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Gender</b-col>
        <b-col md="6">{{ form.Gender }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Marital Status</b-col>
        <b-col md="6">{{ form.MaritalStatus }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Agency Name</b-col>
        <b-col md="6">{{ form.AgencyName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Job Duration</b-col>
        <b-col md="6">{{ form.JobDuration }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Principal Employer Name</b-col>
        <b-col md="6">{{ form.PrincipalEmpName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Job Duration with Principal Employer</b-col>
        <b-col md="6">{{ form.PrincipalEmpJobDurth }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Current Salary</b-col>
        <b-col md="6">{{ form.CurrSal }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Salary Date</b-col>
        <b-col md="6">{{ form.SalDate }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Salary Mode</b-col>
        <b-col md="6">{{ form.SalMode }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Is Salary Slip Provided to You ?</b-col>
        <b-col md="6">{{ form.SalSlip }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Bank Name</b-col>
        <b-col md="6">{{ form.BankName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Bank Branch Name</b-col>
        <b-col md="6">{{ form.BankBranch }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Bank Account Number</b-col>
        <b-col md="6">{{ form.AccNo }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Daily Working Hours </b-col>
        <b-col md="6">{{ form.WorkingHours }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Overtime Charges </b-col>
        <b-col md="6">{{ form.OTRate }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Weekly Off </b-col>
        <b-col md="6">{{ form.Weeklyoff }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Paid Leave</b-col>
        <b-col md="6">{{ form.PaidLeave }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Paid Holidays</b-col>
        <b-col md="6">{{ form.PaidHolidays }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Accidental Insurance</b-col>
        <b-col md="6">{{ form.AccidentInsurance }}</b-col>
      </b-row>

      <b-row>
        <b-col md="1"></b-col><b-col md="5">LTA </b-col>
        <b-col md="6">{{ form.LTA }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">LTA Amount</b-col>
        <b-col md="6">{{ form.LTAAmount }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">HRA</b-col>
        <b-col md="6">{{ form.HRA }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">HRA Amount</b-col>
        <b-col md="6">{{ form.HRAAmount }}</b-col>
      </b-row>

      <b-row>
        <b-col md="1"></b-col><b-col md="5">Do you Receive Bonus ?</b-col>
        <b-col md="6">{{ form.Bonus }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Bonus Amount</b-col>
        <b-col md="6">{{ form.BonusAmt }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Is Provident Fund Deducted From Salary?</b-col>
        <b-col md="6">{{ form.PF }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">PF No</b-col>
        <b-col md="6">{{ form.PFNo }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Is ESIC Deducted from Salary</b-col>
        <b-col md="6">{{ form.ESIC }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">ESIC No</b-col>
        <b-col md="6">{{ form.ESICNo }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Do You Get Medical Benifits ?</b-col>
        <b-col md="6">{{ form.MedicalBenifits }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Are you Covered under Accident Insurance ?</b-col>
        <b-col md="6">{{ form.AccidentInsu }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Accident Insurance Amount?</b-col>
        <b-col md="6">{{ form.Amt }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Are You Permanent ?</b-col>
        <b-col md="6">{{ form.Permanant }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Have You Received Appointment Letter ?</b-col>
        <b-col md="6">{{ form.AppLetter }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Is Uniform Provided By Agency ?</b-col>
        <b-col md="6">{{ form.Uniform }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5"> Do You Pay For Uniform ?</b-col>
        <b-col md="6">{{ form.PayForUniform }}</b-col>
      </b-row>

      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">No Of Years Of Service To Receive Uniform ?</b-col>
        <b-col md="6">{{ form.SrvcYrsUniform }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5"> Do You Hold Gun ?</b-col>
        <b-col md="6">{{ form.IsGunHolder }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col
        ><b-col md="5">Do You Have Licence For Gun ?</b-col>
        <b-col md="6">{{ form.IsGunLicense }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">License Number of Gun</b-col>
        <b-col md="6">{{ form.GunLicenseNo }}</b-col>
      </b-row>

      <b-row>
        <b-col md="1"></b-col><b-col md="5">Have You Received Training ?</b-col>
        <b-col md="6">{{ form.Traning }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Traning Location </b-col>
        <b-col md="6">{{ form.TraningLocation }}</b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col><b-col md="5">Union Name</b-col>
        <b-col md="6">{{ form.UnionName }}</b-col>
      </b-row>
    </div>
    <b-row> </b-row>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  props: ["id"],
  components: {Loading},
  data() {
    return {
      form: {},
      RegistrationNumber: "",
      PaymentDate: "",
      DistrictName: "",
      StateName: "",
      BoardName: "",
      RegistrationID: "",
      isloading:false,
      fullpage:false,
    };
  },
  methods: {
    print() {
      // 	$(".hide-print").hide();
      //   this.$htmlToPaper("printme");
      window.print();
    },
    goback() {
      //this.$router.back();
      this.$router.push({
        name: "PrivateRegistration",
        params: {
          userDistrictID: this.$store.getters.getUserDistrict,
          userApprovalStatus: this.$store.getters.getAppStatus,
          userLoanType: 1,
        },
      });
    },
    readProfile(type) {
      let promise = apis.ReadRegistrationDetails(this.id, 12);
      promise
        .then((response) => {
          //console.log('Reponse' ,response);
          if (
            response.status == 200 &&
            response.body != "Error in Registration"
          ) {
            this.form = JSON.parse(response.body.FormDetails);

            this.BoardName = response.body.BoardName;
            this.PaymentDate = response.body.PaymentDate;
            this.RegistrationNumber = response.body.RegistrationNumber;
            this.DistrictName = response.body.DistrictName;
            this.StateName = response.body.StateName;
          } else {
            this.form = {};
          }
        })
        .catch(err => {
          this.$dialog.alert("Error In Loading previous Details.");
        });
    },
  },
  mounted() {
    this.readProfile();
  },
};
</script>

<style>
.newfont {
  font-size: 0.875rem;
}
</style>
