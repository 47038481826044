<template>
  <b-container>
    
    <div class="application-view">
      <h5 class="reg-form-title text-info" style="margin-left:12%;margin-bottom:2%;">Approval Processs</h5>
      <b-form @submit="onSubmit">
        <b-row>
          <b-col class="py-0" md="4" offset-md="2">
            <b-form-group
              id="grpSts"
              label-cols="2"
              label="Status"
              label-size="sm"
              label-for="ipSts"
            >
              <b-select
                id="ipSts"
                class="form-control"
                value-field="StatusID"
                size="sm"
                text-field="Status"
                :options="approval"
                v-model="form.ApplicationStatus"
                @change.native="checkReject"
              ></b-select>
            </b-form-group>
          </b-col>

          <b-col class="py-0" md="4">
            <b-form-group
              id="grpReason"
              label-cols="3"
              label="Reject Reason"
              label-size="sm"
              label-for="ipReason"
            >
              <b-select
                id="ipReason"
                :options="reject"
                value-field="RejectionReasonId"
                text-field="RejectionReason"
                required
                size="sm"
                :disabled="!isRejects"
                v-model="form.RejectReason"
                class="form-control"
              ></b-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="py-0" md="8" offset-md="2">
            <b-form-group
              id="grpRemarks"
              label-cols="1"
              label="Remark"
              label-size="sm"
              label-for="ipRmk"
            >
              <b-form-textarea
                id="ipRemarks"
                v-model="form.Remarks"
                size="sm"
                rows="2,"
                max-rows="4"
                placeholder="Remarks"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <center>
              <b-button 
              :disabled="save"
                class="hide-print"
                type="submit"
                variant="primary"
                size="sm"
                v-if="approval.length>0"
                >Save Status</b-button
              >
            </center>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>
<script>
import apis from "@/apis";
import DatePicker from "vue2-datepicker";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter.js";
import lambda from "@/apis/lambda";
import axios from "axios";

export default {
  components: { Spinner, DatePicker },
  props: [
    "UserID",
    "ApplicationStatus",
    "ApplicationID",
    "ApprovalStatus",
    "RejectReasons",
    "EmployerApprovalStatus",
    "GuardApprovalStatus",
    "CheckListStatus",
    "remark",
    
  ],

  data() {
    return {
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      app_id: "",
      approval: [],
      reject: [],
      isLoading: false,
      logTbl: [],
      save:false,
      isRejects: false,
      fields: [
        { key: "OldPath", label: "Old Doc" },
        { key: "NewPath", label: "New Doc" },
        { key: "ChangeDate", label: "Date", sortable: true },
        { key: "Remarks", label: "Remarks" },
      ],
      user_id: "",
      form: {
        UserID: "",
       
        ApplicationID: "",
        RejectReason: 0,
        Remarks: "",
        ApplicationStatus: "",
      },
      isFileLoading: false,
      progressval: 0,
      DocTypes: [{ DocTypeID: 1, DocType: "Ration Card" }],
    };
  },
  methods: {
    checkReject(evt) {
      let id = parseInt(evt.target.value);
      let appStatus = _.filter(this.ApprovalStatus, { StatusID: id });
      if (appStatus) {
        if (appStatus.length > 0) {
          this.isRejects = appStatus[0].ISReject;
          if (!this.isRejects) {
            this.form.RejectReason = 0;
          }
        }
      }
    },
    onSubmit(evt) {
     
      evt.preventDefault();
      //alert(this.GuardApprovalStatus);
      this.form.ApplicationID = this.app_id;
      this.form.UserID = this.user_id;

      if (this.form.ApplicationStatus == this.ApplicationStatus) {
        return;
      }
      if (this.form.ApplicationStatus==2 || this.form.ApplicationStatus==6  &&(this.EmployerApprovalStatus==false || this.GuardApprovalStatus==false || this.CheckListStatus==false) )
      {
         //alert(this.GuardApprovalStatus )
        if (this.EmployerApprovalStatus==false) {
           this.$dialog.alert("Approved failed..Employer Approved Pending");
           return false;
        }
        if (this.GuardApprovalStatus==false) {
           this.$dialog.alert("Approved failed..Guard Approved Pending");
           return false;
        }
         if (this.CheckListStatus==false) {
           this.$dialog.alert("Approved failed..Checklist Approved Pending");
            return false;
        }
       
      }
      //console.log(this.form);
      let promise = apis.UpdateXapplicationStatus(this.form);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.$dialog.alert("Approved Succesfully..");
            this.save=true;
            // this.$router.push({
            // name: "exemption"
       
            // });
          } else {
            this.$dialog.alert(response.body);
          }
        })
        .catch((err) => {
          this.$dialog.alert(err.message);
        });
    },
    onReset(evt) {
      evt.preventDefault();
    },
  },
  watch: {
    remark: function(newVal, oldVal)
    {
    this.form.Remarks = newVal;
    
    },
    // ApplicationStatus: function(newVal, oldVal)
    // {

    // this.form.ApplicationStatus = newVal;
    // }
  
EmployerApprovalStatus()
{
    this.EmployerApprovalStatus=this.EmployerApprovalStatus;
   // alert(this.EmployerApprovalStatus);
},
GuardApprovalStatus()
{
    this.GuardApprovalStatus=this.GuardApprovalStatus;
   // alert(this.GuardApprovalStatus);
},
CheckListStatus()
{
  
  this.CheckListStatus=this.CheckListStatus;
},
  },
  mounted() {
    // alert(this.CheckListStatus);
    // alert(this.GuardApprovalStatus);
    // alert(this.EmployerApprovalStatus);
      //  alert(this.EmployerApprovalStatus);
      //  alert(this.GuardApprovalStatus)
  },
  created() {
    //alert(this.RoleID);
   
    this.form.ApplicationStatus = this.ApplicationStatus;
    this.app_id = this.ApplicationID;
    this.user_id = this.$store.getters.loggedUser.UserId;
    this.approval = this.ApprovalStatus;
    this.reject = this.RejectReasons;
  },
};
</script>
<style scoped></style>
