<template>
  <b-container>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Guard Registration</router-link>
        </li>
        <li
          class="breadcrumb-item active"
          style="font-size: 0.875rem"
          aria-current="page"
        >
          Applicant Wise List
        </li>
      </ol>
    </nav>

     <ApplicantWise-filter
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      :name="Applications_name"
      :RoleID="RoleID"
      @submit="onSubmit"
      @reset="onReset"
    ></ApplicantWise-filter>
    
    <!-- <b-row> -->
    <!-- <hr />
    <div class="row" style="margin-top: 20px">
      <b-col md="11"></b-col>
    </div> -->
    <!-- </b-row> -->
   
    <!-- <b-form  @submit="onSubmit" @reset="onReset " v-if="RoleID==99">
   
        <div class="form-row" >
        <b-col class="mt-2" md="4">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              v-model="BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
        
        <b-col class="mt-3" md="2" style="margin-left: 5%">
          <b-button
            type="button"
            size="sm"
            variant="primary"
            @click="getlist(2)"
            style="float: left"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      
      </div>
         

      
    </b-form> -->
    <hr />
   
    <b-row class="mb-2">
      <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="filter"
            placeholder="Type to Search"
            size="sm"
          />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''" size="sm"
              >Clear</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="3">
        <p>Total Records: {{ Total }}</p>
      </b-col>
      <b-col>
        
        <b-button 
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
         
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
           @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
      </b-col>
      
    </b-row>
    <loading
      :active.sync="isLoading"
      color="#5dbbda"
      loader="spinner"
      :is-full-page="fullPage"
      width:2
      height:2
    ></loading>
 
    <b-table 
      id="tblApplication"
      show-empty
      select-mode="multi"
      head-variant="dark"
      empty-text="No Data available."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      style="font-size: 12px"
    >
    <template v-slot:cell(sr_no)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
       <template v-slot:cell(Applicant)="row">
       <span v-if="row.item.EventDetails"> 
       {{jsonconverter(row.item.EventDetails)}}
       </span>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click.stop="viewProfile(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
        >
          <i class="fas fa-eye"></i>
        </b-button>
      </template>
 
      <template v-slot:cell(action)="row"  >
        <b-button 
          size="sm"
           v-if="row.item.Status== 'Pending'"
          @click.stop="editDocument(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="info"
        >
          <i class="fas fa-edit"></i>
        </b-button>
      </template>
    </b-table>
    <b-row>
      <b-col md="8" class="my-1">
        <b-pagination
          :total-rows="Total"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
          @change="ChangeEvent"
        />
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group
          horizontal
          label="Per page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div></div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import * as _ from "lodash";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ApplicantWiseFilter from "@/components/ApplicantWiseFilter.vue";

export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType","value"],
  components: { Spinner, ApplicantWiseFilter, DatePicker, Loading },
  data() {
    return {
      form: this.value || {},
      entry_date:"",
      showBtn: false,
      BatchNumber:"",
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      EventID: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      applications: [],
      bscReport: "",
      bscReportPath: "",
      appCount: 0,
      appDate: null,
      attendence_applications: [],
      fields1: [
        { key: "sr_no", label: "SrNo" },
        { key: "PoolType", label: "Type" },
        { key: "ApplicantName", label: "Applicant Name" },
        { key: "AadharNo", label: "Adhar No" },
        { key: "Status", label: "Status" },
         
        { key: "LoginDate", label: "Login Created Date" ,formatter: "convertDateFormat",},
      
        // { key: "actions", label: "View" },
        // { key: "action", label: "Edit" },
        
      
      ],
      fields: [
        { key: "sr_no", label: "SrNo" },
        { key: "PoolType", label: "Type" },
        { key: "ApplicantName", label: "Applicant Name" },
         { key: "AadharNo", label: "Adhar No" },
        { key: "Status", label: "Status" },
         
        { key: "LoginDate", label: "Login Created Date" ,formatter: "convertDateFormat",},
     
        // { key: "actions", label: "View" },
       
        
      
      ],
      marks_applications: [],
      selected: [],
      showbtn: false,
      selectedApplication: "",
      currentPage: 1,
      Applications_name:[{name:"Applications"}],
      perPage: 5,
      Total: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      fullPage: false,
      isLoadingFile: false,
      isAdmin: false,
      FullName:"",
      
      form:{
        BoardID: "",
        OffSet:0,
        Limit:5
      }
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
    RoleID() {
      var nme = this.$store.getters.loggedUser;
      return nme.RoleID;
      //alert(nme.RegistrationType);
    },
  },
  methods: {

    
       editDocument(item) {
      this.$router.push({
        name: "Registration",
        params: {
           RecordDetails:item,      
        },
      });
    },
     viewProfile(item) { 
      
        this.$router.push({
        name: "Viewsignup",
        params: {
         RecordDetails:item,
         Back_flag:1
        },
      });
            
        
     },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.Total = filteredItems.length;
      this.currentPage = 1;
    },
    jsonconverter(data){
      var temp = JSON.parse(data);
      
      var FullName = temp.ApplicantDetails[0].FirstName+' '+temp.ApplicantDetails[0].MiddleName+' '+temp.ApplicantDetails[0].LastName
      return FullName;
      
    },
 

    convertDateFormat(inDate) {
      if (inDate) {
        return Formatter.convertDateFormat(inDate);
      }else{
        return "";
      }
      
    },
    async downloadExcel() {
      var columns = [
        "Sr No",
        "Type",
        "Applicant Name",
        "DOB",
        "Mobile",
        "Email",
        "Aadhar No",
        "Remark",
        "Status",
        "Login Created Date",
        
      ];

      let app_data = [];
      // for (var i = 0; i < this.applications.length; i++) 
      //     {
      //       var temp = JSON.parse(this.applications[i].EventDetails);
       
      //       this.DOB = temp.ApplicantDetails[0].DOB;
      //       this.AadharNo = temp.ApplicantDetails[0].AadharNo;
      //       this.Email = temp.ApplicantDetails[0].Email;
      //       this.Mobile = temp.ApplicantDetails[0].Mobile;
      //       this.Remarks = temp.ApplicantDetails[0].Remarks;
            
      //       if (this.applications[i].EventDetails!=null) {
      //         var temp = JSON.parse(this.applications[i].EventDetails);
      //         this.applications[i].FullName = temp.ApplicantDetails[0].FirstName+' '+temp.ApplicantDetails[0].MiddleName+' '+temp.ApplicantDetails[0].LastName; 
      //         this.applications[i].Mobile = temp.ApplicantDetails[0].Mobile;
      //         this.applications[i].DOB = temp.ApplicantDetails[0].DOB;
      //         this.applications[i].AadharNo = temp.ApplicantDetails[0].AadharNo;
      //         this.applications[i].Email = temp.ApplicantDetails[0].Email;
      //         this.applications[i].Remarks = temp.ApplicantDetails[0].Remarks;
      //       }
      //     }
        
        await this.applications.forEach((element) => {
          
        let obj = {
          "Type": "",
          "Applicant Name": "",
          "DOB":"",
          "Mobile":"",
          "Email":"",
          "Aadhar No":"",
          "Remark":"",
          "Status": "",
          "Login Created Date":"",
        
          
        };
        obj["Sr No"] = element.sr_no;
        obj["Type"] = element.PoolType;
        obj["Applicant Name"] = element.ApplicantName;
        obj["DOB"] = element.DOB;
        obj["Mobile"] = element.Mobile;
        obj["Email"] = element.Email;
        obj["Aadhar No"] = element.AadharNo;
        obj["Remark"] = element.Remarks;
        obj["Status"] = element.Status;
        obj["Login Created Date"] = this.convertDateFormat(element.LoginDate);
        
       

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },

     convertToPdf() {
     
      var Total = this.Total?this.Total.toString():'0';
      var pdf = new jsPDF("l", "pt");
    
  
    //  for (var i = 0; i < this.applications.length; i++) 
    //       {
    //         if (this.applications[i].EventDetails!=null) {
    //           var temp = JSON.parse(this.applications[i].EventDetails);
    //           this.applications[i].FullName = temp.ApplicantDetails[0].FirstName+' '+temp.ApplicantDetails[0].MiddleName+' '+temp.ApplicantDetails[0].LastName;
    //           this.applications[i].Mobile = temp.ApplicantDetails[0].Mobile;
    //           this.applications[i].DOB = temp.ApplicantDetails[0].DOB;
    //           this.applications[i].AadharNo = temp.ApplicantDetails[0].AadharNo;
    //           this.applications[i].Email = temp.ApplicantDetails[0].Email;
    //           this.applications[i].Remarks = temp.ApplicantDetails[0].Remarks;
    //         }
    //       }
      var columns = [
        { dataKey: "sr_no", title: "Sr No" },
        { dataKey: "PoolType", title: "Type" },
        { dataKey: "ApplicantName", title: "Applicant Name" },
        { dataKey: "DOB", title: "DOB" },
        { dataKey: "Mobile", title: "Mobile" },
        { dataKey: "Email", title: "Email" },
        { dataKey: "AadharNo", title: "Aadhar No" },
        { dataKey: "Remarks", title: "Remark" },
        { dataKey: "Status", title: "Status" },
        { dataKey: "LoginDate", title: "Login Created Date",Formatter:"convertDateFormat" },  
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (item.SecurityBoardID == this.$store.getters.getUserBoard
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      // var event = "";
      // this.$store.getters.getEvents.forEach((e) => {
      //   if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
      //     event = e.EventTitle;
      //     return;
      //   }
      // });
      var ref = this;
      var pageContent = function (data) {
       pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Applicant Wise List " ,
          pdf.internal.pageSize.getWidth() / 2,
          60,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        pdf.text("Total Applications :", 610, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
         pdf.text(Total,720, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            
          },
        }
      );
      pdf.save("ApplicantWiseList.pdf");
    },
   

     convertDateFormatYMD(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD(inDate);
      }
    },
      onSubmit() {
     
        if (this.form.entry_date && this.form.entry_date.length > 0 && this.form.entry_date[0]==null && this.form.entry_date[1]==null) 
        {
        
          this.$delete(this.form, 'StartDate');
          this.$delete(this.form, 'EndDate');
          this.$delete(this.form, 'entry_date');
        }
        else
        if (this.form.entry_date && this.form.entry_date.length > 0 && this.form.entry_date[0]!='1970-01-1' ) 
        { 
          this.form.StartDate  = Formatter.convertDateFormatYMD(this.form.entry_date[0]);
          this.form.EndDate    = Formatter.convertDateFormatYMD(this.form.entry_date[1]);
        } 
          this.form.Limit      = this.perPage;
          this.form.OffSet     = 0;
          //this.$delete(this.form, 'entry_date');
         
          this.getlist(1);

    },
    onReset()
    {
       if (this.form.entry_date && this.form.entry_date.length > 0 && this.form.entry_date[0]==null && this.form.entry_date[1]==null) 
        {
          // this.form.StartDate = null;
          // this.form.EndDate = null;
          this.$delete(this.form, 'StartDate');
          this.$delete(this.form, 'EndDate');
          this.$delete(this.form, 'entry_date');
        }
          this.$delete(this.form,'ApplicantName');
          this.$delete(this.form,'RecordStatus');
         this.$delete(this.form,'EventID');
       this.getlist(1);

    },
    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getlist(1);
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getlist(1);
    },

    getApplicationsSub() {

      this.isLoading = true;
      this.showDismissibleAlert = false;
      this.$store.dispatch("setSearchForm", this.form);
      let promise = apis.Applicantwisenewprofilelist(this.form);

      promise
        .then((response) => {
         this.isLoading = false;
          if (response.status == 200) {
            this.applications = response.body;
            this.Total = response.body.Table[0].TotalRecords;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },



    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
  

    getlist(flag){
     
    // this.$delete(this.form, 'entry_date');
      if (flag==2) {
        this.form.BoardID=this.BoardID;
      }

  //  this.form.RecordStatus = this.form.EventID;
     
    //  this.data ={
    //     BoardID :this.form.BoardID,
    //     StartDate : this.form.StartDate,
    //     EndDate : this.form.EndDate,
    //     ApplicantName :this.form.ApplicantName,
    //     RecordStatus :this.form.RegType,
    //     RegType:this.form.EventID,
    //     EventID:'00000000-0000-0000-0000-000000000000',
    //     Offset: 0,
    //     Limit:10
    //  }
        let data=
        {
            "BoardID": this.form.BoardID,
            "OffSet": this.form.OffSet,
            "Limit": this.form.Limit,
            "StartDate": this.form.StartDate,
            "EndDate": this.form.EndDate,
            "RecordStatus": this.form.RecordStatus,
            "EventID": this.form.EventID,
            "ApplicantName": this.form.ApplicantName,
            "AdharNo": this.form.AdharNo
        }
     let promise = apis.Applicantwisenewprofilelist(data);

    promise
      .then((response) => {
        if (response.status == 200) {
          // this.form.entry_date.push(new Date(this.form.StartDate));
          // this.form.entry_date.push(new Date(this.form.EndDate));

         
          this.applications = response.body.Table1;
          for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
          }
         this.Total = response.body.Table[0].TotalRecords;
        }
    else {
        }
      })
      .catch((err) => {});
    },
  },

  mounted() {
   
     this.form.BoardID = this.userBoard;
    this.BoardID = this.userBoard;
    if (this.$route.params.BoardID)
     {
        this.form.BoardID = this.$route.params.BoardID;
        this.BoardID = this.$route.params.BoardID;
    }
   
    this.getlist(1);
 
  },

};
</script>
<style>
.autowidth {
  width: 300px !important;
  text-align: left;
}
</style>
