<template>
  <b-container>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Guard Registration</a></li>
        <li class="breadcrumb-item active" aria-current="page">
          Result Published
        </li>
      </ol>
    </nav>
    <b-container>
      <Applicationpolice
        v-model="form"
        :boards="boards"
        :approvalStatus="approvalStatus"
        :events="events"
        @submit="onSubmit"
      ></Applicationpolice>

      <hr />
      <b-row class="mb-1">
        <div class="col-md-2" style="text-align: left">
          <b-form-checkbox
            v-model="allSelected"
            :indeterminate="indeterminate"
            @change="selectAllRows"
          >
            {{ allSelected ? "Un-select All" : "Select All" }}
          </b-form-checkbox>
        </div>

        <b-col md="3">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Type to Search"
              size="sm"
            />
            <b-input-group-append>
              <b-btn :disabled="!filter" @click="filter = ''" size="sm"
                >Clear</b-btn
              >
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col md="3">
          <p>
            Total Applications: {{ totalRows }} / Selected
            {{ selected.length }}
          </p>
        </b-col>

        <b-col>
          <b-button
            class="float-right mx-3"
            variant="outline-danger"
            size="sm"
            @click="convertToPdf"
            style="float: right; font-size: 1em; font-color: red"
          >
            <i class="fa fa-file-pdf"></i>
          </b-button>
          <b-button
            variant="outline-success"
            size="sm"
            @click="downloadExcel"
            style="float: right; font-size: 1em; font-color: green"
          >
            <i class="fa fa-file-excel"></i>
          </b-button>
          <b-button
            v-if="showbtn"
            variant="warning"
            size="sm"
            class="mx-2"
            @click="publishResult"
            style="float: right"
            >Publish Result</b-button
          >
        </b-col>
      </b-row>
       <loading :active.sync="isLoading" 
        color="#5dbbda"
        loader="spinner"
        :is-full-page="fullPage"
        width:2
        height:2
        ></loading>
               
      <b-table
        id="tblApplication"
        show-empty
        ref="tblApplication"
        head-variant="dark"
        empty-text="No Applications available."
        stacked="md"
        bordered
        striped
        :items="applications"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="currentPage"
        :per-page="perPage"
        @filtered="onFiltered"
        style="font-size: 12px"
      >
        <template v-slot:cell(selected)="row">
          <b-form-checkbox
            v-model="row.item.selected"
            @change="onRowSelected(row.index, row.item)"
          >
          </b-form-checkbox>
        </template>

        <template v-slot:cell(Sr)="row">{{
          (currentPage - 1) * perPage + row.index + 1
        }}</template>
        <template v-slot:cell(action)="row">
          <b-button
            size="sm"
            @click.stop="viewProfile(row.item)"
            class="mr-1 pt-0 pb-0"
            variant="success"
            v-b-tooltip.hover
            title="View Details"
            >View</b-button
          >
        </template>
        <!-- <template v-slot:cell(Gender)="row">
          <template v-if="row.item.Gender">
            <span>Male</span>
          </template>
          <template v-else>
            <span>Female</span>
          </template>
        </template> -->
        <template v-slot:cell(EvaluationStatus)="row">
          <template v-if="row.item.EvaluationStatus">
            <span>Yes</span>
          </template>
          <template v-else>
            <span>No</span>
          </template>
        </template>
        <template v-slot:cell(marks)="row">
          <b-button
            size="sm"
            @click.stop="enterMarks(row.item, row.index, $event.target)"
            class="mr-1 pt-0 pb-0"
            variant="warning"
            v-b-tooltip.hover
            title="Edit Doc"
            v-if="row.item.EvaluationStatus == 0"
            >Marks</b-button
          >
        </template>
      </b-table>
      

      <b-row>
        <b-col md="8" class="my-1">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0"
            size="sm"
          />
        </b-col>
        <b-col md="4" class="my-1">
          <b-form-group
            horizontal
            label="Per page"
            class="mb-0"
            label-size="sm"
            :label-cols="6"
          >
            <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <div>
      <b-modal id="modalConfirm2" title="Published" size="sm" hide-footer>
        <pre>Total Applications: {{ appCount }}</pre>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onSubmit">
            OK
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancel
          </button>
        </div>
        <div class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-body">
              <p>Are You Sure Published Result</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary">OK</button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal id="confiramation_model" title="Confirmation" hide-footer>
        <p class="my-4">Are You Sure, you want to Publish the result?</p>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="publishResult2">
            OK
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancel
          </button>
        </div>
      </b-modal>

      <b-modal id="mdlMarks" :title="title" hide-footer size="xl">
        <marks-entry
          :applicationID="selectedApplication"
          :gender="selectedGender"
          :age="selectedAge"
        ></marks-entry>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import * as _ from "lodash";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";

import ApplicationFilter from "@/components/ApplicationFilter.vue";
import Applicationpolice from "@/components/Applicationpolice.vue";
import MarksEntry from "@/components/MarksEntry.vue";

export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType"],
  components: { Spinner, ApplicationFilter, Applicationpolice, MarksEntry, Loading},
  data() {
    return {
      title: "",
      showsts: false,
      isLoading:false,
      fullpage:false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      pagename1: "App",
      App: "App",
      fullPage:"",
      pendingWithList: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      applications: [],
      appCount: 0,

      appDate: null,
      fields: [
        { key: "selected", label: "Selected" },
        { key: "Sr", label: "SrNo" },
        { key: "ApplicationNo", label: "Application No", sortable: true },

        { key: "FullName", label: "Applicant Name", sortable: true },
        { key: "Gender", label: "Gender", sortable: true },

        { key: "ApplicantAge", label: "Age", sortable: true },

        { key: "Height", label: "Height (cm)" },
        { key: "Weight", label: "Weight (kg)" },
        { key: "EducationalMark", label: "Education Marks" },
        { key: "ChestDeflated", label: "Chest D (cm)" },
        { key: "ChestInflated", label: "Chest I (cm)" },
        { key: "RunningTime", label: "Running Time" },
        { key: "RunningMark", label: "Runnning Marks" },
        { key: "PullUps", label: "PullUps" },
        { key: "PullupsMark", label: "PullUp Marks" },
        { key: "SitUps", label: "Situps" },
        { key: "SitupsMark", label: "Situp Marks" },
        { key: "GroundMarks", label: "Ground Marks" },
        { key: "TotalMarks", label: "Total Marks" },
        { key: "ResultStatus", label: "Result" },
        { key: "marks", label: "Edit" },
      ],
      selected: [],
      selected_all: [],
      allSelected: false,
      indeterminate: false,
      form: {
        //BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        EvaluationStatus: 0,
        Gender: 1,
        //   MobileNo: "",
        //   ApplicationNo: "",
        //   StartDate: "01-01-1970",
        //   EndDate: "01-01-1970",
        //  //ExServiceMan:3,
        //   PinCode:null,
        //   OffSet: 0,
        //   Limit: 500,
        //   Purpose:0,
        //   pagename2:'Gen'
      },
      formBatch: {
        BatchDate: null,
        BatchTime: null,
        ApplicationIDs: [],
        IsActive: true,
      },
      showbtn: false,
      selectedApplication: "",
      selectedGender: "",
      selectedAge: "",
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  watch: {
    selected(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.applications.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  methods: {
    selectAllRows(checked) {
      for (var i = 0; i < this.applications.length; i++) {
        this.applications[i].selected = checked;
      }
      this.selected = checked ? this.applications.slice() : [];
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
    onfilter(item) {
      this.$router.push({
        name: "Search",
        params: {
          ApplicationStatus: item.ApplicationStatusID,
        },
      });
    },
    onRowSelected(index, item) {
      if (item.selected) {
        this.selected.push(item);
      } else {
        this.selected = _.filter(this.selected, function (x) {
          return x.sr_no != item.sr_no;
        });
      }

      console.log("SELECTED", this.selected);
    },
    publishResult() {
      this.appCount = this.selected.length;
      if (this.appCount == 0) {
        this.$dialog.alert("Please Select Applications For Publish");
        return;
      }

      //this.$root.$emit("bv::show::modal", "modalConfirm2");
      this.$root.$emit("bv::show::modal", "confiramation_model");
    },
    publishResult2() {
      this.$root.$emit("bv::hide::modal", "confiramation_model");

      let applications = "[";
      for (var i = 0; i < this.selected.length; i++) {
        if (i == 0) {
          applications += '"' + this.selected[i]["ApplicationNo"] + '"';
        } else {
          applications += ', "' + this.selected[i]["ApplicationNo"] + '"';
        }
      }

      applications += "]";

      let payload = {
        EventID: this.form.EventID,
        UserID: this.$store.getters.loggedUser.UserID,
        Gender: this.form.Gender,
        ApplicationNumberList: applications,
      };

      let Promise = apis.publishResult(payload);

      Promise.then((response) => {
        if (response.status == 200) {
          this.$dialog.alert("Marks Published Successfully");
        }
      }).catch((err) => {
        this.$dialog.alert("ERROR!!");
      });

      this.getApplicationsSub();
    },
    viewProfile(item) {
      this.$router.push({
        name: "ViewProfile",
        params: {
          ApplicationID: item.UserAppllicationID,
          ApplicationStatus: item.ApplicationStatusID,
        },
      });
    },
    enterMarks(item, index, button, n) {
      //alert(JSON.stringify(item));
      this.EvaluationStatus = item.EvaluationStatus;
      this.selectedApplication = item.UserAppllicationID;
      this.selectedGender = item.Gender;
      this.selectedAge = item.ApplicantAge;
      this.title = item.FullName + " (" + item.ApplicationNo + ")";
      this.$root.$emit("bv::show::modal", "mdlMarks");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onSubmit() {
      this.$root.$emit("bv::hide::modal", "modalConfirm2");
      if (this.appDate != null) {
        this.form.StartDate = Formatter.convertDateFormatYMD(this.appDate[0]);
        this.form.EndDate = Formatter.convertDateFormatYMD(this.appDate[1]);
      } else {
        this.form.StartDate = "0001-01-01";
        this.form.EndDate = "0001-01-01";
      }
      //alert(this.perPage);
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.Limit = 5;
      this.form.OffSet = 0;

      this.getApplicationsSub();
    },

    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      //this.perPage = 500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getApplicationsSub();
    },
    getApplicationsSub() {
      this.isLoading = true;
      this.showDismissibleAlert = false;

      if (this.form.ApplicationStatus == 2) {
        this.showsts = true;
      } else {
        this.showsts = false;
      }

      if (this.form.EvaluationStatus == "0") {
        this.showbtn = true;
      } else {
        this.showbtn = false;
      }

      this.$store.dispatch("setSearchForm", this.form);
      //console.log(this.form);
      let data = {
        EventID: this.form.EventID,
        EvaluationStatus: this.form.EvaluationStatus, //or 1
        Gender: this.form.Gender, // or 0 for Female , 1 for Male
      };
      let promise = apis.GetResults(this.form);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.applications = response.body;
            for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
              this.applications[i].selected = false;
            }
            this.totalRows = this.applications.length;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        EvaluationStatus: 1,
        Gender: 1,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: null,
        EndDate: null,
      };
    },
    // =================================================================================

    // ====================================================================
    convertToPdf() {
      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "sr_no", title: "Sr No" },
        { dataKey: "ApplicationNo", title: "Application No", sortable: true },
        { dataKey: "FullName", title: "Applicant Name", sortable: true },
        { dataKey: "Gender", title: "Gender", sortable: true },
        { dataKey: "ApplicantAge", title: "Age", sortable: true },
        { dataKey: "Height", title: "Height (cm)" },
        { dataKey: "Weight", title: "Weight (kg)" },
        { dataKey: "EducationalMark", title: "Education Marks" },
        { dataKey: "ChestDeflated", title: "Chest D (cm)" },
        { dataKey: "ChestInflated", title: "Chest I (cm)" },
        { dataKey: "RunningTime", title: "Running Time" },
        { dataKey: "RunningMark", title: "Runnning Marks" },
        { dataKey: "PullUps", title: "PullUps" },
        { dataKey: "PullupsMark", title: "PullUp Marks" },
        { dataKey: "SitUps", title: "Situps" },
        { dataKey: "SitupsMark", title: "Situp Marks" },
        { dataKey: "GroundMarks", title: "Ground Marks" },
        { dataKey: "TotalMarks", title: "Total Marks" },
        { dataKey: "ResultStatus", title: "Result" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchForm.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      var event = "";
      this.$store.getters.getEvents.forEach((e) => {
        if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
          event = e.EventTitle;
          return;
        }
      });
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Guard Applications Of " + event,
          pdf.internal.pageSize.getWidth() / 2,
          50,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            if (data.cell.section != "head") {
              switch (data.column.dataKey) {
                case "Createdate":
                  data.cell.text = vc.convertDateFormat(data.cell.text);
                  break;
                case "Gender":
                  if (data.cell.text == "false") {
                    data.cell.text = "Female";
                  } else {
                    data.cell.text = "Male";
                  }
                  break;
                case "ExServiceMan":
                  if (data.cell.text == "false") {
                    data.cell.text = "No";
                  } else {
                    data.cell.text = "Yes";
                  }
                  break;
              }
            }
          },
        }
      );
      pdf.save("Applications.pdf");
    },
    async downloadExcel() {
      var columns = [
        "Sr No",
        "Application No",
        "Applicant Name",
        "Gender",
        "Age",
        "Height (cm)",
        "Weight (kg)",
        "Education Marks",
        "Chest D (cm)",
        "Chest I (cm)",
        "Running Time",
        "Runnning Marks",
        "PullUps",
        "PullUp Marks",
        "Situps",
        "Situp Marks",
        "Ground Marks",
        "Total Marks",
        "Result"
      ];

      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {};
        obj["Sr No"] = element.sr_no;
        obj["Application No"] = element.ApplicationNo;

        obj["Applicant Name"] = this.titleCase(element.FullName);
        obj["Gender"] = element.Gender;

        obj["Age"] = element.ApplicantAge;
        obj["Height (cm)"] = element.Height;
        obj["Weight (kg)"] = element.Weight;
        obj["Education Marks"] = element.EducationalMark;
        obj["Chest D (cm)"] = element.ChestDeflated;
        obj["Chest I (cm)"] = element.ChestInflated;
        obj["Running Time"] = element.RunningTime;
        obj["Runnning Marks"] = element.RunningMark;
        obj["PullUps"] = element.PullUps;
        obj["PullUp Marks"] = element.PullupsMark;
        obj["Situps"] = element.SitUps;
        obj["Situp Marks"] = element.SitupsMark;
        obj["Ground Marks"] = element.GroundMarks;
        obj["Total Marks"] = element.TotalMarks;  
        obj["Result"] = element.ResultStatus;     
        //obj["Ground Marks"] = element.GroundMarks;

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    app(app_status) {
      if (app_status) {
        return "Yes";
      } else {
        return "No";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  mounted() {
    // this.approvalStatus = this.$store.getters.getApprovalStatus;
    // this.boards = this.$store.getters.getBoards;
    this.form.BoardID = this.userBoard;
    // this.events = this.$store.getters.getEvents;
    let frm = this.$store.getters.getAppSearchForm;

    if (frm != {} && frm.ApplicationStatus != undefined) {
      this.form = frm;
      this.getApplications();
    }
  },
  created(){
     this.form.BoardID = this.userBoard;
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
