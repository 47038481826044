<template>
  <b-container>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-table
        show-empty
        head-variant="dark"
        empty-text="No Records Available"
        bordered
        striped
        responsive
        hover
        :items="rows"
        :fields="table_fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @filtered="onBankFiltered"
        style="font-size: 12px"
      >
      </b-table>
    </b-form>
    <b-table :items="items" class="mt-2"></b-table>
  </b-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import apis from "../apis";
import viewshow from "./viewshow.vue";

export default {
  components: { DatePicker, viewshow },
  props: ["applicationID"],
  data() {
    return {
      showHour: false,
      exmptOptions: [
        { value: 0, text: "Not Exempted" },
        { value: 1, text: "Exempted due to Age" },
        { value: 2, text: "Exempted Temporarily till Ground Test" },
      ],
      form: {
        ApplicationID: "00000000-0000-0000-0000-000000000000",
        Height: 0,
        Weight: 0,
        ChestD: 0,
        ChestI: 0,
        RunningTime: "0:0",
        Pullups: 0,
        Situps: 0,
        Exemption: 0,
        PublishMarks: false,
      },
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingR: false,
      items: [],
      table_fields: [
        {
          key: "Application_No",
          label: "Application No",
          sortable: true,
        },
        {
          key: "Name",
          label: "Name",
          sortable: true,
        },
        {
          key: "Ground_Marks",
          label: "Ground Marks",
          sortable: true,
        },
        {
          key: "Education_Marks",
          label: "Education Marks",
          sortable: true,
        },
        {
          key: "Total_Marks",
          label: "Total Marks",
          sortable: true,
        },
        {
          key: "Date_of_Birth",
          label: "Date of Birth",
          sortable: true,
        },
        {
          key: "Rank",
          label: "Rank",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.isLoading = true;
      this.form.ApplicationID = this.applicationID;
      let promiseSave = apis.saveMarks(this.form);

      promiseSave
        .then((response) => {
          if (response.status == 200) {
            this.alertText = "Marks Saved Successfully";
            this.variant = "success";
            this.items = response.body;
          } else {
            this.alertText = "Error !";
            this.variant = "warning";
          }
        })
        .catch((err) => {
          this.alertText = "Error while saving data";
          this.variant = "danger";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();

      resetForm();
    },
    // resetForm() {
    //   this.isLoadingR = true;
    //   form = {
    //     ApplicationID: "00000000-0000-0000-0000-000000000000",
    //     Height: 0,
    //     Weight: 0,
    //     ChestD: 0,
    //     ChestI: 0,
    //     RunningTime: "0:0",
    //     Pushups: 0,
    //     Situps: 0,
    //     Exemption: 0,
    //   };
    //   this.isLoadingR =false;
    // },
  },
};
</script>