export default {
  survey: {
    title: "Create New Login",
    description: "Create new login for online registration process",
    logoPosition: "right",
    completedHtml: "<p>The Data is Saved Successfully</p>",
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "dropdown",
            name: "Board",
            title: "Please Select the Board",
            isRequired: true,
            choicesByUrl: {
              url: "https://9lmwzmqfa6.execute-api.ap-south-1.amazonaws.com/Prod/api/Masters/ReadSecurityBoard",
              valueName: "SecurityBoardName",
              titleName: "SecurityBoardName",
            },
          },
        ],
        title: "Board Choice",
      },
      {
        name: "page2",
        elements: [
          {
            type: "dropdown",
            name: "PoolType",
            title: "Please Select the Pool",
            isRequired: true,
            choicesByUrl: {
              url: "https://9lmwzmqfa6.execute-api.ap-south-1.amazonaws.com/Prod/api/events/ReadPoolTypes",
              valueName: "RegistrationTypeID",
              titleName: "RegistrationType",
            },
          },
        ],
        title: "Pool Choice",
      },
      {
        name: "page3",
        elements: [
          {
            type: "text",
            name: "EmployerName",
            visibleIf: "{PoolType} = 1 or {PoolType} = 2",
            title: "Name of the Employer",
            isRequired: true,
            "maxLength": 200
          },
          {
            type: "text",
            name: "EmployerRegNo",
            visibleIf: "{PoolType} = 1 or {PoolType} = 2",
            title: "Registration No. of the Employer",
            isRequired: true,
            "maxLength": 50
            
            // validators: [
            //   {
            //     type: "text",
            //     minValue: 1, // Minimum allowed value
            //     maxValue: 9999999999, // Maximum allowed value
            //     text: "Please enter a valid registration number",
            //   },
            // ]
          },
          {
            type: "text",
            name: "PetitionNo",
            visibleIf: "{PoolType} = 4",
            title: "Court Case No",
            isRequired: true,
            validators: [
              {
                type: "text",
                minValue: 1, // Minimum allowed value
                maxValue: 9999999999, // Maximum allowed value
                text: "Please enter a valid Petition number",
              },
            ]
          },
          {
            type: "text",
            name: "CourtName",
            visibleIf: "{PoolType} = 4",
            title: "Court Name",
            isRequired: true,
            "maxLength": 200
          },
          // {
          //   type: "text",
          //   name: "OrderNo",
          //   visibleIf: "{PoolType} = 4",
          //   title: "Order Number",
          //   isRequired: true,
          //   validators: [
          //     {
          //       type: "numeric",
          //       minValue: 1, // Minimum allowed value
          //       maxValue: 9999999999, // Maximum allowed value
          //       text: "Please enter a valid Order number",
          //     },
          //   ]
          // },
          {
            type: "text",
            name: "OrderDate",
            visible: false,
            visibleIf: "{PoolType} = 4",
            title: "Court Order Date",
            isRequired: true,
            inputType: "date",
          },
          {
            type: "text",
            name: "PartyName",
            visibleIf: "{PoolType} = 4",
            title: "Party Name",
            isRequired: true,
            "maxLength": 200
          },
          {
            type: "text",
            name: "DeceasedGuardRegNo",
            visibleIf: "{PoolType} = 3",
            title: "Reg. No of Deceased Guard",
            isRequired: true,
            "maxLength": 50
            // validators: [
            //   {
            //     type: "numeric",
            //     minValue: 1, // Minimum allowed value
            //     maxValue: 9999999999, // Maximum allowed value
            //     text: "Please enter a valid Deceased Guard number",
            //   },
            // ]
          },
          {
            type: "text",
            name: "DeceasedGuardName",
            visibleIf: "{PoolType} = 3",
            title: "Name of Deceased Guard",
            isRequired: true,
            "maxLength": 200
          },
          {
            type: "dropdown",
            name: "ApplicantGuardRelation",
            visibleIf: "{PoolType} = 3",
            title: "Relationship of the Applicant with Deceased Guard",
            isRequired: true,
            choices: [
              "Son",
              "Daughter",
              "Wife",
              "Husband",
              "Brother",
              "Sister",
            ],
            showOtherItem: true,
          },
          {
            type: "text",
            name: "HeirFirstName",
            visibleIf: "{PoolType}=3",
            title: "Legal Heir First Name",
            isRequired: true,
            "maxLength": 200
          },
          {
            type: "text",
            name: "HeirMiddleName",
            visibleIf: "{PoolType}=3",
            title: "Legal Heir Middle Name",
            isRequired: true,
            "maxLength": 200
          },
          {
            type: "text",
            name: "HeirLastName",
            visibleIf: "{PoolType}=3",
            title: "Legal Heir Last Name",
            isRequired: true,
            "maxLength": 200
          },
        ],
        title: "Details of the Pool Creation",
        description:
          "Mention the details regarding employment, court order or deceased guard",
      },
      {
        name: "page4",
        elements: [
          {
            type: "matrixdynamic",
            name: "ApplicantDetails",
            title: "Applicant Details",
            isRequired: true,
            columns: [
              {
                name: "FirstName",
                title: "First Name",
                isRequired: true,
              },
              {
                name: "MiddleName",
                title: "Middle Name",
                isRequired: true,
              },
              {
                name: "LastName",
                title: "Last Name",
                isRequired: true,
              },
              {
                name: "DOB",
                title: "DOB",
                cellType: "text",
                isRequired: true,
                width: "30px",
                inputType: "date"
               },
              {
                name: "Mobile",
                title: "Mobile",
                isRequired: true,
                isUnique: true,
                validators: [
                  {
                    type: "numeric",
                    minValue: 1, // Minimum allowed value
                    maxValue: 9999999999, // Maximum allowed value
                    text: "Please enter a valid Mobile number",
                  },
                ]
              },
              {
                name: "Email",
                title: "Email",
                isRequired: true,
                isUnique: true,
                validators: [
                  {
                    type: "email",
                    text: "Please enter a valid email address",
                  },
                ]
              },
              // {
              //   name: "AadharNo",
              //   title: "Aadhar No",
              //   isRequired: true,
              //   isUnique: true,
              //   validators: [
              //     {
              //       type: "numeric",
              //       minValue: 9, // Minimum allowed value
              //       maxValue: 999999999999, // Maximum allowed value
              //       text: "Please enter a valid Adhar number",
              //     },
              //   ]
                
                
              // },
              {
                "name": "AadharNo",
                "title": "Aadhar No",
                "cellType": "text",
                "isRequired": true,
                "isUnique": true,
                "inputType": "number",
                "min": 100000000000,
                "max": 999999999999
               }
            ],
            choices: [1, 2, 3, 4, 5],
            cellType: "text",
            rowCount: 1,
            minRowCount: 1,
            confirmDelete: true,
            allowRowsDragAndDrop: true,
          },
        ],
        title: "Applicant Details",
        description:
          "Add Applicant Name and Contact details (Mobile Number will be user id of the applicant)",
      },
    ],
    showCompletedPage: true,
    goNextPageAutomatic: false,
    firstPageIsStarted: false,
  },
};
