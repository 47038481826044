import Vue from 'vue'
// require("expose-loader?$!jquery")
// require("expose-loader?jQuery!jquery")

import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex';
import VueResource from 'vue-resource';
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/fontawesome-all.css'
import '@/assets/css/style.css'
import '@/assets/css/Site.css'
import VueCompositionApi from '@vue/composition-api';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueQRCodeComponent from 'vue-qrcode-component'

const options = {
  name: '_blank',
  specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
  ],
  styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);
 
Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(Vuex);
Vue.use(VueCompositionApi);
Vue.use(VuejsDialog);

Vue.component('qr-code', VueQRCodeComponent)

Vue.config.productionTip = false

/*Date Formatting */
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];
Vue.filter('formatDate', function (value) {
  if (!value) return '';
  if (value == '0001-01-01T00:00:00') return '';
  let dbDateTime = new Date(value);
  
      let formattedDate =
        dbDateTime.getDate() +
        "-" +
        months[dbDateTime.getMonth()] +
        "-" +
        dbDateTime.getFullYear();
      return formattedDate;
})
/*Date Formatting */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
