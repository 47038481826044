import Vue from 'vue'
import VueResource from 'vue-resource';
import store from '../store'

//var apibaseurl = "http://localhost:63606/api/"; //lambda local
//var apibaseurl = "https://localhost:44344/api/";
//var apibaseurl = "https://v24g13l91k.execute-api.ap-south-1.amazonaws.com/Prod/api/" //lambda Dev
//var apibaseurl = "https://d2v8797bj7.execute-api.ap-south-1.amazonaws.com/Prod/api/" //lambda Prod
var apibaseurl = "https://9lmwzmqfa6.execute-api.ap-south-1.amazonaws.com/Prod/api/" //lambda Prod


export default {
  login(user) {
    return Vue.http.post(apibaseurl + 'Login/ValidateUser', user, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  readSGBoardName() {
    return Vue.http.get(apibaseurl + 'Masters/ReadSecurityBoard/', {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readAppStatus() {
    return Vue.http.get(apibaseurl + 'Admin/ReadAppStatus/', {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readEvents(boardID) {
    return Vue.http.get(apibaseurl + 'Admin/ReadEvents/' + boardID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readRejectReason() {
    return Vue.http.get(apibaseurl + 'Admin/ReadRejectReason', {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readApplicationList(form) {
    return Vue.http.post(apibaseurl + 'Admin/ReadApplications/', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readscrollList(form) {
    return Vue.http.post(apibaseurl + 'admin/GetBFormScroll/', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },


  ViewProfileList(form){
    return Vue.http.post(apibaseurl + 'admin/ReadProfilesWONumber/', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readProfileList(form){
    return Vue.http.post(apibaseurl + 'Admin/ReadProfiles/', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readBFormDetails(AppID) {
    return Vue.http.get(apibaseurl + 'Registration/UserBFormDetail/' + AppID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },

  readBFormSummary(FormID) {

    return Vue.http.get(apibaseurl + 'Registration/UserBFormSummary/' + FormID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readUserDetail(user) {
    return Vue.http.post(apibaseurl + 'admin/ReadApplicationDetails', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readappprofile(user) {
    return Vue.http.post(apibaseurl + 'NewProfile/GetProfileForChanges', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  updateUserDetails(user) {
    return Vue.http.post(apibaseurl + 'NewProfile/UpdateProfile', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  updateDueDate(user) {
    return Vue.http.post(apibaseurl + 'NewProfile/ExtendDueDate', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  createBatch(form) {
    return Vue.http.post(apibaseurl + 'admin/SaveBatchSchedule', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadApplicationStatusByRoleStatus(form) {
    return Vue.http.post(apibaseurl + 'admin/RoleStatusMappingByAppStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  UpdateApplicationStatus(form) {
    return Vue.http.post(apibaseurl + 'admin/UpdateApplicationStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  UpdateXapplicationStatus(form) {
    return Vue.http.post(apibaseurl + 'Xadmin/UpdateApplicationStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  UnlockProfile(form) {
    return Vue.http.post(apibaseurl + 'admin/UnlockProfile', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  UnlockProfile1(form) {
    return Vue.http.post(apibaseurl + 'admin/UnlockProfile', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  getApplicationMarks(form) {
    return Vue.http.get(apibaseurl + 'admin/GetBatchList/' + form.EventID + '/' + form.startbatchDate + '/' + form.endbatchDate + '/' + form.BatchNumber , {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  getApplicationList(form) {
    return Vue.http.get(apibaseurl + 'admin/GetBatchApplicants/' + form.id, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadExemptionApplication(form) {
    return Vue.http.post(apibaseurl + 'XAdmin/ReadApplications', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  getApprovalStatus(form) {
    return Vue.http.get(apibaseurl + 'XAdmin/ReadAppStatus', {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadExemptAppStatusByRoleStatus(form) {
    return Vue.http.post(apibaseurl + 'XAdmin/RoleStatusMappingByAppStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  readExemptionUserDetail(user) {
    return Vue.http.post(apibaseurl + 'XAdmin/ReadApplicationDetails', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadStatusLog(form) {
    return Vue.http.post(apibaseurl + 'Admin/ReadStatusLog', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadRejectReason() {
    return Vue.http.get(apibaseurl + 'XAdmin/ReadRejectReason', {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadGuardList(form) {
    return Vue.http.post(apibaseurl + 'XAdmin/ReadGuardList', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadGuardDetails(form) {
    return Vue.http.post(apibaseurl + 'XAdmin/ReadGuardDetails', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadEmployerDetails(form) {
    return Vue.http.post(apibaseurl + 'XAdmin/ReadEmployerDetails', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadEmployerList(form) {
    return Vue.http.post(apibaseurl + 'XAdmin/ReadEmployerList', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  deleteBatch(form) {
    return Vue.http.post(apibaseurl + 'Admin/DeactivateBatch', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  saveMarks(form) {
    return Vue.http.post(apibaseurl + 'Admin/SaveMarks', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  savedata(form) {
    return Vue.http.post(apibaseurl + ' Events/SaveEvent', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  Listdetails(boardid) {
    return Vue.http.get(apibaseurl + 'Events/ReadEventIdByEventType/0/' + boardid, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  neweventsubmit(form) {
    return Vue.http.post(apibaseurl + 'Events/SaveEvent', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  deleteEvent(eventID) {
    return Vue.http.post(apibaseurl + 'Events/deleteEvent/', eventID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  addDetails(eventID) {
    return Vue.http.get(apibaseurl + '/Events/ReadEventByID/' + eventID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  updateDocStatus(form) {
    return Vue.http.post(apibaseurl + 'Admin/UpdateDocStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadBFormDetails(AppID) {
    return Vue.http.get(apibaseurl + 'Registration/UserBFormDetail/' + AppID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadMarksApplicationwise(AppID) {
    return Vue.http.get(apibaseurl + 'Admin/GetResultsByApplicationID/' + AppID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  updateDocStatus(form){
    return Vue.http.post(apibaseurl+  'Admin/UpdateDocStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  GetResults(form){
    return Vue.http.post(apibaseurl+  'Admin/GetResults', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  GetIntimationList(form){
    return Vue.http.post(apibaseurl+  'Admin/GetIntimationList', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  SearchResult(boardID){
    return Vue.http.post(apibaseurl+  'AgencyRegistration/ReadRegistrationList', boardID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  ReadRegistrationDetails(id, reg_type){
    return Vue.http.get(apibaseurl+  'AgencyRegistration/ReadRegistrationByID/' + id + "/" + reg_type, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  getAgencyGuardMapping(agencyId){
    return Vue.http.get(apibaseurl+  'AgencyRegistration/ReadAgencyGuardMapping/' + agencyId, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  publishResult(form){
    return Vue.http.post(apibaseurl+  'Admin/PublishResults', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  getdashboard(BoardID){
    return Vue.http.post(apibaseurl+  'Admin/Dashboard', BoardID, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  get_application_checklist(application_id){
    return Vue.http.get(apibaseurl+  'XAdmin/ReadExemtionCheckListByApplicationID/'+ application_id, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  save_application_checklist(data){
    return Vue.http.post(apibaseurl+  'XAdmin/SaveExemtionCheckList/', data, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  UpdateEmployerExemptionStatus(form) {
    return Vue.http.post(apibaseurl + 'Xadmin/UpdateApplicationEmployerStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  SaveExemtionInspectorReport(data) {
    return Vue.http.post(apibaseurl + 'Xadmin/SaveExemtionInspectorReport', data);
  },
  GetExemtionInspectorReport(data) {
    return Vue.http.post(apibaseurl + 'Xadmin/GetExemtionInspectorReport', data);
  },
  UpdateGuardExemptionStatus(form) {
    return Vue.http.post(apibaseurl + 'Xadmin/UpdateApplicationGuardStatus', form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  get_language(data)
  {
    return Vue.http.get("https://inputtools.google.com/request?text="+data+"&itc=mr-t-i0-und&num=13&cp=0&cs=1&ie=utf-8&oe=utf-8&app=demopage");
  },
  save_gr_details(data)
  {
    return Vue.http.post(apibaseurl + 'Xadmin/SaveGr', data, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },

  SendIntimationLetter(data) {
    return Vue.http.post(apibaseurl + 'Admin/SendIntimationLetter',data, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  savePTSpecial(form){
    return Vue.http.post(apibaseurl + 'Admin/SavePTSpecial',form, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
    });
  },
  saveConfirmPassword(user) {
    return Vue.http.post(apibaseurl + 'Login/saveConfirmPassword', user, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + store.getters.authToken
        }
    });
},
signup(user) {
  return Vue.http.post(apibaseurl + 'NewProfile/AddNewProfiles', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
deleterecord(user) {
  return Vue.http.post(apibaseurl + 'NewProfile/DeleteProfile', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
newprofilelist(user) {
  return Vue.http.post(apibaseurl + 'NewProfile/NewProfileListQuery', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
Applicantwisenewprofilelist(user) {
  return Vue.http.post(apibaseurl + 'NewProfile/ApplicantWiseNewProfileListQuery', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
createlogin(user) {
  return Vue.http.post(apibaseurl + 'NewProfile/CreateLogin', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
Resendlogin(user) {
  return Vue.http.post(apibaseurl + 'NewProfile/ResendMail', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
GetPoliceMedicalList(user) {
  return Vue.http.post(apibaseurl + 'Reports/GetPoliceMedicExemptions', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
GetGroundList(user) {
  return Vue.http.post(apibaseurl + 'Reports/GetGroundExemptions', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},
LogDetails(user) {
  return Vue.http.post(apibaseurl + 'NewProfile/ProfileEditLog', user, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + store.getters.authToken
      }
  });
},




}
