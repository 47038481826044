<template>
     <b-container class="newfont">
		<div id="printme">			
			<b-row>
			<b-col style="text-align:center">
				<img src="@/assets/images/logo_trans.png" class="float-left" width="80px">
				<h2> Security Guards Board</h2>
				<h4>Registration of Security Guards Working with Private Agency</h4>				
			</b-col>			
			</b-row>			
			<hr/>
			<!-- <b-row>
				<b-col md="1"></b-col>
				<b-col><strong>Registration Number:</strong> {{RegistrationNumber}}</b-col>
				<b-col></b-col>
				<b-col class="float-right"><strong>Date:</strong>{{PaymentDate}}</b-col>
			</b-row>
        <b-row>
		 <b-col md="4">Security Guards Board</b-col>
		 <b-col md="6">{{BoardName}}</b-col>
	    </b-row> -->
		 <div class="row">
			 <div class="col-md-10">
				 <b-row >
		 <b-col md="4">Full Name </b-col>
		 <b-col md="6">{{form.name}}</b-col>
		 <!-- <b-col md="2"> <img style="width:140px" :src="getImgUrl(form.GuardPhoto[0].content)"><br/>
                        
		 </b-col> -->
		 
	    </b-row>
		<b-row>
		  <b-col md="4">FatherName</b-col>
		  <b-col md="6">{{form.FatherName}}</b-col>
	    </b-row>
		<b-row>
		  <b-col md="4">Adhar No</b-col>
		  <b-col md="6">{{form.aadharno}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Address</b-col>
		  <b-col md="6">{{form.Address}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">State</b-col>
		  <b-col md="6">{{StateName}}</b-col>
	    </b-row>
       <b-row>
		 <b-col md="4">City</b-col>
		   <b-col md="6">{{DistrictName}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">PinCode</b-col>
		   <b-col md="6">{{form.PinCode}}</b-col>
	    </b-row>
	
	    <b-row>
		 <b-col md="4">Designation</b-col>
		   <b-col md="6">{{form.Designation}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">BirthDate</b-col>
		 <b-col md="6">{{form.BirthDate}}</b-col>
	    </b-row>
		 <b-row>
		 <b-col md="4">Age</b-col>
		  <b-col md="6">{{form.Age}}</b-col>

	    </b-row>
          <b-row>
		 <b-col md="4">Education</b-col>
		  <b-col md="6">{{form.Education}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Gender</b-col>
		  <b-col md="6">{{form.Gender}}</b-col>
		  
        </b-row>
		 <b-row>
		 <b-col md="4">Marital Status</b-col>
		  <b-col md="6">{{form.MaritalStatus}}</b-col>
	    </b-row>
         <b-row>
		 <b-col md="4">Agency Name</b-col>
		  <b-col md="6">{{form.AgencyName}}</b-col>
	    </b-row>
		 <b-row>
		 <b-col md="4">Job Duration</b-col>
		  <b-col md="6">{{form.JobDuration}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Principal Employer Name</b-col>
		  <b-col md="6">{{form.PrincipalEmpName}}</b-col>
	    </b-row>
		 <b-row>
		 <b-col md="4">Job Duration with Principal Employer</b-col>
		  <b-col md="6">{{form.PrincEmpJobDurtn}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Current Salary</b-col>
		  <b-col md="6">{{form.CurrSal}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Salary Days</b-col>
		  <b-col md="6">{{form.SalDate}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Salary Mode</b-col>
		  <b-col md="6">{{form.SalMode}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Is Salary Slip Provided to You ?</b-col>
		  <b-col md="6">{{form.SalSlip}}</b-col>
	    </b-row>
          <b-row>
		 <b-col md="4">Bank Name</b-col>
		  <b-col md="6">{{form.BankName}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Bank Branch Name</b-col>
		  <b-col md="6">{{form.BankBranch}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Bank Account Number</b-col>
		  <b-col md="6">{{form.AccNo}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Daily Working Hours </b-col>
		  <b-col md="6">{{form.WorkingHours}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Overtime Charges </b-col>
		  <b-col md="6">{{form.OTRate}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Weekly Off </b-col>
		  <b-col md="6">{{form.Weeklyoff}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Paid Leave</b-col>
		  <b-col md="6">{{form.PaidLeavesNum}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Paid Holidays</b-col>
		  <b-col md="6">{{form.PaidHolidayNum}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">Accidental Insurance</b-col>
		  <b-col md="6">{{form.AccidentInsurance}}</b-col>
	    </b-row>
        
		<b-row>
		 <b-col md="4">LTA  </b-col>
		  <b-col md="6">{{form.LTA}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">LTA Amount</b-col>
		  <b-col md="6">{{form.LTAAmount}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">HRA</b-col>
		  <b-col md="6">{{form.HRA}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">HRA Amount</b-col>
		  <b-col md="6">{{form['HRA Amount']}}</b-col>
	    </b-row>
		
        
        <b-row>
		 <b-col md="4">Do you Receive Bonus ?</b-col>
		  <b-col md="6">{{form.Bonus}}</b-col>
	    </b-row>
         <b-row>
		 <b-col md="4">Bonus Amount</b-col>
		  <b-col md="6">{{form.BonusAmt}}</b-col>
	    </b-row>
         <b-row>
		 <b-col md="4">Is Provident Fund Deducted From Salary?</b-col>
		  <b-col md="6">{{form.PF}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">PF No</b-col>
		  <b-col md="6">{{form.PFNo}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Is ESIC Deducted from Salary</b-col>
		  <b-col md="6">{{form.ESIC}}</b-col>
	    </b-row>
		<b-row>
		 <b-col md="4">ESIC No</b-col>
		  <b-col md="6">{{form.ESICNo}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Do You Get Medical Benifits ?</b-col>
		  <b-col md="6">{{form.MedicalBenifits}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Are you Covered under Accident Insurance ?</b-col>
		  <b-col md="6">{{form.AccidentInsu}}</b-col>
	    </b-row>
         <b-row>
		 <b-col md="4">Accident Insurance Amount?</b-col>
		  <b-col md="6">{{form.Amt}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Are You Permanent ?</b-col>
		  <b-col md="6">{{form.Permanant}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Have You Received Appointment Letter ?</b-col>
		  <b-col md="6">{{form.AppLetter}}</b-col>
	    </b-row>
         <b-row>
		 <b-col md="4">Is Uniform Provided By Agency ?</b-col>
		  <b-col md="6">{{form.Uniform}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4"> Do You Pay For Uniform ?</b-col>
		  <b-col md="6">{{form.PayForUniform}}</b-col>
	    </b-row>
        
		 
        <b-row>
		 <b-col md="4">No Of Years Of Service To Receive Uniform ?</b-col>
		  <b-col md="6">{{form.SrvcYrsUniform}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4"> Do You Hold Gun ?</b-col>
		  <b-col md="6">{{form.IsGunHolder}}</b-col>
	    </b-row>
         <b-row>
		 <b-col md="4">Do You Have Licence For Gun ?</b-col>
		  <b-col md="6">{{form.IsGunLicense}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">License Number of Gun</b-col>
		  <b-col md="6">{{form.GunLicenseNo}}</b-col>
	    </b-row>
        
        <b-row>
        <b-col md="4">Have You Received Training ?</b-col>
		  <b-col md="6">{{form.Traning}}</b-col>
	    </b-row>
        <b-row>
		 <b-col md="4">Traning Location </b-col>
		  <b-col md="6">{{form.TraningLocation}}</b-col>
	    </b-row>
          <b-row>
		 <b-col md="4">Union Name</b-col>
		  <b-col md="6">{{form.UnionName}}</b-col>
	    </b-row>
		 
		 
		 </div>
			 
			 
			 <div class="col-md-2" v-if="form.GuardPhoto"><img style="width:140px;height:140px;"  :src="getImgUrl(form.GuardPhoto[0].content)"></div>
		 </div>

        
		</div>
		<div class="reg-form-box">
                <h4 class="reg-form-title text-primary">Documents Uploaded
                </h4>
                <div class="reg-form-body">
                    <table class="table table-sm">
                        <tr>
                            <th style="text-align:left">Document Type</th>
                            <th style="text-align:left">Link</th>
                            
                        </tr>
                         <tr style="text-align:left" v-if="form.GuardPhoto">
	                        <td> Photo</td>
                             <td><a target="_blank" :href="getImgUrl(form.GuardPhoto[0].content)">View</a></td> 
                        </tr> 
                       
                       <!-- <tr v-if="personalDetails.UserDocuments.SignaturePath !=''"> 
                            <td>Signature</td>
                             <td><a target="_blank" :href="getImgUrl(personalDetails.UserDocuments.SignaturePath)">View</a></td>
                         </tr> -->

                        <tr style="text-align:left" v-if="form.AadharCard "> 
                            <td>AdharCard</td>
                             <td><a target="_blank" :href="getImgUrl(form.AadharCard[0].content)">View</a></td> 
                        </tr>
						  <tr style="text-align:left" v-if="form.PoliceVerification[0].content !=''">
                            <td>PoliceVerification</td>
                            <td>
								<a target="_blank" :href="getImgUrl(form.PoliceVerification[0].content)">View</a>
								<!-- {{form}} -->
							</td> 
                         </tr> 

                      

                        
                       

                       

                       

                       

                       

                       

                        
                    </table>
                </div>
            </div>	
		<b-row>
		<b-col>
			<b-button class="float-right mt-2" variant="info" @click="print">Print</b-button>
		</b-col>
		</b-row>		
     </b-container>
</template>

<script>
import apis from "@/apis";
export default {
  props: ["id"],
  data() {
    return {
      form: {},
      RegistrationNumber: "",
      PaymentDate: "",
      DistrictName: "",
      StateName: "",
      BoardName: "",
      RegistrationID: ""
    };
  },
  methods: {
    print() {
      this.$htmlToPaper("printme");
    },
	getImgUrl(pic) {
		if(pic)
		{
		let userinfo = this.$store.getters.loggedUser;
		let userId = userinfo.UserId;
		if (pic) {
			return (
			pic
			);
		}
		}
    },
    readProfile(type) {
      let promise = apis.ReadGuardDetails({ID:this.id});
      promise
        .then(response => {
          //console.log('Reponse' ,response);

          if (
            response.status == 200 &&
            response.body != "Error in Registration"
          ) {
           
            this.form = JSON.parse(response.body[0].GuardProfile);
			this.DistrictName=response.body[0].DistrictName;
			this.StateName=response.body[0].StateName;

			// alert(this.form.PoliceVerification);
			if(this.form.PoliceVerification==undefined)
			{
				this.form.PoliceVerification=[];
				this.form.PoliceVerification.push({'content':''});
			}

            // this.BoardName = response.body.BoardName;
            // this.PaymentDate = response.body.PaymentDate;
            // this.RegistrationNumber = response.body.RegistrationNumber;
            // this.DistrictName = response.body.DistrictName;
            // this.StateName = response.body.StateName;
          } else {
            this.form = {};
          }
        })
        .catch(err => {
          this.$dialog.alert("Error In Loading previous Details.");
        });
    }
  },
  mounted() {
    this.readProfile();
  }
};
</script>

<style>
.newfont {
  font-size: 0.874rem;
}
</style>
