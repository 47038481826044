<template>
  <b-container class="newfont">
    <div id="printme">
      <b-row class="my-2">
		    <b-col style="text-align:center">
          <img src="@/assets/images/logo_trans.png" class="float-left" width="80px">
          <h4>Security Guards Board</h4>
          <h5>Registration of Principal Employer</h5>
        </b-col>
			</b-row>
			<hr/>
			<b-row class="my-2">
				<b-col md="1"></b-col>
				<b-col><strong>Registration Number:</strong> {{RegistrationNumber}}</b-col>
				<b-col></b-col>
				<b-col class="float-right"><strong>Date:</strong>{{PaymentDate}}</b-col>
			</b-row>
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Board</b-col>
      <b-col md="6">{{BoardName}}</b-col>
    </b-row>
     <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Application No:</b-col>
      <b-col md="6">{{app_no}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Employer Name:</b-col>
      <b-col md="6">{{form.EmployerName}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Employer Address</b-col>
      <b-col md="6">{{form.EmployerAddress}}</b-col>
    </b-row>
    
    
	 <!-- <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">City</b-col>
      <b-col md="6">{{form.City}}</b-col>
    </b-row> -->
    
    
	 <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Pincode</b-col>
      <b-col md="6">{{form.Pincode}}</b-col>
    </b-row>
    
    

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">TelNo</b-col>
      <b-col md="6">{{form.TelNo}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Email ID</b-col>
      <b-col md="6">{{form.EmailId}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Type of Business</b-col>
      <b-col md="6">{{form.BusinessType}}</b-col>
    </b-row>
    
    

    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">FactoryType</b-col>
      <b-col md="6">{{form.FactoryType}}</b-col>
    </b-row>
    
    
	<b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Establishment Is</b-col>
      <b-col md="6">{{form.WhetherTheEstablishmentIs}}</b-col>
    </b-row>
    
    

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Date Of Incorporation</b-col>
      <b-col md="6">{{form.IncorporationDate}}</b-col>
    </b-row>
    
    

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Registration</b-col>
      <b-col md="6">{{form.RegistrationNo}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Registration Date</b-col>
      <b-col md="6">{{form.RegistrationDate}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Owners/Partners/Directors Details</b-col>
    </b-row>  
    <div>
      <b-row class="my-2">
      <b-col md="1"></b-col><b-col>
      <table>
        <tr>
          <th>Owner Name</th>
          <th>Owner Address</th>
          <th>Owner Mobile</th>
          <th>Owner Email</th>
        </tr>
        <tr v-for="owner in form.OwnerDetails" :key="owner.OwnerName">

          <td>{{owner.OwnerName}}</td>
          <td>{{owner.OwnerAddress}}</td>
          <td>{{owner.OwnerMobile}}</td>
          <td>{{owner.OwnerEmail}}</td>
        </tr>
      </table>
      </b-col>
      </b-row>
      </div>


    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Details of the Guards Employed</b-col>
    </b-row>  
    <div>
      <b-row class="my-2">
      <b-col md="1"></b-col><b-col>
      <table>
        <tr>
          <th>Class</th>
          <th>Male</th>
          <th>Female</th>
          <th>Basic Salary</th>
          <th>DA</th>
          <th>Other Allowance</th>
          <th>total Salary</th>
        </tr>
        <tr>
          <td>SecurityGuards</td>
          <td>{{form.SecurityGuards.TotalMaleGuards}}</td>
          <td>{{form.SecurityGuards.TotalFemaleGuards}}</td>
          <td>{{form.SecurityGuards.BasicSalary}}</td>
          <td>{{form.SecurityGuards.DA}}</td>
          <td>{{form.SecurityGuards.OtherAllowance}}</td>
          <td>{{form.SecurityGuards.TotalSalary}}</td>
        </tr>
        <tr>
          <td>SecuritySupervisor</td>
          <td>{{form.SecuritySupervisor.TotalMaleSupervisor}}</td>
          <td>{{form.SecuritySupervisor.TotalFemaleSupervisor}}</td>
          <td>{{form.SecuritySupervisor.BasicSalary}}</td>
          <td>{{form.SecuritySupervisor.DA}}</td>
          <td>{{form.SecuritySupervisor.OtherAllowance}}</td>
          <td>{{form.SecuritySupervisor.TotalSalary}}</td>
        </tr>
        <tr>
          <td>SecurityOfficer</td>
          <td>{{form.SecurityOfficer.TotalMaleOfficer}}</td>
          <td>{{form.SecurityOfficer.TotalFemaleOfficer}}</td>
          <td>{{form.SecurityOfficer.BasicSalary}}</td>
          <td>{{form.SecurityOfficer.DA}}</td>
          <td>{{form.SecurityOfficer.OtherAllowance}}</td>
          <td>{{form.SecurityOfficer.TotalSalary}}</td>
        </tr>
        <tr>
          <td>HeadSecurityOfficer</td>
          <td>{{form.HeadSecurityOfficer.TotalMaleHeadOfficer}}</td>
          <td>{{form.HeadSecurityOfficer.TotalFemaleHeadOfficer}}</td>
          <td>{{form.HeadSecurityOfficer.BasicSalary}}</td>
          <td>{{form.HeadSecurityOfficer.DA}}</td>
          <td>{{form.HeadSecurityOfficer.OtherAllowance}}</td>
          <td>{{form.HeadSecurityOfficer.TotalSalary}}</td>
        </tr>
      </table>
      </b-col>
      </b-row>
    </div>

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Do You Transfer Guards?</b-col>
      <b-col md="6">{{form.Transfer}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Have You completed Police Verification of Guards?</b-col>
      <b-col md="6">{{form.PoliceVerification}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4" v-if="form.PoliceVerification=='No'"> Reason for No Police Verification:</b-col>
      <b-col md="6" v-if="form.PoliceVerification=='No'">{{form.NonVerificationReason}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Have You Recruited Guards from the Security Guards Board?</b-col>
      <b-col md="6">{{form.BoardsGuardsHistory}}</b-col>
    </b-row>
     <!-- <div class="col-md-2" ><img style="width:140px;height:140px;"  :src="getImgUrl()"></div> -->
		 
    <div class="reg-form-box">
                <h4 class="reg-form-title text-primary">Documents Uploaded
                </h4>
                <div class="reg-form-body">
                    <table class="table table-sm">
                        <tr>
                            <th style="text-align:left">Document Type</th>
                            <th style="text-align:left">Link</th>
                            
                        </tr>
                         <tr style="text-align:left">
	                        <td> Agreement Copy with Principal Agency </td>
                             <td><a target="_blank" :href="getImgUrl(form.AgreementCopy[0].content)">View</a></td> 
                        </tr> 
                       
                       <!-- <tr v-if="personalDetails.UserDocuments.SignaturePath !=''"> 
                            <td>Signature</td>
                             <td><a target="_blank" :href="getImgUrl(personalDetails.UserDocuments.SignaturePath)">View</a></td>
                         </tr> -->

                        <tr style="text-align:left "> 
                            <td>PAN Card</td>
                             <td><a target="_blank" :href="getImgUrl(form.PAN[0].content)">View</a></td> 
                        </tr>
						  <tr style="text-align:left" >
                            <td>GST Registration Certificate</td>
                            <td>
								<a target="_blank" :href="getImgUrl(form.GSTRegistration[0].content)">View</a>
								<!-- {{form}} -->
							</td> 
                         </tr> 

                        
                    </table>
                </div>
            </div>	
            
   <!-- <div class="application-view">
      <h5 class="reg-form-title text-info" style="margin-left:12%;margin-bottom:2%;">Approval Processs</h5>
      <b-form >
        <b-row>
          <b-col class="py-0" md="4" offset-md="2">
            <b-form-group
              id="grpSts"
              label-cols="2"
              label="Status"
              label-size="sm"
              label-for="ipSts"
            >
              <b-select
                id="ipSts"
                class="form-control"
                value-field="StatusID"
                size="sm"
                text-field="Status"
                :options="approval"
                v-model="form.ApplicationStatus"
                @change.native="checkReject"
              ></b-select>
            </b-form-group>
          </b-col>

          <b-col class="py-0" md="4">
            <b-form-group
              id="grpReason"
              label-cols="3"
              label="Reject Reason"
              label-size="sm"
              label-for="ipReason"
            >
              <b-select
                id="ipReason"
                :options="reject"
                value-field="RejectionReasonId"
                text-field="RejectionReason"
                required
                size="sm"              
                v-model="form.RejectReason"
                class="form-control"
              ></b-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="py-0" md="8" offset-md="2">
            <b-form-group
              id="grpRemarks"
              label-cols="1"
              label="Remark"
              label-size="sm"
              label-for="ipRmk"
            >
              <b-form-textarea
                id="ipRemarks"
                v-model="form.Remarks"
                size="sm"
                rows="2,"
                max-rows="4"
                placeholder="Remarks"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <center>
              <b-button
                class="hide-print"
                type="submit"
                variant="primary"
                size="sm"
               
                >Save Status</b-button
              >
            </center>
          </b-col>
        </b-row>
      </b-form>
    </div> -->

            
    </div>
    <b-row class="my-2">
      <b-col>
    <!-- <b-btn variant="success" class="float-right mt-2" size="sm" v-print="'#printMe'">Print Receipt</b-btn> -->
          <b-button class="float-right mt-2" variant="info" @click="print">Print</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import apis from "@/apis";
import vueHtmlToPaper from "vue-html-to-paper";
export default {
  props: ["id"],
  data() {
    return {
      approval: [],
      reject:[],
      show: true,
      form: {},
      RegistrationID:"",
      app_no:"",
      DistrictName:""
    };
  },
  methods: {
    //   onSubmit(evt) {
     
    //   evt.preventDefault();
    //   //alert(this.GuardApprovalStatus);
    //   this.form.ApplicationID = this.app_id;
    //   this.form.UserID = this.user_id;

    //   if (this.form.ApplicationStatus == this.ApplicationStatus) {
    //     return;
    //   }
    //   if (this.form.ApplicationStatus==2 || this.form.ApplicationStatus==6  &&(this.EmployerApprovalStatus==false || this.GuardApprovalStatus==false || this.CheckListStatus==false) )
    //   {
    //      //alert(this.GuardApprovalStatus )
    //     if (this.EmployerApprovalStatus==false) {
    //        this.$dialog.alert("Approved failed..Employer Approved Pending");
    //        return false;
    //     }
    //     if (this.GuardApprovalStatus==false) {
    //        this.$dialog.alert("Approved failed..Guard Approved Pending");
    //        return false;
    //     }
    //      if (this.CheckListStatus==false) {
    //        this.$dialog.alert("Approved failed..Checklist Approved Pending");
    //         return false;
    //     }
       
    //   }
    //   //console.log(this.form);
    //   let promise = apis.UpdateXapplicationStatus(this.form);

    //   promise
    //     .then((response) => {
    //       if (response.status == 200) {
    //         this.$dialog.alert("Approved Succesfully..");
    //       } else {
    //         this.$dialog.alert(response.body);
    //       }
    //     })
    //     .catch((err) => {
    //       this.$dialog.alert(err.message);
    //     });
    // },
    print() {
      this.$htmlToPaper("printme");
    },
    getImgUrl(pic) {
		if(pic)
		{
		let userinfo = this.$store.getters.loggedUser;
		let userId = userinfo.UserId;
		if (pic) {
			return (
			pic
			);
		}
		}
    },
    readProfile() {
      let promise = apis.ReadEmployerDetails({ID:this.id});

      promise
        .then(response => {
          //console.log('Reponse' ,response);
          if (
            response.status == 200 &&
            response.body != "Error in Registration"
          ) {
           
            this.form = JSON.parse(response.body[0].EmployerProfile);
          this.app_no = response.body[0].ApplicationNum;

            // this.BoardName = response.body.BoardName;
            // this.PaymentDate = response.body.PaymentDate;
            // this.RegistrationNumber = response.body.RegistrationNumber;
            // this.DistrictName = response.body.DistrictName;
            // this.StateName = response.body.StateName;
          } else {
            this.form = {};
          }
        })
        .catch(err => {
          console.log('ERROR', err);
          this.$dialog.alert("Error In Loading previous Details.");
        });
    }
  },
  mounted() {
    this.readProfile();
     this.approval = this.ApprovalStatus;
     this.reject = this.RejectReasons;
  }
};
</script>
		<style scoped>
table {
  font-family: arial, sans-serif;
  /* border-collapse: collapse; */
  width: 80%;
  margin-left: 10%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
}
.newfont {
  font-size: 0.875rem;
}
</style>
		
		