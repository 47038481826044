<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-info">
            <!-- <div class="panel-heading">
              <b> UPLOAD PHYSICAL AND GROUND MARKS (BULK):</b>
            </div> -->
            <div class="p-3 mb-2 bg-secondary text-white"><b>UPLOAD PHYSICAL AND GROUND MARKS :</b></div>
            <div class="panel-body">
              <!-- <b-container> -->
              <b-form
                @submit="Submit"
                @reset="onReset"
                style="background-color: white"
              >
                <div class="form-row">
                  <b-col class="mt-3" md="12">
                    <b-form-group
                      style="width: 60%"
                      id="grp2"
                      label-cols="3"
                      label="Board*"
                      label-size="sm"
                      label-for="ipBoard"
                    >
                      <b-select
                        id="ipBoard"
                        :options="boards"
                        value-field="SecurityBoardID"
                        text-field="SecurityBoardName"
                        required
                        size="sm"
                        :disabled="
                          value.BoardID !=
                          '00000000-0000-0000-0000-000000000000'
                        "
                        v-model="value.BoardID"
                        class="form-control"
                      ></b-select>
                    </b-form-group>
                  </b-col>
                </div>
                <div class="form-row">
                  <b-col class="mt-3" md="12">
                    <b-form-group
                      id="grp1"
                      style="width: 60%"
                      label-cols="3"
                      label="Pool Type*"
                      label-size="sm"
                      label-for="ipPoolType"
                    >
                      <b-select
                        id="ipPoolType"
                        class="form-control"
                        value-field="EventID"
                        size="sm"
                        text-field="EventTitle"
                        :options="events"
                        v-model="value.EventID"
                      ></b-select>
                    </b-form-group>
                  </b-col>
                </div>

                <!-- <div class="form-row"> -->
                <!-- <b-col class="mt-3" md="12">
              <b-form-group
                id="grp1"
                style="width: 60%"
                label-cols="3"
                label="Ganerate Merit For All*"
                label-size="sm"
                label-for="ipPoolType"
              >
                <b-select
                  id="ipgndr"
                  class="form-control"
                  :options="ExSvcOptions"
                  v-on:change="temp(value.ExServiceMan)"
                  value-field="Id"
                  text-field="Value"
                  required
                  size="sm"
                  v-model="value.ExServiceMan"
                ></b-select>
              </b-form-group>
            </b-col> -->
                <!-- </div> -->
                <div class="form-row">
                  <b-col md="12" class="mt-3">
                    <b-form-group
                      style="width: 60%"
                      id="grp1"
                      label-cols="3"
                      label="Upload File*"
                      label-size="sm"
                      label-for="ipPoolType"
                    >
                      <input
                        type="file"
                        class="form-control"
                        id="rfp_file_document"
                        ref="rfp_file_document"
                        v-on:change="handleFileUpload()"
                        placeholder
                        tabindex="7"
                      />
                    </b-form-group>
                  </b-col>
                </div>
                <div class="form-row">
                  <b-col md="12" class="mt-3">
                    <b-form-group
                      style="width: 60%"
                      id="grp1"
                      label-cols="3"
                      label="Upload Remark*"
                      label-size="sm"
                      label-for="ipPoolType"
                    >
                      <textarea
                        type="text"
                        class="form-control"
                        v-model="remark"
                        placeholder
                        tabindex="7"
                      />
                    </b-form-group>
                  </b-col>
                </div>
                <!-- <div class="row">
           <div class="form-group col-md-12">
                    <label for="inputUser3" class="col-sm-4 control-label">Remark</label>
                    <div class="col-sm-8">
                      <textarea
                        rows="2"
                        v-model="remark"
                        style="width: 100%; border-color: rgb(207, 207, 207); border-width: 1px; border-style: solid; border-radius: 0px;"
                      ></textarea>
                    </div>
                  </div>
            </div> -->
                <button
                  type="button"
                  @click.stop="Submit()"
                  title="Submit"
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#Submit"
                >
                  Submit</button
                >&nbsp;
                <b-button
                  type="reset"
                  @click.stop="onReset()"
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#reset"
                  >Reset</b-button
                >
              </b-form>
              <!-- </b-container> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import apis from "@/apis";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";

import DatePicker from "vue2-datepicker";

//import ApplicationFilter from "../components/ApplicationFilter.vue";
//import MarksEntry from "../components/MarksEntry.vue";
//alert(1);
export default {
  // props: ["userBoardID", "EventTitle"],
  props: ["value"],
  components: { DatePicker, Spinner },
  data() {
    return {
      form: this.value || {},
      ExSvcOptions: [
        { Id: 1, Value: "Yes" },
        { Id: 3, Value: "No" },
      ],

      showBtn: false,
      selectAll: "",
      FemaleselectAll: "",
      select: "",
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      pendingWithList: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      appDate: null,
      all_candidate: "",
      // EventTitle:"",
      value2: "",
      value1: "",
      status: true,
      no_of_posts_for_male: "",
      no_of_posts_for_female: "",
      no_of_posts: "",
      no_of_posts1: "",
      no_of_posts4: "",
      no_of_posts2: "",
      no_of_posts3: "",
      discriptions: "",
      rfp_file_document: "",
      BoardID: "",
      appStatus: "",
      disable_button: false,
      remark: "",
      genderList: this.$store.getters.getGenderList,
      viewfields: [
        { key: "serial_number", label: "Sr.No", sortable: true },
        { key: "GazetteReferenceNo", label: "Application No", sortable: true },
        { key: "no_of_guards", label: "Name", sortable: true },
        { key: "no_of_pf", label: "Ground Marks", sotable: true },
        { key: "agency_code", label: "Education marks", sortable: true },
        { key: "status", label: "Total Marks", sortable: true },
        { key: "my_action", label: "Date of Birth" },
        { key: "actions5", label: "Rank" },
      ],
      Board: "Amravati District Security Guards ",
      fields: [
        { key: "selected", label: "Selected" },
        { key: "Sr", label: "SrNo" },
        { key: "EventTitle", label: "Event Title", sortable: true },
        {
          key: "Createdate",
          label: "Application Date",
          sortable: true,
          formatter: "convertDateFormat",
        },
        { key: "EventTitle", label: "Event Title", sortable: true },
        { key: "EventTotalPosts", label: "Total Posts", sortable: true },
        { key: "EventMalePost", label: "Posts for Male", sortable: true },
        { key: "EventFemalePosts", label: "Posts for Female", sortable: true },
        {
          key: "ApplicationStartDt",
          label: "Application Start Date",
          sortable: true,
        },
        {
          key: "ApplicationEndDt",
          label: "Application End Date",
          sortable: true,
        },
        { key: "action", label: "View" },
      ],
      selected: [],
      form: {
        BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        EventTitle: "",
        EventDescription: "",
        EventTotalPosts: "",
        EventMalePosts: "",
        EventFemalePosts: "",
        ApplicationStartDt: "",
        ApplicationEndDt: "",
        IsActive: "",
        EventDetailsFile: "",
      },
      showbtn: false,
      formBSC: {
        BSCID: 0,
        BoardID: "",
        MeetingDate: null,
        ApprovalStatus: 13,
        ApprovalDoc: "",
        UserID: "",
        ApplicationIDs: [],
      },
      selectedApplication: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
      store_id: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    Submit() {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    },
    onReset() {
      this.reset();
      evt.preventDefault();
      let board = this.form.BoardID;
      this.form = {
        BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        remark: "",
        Upload_File: "",
      };
    },
    reset() {
      this.remark = "";
    },

    // View_data(item, index, button, n) {
    //   //alert(JSON.stringify(item));
    //   // this.selectedApplication = item.UserAppllicationID;
    //   this.$root.$emit("bv::show::modal", "mdlMarks");
    // },
    temp(data) {
      //alert(value.ExServiceMan);
      if (data == "Yes") {
        this.disable_button = true;
      } else {
        this.disable_button = false;
      }
    },

    handleFileUpload() {
      this.rfp_file_document = this.$refs.rfp_file_document.files[0];
      // alert(this.rfp_file_document.name)
    },

    onSubmit1(evt) {
      //console.log();
      var postfile = this.rfp_file_document;
      var v1 = this.value1.split(" ");
      var v2 = this.value2.split(" ");
      var date1 = v1[0].split("-");
      var date1 = date1[2] + "/" + date1[1] + "/" + date1[0] + " " + v1[1];
      var date2 = v2[0].split("-");
      var date2 = date2[2] + "/" + date2[1] + "/" + date2[0] + " " + v2[1];

      //alert(value1);
      // alert(date2);
      var data = {
        EventID: "00000000-0000-0000-0000-000000000000",
        EventTitle: this.EventTitle,
        EventDescription: this.discriptions,
        EventTotalPosts: this.no_of_posts,
        EventMalePosts: this.no_of_posts_for_male,
        EventFemalePosts: this.no_of_posts_for_female,
        ApplicationStartDt: date1,
        ApplicationEndDt: date2,
        IsActive: false,
        BoardID: this.userBoard,
        EventDetailsFile: this.rfp_file_document.name,
      };
      let promise = apis.neweventsubmit(data);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "";
            this.variant = "success";
            this.showDismissibleAlert = true;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.showDismissibleAlert = false;
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      this.formBatch.LoginID = LoginID;
      //console.log("SELECTED", this.selected);
      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array
        this.formBatch.EventID.push(this.selected[i].LoanApplicationID);
      }
      // this.selected.forEach(function(data, index) {
      //   this.formBatch.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });
      let promise = apis.createBatch(this.formBatch);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "";
            this.variant = "success";
            this.showDismissibleAlert = true;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      //this.getApplicationsSub();
    },

    onSubmit(evt) {
      evt.preventDefault();
      this.showBtn = false;
      this.showDismissibleAlert = false;
      if (this.formBSC.ApprovalDoc == "") {
        this.alertText =
          "Approval File is not yet Uploaded. Please check the file icon before submit.";
        this.showDismissibleAlert = true;
        return;
      }
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      // this.formBSC.UserID = LoginID;
      // this.formBSC.BoardID = this.form.BoardID;
      //console.log("SELECTED", this.selected);
      for (var i = 0; i < this.selected.length; i++) {
        //loop through the array
        this.formBSC.ApplicationIDs.push(this.selected[i].LoanApplicationID);
      }
      //console.log(this.formBSC);
      // this.selected.forEach(function(data, index) {
      //   this.form.ApplicationIDs.push(Object.values(data.ApplicationID));
      // });
      let promise = apis.UpdateBSCDetails(this.formBSC);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.alertText = "Data Saved Successfully";
            this.variant = "success";
            this.showDismissibleAlert = true;
            //this.ResetBSCForm();
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
            this.showBtn = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
          this.showBtn = false;
        });
    },
    // onReset(evt) {
    //   evt.preventDefault();
    // },
    ResetBSCForm() {
      this.$refs.finp.reset();
      this.bscReportPath = "";
      // this.formBSC = {
      //   BSCID: 0,
      //   BoardID: "",
      //   MeetingDate: null,
      //   ApprovalStatus: 13,
      //   ApprovalDoc: "",
      //   UserID: "",
      //   ApplicationIDs: [],
    },
    deleteDocument(item, index, button) {
      //console.log(item);
      this.selecteObj.Document = item.Document;
      this.selecteObj.Path = item.Path;
      let info = "You want to delete record for:" + "\n" + item.Document;
      this.modalInfo = info;
      this.$root.$emit("bv::show::modal", "modalConfirm");
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.isLoadingFile = true;
      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1].toLowerCase();
      }
      if (extn != "jpeg" && extn != "png" && extn != "jpg" && extn != "pdf") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.finp.reset();
        this.isLoadingFile = false;
        return;
      }
      let dt = Date.now();
      var filetype = files[0].type;
      var filepath = "bsc_" + dt + "." + extn;
      let promisefoto = lambda.getSignedURL("msobcfdc_bsc", filepath, filetype);
      promisefoto
        .then((response) => {
          let signedUrl = response;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": files[0].type,
            },
          };
          return axios.put(signedUrl, files[0], options);
        })
        .then((res) => {
          if (res.status == 200) {
            //this.formBSC.ApprovalDoc = filepath;
            this.showBtn = true;
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoadingFile = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoadingFile = false;
        });
    },

    // props: ["userBoardID"],
    //components: { datetime, Spinner},
    mounted() {
      //alert(1);
      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      // console.log(LoginID);
      // this.approvalStatus = this.$store.getters.getApprovalStatus;
      //this.boards = this.$store.getters.getBoards;
      this.BoardID = this.userBoard;

      // this.events = this.$store.getters.getEvents;
      let frm = this.$store.getters.getAppSearchForm;
      if (frm != {} && frm.ApplicationStatus != undefined) {
        this.form = frm;
        this.getApplications();
      }
    },
  },
  created() {
    //alert(1);
    var EventTitle = this.$route.params.EventTitle;
    var EventTotalPosts = this.$route.params.EventTotalPosts;
    var EventMalePosts = this.$route.params.EventMalePosts;
    var EventFemalePosts = this.$route.params.EventFemalePosts;
    var ApplicationStartDt = this.$route.params.ApplicationStartDt;
    var ApplicationEndDt = this.$route.params.ApplicationEndDt;
    var EventID = this.$route.params.EventID;
    var BoardID = this.$route.params.BoardID;
    var IsActive = this.$route.params.IsActive;
    var EventDetailsFile = this.$route.params.EventDetailsFile;
    var EventDescription = this.$route.params.EventDescription;

    this.BoardID = BoardID;
    this.EventTitle = EventTitle;
    this.discriptions = EventDescription;
    this.no_of_posts = EventTotalPosts;
    this.no_of_posts_for_male = EventMalePosts;
    this.no_of_posts_for_female = EventFemalePosts;
    this.value1 = ApplicationStartDt;
    this.value2 = ApplicationEndDt;
    this.status = IsActive;
    this.valrfp_file_documentue2 = EventDetailsFile;

    //   this.store_id = this.$route.params.id;
    //   this.EventTitle = this.$route.params.id;
    //   var boardname="";
    //    this.$store.getters.getBoards.forEach(item => {
    //      if(item.SecurityBoardID == this.$store.getters.getAppSearchForm.BoardID){
    //        boardname = item.SecurityBoardName;
    //      }
    //    });
    //    consloe.log(boardname);
    //   let frm = this.$store.getters.getAppSearchForm;
    //   if (frm != {} && frm.ApplicationStatus != undefined) {
    //     this.form = frm;
    //     this.getApplications();
    //   }
  },
};
</script>
<style>
.month-setter,
.nav-r,
.nav-l,
.year-month-wrapper,
.activePort {
  background-color: #03406c !important;
}
.days,
.okButton {
  color: #03406c !important;
}
.port:hover {
  color: #03406c !important;
}
.activePort:hover {
  color: white !important;
}
#tj-datetime-input {
  width: 250px !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.panel-heading {
  border-color: #00bbd9;
  color: #fff;
  background-color: #00bbd9;
  block-size: 50px;
}
</style>
