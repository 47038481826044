<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="form-row">
        <b-col class="mt-3" md="6">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              :disabled="
                value.BoardID != '00000000-0000-0000-0000-000000000000'
              "
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" md="6">
          <b-form-group
            id="grpRegType"
            label-cols="2"
            label="Registration Type"
            label-size="sm"
            label-for="ipRegTyp"
          >
            <b-select
              id="ipRegTyp"
              :options="RegTypes"
              required
              size="sm"
              v-model="value.RegType"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
      </div>
      <div class="form-row">
        <b-col md="6" class="mt-1">
          <b-form-group
            id="grp1"
            label-cols="2"
            label="Pool Type"
            label-size="sm"
            label-for="ipPoolType"
          >
            <b-select
              id="ipPoolType"
              class="form-control"
              value-field="EventID"
              size="sm"
              text-field="EventTitle"
              :options="events"
              v-model="value.EventID"
            ></b-select>
          </b-form-group>
        </b-col>
    
     
      </div>
      <b-row>
        <b-col md="6" class="mt-1">
          <b-form-group
            id="grpEmp"
            label-cols="2"
            label="Employer Name"
            label-size="sm"
            label-for="ipEmpNum"
            description="Please check the results thoroughly as search word 'adani' will also search for 'hiranadani'."
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              v-model="value.EmployerName"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" class="mt-1">
          <b-form-group
            id="grpEmp"
            label-cols="2"
            label="Applicant Name"
            label-size="sm"
            label-for="ipAplnctNum"
            description="Please check the results thoroughly as search word 'adani' will also search for 'hiranadani'."
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAplnctNum"
              v-model="value.ApplicantName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
      
        <b-col md="4" class="mt-1">
          <b-form-group
            id="grp4"
            label-cols="3"
            label="Mobile"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.MobileNo"
            />
          </b-form-group>
        </b-col>
       

        <b-col class="mt-1" md="2">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect,DatePicker },
  props: ["boards", "events", "approvalStatus", "value", "name"],

  data() {
    return {
      RegTypes: [
        // { value: 0, text: "New Guard" },
        { value: 1, text: "Affected" },
        { value: 2, text: "Recommended by Principal Employer" },
        { value: 3, text: "Compassionate" },
        { value: 4, text: "Court Order" },
      ],
      fileAppnum: null,
      ExSvcOptions: [
        { Id: 0, Value: "No" },
        { Id: 1, Value: "Yes" },
        { Id: 3, Value: "All" },
      ],
      appDate: null,
      name_status: "",
      name_data: "",
      appStatus: [
        //{ Id: 0, Value: "All" },
        { Id: 1, Value: "Yes" },
        { Id: 0, Value: "No" },
      ],
      appStatusAll: [],

      genderList: this.$store.getters.getGenderList,
    };
  },
  mounted() {
    this.appStatus = this.approvalStatus;

    if (this.events[0].EventTitle == "All") {
      this.events.shift();
    }
    if (this.name[0].name == "Applications") {
      this.name_status = true;
    }
  },

  methods: {
     filter(data){
    const statusesToCheck = ["All"];
    const allStatusesPresent = statusesToCheck.every(ApplicationStatus => data.some(item => item.ApplicationStatus === ApplicationStatus));
    //console.log(allStatusesPresent);
    if (allStatusesPresent) 
    {
      //console.log("All status values are present in the JSON array.");
      this.form.selectValue=[];
      this.form.selectValue=this.appStatus;
      
    } 
   },
    onSubmit(evt) {
      this.isloading = true;
      evt.preventDefault();
      this.$emit("submit");
      this.isloading = false;
    },

    onReset(evt) {
      evt.preventDefault();
      //console.log(this.value);
      this.value.ApplicationStatus=0;
      this.value.RegType="";
    this.value.Gender=3;
    this.value.EmployerName="";
    this.value.ApplicationNo="";
    this.value.MobileNo="";
    this.value.ExServiceMan=3;
    this.value.PinCode=null;
    this.value.RegistrationNo="";
    this.fileAppnum="";
    this.value.EventID="";
    this.value.ProfileNumber=null;
    this.value.ApplicantName="";

      

    },
  },
};
</script>
<style scoped>
.multi-select {
  height: auto;
  max-height: 120px; /* Set your desired maximum height here */
  overflow-y: auto;
}
</style>
