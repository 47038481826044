<template>

  <b-container>
    

    <application-filter
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      @submit="onSubmit"
      @change="onChange"
      @reset="onReset"
    ></application-filter>
    <hr />
    <b-form @submit="onSubmitBatch" @reset="onResetBatch">
      <b-alert show variant="success">Total Applications: {{ totalApplications }} &nbsp;&nbsp; <b-button size="sm" @click="viewguards">View Guards</b-button> </b-alert
      >

    
       <!-- <b-spinner
              small
              type="grow"
              variant="warning"
              
            ></b-spinner> -->
      <b-row>
      <b-col>
      <!-- <b-button size="sm" @click="selectAllRows">Select all</b-button> &nbsp;
      <b-button size="sm" @click="clearSelected ">Clear</b-button> -->
      </b-col>
      <b-col>
          <!-- <b-button
            class="float-right mx-3"
            variant="outline-danger"
            size="sm"
            @click="convertToPdf"
            style="float: right; font-size: 1em; font-color: red"
          >
            <i class="fa fa-file-pdf"></i>
          </b-button> -->
          <b-button
            variant="outline-success"
            size="sm"
            @click="download_excel"
            style="float: right; font-size: 1em; font-color: green"
          >
            <i class="fa fa-file-excel"></i>
          </b-button>
         </b-col>
         </b-row>
          <loading :active.sync="isLoading" 
        color="#5dbbda"
        loader="spinner"
        :is-full-page="fullPage"
        width:2
        height:2
        ></loading>
       
       <b-table 
       v-if="showtable"
      id="tblApplication"
      show-empty
      head-variant="dark"
      empty-text="No Applications available."
      stacked="md"
      bordered
      striped 
      :items="totalApplications_list" 
      :fields="totalApplications_list_fields"
      :current-page="currentPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      style="font-size: 12px"
      ref="selectableTable"
      selectable
      @row-selected="onRowSelected"
      :select-mode="selectMode"
      >
      <!-- <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template> -->
      <template v-slot:cell(Sr)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
      <template slot="Gender" slot-scope="row">
        <span v-if="row.item.Gender==true">Male</span>
        <span v-else-if="row.item.Gender==false">Female</span>
        </template>
      </b-table>
      <b-row>
      <b-col md="8" class="my-1">
      <b-pagination :total-rows="totalApplications" :per-page="perPage" v-model="currentPage" class="my-0"  size="sm"
                             @change="ChangeEvent"
                              />
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group
          horizontal
          label="Per page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
      </b-row>
      <b-row>
       <b-col>
         <!-- <p>
      Selected Rows:<br>
      {{ selected }}
    </p> -->
         <hr>
       </b-col>
      </b-row>
     

      <b-row align-h="center">
        <b-col class="mt-1" cols="!2" md="6">
          <b-form-group
            id="brpbn"
            label-cols="2"
            label="Batch Name*"
            label-size="sm"
            label-for="ipBtNme"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipBtNme"
              required
              v-model="formBatch.BatchName"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col class="mt-1" cols="!2" md="3">
          <b-form-group
            id="grpbnum"
            label-cols="4"
            label="Batch Number*"
            label-size="sm"
            label-for="ipBtNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipBtNum"
              required
              v-model="formBatch.BatchNumber"
            />
          </b-form-group>
        </b-col> -->


        <b-col class="mt-1" cols="!2" md="3">
          <b-form-group
            id="grpbsz"
            label-cols="4"
            label="Batch Size*"
            label-size="sm"
            label-for="ipBtSz"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipBtSz"
              min="1"
              max="20000"
              required
              v-model="formBatch.BatchSize"
            />
          </b-form-group>
        </b-col>
        <b-col>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-2" md="4">
          <b-form-group
            id="grpbv"
            label-cols="3"
            label="Address*"
            label-size="sm"
            label-for="ipBtVn"
            description="(Maximum 30 characters Allowed)"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipBtNum"
              required
              v-model="formBatch.BatchVenue"
              maxlength="30"
              onkeypress="return event.charCode != 44 && event.charCode != 35 "
              
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="4">
          <b-form-group
            id="grpbv"
            label-cols="3"
            label="Address Line1*"
            label-size="sm"
            label-for="ipBtVn"
            description="(Maximum 30 characters Allowed)"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipBtNum"
              required
              v-model="formBatch.BatchVenue2"
              onkeypress="return event.charCode != 44 && event.charCode != 35 "
              maxlength="30"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="4">
          <b-form-group
            id="grpbv"
            label-cols="3"
            label="Address Line2*"
            label-size="sm"
            label-for="ipBtVn"
            description="(Maximum 30 characters Allowed)"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipBtNum"
              required
              v-model="formBatch.BatchVenue3"
              onkeypress="return event.charCode != 44 && event.charCode != 35 "
              maxlength="30"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-1" md="4">
          <b-form-group
            id="grpDt"
            label-cols="3"
            label="Batch Date*"
            label-size="sm"
            label-for="ipBtDt"
          >
            <date-picker
              v-model="batchDate"
              format="DD-MM-YYYY"
              id="ipBtDt"
              lang="en"
              size="small"
              required
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="4">
          <b-form-group
            id="grpDt"
            label-cols="3"
            label="Batch Time*"
            label-size="sm"
            label-for="ipBtDt"
          >
            <date-picker
              v-model="batchTime"
              format="hh:mm A"
              type="time"
              id="ipBtDt"
              lang="en"
              size="small"
              value-type="format"
              :minute-step="minuteSteps"
              :show-second="showSeconds"
              required
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="4">
          <b-form-group
            id="grpSms"
            label-cols="2"
            label=""
            label-size="sm"
            label-for="ipsms"
          >
            <b-form-checkbox
              id="checkbox-1"
              v-model="formBatch.SendSMS"
              name="cbSMS"
              value="1"
              unchecked-value="0"
            >
              Yes, Send SMS to the Applicant
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-1">
          <b-button
            type="submit"
            size="sm"
            variant="success"
            class="float-right mx-2"
            >Create Batch
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoading"
            ></b-spinner>
          </b-button>
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: right"
            >Reset</b-button
          >
        </b-col>
      </b-row>
      <b-alert
        :show="showDismissibleAlert"
        dismissible
        @dismissed="showDismissibleAlert = false"
        :variant="variant"
      >
        {{ alertText }}
      </b-alert>
    </b-form>
  </b-container>
</template>
<script>
import ApplicationFilter from "@/components/ApplicationFilter.vue";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import apis from "@/apis";
import { jsPDF } from "jspdf";
import Spinner from "vue-simple-spinner";
import "jspdf-autotable";
import Formatter from "@/Formatter.js";
import XLSX from "xlsx";

export default {
  components: { ApplicationFilter, DatePicker ,Loading, Spinner},
  data() {
    return {
      isLoading:false,
      fullPage:false,
      form: {
        BoardID: "",
        EventID: "",
        ApplicationStatus: [],
        selectValue: [],
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: "01-01-1970",
        EndDate: "01-01-1970",
        ExServiceMan: 3,
        PinCode: null,
        OffSet: 0,
        Limit: 10,
        Purpose: 1,
        EmployerName:"",
        ApplicationNumberList:"",
      },
      batchDate: null,
      batchTime: "11:00",
      formBatch: {
        BatchName: "",
        BatchNumber: 1,
        BatchSize: 1,
        BatchTime: "11:00",
        BatchVenue: "",
        BatchVenue2: "",
        BatchVenue3: "",
        BatchDate: null,
        JobId: "00000000-0000-0000-0000-000000000000",
        SendSMS: 1,
        SchedulePurpose: 1,
        UserID: "00000000-0000-0000-0000-000000000000",
      },
      boards: this.$store.getters.getBoards,
      approvalStatus: this.$store.getters.getApprovalStatusPurpose,
      events: [],
      totalApplications: 0,
      minuteSteps: 30,
      showSeconds: false,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      selectMode: 'multi',
      selected: [],
      currentPage: 1,
      total_list:"",
      perPage: 10,
      totalApplications_list:[],
      totalApplications_list_fields:[
        //{ key: "selected", label: "Selected" },
        { key: "Sr", label: "SrNo" },
        
        {
          key: "ApplicationNo", 
          label: "ApplicationNo", 
          sortable: true
        },
        {
          key: "FullName", 
          label: "FullName", 
          sortable: true
        },
        {
          key: "Gender", 
          label: "Gender", 
          sortable: true
        },
        {
          key: "Mobile", 
          label: "Mobile", 
          sortable: true
        },
        {
          key: "ApplicantAge", 
          label: "ApplicantAge", 
          sortable: true
        },
        {
          key: "EmailID", 
          label: "EmailID", 
          sortable: true
        },
        {
          key: "Qualification", 
          label: "Qualification", 
          sortable: true
        },
        {
          key: "Mobile", 
          label: "Mobile", 
          sortable: true
        },
        {
          key: "ApplicationStatus", 
          label: "ApplicationStatus", 
          sortable: true
        }
      ],
      showtable:false,

    };
  },
  computed: {
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    download_excel()
    { this.total_list="";
      this.isLoading = true;
      this.showDismissibleAlert = false;
      this.form.Limit=this.totalApplications;
      let promise = apis.readApplicationList(this.form);
      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.totalApplications = response.body.Table[0].TotalRecords;
             this.total_list = response.body.Table1;
            

            for(var i=0;i< this.total_list.length;i++)
          {
              this.total_list[i].sr_no=i+1;
          }
          }
          this.isLoading = false;

          this.downloadExcel();
        });
    },
    async downloadExcel() {
      


      var columns = [
        "Sr No",
        "Application No",
        "Applicant Name",
        "Gender",
        "Age",
        "Mobile",
        "EmailID",
        "Qualification",
        "ApplicationStatus"
        
      ];

      let app_data = [];

      await  this.total_list.forEach((element) => {
        let obj = {};
        obj["Sr No"] = element.sr_no;
        obj["Application No"] = element.ApplicationNo;
        obj["Applicant Name"] = this.titleCase(element.FullName);
        obj["Gender"] = this.gender_h(element.Gender);
        obj["Age"] = element.ApplicantAge;
        obj["Mobile"] = element.Mobile;
        //obj["Weight (kg)"] = element.Weight;
        obj["EmailID"] = element.EmailID;
        obj["Qualification"] = element.Qualification;
        obj["ApplicationStatus"] = element.ApplicationStatus;
        // obj["Height (cm)"] = element.Height;
        // obj["Weight (kg)"] = element.Weight;
        // obj["Education Marks"] = element.EducationalMark;
        // obj["Chest D (cm)"] = element.ChestDeflated;
        // obj["Chest I (cm)"] = element.ChestInflated;
        // obj["Running Time"] = element.RunningTime;
        // obj["Runnning Marks"] = element.RunningMark;
        // obj["PullUps"] = element.PullUps;
        // obj["PullUp Marks"] = element.PullupsMark;
        // obj["Situps"] = element.SitUps;
        // obj["Situp Marks"] = element.SitupsMark;
        // obj["Ground Marks"] = element.GroundMarks;
        // obj["Total Marks"] = element.TotalMarks;  
        // obj["Result"] = element.ResultStatus;     
        //obj["Ground Marks"] = element.GroundMarks;

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },
    ChangeEvent(evt) {
     
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getApplicationsSub();
    },
    onRowSelected(items) {
        this.selected = items
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      viewguards()
      {
        if (this.totalApplications >0) {
          this.showtable=true;
        }
        else{
          this.$dialog.alert("Guard Not present");
        }
        
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
    onSubmitBatch(evt) {
      evt.preventDefault();
      this.isLoading = true;
      this.formBatch.BatchDate = Formatter.convertDateFormatYMD(this.batchDate);
      this.formBatch.BatchTime = this.batchTime;
      this.formBatch.JobId = this.form.EventID;
      this.formBatch.ApplicationStatus = this.form.ApplicationStatus;
      this.formBatch.Gender = this.form.Gender;
      this.formBatch.MobileNo = this.form.MobileNo;
      this.formBatch.ApplicationNo = this.form.ApplicationNo;
      this.formBatch.SchedulePurpose = this.form.Purpose;
      this.formBatch.ExServiceman = this.form.ExServiceMan;
      this.formBatch.PinCode = this.form.PinCode;
      this.formBatch.EmployerName= this.form.EmployerName;
      this.formBatch.ApplicationNumberList= this.form.ApplicationNumberList;
      let promise = apis.createBatch(this.formBatch);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.$dialog.alert("Batch Sheduled Successfully..");
            this.formBatch.BatchNumber += 1;
            var pdf = new jsPDF("p", "pt");
            var columns = [
              { dataKey: "SrNo", title: "Sr No" },
              { dataKey: "ApplicationNo", title: "Application No" },
              { dataKey: "FullName", title: "Name of Applicant" },
              { title: "Signature" },
            ];

            var boardname = "";
            var StatusPurpose = response.data[0].StatusPurpose;
          this.$store.getters.getBoards.forEach((item) => {
        if (item.SecurityBoardID == this.$store.getters.getUserBoard) {
          boardname = item.SecurityBoardName;
        }
      });
            // var event = "";
            // this.$store.getters.getEvents.forEach((e) => {
            //   if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
            //     event = e.EventTitle;
            //     return;
            //   }
            // });
            pdf.setFontSize(11);
             pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");

            pdf.setFontSize(11);
            pdf.text(
              StatusPurpose + " Attendance Sheet",
              pdf.internal.pageSize.width / 2,
              45,
              {
                align: "center",
              }
            );

            pdf.text(
              "Batch Name : " + this.formBatch.BatchName,
              pdf.internal.pageSize.width / 2,
              55,
              {
                align: "center",
              }
            );
            pdf.text(
              "Batch Time : " +
                this.convertDateFormat(this.formBatch.BatchDate) +
                " " +
                this.formBatch.BatchTime,
              pdf.internal.pageSize.width / 2,
              65,
              {
                align: "center",
              }
            );
             var start_y_pos = 90;
            var vc = this;
            
            pdf.autoTable(columns, response.body, {
              startY: start_y_pos,
               margin: { top: 90 },
              styles: {
            lineWidth: 1,
          },
          headerStyles: {
            lineWidth: 1,
          },
              // tableLineColor: [189, 195, 199],
              // tableLineWidth: 0.75,
              didParseCell(data) {
                if (data.cell.section != "head") {
                  switch (data.column.dataKey) {
                    case "Createdate":
                      data.cell.text = vc.convertDateFormat(data.cell.text);
                      break;
                  }
                }
              },
            });
            pdf.save("BatchAttendance_" + vc.formBatch.BatchNumber + ".pdf");
          }
 else {
            this.showDismissibleAlert = true;
            this.variant = "danger";
            this.alertText = "Error while creating Batch!!";
          }
        })
        .catch((err) => {
          console.error(err);
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.alertText = "Error while creating Batch!!";
        })
        .finally(() => {
          this.isLoading = false;
          this.onResetBatch();
        });
    },
    onReset()
    {
      this.totalApplications=0;
      this.showtable=false;
      this.totalApplications_list=[];
      this.formBatch.BatchSize= 0;
    },
    onResetBatch(evt) { 
      this.totalApplications=0;
      this.showtable=false;
      this.totalApplications_list='';
      this.formBatch.BatchSize= 0;
      this.formBatch.BatchName='';
      this.formBatch.BatchNumber='';
      this.formBatch.BatchSize='';
      this.formBatch.BatchVenue='';
      this.formBatch.BatchVenue2='';
      this.formBatch.BatchVenue3='';
      this.batchDate=[];
      this.batchTime=[];
      // evt.preventDefault();
    },
    onChange(){
      this.totalApplications = 0;
      this.totalApplications_list = [];

    },
    onSubmit() {
      //evt.preventDefault();
      this.getApplicationsSub();
    },
    getApplicationsSub() {
      this.isLoading = true;
        this.form.ApplicationStatus = JSON.parse(
        JSON.stringify(this.form.selectValue)
      );
     // this.form.ApplicationStatus=this.form.selectValue;
      this.showDismissibleAlert = false;

        if (
        this.form.ApplicationStatus &&
        this.form.ApplicationStatus.length == 0
      ) {
        for (let x = 0; x < this.approvalStatus.length; x++) {
          this.form.ApplicationStatus.push(this.approvalStatus[x]);
        }
      }
    
      let promise = apis.readApplicationList(this.form);

      promise
        .then((response) => {
          //console.log(response);
          this.isLoading = false;
          if (response.status == 200) {
            this.totalApplications = response.body.Table[0].TotalRecords;
            this.formBatch.BatchSize= response.body.Table[0].TotalRecords;
            this.totalApplications_list = response.body.Table1;
            for(var i=0;i<this.totalApplications_list.length;i++)
          {
             this.totalApplications_list[i].sr_no=i+1;
          }
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
  },
  mounted() {
    //this.approvalStatus = this.$store.getters.getApprovalStatusPurpose;

    //this.boards = this.$store.getters.getBoards;
    this.form.BoardID = this.userBoard;

    for (var i = 0; i < this.$store.getters.getEvents.length; i++) {
      this.events.push(this.$store.getters.getEvents[i]);
    }
  },
};
</script>