<template>
  <b-container>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Guard Registration</router-link>
        </li>
        <li
          class="breadcrumb-item active"
          style="font-size: 0.875rem"
          aria-current="page"
        >
          Shcedule Verification Batch
        </li>
      </ol>
    </nav>
    <!-- <b-row> -->
    <hr />
    <div class="row" style="margin-top: 20px">
      <b-col md="11"></b-col>
    </div>
    <!-- </b-row> -->
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="form-row">
        <b-col md="5" class="mt-1" style="margin-left: 20px">
          <b-form-group
            id="grp1"
            label-cols="3"
            label="Pool Type"
            label-size="sm"
            label-for="ipPoolType"
            style="width: 80%"
          >
            <b-select
              id="ipPoolType"
              class="form-control"
              value-field="EventID"
              size="sm"
              text-field="EventTitle"
              :options="events"
              v-model="form.EventID"
              required
            ></b-select>
          </b-form-group>
        </b-col>

        <b-col class="mt-1" md="4">
          <b-form-group
            id="grpDt"
            label-cols="3"
            label="Batch Date"
            label-size="sm"
            label-for="ipBtDt"
          >
            <date-picker
              v-model="batchDate"
              format="DD-MM-YYYY"
              id="ipBtDt"
              lang="en"
              size="small"
              style=""
              range
              
            ></date-picker>
          </b-form-group>
        </b-col>
         <!-- <b-col md="4" class="mt-3" style="margin-left: 20px">
          <b-form-group
            id="grpDt"
            label-cols="3"
            label="Batch Number"
            label-size="sm"
            label-for="ipBtDt"
          >
           <b-form-input
              type="text"
              size="sm"
              id="ipBtNum"
              
              v-model="form.BatchNumber"
            />
          </b-form-group>
        </b-col> -->

        <b-col class="mt-2" md="2" >
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      </div>
    </b-form>
    <hr />
    <b-row class="mb-2">
      <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="filter"
            placeholder="Type to Search"
            size="sm"
          />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''" size="sm"
              >Clear</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="3">
        <p>Total Batches: {{ totalRows }}</p>
      </b-col>
      <b-col md="4"></b-col>
      <b-col md="2">
        <b-button
          type="submit"
          size="sm"
          variant="success"
          style="float: left"
          @click="go_next()"
        >
          Create New Batch
        </b-button>
      </b-col>
    </b-row>
    <loading
      :active.sync="isLoading"
      color="#5dbbda"
      loader="spinner"
      :is-full-page="fullPage"
      width:2
      height:2
    ></loading>
    <b-table
      id="tblApplication"
      show-empty
      select-mode="multi"
      head-variant="dark"
      empty-text="No Data available."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      style="font-size: 12px"
    >
      <template v-slot:cell(action)="row" style="width: 100px">
        <b-button
          size="sm"
          @click.stop="convertToPdfAttendence(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
          >Attendance</b-button
        >
        <b-button
          size="sm"
          @click.stop="convertToPdf(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="primary"
          style="margin: 2px"
          >Marks Entry</b-button
        >&nbsp;

        <b-button
          size="sm"
          @click.stop="deleteDocument(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="danger"
          style="margin: 2px"
        >
          <i class="fa fa-trash"></i>
        </b-button>
      </template>
    </b-table>
    <b-row>
      <b-col md="8" class="my-1">
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
          @change="ChangeEvent"
        />
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group
          horizontal
          label="Per page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div></div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ApplicationFilter from "@/components/ApplicationFilter.vue";

export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType"],
  components: { Spinner, ApplicationFilter, DatePicker, Loading },
  data() {
    return {
      showBtn: false,
      BatchNumber:"",
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      EventID: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      applications: [],
      bscReport: "",
      bscReportPath: "",
      appCount: 0,
      appDate: null,
      attendence_applications: [],
      fields: [
        // { key: "SchedulePurpose", label: "Batch Type" },
        { key: "BatchName", label: "Batch Name", sortable: true },
        // {
        //   key: "BatchNumber",
        //   label: "Batch No",
        //   sortable: true,
        // },

        {
          key: "BatchDate",
          label: "Batch Date",
          sortable: true,
        },
        { key: "BatchSize", label: "Batch Size", sortable: true },
        { key: "BatchVenue", label: "Batch Venue", sortable: true },
        { key: "action", label: "Downloads", class: "autowidth" },
      ],
      marks_applications: [],
      batchDate: "",
      batchno :"",
      BatchDate: "",
      selected: [],
      form: {
        id: "",
        BoardID: "",
        EventID: "",
        startbatchDate: "",
        endbatchDate: "",
        OffSet: 0,
        Limit: 500,
        Purpose: 0,
        BatchNumber: "",
      },
      showbtn: false,
      selectedApplication: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      fullPage: false,
      isLoadingFile: false,
      isAdmin: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onReset(evt) {
      this.batchDate="",
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        batchDate: "",
        BatchNumber: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        form: {
          EventID: "00000000-0000-0000-0000-000000000000",
        },
      };
    },
    go_next() {
      this.$router.push("/batchschedule");
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
    async convertToPdf(data) {
       //alert(6666);
      var batchDate = data.BatchDate;
      var batchno = data.BatchNumber.toString();
      //alert(batchDate);
      this.form.id = data.Id;
      let promise = await apis.getApplicationList(this.form);
      let applicants = promise.body;
      /*promise
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            return response.body;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
            return [];
          }
          
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
          return [];
        });
      */

      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "ApplicationNo", title: "Application No" },
        { dataKey: "FullName", title: "Applicant Name" },
        { dataKey: "Gender", title: "Gender" },
        { dataKey: "Height", title: "Height (cm)" },
        { dataKey: "Weight", title: "Weight(Kg)" },
        { dataKey: "ChestDeflated", title: "Chest Deflated" },
        { dataKey: "ChestInflated", title: "Chest Inflated" },
        { dataKey: "RunningTime", title: "Running Time" },
        { dataKey: "Sit Ups", title: "Situps" },
        { dataKey: "Pullups", title: "PullUps" },
        { dataKey: "remarks", title: "Remarks" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (item.SecurityBoardID == this.$store.getters.getUserBoard) {
          boardname = item.SecurityBoardName;
        }
      });
      var event = "";
      this.$store.getters.getEvents.forEach((e) => {
        if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
          event = e.EventTitle;
          return;
        }
      });
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Guard Marks Entry",
          pdf.internal.pageSize.getWidth() / 2,
          50,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
         pdf.text(ref.convertDateFormat(batchDate), 70, 70);
          //pdf.text("Batch No :", 35, 85);
        pdf.text(batchno, 100, 85);
        pdf.page = 1;
        function footer() {
          pdf.text("Name and Signature of Inspector", 25);
          // pdf.text("Secretary", 285, 750);
          // pdf.text("Chairman", 450, 750);
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 90;

      pdf.autoTable(
        columns,
        applicants,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          theme: "grid",
          margin: { top: 90 },
          styles: {
            lineWidth: 1,
          },
          headerStyles: {
            lineWidth: 1,
          },
        },

        {
          didParseCell(data) {
            if (data.cell.section != "head") {
              switch (data.column.dataKey) {
                case "RunningTime":
                  data.cell.text = vc.convertDateFormat(data.cell.text);
                  break;
                case "Gender":
                  if (data.cell.text == "false") {
                    data.cell.text = "Female";
                  } else {
                    data.cell.text = "Male";
                  }
                  break;
                case "ExServiceMan":
                  if (data.cell.text == "false") {
                    data.cell.text = "No";
                  } else {
                    data.cell.text = "Yes";
                  }
                  break;
              }
            }
          },
        }
      );
      pdf.setFontSize(12);
      var height = pdf.internal.pageSize.getHeight() - 20;
      pdf.text("Name and Signature of Inspector", 150, height, "center");
      pdf.text("Secretary", 400, height);
      pdf.text("Chairman", 650, height);
      pdf.save("BatchApplication.pdf");
    },
    async convertToPdfAttendence(data) {
      console.log(data.BatchNumber);
      var batchDate = data.BatchDate;
      var batchno = data.BatchNumber.toString();
      var BatchName = data.BatchName;
      var statusPurpose = data.StatusPurpose
      
     //alert(batchdate);
      this.form.id = data.Id;
      let promise = await apis.getApplicationList(this.form);

      let applicants = promise.body;
      
      // for (let x = 0; x < applicants.length; x++) {
      // applicants[x].FullName=applicants[x].FullName.toUpperCase();
        
      // }
    
      var pdf = new jsPDF("p", "pt");
        
      var columns = [
        
        { dataKey: "SrNo", title: "Sr No" },
        { dataKey: "ApplicationNo", title: "Application No" },
        { dataKey: "FullName", title: "Full Name" },
        { dataKey: "Gender", title: "Gender" },
        { dataKey: "Signature", title: "Signature" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (item.SecurityBoardID == this.$store.getters.getUserBoard) {
          boardname = item.SecurityBoardName;
        }
      });
      var event = "";
      this.$store.getters.getEvents.forEach((e) => {
        if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
          event = e.EventTitle;
          return;
        }
      });
      var ref = this;
      var pageContent = function (data) {
         pdf.setFontSize(11);
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
         statusPurpose + " Attendance Sheet" ,
          pdf.internal.pageSize.getWidth() / 2,
          45,
        {
           align: "center",
        }
        );
        pdf.text(
          "Batch Name:" + BatchName,
          pdf.internal.pageSize.getWidth() / 2,
          55,
        {
          align: "center",
        }
        );
         pdf.text(
          "Batch Time:" + batchDate,
          pdf.internal.pageSize.getWidth() / 2,
          65,
        {
         align: "center",
        }
        );
        pdf.setFontSize(10);
        // pdf.text("Date :", 35, 70);
        // pdf.text(ref.convertDateFormat(batchDate), 70, 70);
        // pdf.text("Batch No :",35, 85);
        // pdf.text(batchno, 100, 85);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 90;
      var vc = this;
      pdf.autoTable(
        columns,
        applicants,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          // theme: "grid",
          margin: { top: 90 },
          styles: {
            lineWidth: 1,
          },
          headerStyles: {
            lineWidth: 1,
          },
          columnStyles: {
            SrNo: { columnWidth: 80 },
            ApplicationNo: { columnWidth: 80 },
            FullName: { columnWidth: 200 },
            Gender: { columnWidth: 80 },
            Signature: { columnWidth: 100 },
          },
        },

        {
          didParseCell(data) {
            if (data.cell.section != "head") {
              switch (data.column.dataKey) {
                case "Gender":
                  if (data.cell.text == "false") {
                    data.cell.text = "Female";
                  } else {
                    data.cell.text = "Male";
                  }
                  break;
              }
            }
          },
        }
      );
      pdf.save("BatchAttendence.pdf");
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
    deleteDocument(item, index, button) {
      var vc = this;

      this.$dialog
        .confirm(
          "Please confirm, if you want to Delete the Batch of " +
            item.BatchDate +
            "?"
        )
        .then(function (dialog) {
          var userinfo = vc.$store.getters.loggedUser;
          var userID = userinfo.UserId;

          var payload = { ID: item.Id, UserID: userID };

          let promiseD = apis.deleteBatch(payload);
          promiseD
            .then((response) => {
              if (response.status == 200) {
                this.$dialog.alert("Record Deleted Successfully");
                vc.getApplicationsSub();
              }
            })
            .catch((err) => {
              this.$dialog.alert("Error while deleting the record.");
            });
        })
        .catch(function () {
          //console.error(err);
          console.log("Clicked on cancel");
        });
    },

    onSubmit(evt) {
      console.log(this.form);
      if (this.form.EventID=='') {
        this.form.EventID="00000000-0000-0000-0000-000000000000";
      }

      evt.preventDefault();
      // if (this.form.batchDate=="") {

      //   this.form.startbatchDate = Formatter.convertDateFormatYM(this.batchDate[0])
      //    this.form.endbatchDate = Formatter.convertDateFormatYM(this.batchDate[0])
      // }else{
        {
      this.form.startbatchDate = this.batchDate[0]?Formatter.filter_convertDateFormatYM(this.batchDate[0],2):Formatter.filter_convertDateFormatYM(this.batchDate[0],1);
      this.form.endbatchDate = Formatter.filter_convertDateFormatYM(this.batchDate[1],2);
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
    

      this.getApplicationsSub();
      }
    },
    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getApplicationsSub();
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },

    getApplicationsSub() {
       
      if (this.form.BatchNumber=='') {

        this.form.BatchNumber=0;
        
      }
      
      this.isLoading = true;
      this.showDismissibleAlert = false;
      let promise = apis.getApplicationMarks(this.form);

      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.applications = response.body;

            this.totalRows = this.applications.length;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },

    getApplicationsMarks() {
      this.isLoading = true;
      this.showDismissibleAlert = false;
      let promise = apis.getApplicationList(this.form);

      promise
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            return response.body;
          } else {
            this.alertText = response.body;
            this.variant = "warning";
            this.showDismissibleAlert = true;
            return [];
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
          return [];
        });
    },
    getApplicationsAttendance() {
      this.isLoading = true;
      this.showDismissibleAlert = false;

      if (this.form.ApplicationStatus == 2) {
        this.showsts = true;
      } else {
        this.showsts = false;
      }

      if (this.form.ApplicationStatus == 1) {
        this.showbtn = true;
      } else {
        this.showbtn = false;
      }

      this.$store.dispatch("setSearchForm", this.form);
      let promise = apis.readApplicationList(this.form);

      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.attendence_applications = response.body.Table1;
            var userinfo = this.$store.getters.loggedUser;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },

    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },

  mounted() {
    this.form.BoardID = this.userBoard;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    let today_date = dd + "/" + mm + "/" + yyyy+1;
    this.current_date = today_date;
  },
};
</script>
<style>
.autowidth {
  width: 300px !important;
  text-align: left;
}
</style>
