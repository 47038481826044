<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="form-row">
        <b-col class="mt-3" md="6">
         
          <b-form-group 
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              :disabled="
                value.BoardID != '00000000-0000-0000-0000-000000000000'
              "
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" md="6">
          <b-form-group
            id="grpPurpose"
            label-cols="2"
            label="Schedule For"
            label-size="sm"
            label-for="ipSched"
            v-if="value.Purpose != 0"
          >
            <b-select
              id="ipSched"
              :options="purposes"
              required
              size="sm"
              v-model="value.Purpose"
              class="form-control"
              @change="filterAppStatus"
            ></b-select>
          </b-form-group>
        </b-col>
      </div>
      <div class="form-row">
        <b-col md="6" class="mt-1">
          <b-form-group 
            id="grp1"
            label-cols="2"
            label="Pool Type"
            label-size="sm"
            label-for="ipPoolType"
          >
            <b-select
              id="ipPoolType"
              class="form-control"
              value-field="EventID"
              size="sm"
              text-field="EventTitle"
              :options="events"
             
              v-model="value.EventID"
            ></b-select>
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="4">
          <b-form-group
            id="grp3"
            label-cols="3"
            label="Appln. Status"
            label-size="sm"
            label-for="ipAPSts"
          >
            <b-select
              id="ipAPSts"
              class="form-control"
              :options="appStatus"
              value-field="Id"
              text-field="ApplicationStatus"
              required
              size="sm"
              v-model="value.ApplicationStatus"
            ></b-select>
          </b-form-group>
        </b-col>
       
      </div>
      <b-row>
        
        
        
        <b-col class="mt-1" md="2">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            >Search</b-button
          >
          
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
          
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  props: ["boards", "events", "approvalStatus", "value"],

  data() {
    return {
      form: this.value || {},
      ExSvcOptions:[
        { Id: 0, Value: "No" },
        { Id: 1, Value: "Yes" },
        { Id: 3, Value: "All" },
      ],
      appDate: null,
      appStatus: [],
      appStatusAll: [],
      genderList: this.$store.getters.getGenderList,
      
    };
  },
  mounted() {
    if (this.value.Purpose == 0) {
      this.appStatus = this.approvalStatus;
    } else {
      this.filterAppStatus(this.value.Purpose);
    }
    this.events.shift();
  },
  methods: {
    filterAppStatus(purposeid) {
      this.appStatus = [];
      for (var i = 0; i < this.approvalStatus.length; i++) {
        if (
          this.approvalStatus[i].StatusPurposeID == purposeid ||
          this.approvalStatus[i].StatusPurposeID == 0
        )
          this.appStatus.push(this.approvalStatus[i]);
      }
      this.appStatusAll = this.appStatus;
    },
    
    change_options(id) {
      // alert(id.target.value);
      // alert(id);
      
      var o1 = [13];
      var o2 = [10, 37];
      // var o3=[4,5];
      // var o4=[6,7];
      this.appStatus = [];
      
      for (var i = 0; i < this.approvalStatus.length; i++) {
        // console.log(this.approvalStatus[i]);
        // return;
        
        if (id == "00000000-0000-0000-0000-000000000000") {
          if (o1.includes(this.approvalStatus[i].Id)) {
            this.appStatus.push(this.approvalStatus[i]);
          }
        }
        else if (id == "00000000-0000-0000-0000-000000000000") {
          if (o1.includes(this.approvalStatus[i].Id)) {
            this.appStatus.push(this.approvalStatus[i]);
          }
        }
        else {
          if (o2.includes(this.approvalStatus[i].Id)) {
            this.appStatus.push(this.approvalStatus[i]);
          }
        }
      }
      // console.log(this.)
      //this.appStatus = this.ApplicationStatus
    },
    // updateForm(fieldName, value) {
    //   this.value= this.form;
    //   this.$emit("input", this.value);
    // },
    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("submit");
    },
    onReset() {
      evt.preventDefault();
      let board = this.form.BoardID;
      this.form = {
        BoardID: board,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: 3,
        ExServiceMan:3,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: null,
        EndDate: null,
        PinCode:null
      };
    },
  },
};
</script>
