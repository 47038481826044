<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-row>
        <b-col md="5">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              :disabled="value.BoardID != '00000000-0000-0000-0000-00000000'"
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>

        <!-- <b-col class="mt-2" md="4"> -->
        <b-col md="4">
          <b-form-group
            id="grp3"
            label-cols="3"
            label="Entity Type"
            label-size="sm"
            label-for="ipAPSts"
          >
            <select
              id="ipAPSts"
              class="form-control"
              value-field="Id"
              text-field="ApplicationStatus"
              required
              size="sm"
              v-model="value.RegistrationType"
            >
              <option value="10">All</option>
              <option value="13">Employer Agency</option>
              <option value="12">Private Security Guard</option>
              <option value="11">Principal Employer</option>
            </select>
          </b-form-group>
        </b-col>
        <!-- <b-col>
          <b-form-group
            id="grpGndr"
            label-cols="3"
            label="Select Type "
            label-size="sm"
            label-for="ippublishrdb"
            class="ml-1"
          >
            <b-form-radio-group
              id="ippublishrdb"
              v-model="value.EvaluationStatus"
              :options="options"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col md="5">
          <b-form-group
            id="grp4"
            label-cols="2"
            label="Entity Name"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.EntityName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            id="grp4"
            label-cols="3"
            label="Temp. Reg. No."
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.RegistrationNumber"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            @click="onSubmit"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="mdlAdvSearch"
      ref="mdlAdvSearch"
      size="lg"
      title="Advance Search"
      hide-footer
    >
    </b-modal>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import axios from "axios";
import apis from "@/apis";
import lambda from "@/apis/lambda";
const base_url =
  "https://d2v8797bj7.execute-api.ap-south-1.amazonaws.com/Prod/api/";
export default {
  components: { DatePicker },
  props: ["boards", "events", "approvalStatus", "value"],

  data() {
    return {
      user_id: "",
      reg_type: "",
      form: this.value || {},
      options: [{ text: "Register" }, { text: "Not Register" }],

      appDate: null,
      Gen: "",
      appStatus: [],
      ExSvcOptions: [
        { Id: 1, EvaluationStatus: "Yes" },
        { Id: 0, EvaluationStatus: "No" },
      ],
      genderList: this.$store.getters.getGenderList,
      purposes: [
        { value: 1, text: "Document Verification" },
        { value: 2, text: "Document Verification and Physical Test" },
        { value: 3, text: "Physical Test" },
        { value: 4, text: "Physical and Ground Test" },
        { value: 5, text: "Ground Test" },
        { value: 6, text: "Character and Medical Certificate Verification" },
      ],
    };
  },
  mounted() {
    if (this.value.Purpose == 0) {
      this.appStatus = this.approvalStatus;
    } else {
      this.filterAppStatus(this.value.Purpose);
    }
  },
  methods: {
    agency_details() {
      //   if (this.reg_type == 11) {
      //     let data = {
      //       reg_type: this.reg_type,
      //       user_id: this.user_id,
      //     };
      //     let promise = apis.ReadRegistrationDetails(data);
      //   }
      //   if (this.reg_type == 12) {
      //     let data = {
      //       reg_type: this.reg_type,
      //       user_id: this.user_id,
      //     };

      let promise = apis.ReadRegistrationDetailsOnbehalf(data);

      if (this.reg_type == 13) {
        let promise = apis.getAgencyGuardMapping(this.reg_type);
      }
      if (this.reg_type == 10) {
        let details = {
          BoardID: data.BoardID,
          RegistrationType: data.RegistrationType, //or 1
          Mobile: data.Mobile,
          EntityName: data.EntityName,
          //IsRegistered: data.IsRegistered,
        };

        //     let promise = apis.ReadRegistrationDetailsOnbehalf(data);
        //   }
        //   if (this.reg_type == 13) {
        //     let promise = apis.getAgencyGuardMapping(this.reg_type);
        //   }
        //   if (this.reg_type == 10) {
        //     let details = {
        //       BoardID: data.BoardID,
        //       RegistrationType: data.RegistrationType, //or 1
        //       Mobile: data.Mobile,
        //       EntityName: data.EntityName,
        //       IsRegistered: data.IsRegistered,
        //     };

        //     axios
        //       .post(
        //         base_url + "AgencyRegistration/ReadRegistrationList",
        //         JSON.stringify(details)
        //       )
        //       .then((response) => {});
      }
    },

    filterAppStatus(purposeid) {
      this.appStatus = [];
      for (var i = 0; i < this.approvalStatus.length; i++) {
        if (
          this.approvalStatus[i].StatusPurposeID == purposeid ||
          this.approvalStatus[i].StatusPurposeID == 0
        )
          this.appStatus.push(this.approvalStatus[i]);
      }
    },

    onSubmit(evt) {
      //alert( this.form.BoardID);
      evt.preventDefault();
      //evt.preventDefault()
      this.$emit("submit");
      //this.GetResults1();

      let board = this.form.BoardID;
      this.form = {
        BoardID: "00000000-0000-0000-0000-000000000000",
        RegistrationType: 10,
        Mobile: "",
        EntityName: "",
        //IsRegistered: true,
      };
    },
    onReset() {
      evt.preventDefault();
      let board = this.value.BoardID;
      this.form = {
        BoardID: "00000000-0000-0000-0000-000000000000",
        //EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: "",
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        RegistartionNO: "",
        AgencyName: "Top",
        StartDate: null,
        EndDate: null,
        //PerPage:"",
      };
    },
  },
  created() {
    this.user_id = this.$session.get("UserId");
    //this.user_id = this.$store.getters.loggedUser.UserId;
    console.log(this.user_id);
  },
};
</script>