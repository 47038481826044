<template>
  <b-container>
    <div class="body-wrapper">
      <div class="body-content">
        <Spinner size="32" message="Loading..." v-if="isLoading"> </Spinner>
        <b-alert
          :show="showDismissibleAlert"
          dismissible
          @dismissed="showDismissibleAlert = false"
          :variant="variant"
        >
          {{ alertText }}
        </b-alert>

        <div
          style="padding: 10px; border: 2px solid #c4c6be; border-radius: 15px"
          class="mt-2"
        >
          <survey :survey="survey"></survey>
          <hr />
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import apis from "@/apis";
import Spinner from "vue-simple-spinner";
import { Model } from "survey-core";
import { Survey } from "survey-vue-ui";
import "survey-core/defaultV2.min.css";

import data from "@/data";
import * as _ from "lodash";

const surveyJSON = data.survey;

export default {
  props: ["RecordDetails"],
  components: { Survey, Spinner },

  data() {
    const survey = new Model(surveyJSON);
    survey.onComplete.add(this.onSurveyComplete);
    return {
      title: "",
      discription: "",
      survey: survey,
      isLoading: false,
      variant: "success",
      alertText: "",
      showDismissibleAlert: false,
      show: true,
      RegisterID: "",
      BoardID: "",
      UserID: "",
      RegType: "",
      EventDetails: {},
      form: {
        RegisterID: "00000000-0000-0000-0000-000000000000",
        BoardID: "00000000-0000-0000-0000-000000000000",
        UserID: "00000000-0000-0000-0000-000000000000",
        RegType: 0,
        EventDetails: "",
      },
    };
  },
  methods: {
    onSubmit() {
      //alert("The results are:" + JSON.stringify(this.survey.data));
    },
    onReset() {},
    onSurveyComplete(sender) {
      this.form.RegType = sender.data.PoolType;
      this.form.EventDetails = JSON.stringify(sender.data);
    
      var json_data=JSON.parse(this.form.EventDetails);
      
      if (this.form.BoardID == "00000000-0000-0000-0000-000000000000") {
        let boards = this.$store.getters.getBoards;
        let _board = _.filter(boards, { SecurityBoardName: json_data.Board });  
        
        this.form.BoardID = _board[0].SecurityBoardID;
      }

      let promise = apis.signup(this.form);
      promise
        .then((response) => {
          if (response.status == 200) {
            this.$dialog.alert("User Create Succesfully..");
            this.$router.push({
              name: "RegistrationList",
              params: {
                        BoardID: this.form.BoardID, 
                      },
                });
          } else {
            this.$dialog.alert(" Unsuccesfully");
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
  },
  mounted() {
    var userinfo = this.$store.getters.loggedUser;
    this.form.UserID = userinfo.UserId;
    this.form.BoardID = this.$store.getters.getUserBoard;
    this.RecordDetails = this.$route.params.RecordDetails;
    this.form.RegisterID = this.RecordDetails.RegisterID;
    //this.form.BoardID = this.RecordDetails.BoardID;
    // this.RegType = this.RecordDetails.RegistrationType;
    // this.EventDetails = JSON.parse(this.RecordDetails.EventDetails);
       this.survey.data = JSON.parse(this.RecordDetails.EventDetails);
  },
};
</script>
<style scoped>
.body-wrapper {
  margin-top: 20px;
  padding-bottom: 20px;
  background: #fff;
  border-radius: 4px;
  border: solid thin #ccc;
  -webkit-box-shadow: -7px 10px 14px -8px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: -7px 10px 14px -8px rgba(0, 0, 0, 0.32);
  box-shadow: -7px 10px 14px -8px rgba(0, 0, 0, 0.32);
}

.body-title {
  padding: 15px 25px;
  background: #fcfcfc;
  border-bottom: solid 2px #aaa;
}

.body-content {
  padding: 25px 10px;
}
h4 {
  font-size: 1rem !important;
}
h5 {
  font-size: 0.875rem !important;
}
.form-control {
  font-size: 0.75rem;
}
.btn sv_prev_btn {
  color: "red";
}
</style>
