<template>
  <b-container fluid style="background-color: #e8eff9">
    <b-row class="mt-5">
      <b-col> </b-col>
      <b-col md="4">
        <div>
          <div class="login-box-inner">
            <h6>Login</h6>
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem" />
            <b-row>
              <b-col>
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <b-alert
                    :show="showDismissibleAlert"
                    dismissible
                    @dismissed="showDismissibleAlert = false"
                    :variant="variant"
                  >
                    {{ alertText }}
                  </b-alert>
                  <div style="text-align: center; margin-top: 10px">
                    <p style="color: red">
                      ONLY BOARD EMPLOYEES CAN LOGIN TO THIS PORTAL
                    </p>
                  </div>
                  <div class="form-group">
                    <small for="exampleInputEmail1">User Name</small>
                    <b-input
                      type="text"
                      :formatter="format"
                      lazy-formatter
                      v-model="form.userName"
                      required
                      class="form-control"
                      size="sm"
                      id="inputUserID"
                      aria-describedby="emailHelp"
                      placeholder="Enter Your UserName"
                    ></b-input>
                  </div>

                  <div class="form-group mt-2">
                    <small for="exampleInputEmail1">Password</small>
                    <b-input
                      type="password"
                      :formatter="format"
                      lazy-formatter
                      v-model="form.Password"
                      class="form-control"
                      size="sm"
                      id="inputPwd"
                      aria-describedby="emailHelp"
                      placeholder="Enter Password"
                    ></b-input>
                  </div>
                  <div class="form-group">
                    <small for="exampleInputEmail1" class="label-text">
                        Please following type and click <i class="fa fa-check"></i> key
                      <span>*</span>
                    </small>
                    <captcha
                      :callSuccess="captchaOk"
                      color="black"
                      resolve="text"
                      :isAuth="auth"
                    ></captcha>
                  </div>
                  <div style="text-align: center">
                    <b-button
                      variant="primary"
                      size="sm"
                      class="mt-3"
                      type="submit"
                    >
                      Login
                    </b-button>
                  </div>
                  <!-- <div>
                    <b-btn
                      variant="link"
                      size="sm"
                      class="float-right"
                      @click="showModal"
                      >Forgot Password</b-btn
                    >
                  </div> -->
                </b-form>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
import apis from "@/apis";
import captcha from "@/components/Captcha.vue"; //change the path as per your project

export default {
  components: { captcha},
  data() {
    return {
      auth: false,
      dismissSecs: 20,
      dismissCountDown: 0,
      form: {
        userName: "",
        Password: "",
      },
      slide: 0,
      sliding: null,
      show: true,
      alertText: "",
      showDismissibleAlert: false,
      variant: "success",
      isLoading: false,
    };
  },
  methods: {
    captchaOk() {
      this.auth = true;
      
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    format(value, event) {
      if (value) {
        return value.trim();
      }
    },
    showModal() {
      this.$refs.myModalRef.show();
    },
    hideModal() {
      this.$refs.myModalRef.hide();
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = true;
    },
    getImgUrl(pic) {
      return require("../assets/" + pic);
    },
    onSubmit(evt) {
      if (!this.auth) {
    this.$dialog.alert("Please enter Captcha");
    return false;
}
      evt.preventDefault();
      this.isLoading = true;
      this.showDismissibleAlert = false;

      let promise = apis.login(this.form);

      promise
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            let userary = response.body;
            if (userary.length == 0) {
              this.alertText = "User name or Password is invalid";

              this.variant = "warning";
              this.showDismissibleAlert = true;
            } else if (response.body[0].RoleID == 0) {
              this.alertText = "User name or Password is invalid";

              this.variant = "warning";
              this.showDismissibleAlert = true;
            } else {
              this.$store.dispatch("login", {
                token: response.body[0].SessionID,
                user: response.body[0],
              });
              this.$store.dispatch("setMasters", response.body[0].BoardID);
              this.$router.replace("/dashboard");
            }
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.body || "Error while connecting to server";
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    onReset(evt) {},
  },
  mounted() {
    this.dismissCountDown = this.dismissSecs;
  },
};
</script>

<style scoped>
body {
  background: #e8eff9 !important;
}

ul {
  padding: 0;
  margin: 0;
}
.top-land {
  /*border-bottom : solid 2px #ccc;*/
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar {
  padding: 0 !important;
  background: #5c6bc0 !important;
}
.navbar-brand {
  font-size: 12pt !important;
  color: #ccc !important;
}
.login-box-inner {
  background: #fbfbfb;
  padding: 25px 20px;
  border: solid thin #ddd;
  border-radius: 4px;
  -webkit-box-shadow: 8px 14px 14px -4px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 8px 14px 14px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 8px 14px 14px -4px rgba(0, 0, 0, 0.32);
}
</style>
