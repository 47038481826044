<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="form-row">
       <b-col class="mt-3" md="6">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              required
              size="sm"
              :disabled="
                value.BoardID != '00000000-0000-0000-0000-000000000000'
              "
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
      </div>
      <div class="form-row">
        <b-col md="6" class="mt-1">
          <b-form-group
            id="grp1"
            label-cols="2"
            label="Pool Type"
            label-size="sm"
            label-for="ipPoolType"
          >
            <b-select
              id="ipPoolType"
              class="form-control"
              value-field="EventID"
              size="sm"
              text-field="EventTitle"
              :options="events"
              @change="change_options(value.EventID)"
              v-model="value.EventID"
            ></b-select>
          </b-form-group>
        </b-col>
        <!-- //tushar start -->
        <b-col class="mt-1" md="3">
          <b-form-group
            id="grpGndr"
            label-cols="5"
            label="Result Published?"
            label-size="sm"
            label-for="ippublishrdb"
            class="ml-1"
          >
            <b-form-radio-group
              id="ippublishrdb"
              v-model="value.EvaluationStatus"
              :options="options"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <!-- tushar end -->
        <b-col class="mt-1" md="3">
          <b-form-group
            id="grpGndr"
            label-cols="4"
            label="Gender"
            label-size="sm"
            label-for="ipgndr"
          >
            <b-select
              id="ipgndr"
              class="form-control"
              :options="genderList"
              value-field="GenderID"
              text-field="Gender"
              required
              size="sm"
              v-model="value.Gender"
            ></b-select>
          </b-form-group>
        </b-col>
      </div>
      <b-row>
        <b-col md="3" class="mt-1">
          <b-form-group
            id="grp4"
            label-cols="4"
            label="Application No"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.ApplicationNo"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" class="mt-1">
          <b-form-group
            id="grp4"
            label-cols="2"
            label="Mobile"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.MobileNo"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" class="mt-1">
          <b-form-group
            id="grpPin"
            label-cols="2"
            label="PIn"
            label-size="sm"
            label-for="ipPin"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipPin"
              v-model="value.PinCode"
              min="100000"
              max="999999"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="2">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            @click="onSubmit"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  props: ["boards", "events", "approvalStatus", "value"],

  data() {
    return {
      form: this.value || {},
      ExSvcOptions: [
        { Id: 1, EvaluationStatus: "Yes" },
        { Id: 0, EvaluationStatus: "No" },
      ],
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
      app_status: "",
      //   Application:[
      //   { Id: 0, Value: "All" },
      //   { Id: 1, Value: "Yes" },
      //   { Id: 3, Value: "No" },
      // ],
      appDate: null,
      appStatus: [
        //{ Id: 0, Value: "All" },
        { Id: 1, Value: "Yes" },
        { Id: 0, Value: "No" },
      ],
      appStatusAll: [],
      genderList: this.$store.getters.getGenderList,
    };
  },
  mounted() {
    if (this.value.Purpose == 0) {
      //alert(this.appStatus);
      this.appStatus = this.approvalStatus;
    } else {
      this.filterAppStatus(this.value.Purpose);
    }
    if (this.events[0].EventTitle == "All") {
      this.events.shift();
    }
  },
  methods: {
    filterAppStatus(purposeid) {
      this.appStatus = [];
      for (var i = 0; i < this.approvalStatus.length; i++) {
        if (
          this.approvalStatus[i].StatusPurposeID == purposeid ||
          this.approvalStatus[i].StatusPurposeID == 0
        )
          this.appStatus.push(this.approvalStatus[i]);
      }
      this.appStatusAll = this.appStatus;
    },

    change_options(id) {
      var o1 = [13];
      var o2 = [10, 37];
      // var o3=[4,5];
      // var o4=[6,7];
      //alert(this.approvalStatus.length);
      this.appStatus = [];

      for (var i = 0; i < this.approvalStatus.length; i++) {
        // console.log(this.approvalStatus[i]);
        // return;

        if (id == "00000000-0000-0000-0000-000000000000") {
          if (o1.includes(this.approvalStatus[i].Id)) {
            this.appStatus.push(this.approvalStatus[i]);
          }
        } else if (id == "6d3d8651-7dbd-47af-818d-003bb5b35a07") {
          if (o1.includes(this.approvalStatus[i].Id)) {
            this.appStatus.push(this.approvalStatus[i]);
          }
        } else {
          if (o2.includes(this.approvalStatus[i].Id)) {
            this.appStatus.push(this.approvalStatus[i]);
          }
        }
      }

      //this.appStatus = this.ApplicationStatus
    },

    onSubmit(evt) {
      // alert(this.app_status);
      evt.preventDefault();
      //evt.preventDefault()
      this.$emit("submit");
      //this.GetResults1();

      let board = this.form.EventID;
      this.form = {
        EventID: "00000000-0000-0000-0000-000000000000",
        EvaluationStatus: 0,
        Gender: 0,
      };
    },

    onReset(evt) {
      evt.preventDefault();
      this.value.EventID = "";
      this.value.EvaluationStatus = 0;
      this.value.Gender =0;
      this.value.ApplicationNo ="";
      this.value.MobileNo ="";
      this.value.PinCode= "";
      this.$emit("reset");
      

    },
  },
  created() {
    //console.log(this.$store.getters.getGenderList);
  },
};
</script>
