import Vue from 'vue'
import Vuex from 'vuex'
import apis from '@/apis'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    isLoading: false,
    product: {},
    userToken: null,
    user: {"RoleID":999},
    genderList: [{ GenderID: 0, Gender: "Female" }, { GenderID: 1, Gender: "Male" },{ GenderID: 3, Gender: "Both" }],
    appSearchForm: {},
    appSearchFormIntimation: {},
    appSearchFormPvt: {},
    appSearchFormExmpt: {},
    boards:[],
    events:[],
    approvalStatus:[],
    approvalStatusPurpose:[],
    rejectReasons:[]
  },
  mutations: {
    LOGIN: function (state) {
      state.pending = true;
    },
    LOGIN_SUCCESS: function (state, user) {
      state.isLoggedIn = true;
      state.pending = false;
      state.userToken = user.token;
      state.user = user.user;
    },
    LOGOUT: function (state) {

      state.isLoggedIn = false;
      state.userToken = null;
      state.user = {};
    },
    SET_SEARCH_FORM(state, SearchForm) {
      state.appSearchForm = SearchForm;
    },
    SET_SEARCH_FORM_INTIMATION(state, SearchForm) {
      state.appSearchFormIntimation = SearchForm;
    },
    SET_SEARCH_FORM_PVT(state, SearchForm) {
      state.appSearchFormPvt = SearchForm;
    },
    SET_SEARCH_FORM_EXMPT(state, SearchForm) {
      state.appSearchFormExmpt = SearchForm;
    },
    SET_BOARDS(state, boards){
      state.boards = boards;
    },
    SET_EVENTS(state, events){
      state.events = events;
    },
    SET_REJECT_REASON(state, rejectReasons){
      state.rejectReasons = rejectReasons;
    },
    SET_STATUS(state, status){
      // status.Table.unshift({
      //   Id: 0,
      //   ApplicationStatus: "All",
      // });
      state.approvalStatus = status.Table;      
      let approvalStatusP = status.Table1;
      // approvalStatusP.unshift({
      //   Id: 0,
      //   ApplicationStatus: "All",
      //   StatusPurposeID:0
      // });
      state.approvalStatusPurpose = approvalStatusP;
    }
  },
  actions: {
    login: function ({ commit }, user) {
      commit("LOGIN_SUCCESS", user);
    },
    logout: function ({ commit }) {
      commit("LOGOUT");
    },
    setRegType: function ({ commit }, regtyp) {
      commit("REGTYPE", regtyp);
    },
    setUserData: function ({ commit }, userData) {
      commit("USERDATA", userData);
    },
    setSearchForm: function ({ commit }, searchForm) {
      commit("SET_SEARCH_FORM", searchForm);
    },
    setSearchFormIntimation: function ({ commit }, searchForm) {
      commit("SET_SEARCH_FORM_INTIMATION", searchForm);
    },
    setSearchFormPvt: function ({ commit }, searchForm) {
      commit("SET_SEARCH_FORM_PVT", searchForm);
    },
    setSearchFormExmpt: function ({ commit }, searchForm) {
      commit("SET_SEARCH_FORM_EXMPT", searchForm);
    },
    setMasters: function({commit}, boardID){
      let promiseB = apis.readSGBoardName();        
      let promiseE = apis.readEvents(boardID);
      let promiseS = apis.readAppStatus();
      let promiseR = apis.readRejectReason();

      Promise.allSettled([promiseB, promiseE, promiseS,promiseR])
        .then((results) => {
          if (results[0].status == 'fulfilled') {
            let boards = results[0].value.body;
            boards.unshift({
              SecurityBoardID: "00000000-0000-0000-0000-000000000000",
              SecurityBoardName: "All",
            });
            commit("SET_BOARDS", boards);
          }
          if(results[1].status == 'fulfilled'){
            let events = results[1].value.body;
            events.unshift({
              EventID: "00000000-0000-0000-0000-000000000000",
              EventTitle: "All",
            });
            commit("SET_EVENTS", events);
          }

          if(results[2].status == "fulfilled"){
            let approvalStatus = results[2].value.body;
            
            commit("SET_STATUS", approvalStatus);
          }

          if(results[3].status == "fulfilled"){
            let rejectReason = results[3].value.body;
            
            commit("SET_REJECT_REASON", rejectReason);
          }
        });

    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn;
    },
    getRole: state =>{
      return state.user;
    },
    authToken: state => {
      return state.userToken;
    },
    loggedUser: state => {
      return state.user;
    },
    getAppSearchForm: state => {
      return state.appSearchForm;
    },
    getAppSearchFormIntimation: state => {
      return state.appSearchFormIntimation;
    },
    getAppSearchFormPvt: state => {
      return state.appSearchFormPvt;
    },
    getAppSearchFormExmpt: state => {
      return state.appSearchFormExmpt;
    },
    getUserBoard: state =>{
      return state.user.BoardID;
    },
    getBoards: state=>{
      return state.boards;
    },
    getEvents: state=>{
      return state.events;
    },
    getRejectReason: state=>{
      return state.rejectReasons;
    },
    getApprovalStatus: state=>{
      return state.approvalStatus;
    },
    getApprovalStatusPurpose: state=>{
      return state.approvalStatusPurpose;
    },
    getGenderList:state=>{
      return state.genderList;
    }
  }
})
