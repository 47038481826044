<template>
  <b-container>
    <b-row class="mb-3">
      <b-col>
        <center>
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            @click.prevent="viewSanction"
            variant="warning"
            v-if="
              ApplicationStatus == 14 ||
              ApplicationStatus == 15 ||
              ApplicationStatus == 34 ||
              ApplicationStatus == 35 ||
              ApplicationStatus == 30
            "
            >Verification Letters</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            @click.prevent="printBform"
            variant="success"
            v-if="ApplicationStatus == 20 || ApplicationStatus == 21"
            >B-Form Receipt</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            variant="info"
            @click="print"
            >Print Application</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            @click.prevent="goback"
            variant="danger"
            size="sm"
            >BACK</b-button
          >
          <!-- <b-button
            class="hide-print mt-3 ml-2"
            @click.prevent="unlock"
            variant="secondary"
            size="sm"
            v-if="
              ApplicationStatus <= 3 ||
              ApplicationStatus == 5 ||
              ApplicationStatus == 23
            "
            >UNLOCK PROFILE
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoadingUnlock"
            ></b-spinner>
          </b-button> -->
        </center>
        <hr />
      </b-col>
    </b-row>
    <b-alert
      :show="showDismissibleAlertUnlock"
      dismissible
      @dismissed="showDismissibleAlertUnlock = false"
      :variant="variantUnlock"
      >{{ alertTextUnlock }}
    </b-alert>
    <div id="printme">
      <b-row class="my-2">
        <b-col style="text-align: center">
          <!-- <img
            src="@/assets/images/logo_trans.png"
            class="float-left"
            width="80px"
          /> -->
          <h2>{{ board1 }}</h2>
          <h4>Application for Exemption U/S 23</h4>
        </b-col>
      </b-row>
      <hr />
      <b-row class="my-2">
        <b-col md="1"></b-col>
        <b-col>
          <strong>Application Number:</strong>
          {{ personalDetails.ExemptionApplicationNo }}
        </b-col>
        <b-col></b-col>
        <b-col class="float-right">
          <strong>Date:</strong>
          {{ convertDateFormat(personalDetails.ExemptionApplicationDate) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col md="1"></b-col>
        <b-col>
          <strong>Docket No.:</strong>
          {{
            Docket_no_new(
              personalDetails.ExemptionApplicationNo,
              personalDetails.ExemptionApplicationDate
            )
          }}
        </b-col>
      </b-row>
      <!-- <b-row class="my-2">
        <b-col md="1"></b-col>
        <b-col md="1">
          <strong>Board</strong>
        </b-col>
        <b-col md="6">{{board1}}</b-col>
      </b-row> -->
      <div
        class="my-2"
        style="border: 1px solid black; border-style: solid; padding: 10px"
      >
        <h5>1.Agency Details</h5>
        <div style="margin-left: 3%">
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Name of Employer Agency</b-col>
            <b-col md="5">{{ form.AgencyName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Address of the Agency</b-col>
            <b-col md="5">{{ form.AgencyAddress }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">City</b-col>
            <b-col md="5">{{ personalDetails.DistrictName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Pin Code</b-col>
            <b-col md="5">{{ form.PinCode }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Contact Number</b-col>
            <b-col md="5">{{ form.ContactNumber }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Email ID</b-col>
            <b-col md="5">{{ form.EmailID }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Type of Company</b-col>
            <b-col md="5">{{ form.CompanyType }}</b-col>
          </b-row>
        </div>
      </div>

      <div
        class="table4 mb-2"
        style="border: 1px solid black; border-style: solid; padding: 10px"
      >
        <h5>2.Owner/Partner/Director Details</h5>
        <br />
        <div style="margin-left: 20px">
          <table class="my-2">
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Number</th>
              <th>Email ID</th>
            </tr>
            <tr v-for="owner in form.OwnerDetails" :key="owner.OwnerName">
              <td>{{ owner.OwnerName }}</td>
              <td>{{ owner.OwnerAddress }}</td>
              <td>{{ owner.OwnerMobile }}</td>
              <td>{{ owner.OwnerEmail }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="table3"
        style="border-style: solid; border: 1px solid black; padding: 10px"
      >
        <h5>3.License Details</h5>
        <div style="margin-left: 4%">
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Year of Establishment</b-col>
            <b-col md="5">{{ form.EstablishmentYear }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4"
              >Registration Number as Per Bombay Shop & Commercial Establishment
              Act</b-col
            >
            <b-col md="5">{{ form.ShopActRegNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Male Employees</b-col>
            <b-col md="5">{{ form.MaleEmployeeCount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Female Employees</b-col>
            <b-col md="5">{{ form.FemaleEmployeeCount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">PSRA License Number</b-col>
            <b-col md="5">{{ form.PSRA }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">License Date</b-col>
            <b-col md="5">{{ form.PsraLicenseDate }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">License Validity Date</b-col>
            <b-col md="5">{{ form.PsaraDueDate }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">PAN</b-col>
            <b-col md="5">{{ form.PAN }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">GST No</b-col>
            <b-col md="5">{{ form.GST }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Professional Tax Reg No</b-col>
            <b-col md="5">{{ form.PTNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Employee Welfare Board Reg No</b-col>
            <b-col md="5">{{ form.EmployeeWelfare }}</b-col>
          </b-row>
        </div>
      </div>
      <div
        class="table4 my-2"
        style="border-style: solid; border: 1px solid black; padding: 10px;page-break-before: always;s"
      >
        <h5>4.GuardDetails</h5>
        <br />
        <div
          class="subtable1 mb-5"
          style="margin-left: 20px; margin-right: 20px"
        >
          <table class="my-2">
            <tr>
              <th>Group</th>
              <th>Toltal Ex-Serviceman</th>
              <th>Maharashtra Domiciled</th>
              <th>Citizens of Kashmir</th>
              <th>Citizens of Other State</th>
              <th>Basic</th>
              <th>D.A.</th>
              <th>Allowance</th>
              <th>Total Salary</th>
            </tr>

            <tr>
              <td>Unarmed Security Guard</td>

              <td>{{ form.SecurityGuardNonWeapon.XSvcMan }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Domiciled }}</td>
              <td>{{ form.SecurityGuardNonWeapon.KasmirCtznext }}</td>
              <td>{{ form.SecurityGuardNonWeapon.OtherCtzn }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Basic }}</td>
              <td>{{ form.SecurityGuardNonWeapon.DA }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Allowance }}</td>
              <td>{{ form.SecurityGuardNonWeapon.Total }}</td>
            </tr>

            <tr>
              <td>Armed Security Guard</td>

              <td>{{ form.SecurityGuardsWeapon.XSvcMan }}</td>
              <td>{{ form.SecurityGuardsWeapon.Domiciled }}</td>
              <td>{{ form.SecurityGuardsWeapon.KasmirCtznext }}</td>
              <td>{{ form.SecurityGuardsWeapon.OtherCtzn }}</td>
              <td>{{ form.SecurityGuardsWeapon.Basic }}</td>
              <td>{{ form.SecurityGuardsWeapon.DA }}</td>
              <td>{{ form.SecurityGuardsWeapon.Allowance }}</td>
              <td>{{ form.SecurityGuardsWeapon.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Head Security Guards</td>

              <td>{{ form.HeadSGNW.XSvcMan }}</td>
              <td>{{ form.HeadSGNW.Domiciled }}</td>
              <td>{{ form.HeadSGNW.KasmirCtznext }}</td>
              <td>{{ form.HeadSGNW.OtherCtzn }}</td>
              <td>{{ form.HeadSGNW.Basic }}</td>
              <td>{{ form.HeadSGNW.DA }}</td>
              <td>{{ form.HeadSGNW.Allowance }}</td>
              <td>{{ form.HeadSGNW.Total }}</td>
            </tr>

            <tr>
              <td>Armed Head Security Guards</td>

              <td>{{ form.HeadSGW.XSvcMan }}</td>
              <td>{{ form.HeadSGW.Domiciled }}</td>
              <td>{{ form.HeadSGW.KasmirCtznext }}</td>
              <td>{{ form.HeadSGW.OtherCtzn }}</td>
              <td>{{ form.HeadSGW.Basic }}</td>
              <td>{{ form.HeadSGW.DA }}</td>
              <td>{{ form.HeadSGW.Allowance }}</td>
              <td>{{ form.HeadSGW.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Security Supervisor</td>

              <td>{{ form.SSNW.XSvcMan }}</td>
              <td>{{ form.SSNW.Domiciled }}</td>
              <td>{{ form.SSNW.KasmirCtznext }}</td>
              <td>{{ form.SSNW.OtherCtzn }}</td>
              <td>{{ form.SSNW.Basic }}</td>
              <td>{{ form.SSNW.DA }}</td>
              <td>{{ form.SSNW.Allowance }}</td>
              <td>{{ form.SSNW.Total }}</td>
            </tr>

            <tr>
              <td>Armed Security Supervisor</td>

              <td>{{ form.SSW.XSvcMan }}</td>
              <td>{{ form.SSW.Domiciled }}</td>
              <td>{{ form.SSW.KasmirCtznext }}</td>
              <td>{{ form.SSW.OtherCtzn }}</td>
              <td>{{ form.SSW.Basic }}</td>
              <td>{{ form.SSW.DA }}</td>
              <td>{{ form.SSW.Allowance }}</td>
              <td>{{ form.SSW.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Asst Security Officer</td>

              <td>{{ form.ASONW.XSvcMan }}</td>
              <td>{{ form.ASONW.Domiciled }}</td>
              <td>{{ form.ASONW.KasmirCtznext }}</td>
              <td>{{ form.ASONW.OtherCtzn }}</td>
              <td>{{ form.ASONW.Basic }}</td>
              <td>{{ form.ASONW.DA }}</td>
              <td>{{ form.ASONW.Allowance }}</td>
              <td>{{ form.ASONW.Total }}</td>
            </tr>

            <tr>
              <td>Armed Asst Security Officer</td>

              <td>{{ form.ASOW.XSvcMan }}</td>
              <td>{{ form.ASOW.Domiciled }}</td>
              <td>{{ form.ASOW.KasmirCtznext }}</td>
              <td>{{ form.ASOW.OtherCtzn }}</td>
              <td>{{ form.ASOW.Basic }}</td>
              <td>{{ form.ASOW.DA }}</td>
              <td>{{ form.ASOW.Allowance }}</td>
              <td>{{ form.ASOW.Total }}</td>
            </tr>

            <tr>
              <td>Unarmed Security Officer</td>

              <td>{{ form.SQNW.XSvcMan }}</td>
              <td>{{ form.SQNW.Domiciled }}</td>
              <td>{{ form.SQNW.KasmirCtznext }}</td>
              <td>{{ form.SQNW.OtherCtzn }}</td>
              <td>{{ form.SQNW.Basic }}</td>
              <td>{{ form.SQNW.DA }}</td>
              <td>{{ form.SQNW.Allowance }}</td>
              <td>{{ form.SQNW.Total }}</td>
            </tr>

            <tr>
              <td>Armec Security Officer</td>

              <td>{{ form.SOW.XSvcMan }}</td>
              <td>{{ form.SOW.Domiciled }}</td>
              <td>{{ form.SOW.KasmirCtznext }}</td>
              <td>{{ form.SOW.OtherCtzn }}</td>
              <td>{{ form.SOW.Basic }}</td>
              <td>{{ form.SOW.DA }}</td>
              <td>{{ form.SOW.Allowance }}</td>
              <td>{{ form.SOW.Total }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="table4 mb-2"
        style="border-style: solid; border: 1px solid black; padding: 10px;page-break-before: always;s"
      >
        <b-row class="table4 mb-2">
          <h5>5.Principal Employer Details</h5>
          <br />
          <b-col md="3">
            <b-input-group>
              <b-form-input
                v-model="filter"
                placeholder="Type to Search"
                size="sm"
              />
              <b-input-group-append>
                <b-btn
                  :disabled="!filter"
                  v-on:click="get_employer_list"
                  size="sm"
                  >Search</b-btn
                >
                <b-btn :disabled="!filter" v-on:click="clear_list" size="sm"
                  >Clear</b-btn
                >
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <div style="margin-left: 20px">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th>Application No</th>
                <th>Employer Name</th>
                <th>Created Date</th>
                <th>Status</th>
                <th>Remark</th>
                <th>Action</th>
                <th>View</th>
                <th>View Guards</th>
              </tr>
            </thead>
            <tr v-for="(item, index) in employer_data" :key="index">
              <td>{{ item.RegistrationNumber }}</td>
              <td>{{ item.EntityName }}</td>
              <td>{{ item.CreatedDate }}</td>
              <td style="width: 150px" v-if="ApprovalStatus.length > 0">
                <b-form-group>
                  <b-select
                    v-if="RoleID == 5"
                    id="ipDocType"
                    class="form-control hide-print"
                    value-field="StatusID"
                    size="sm"
                    text-field="Status"
                    v-on:change="approveDoc9()"
                    :options="ApprovalStatus"
                    v-model="item.EmployerApprovalStatus"
                  ></b-select>
                  <p v-else>
                    {{ item.ApplicationStatus }}
                  </p>
                </b-form-group>
                <p v-if="ApprovalStatus.length == 0"></p>
              </td>
              <td style="width: 150px" v-else>
                {{ item.ApplicationStatus }}
              </td>
              <td v-if="ApprovalStatus.length > 0">
                <input
                  type="text"
                  id="approvalRemarks"
                  class="form-control hide-print"
                  value-field="approvalRemarks"
                  size="sm"
                  text-field="Status"
                  v-model="item.Remark"
                />
              </td>
              <td v-else>
                {{ item.Remark }}
              </td>
              <td style="width: 100px">
                <b-button
                  class="hide-print"
                  v-if="ApprovalStatus.length > 0"
                  @click="save_employer_status(item, index)"
                  size="sm"
                  variant="info"
                  style="margin: 2px"
                  >Update</b-button
                >
              </td>
              <td>
                <b-button
                  class="hide-print"
                  size="sm"
                  variant="primary"
                  style="margin: 2px"
                  @click="view_employer(item)"
                >
                  <i class="fas fa-eye"></i>
                </b-button>
              </td>
              <td>
                <b-button
                  class="hide-print"
                  size="sm"
                  variant="danger"
                  style="margin: 2px"
                  @click="view_guard(item)"
                >
                  View
                </b-button>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="table6 mb-2"
        style="
          border-style: solid;
          border: 1px solid black;
          padding: 10px;
          page-break-before: always;
        "
      >
        <h5>6.Employment Hours, Leaves and Amenities Details</h5>
        <br />
        <div style="margin-left: 3%">
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Daily Working Hours</b-col>
            <b-col md="5">{{ form.WorkingHours }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Overtime Charges</b-col>
            <b-col md="5">{{ form.OTRate }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Weekly Off</b-col>
            <b-col md="5">{{ form.Weeklyoff }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Paid Leave</b-col>
            <b-col md="5">{{ form.PaidLeave }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Paid Leaves</b-col>
            <b-col md="5">{{ form.PaidLeavesNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Paid Holidays</b-col>
            <b-col md="5">{{ form.PaidHolidays }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Number of Paid Holidays</b-col>
            <b-col md="5">{{ form.PaidHolidayNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Accidental Insurance</b-col>
            <b-col md="5">{{ form.AccidentInsurance }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Travel Allowance</b-col>
            <b-col md="5">{{ form.LTA }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Travel Allowance Amount</b-col>
            <b-col md="5">{{ form.LTAAmount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">HRA</b-col>
            <b-col md="5">{{ form.HRA }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">HRA Amount</b-col>
            <b-col md="5">{{ form.HRAAmount }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Bonus%</b-col>
            <b-col md="5">{{ form.Bonus }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Bonus Distribution</b-col>
            <b-col md="5">{{ form.BonusDistribution }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Medical Facilities</b-col>
            <b-col md="5">{{ form.MedicalFacilities }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Provident Fund</b-col>
            <b-col md="5">{{ form.EPF }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">PF Registration Number</b-col>
            <b-col md="5">{{ form.EPFRegNum }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Service Years for Gratuity</b-col>
            <b-col md="5">{{ form.GratuityYears }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">LIC Group Gratuity Scheme Number</b-col>
            <b-col md="5">{{ form.LICGratuityNo }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Uniform Given to Guard</b-col>
            <b-col md="5">{{ form.Uniform }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">ESIC Registration No</b-col>
            <b-col md="5">{{ form.ESIC }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Social /Other Benefits</b-col>
            <b-col md="5">{{ form.SocialBenefits }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Salry Paid By</b-col>
            <b-col md="5">{{ form.SalryPayer }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Salary Mode</b-col>
            <b-col md="5">{{ form.SalaryMode }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Salary Payment Day</b-col>
            <b-col md="5">{{ form.SalaryDay }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Name of Training Provider</b-col>
            <b-col md="5">{{ form.TrainerName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Training Days</b-col>
            <b-col md="5">{{ form.TrainingDays }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Union of Which Your Employees are Member</b-col>
            <b-col md="5">{{ form.UnionName }}</b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="4">Reason for Non Police Verification</b-col>
            <b-col md="5">{{ form.ReasonNonVerification }}</b-col>
          </b-row>
        </div>
      </div>

      <div
        class="table4"
        style="border-style: solid; border: 1px solid black; padding: 10px"
      >
        <h5>7.Bank Account Details of the Agency</h5>
        <br />
        <div class="mb-5" style="margin-left: 20px">
          <table class="my-2">
            <tr>
              <th>Bank Name</th>
              <th>Branch Name</th>
              <th>Bank Account Number</th>
            </tr>
            <tr v-for="bank in form.BankDetails1" :key="bank.BankAccountNumber">
              <td>{{ bank.BankName }}</td>
              <td>{{ bank.BranchName }}</td>
              <td>{{ bank.BankAccountNumber }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="my-2"
        style="
          border-style: solid;
          border: 1px solid black;
          padding: 10px;
          page-break-before: always;
        "
      >
        <h5>8.Documents</h5>
        <br />
        <div style="margin-left: 3%">
          <table class="table table-sm">
            <tr>
              <th>Document Type</th>
              <th>Link</th>
              <th
                v-if="ApprovalStatus.length > 0 && RoleID == 5 && RoleID == 6"
              >
                Status
              </th>
            </tr>
            <tr>
              <td>Owner Police Verification</td>
              <td>
                <a
                  v-if="form.OwnerPoliceVerification"
                  class="hide-print"
                  target="_blank"
                  :href="form.OwnerPoliceVerification[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="id-18"
                  v-if="
                    form.OwnerPoliceVerification &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.OwnerPoliceVerification[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(
                      18,
                      'OwnerPoliceVerification',
                      'Owner Police Verification'
                    )
                  "
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td>PSARA License</td>
              <td>
                <a
                  v-if="form.PSARALicenseCopy"
                  class="hide-print"
                  target="_blank"
                  :href="form.PSARALicenseCopy[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="12"
                  v-if="
                    form.PSARALicenseCopy &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.PSARALicenseCopy[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(12, 'PSARALicenseCopy', 'PSARA License')
                  "
                ></b-form-select>
              </td>
            </tr>

            <tr>
              <td>Affidavit (On Stamp Paper Of Rs.300)</td>
              <td>
                <a
                  v-if="form.BalanceSheets"
                  class="hide-print"
                  target="_blank"
                  :href="form.BalanceSheets[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="5"
                  v-if="
                    form.BalanceSheets &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.BalanceSheets[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="approveDoc1(5, 'BalanceSheets', '300 Affidavit')"
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td>PT Chalan Coppy</td>
              <td>
                <a
                  v-if="form.PTRegistration"
                  class="hide-print"
                  target="_blank"
                  :href="form.PTRegistration[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="2"
                  v-if="
                    form.PTRegistration &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.PTRegistration[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(2, 'PTRegistration', 'PT Chalan Coppy')
                  "
                ></b-form-select>
              </td>
            </tr>
            <!-- <tr>
              <td>GST Returns of Last 3 years</td>
              <td>
                <a v-if="form.GSTReturns"
                  class="hide-print"
                  target="_blank"
                  :href="form.GSTReturns[0].content"
                  >View</a
                >
              </td>
              <td>
                  <b-form-select
                  id="16"
                  v-if="form.GSTReturns && ApprovalStatus.length > 0 && RoleID==5"
                    v-model="form.GSTReturns[0].status"
                    :options="approveOptions"
                    
                    size="sm"
                    style="width: 100px"
                    v-on:change="approveDoc1(16,'GSTReturns','GST Returns of Last 3 years')"
                    
                  ></b-form-select>
                </td>
            </tr> -->
            <tr>
              <td>Provident Fund Chalan Copy</td>
              <td>
                <a
                  v-if="form.PFRegistration"
                  class="hide-print"
                  target="_blank"
                  :href="form.PFRegistration[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="3"
                  v-if="
                    form.PFRegistration &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.PFRegistration[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(
                      3,
                      'PFRegistration',
                      'Provident Fund Chalan Cop'
                    )
                  "
                ></b-form-select>
              </td>
            </tr>

            <tr>
              <td>ESIC Chalan Copy</td>
              <td>
                <a
                  v-if="form.ESICRegistration"
                  class="hide-print"
                  target="_blank"
                  :href="form.ESICRegistration[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="4"
                  v-if="
                    form.ESICRegistration &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.ESICRegistration[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(4, 'ESICRegistration', 'ESIC Chalan Copy')
                  "
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td>PAN Card</td>
              <td>
                <a
                  v-if="form.PANCard"
                  class="hide-print"
                  target="_blank"
                  :href="form.PANCard[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  v-if="
                    form.PANCard && ApprovalStatus.length > 0 && RoleID == 5
                  "
                  v-model="form.PANCard[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="approveDoc1('PANCard', 'PAN Card')"
                ></b-form-select>
              </td>
            </tr>
            <tr v-if="form.EmployeeWelfare != '0'">
              <td>Employee Welfare Board Registration</td>
              <td>
                <a
                  v-if="form.EmpWelfareRegistration"
                  class="hide-print"
                  target="_blank"
                  :href="form.EmpWelfareRegistration[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="1"
                  v-if="
                    form.EmpWelfareRegistration &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.EmpWelfareRegistration[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(
                      1,
                      'EmpWelfareRegistration',
                      'Employee Welfare Board Registration'
                    )
                  "
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td>Company Incorporation</td>
              <td>
                <a
                  v-if="form.CompanyIncorporation"
                  class="hide-print"
                  target="_blank"
                  :href="form.CompanyIncorporation[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id=""
                  v-if="
                    form.CompanyIncorporation &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.CompanyIncorporation[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1('CompanyIncorporation', 'Company Incorporation')
                  "
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td>
                Three months DBT information of security guards (Wage register,
                Payslip)
              </td>
              <td>
                <a
                  v-if="form.DBT"
                  class="hide-print"
                  target="_blank"
                  :href="form.DBT[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id=""
                  v-if="form.DBT && ApprovalStatus.length > 0 && RoleID == 5"
                  v-model="form.DBT[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="approveDoc1(9, 'DBT', 'Company Incorporation')"
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td>Agreement with Employer</td>
              <td>
                <a
                  v-if="form.Agreement"
                  class="hide-print"
                  target="_blank"
                  :href="form.Agreement[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="6"
                  v-if="
                    form.Agreement && ApprovalStatus.length > 0 && RoleID == 5
                  "
                  v-model="form.Agreement[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="approveDoc1(6, 'Agreement', 'Agreement')"
                ></b-form-select>
              </td>
            </tr>
            <!-- <tr>
              <td>Concent Letter</td>
              <td>
                <a
                v-if="form.ConsentLetter"
                  class="hide-print"
                  target="_blank"
                  :href="form.ConsentLetter[0].content"
                  >View</a
                >
              </td>
              <td>
                  <b-form-select
                  id="8"
                   v-if="form.ConsentLetter && ApprovalStatus.length > 0 && RoleID==5"
                    v-model="form.ConsentLetter[0].status"
                    :options="approveOptions"
                    
                    size="sm"
                    style="width: 100px"
                 v-on:change="approveDoc1(8,'ConsentLetter','Concent Letter')"
                    
                  ></b-form-select>
                </td>
            </tr> -->
            <tr>
              <td>Declaration By Employer Agency on Stamp Paper</td>
              <td>
                <a
                  v-if="form.Declaration"
                  class="hide-print"
                  target="_blank"
                  :href="form.Declaration[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="17"
                  v-if="
                    form.Declaration && ApprovalStatus.length > 0 && RoleID == 5
                  "
                  v-model="form.Declaration[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(17, 'Declaration', 'Declaration Form')
                  "
                ></b-form-select>
              </td>
            </tr>

            <tr>
              <td>DBT Bank Scroll (3 month Guards passbook Xerox)</td>
              <td>
                <a
                  v-if="form.BankAccountProof"
                  class="hide-print"
                  target="_blank"
                  :href="form.BankAccountProof[0].content"
                  >View</a
                >
              </td>
              <td>
                <b-form-select
                  id="10"
                  v-if="
                    form.BankAccountProof &&
                    ApprovalStatus.length > 0 &&
                    RoleID == 5
                  "
                  v-model="form.BankAccountProof[0].status"
                  :options="approveOptions"
                  size="sm"
                  style="width: 100px"
                  v-on:change="
                    approveDoc1(10, 'BankAccountProof', 'Bank Account Proof')
                  "
                ></b-form-select>
              </td>
            </tr>
          </table>
          <!-- <b-col>
            <center>
              <b-button
                class="hide-print"
                type="submit"
                variant="primary"
                size="sm"
                @click="update_status()"
             
                >Update Status</b-button
              >
            </center>
          </b-col> -->
        </div>
      </div>
      <div
        class="table4"
        style="border-style: solid; border: 1px solid black; padding: 10px"
      >
        <h5>9. Guards</h5>
        <div class="mb-2" style="margin-left: 20px">
          <span>
            <b-button class="ml-3" variant="link" @click="view_all_guard">
              <span class="mr-2" style="color: black">No. of Guards :</span>
              {{ personalDetails.GuardCount }}
              <small>(click here to view guards)</small>
            </b-button>
          </span>
        </div>
      </div>
    </div>

    <div
      class="table4"
      style="
        border-style: solid;
        border: 1px solid black;
        margin-top: 10px;
        padding: 10px;
        page-break-before: always;
      "
    >
      <h5 class="reg-form-title text-info" style="margin-bottom: 8px"></h5>
      <div v-if="RoleID != 5" class="reg-form-body">
        <div class="myclass">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th>Document Type</th>
                <th style="text-align: right">Link</th>
              </tr>

              <!-- <tr class="hide-print" v-if="RoleID=='6'">
                <td colspan="8" style="text-align:right">
                  <button class="btn btn-sm btn-primary" @click="add_inspection_report()">Upload</button>
                </td>
              </tr> -->
            </thead>
          </table>
        </div>
      </div>
      <div class="reg-form-body">
        <div class="myclass">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th colspan="8">Checklist</th>
                <!-- <th>Link</th> -->
              </tr>
              <!-- <tr class="hide-print" v-if="RoleID == '6'">
                <td colspan="8" style="text-align: right">
                  <input
                    type="radio"
                    id="one"
                    value="Yes"
                    v-on:change="check_all"
                    v-model="checkalllist"
                  />&nbsp; <label for="one">सर्व हो</label>&nbsp;
                  <input
                    type="radio"
                    id="two"
                    value="No"
                    v-on:change="check_all"
                    v-model="checkalllist"
                  />&nbsp;
                  <label for="two">सर्व नाही</label>
                </td>
              </tr> -->

              <tr>
                <td>एजन्सीचा विहित नमुन्यातील अर्ज परिशिष्ठ ब</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[0]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[0]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
                <td>देण्यात येणारे इतर फायदे याबाबतची माहिती</td>
                <td>
                  <input
                    :disabled="RoleID != 6"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[11]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 5">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 6"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[11]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 5">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>ठेकेदार संस्थेचे पोलीस पडताळणी अहवाल</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[18]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[18]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>

                <td>
                  खाजगी सुरक्षा एजन्सी ( नियमनव अधिनियम 2005 अंतर्गत परवान्याची
                  प्रत )
                </td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[12]"
                  />&nbsp; <label for="one">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[12]"
                  />&nbsp;
                  <label for="two">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>शपथपत्र (रु.३०० च्या स्टॅम्पपेपरवर )</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[5]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[5]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
                <td>हमीपत्र रुपये १००/- च्‍या स्टॅम्प पेपरवर</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[17]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[17]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>मुंबई दुकाने व आस्थापना नोंदणी प्रमाणपत्र</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[1]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[1]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
                <td>व्यवसाय कर नोंदणी प्रमाणपत्राची व चलनाची प्रत</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[2]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[2]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>वस्तु व सेवा कर भरल्याची पावती (चलन प्रत)</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[16]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[16]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
                <td>भविष्य निर्वाह निधी नोंदणी प्रमाणपत्राची व चलनाची प्रत</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[3]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[3]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  राज्य कामगार विमा योजना नोंदणी प्रमाणपत्राची व चलनाची प्रत
                </td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[4]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[4]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
                <td>सर्व मुख्य मालकाबाबतची माहिती परिशिष्ट क</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[8]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[8]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>सर्व मुख्य मालकाचे संमतीपत्र</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    class="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[7]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[7]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
                <td>सर्व़ मुख्य मालकासोबतचे करारपत्र</td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[6]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[6]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  सुरक्षा रक्षकांना देण्यात येणाऱ्या वेतनाबाबतची ( तीन महिन्याचे
                  DBT वेतनपत्रक )
                </td>
                <td>
                  <input
                  :disabled="RoleID != 6 && RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[9]"
                  />&nbsp; <label for="one" :disabled="RoleID != 6 && RoleID != 5">हो</label>&nbsp;

                  <input
                  :disabled="RoleID != 6 && RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[9]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID != 6 && RoleID != 5">नाही</label>
                  <br />
                </td>
                <td>
                  सुरक्षा रक्षकांचे वेतन बँकेमार्फत देण्यात येत असल्याबाबत ( तीन
                  महिन्याचे बँकेचे DBT स्क्रोल )
                </td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[10]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[10]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  प्रत्येक सुरक्षा रक्षकांचा विहित नमुन्यातील अर्ज ( फॉर्म डी
                  आधारकार्ड क्रमांकासह )
                </td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[13]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[13]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
                <td>
                  सर्व सुरक्षा रक्षकांचे पोलीस पडताळणी अहवाल अलिकडच्या काळातील
                </td>
                <td>
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[14]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 6">हो</label>&nbsp;
                  <input
                    :disabled="RoleID != 5"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[14]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 6">नाही</label>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  मुख्य मालक/ आस्थापना एजन्सीमार्फत न्यायालयीन प्रकरण प्रलंबित
                  आहे किंवा कसे तसेच<br />
                  मुख्य मालक/ आस्थापना या ठिकाणी कार्यरत सुरक्षा रक्षकांना कलम
                  23 अन्वये सुट दिली होती किंवा कसे ?
                </td>
                <td>
                  <input
                    :disabled="RoleID != 6"
                    type="radio"
                    id="one"
                    value="Yes"
                    v-model="checklist[15]"
                  />&nbsp;
                  <label for="one" :disabled="RoleID == 5">हो</label>&nbsp;

                  <input
                    :disabled="RoleID != 6"
                    type="radio"
                    id="two"
                    value="No"
                    v-model="checklist[15]"
                  />&nbsp;
                  <label for="two" :disabled="RoleID == 5">नाही</label>
                  <br />
                </td>
                <!-- <td>
                    मुख्य  मालक/ आस्थापना  एजन्सीमार्फत  न्यायालयीन प्रकरण प्रलंबित आहे किंवा कसे तसेच<br> मुख्य मालक/ आस्थापना  या ठिकाणी कार्यरत सुरक्षा  रक्षकांना कलम 23 अन्वये सुट दिली होती किंवा कसे ? 
                </td>
                <td>
                    <input type="radio" id="one" value="Yes" v-model="checklist[15]">&nbsp;
                    <label for="one">हो</label>&nbsp;

                    <input type="radio" id="two" value="No" v-model="checklist[15]">&nbsp;
                    <label for="two">नाही</label>
                  <br>
                </td> -->
              </tr>
              <tr v-if="RoleID == 5 || RoleID == 6">
                <td>
                  <button
                    class="btn btn-sm btn-primary"
                    @click="save_check_list()"
                  >
                    Save
                  </button>
                </td>
              </tr>
            </thead>
          </table>
          <!-- {{checklist}} -->
        </div>
      </div>
      <!-- RoleID:{{RoleID}} -->

      <div v-if="RoleID != 5" class="reg-form-body" style="">
        <div class="" style="">
          <table class="table table-sm my-table" style="">
            <tbody
              class="thead-dark"
              v-for="(option, index1) in employerdropdownlist"
              :key="index1"
            >
              <tr>
                <td>Employer Name</td>
                <td colspan="3">
                  <select
                    class="form-control full-length"
                    data-placeholder
                    tabindex="1"
                    :disabled="RoleID != 6"
                    v-model="test_type"
                    @change="select_emp()"
                  >
                    <option value="" selected disabled>
                      {{ option.EntityName }}
                    </option>
                    <!-- <option
                                          
                                            v-for="(
                                              option, index
                                            ) in employerdropdownlist"
                                            :value="option.ExemptionEmployerID"
                                            v-bind:key="index"
                                          >
                                            {{ option.EntityName }}
                                            
                                          </option> -->
                  </select>
                </td>
              </tr>
              <tr>
                <td>Inpsection Report</td>
                <td colspan="2" style="text-align: right">
                  <!-- {{employerdropdownlist[index1]}}
                  {{index1}} -->
                  <!-- <input type="file" class="form_control" >-->
                  <a
                    v-if="employerdropdownlist[index1]['FilePath']"
                    target="_blank"
                    class="hide-print"
                    :href="employerdropdownlist[index1]['FilePath']"
                    >View</a
                  >
                  <!-- {{uploade_file}} -->
                  <div
                    class="file file--upload"
                    style="align: right !important"
                  >
                    <label :for="'input-file' + index1">
                      <i class="material-icons">Select file</i>
                    </label>
                    <input
                      :id="'input-file' + index1"
                      type="file"
                      @change="inspection_report_file($event, index1)"
                      :disabled="RoleID != 6"
                    />
                    <!-- <p v-if="employerdropdownlist[index]['FilePath']">
                      {{ employerdropdownlist[index]['FilePath'] }}
                    </p>
                    {{employerdropdownlist[index]['FilePath']}} -->
                  </div>
                </td>
              </tr>

              <tr>
                <td>Date of inspection</td>
                <td colspan="3">
                  <p v-if="employerdropdownlist[index1]['InspectionDate']">
                    {{ employerdropdownlist[index1]["InspectionDate"] }}
                    <input
                      :disabled="RoleID != 6"
                      type="button"
                      class="btn btn-primary btn-xs"
                      value="change"
                      @click="change_date(index1)"
                    />
                  </p>
                  <date-picker
                    v-else
                    format="YYYY-MM-DD"
                    type="datetime"
                    width="300px"
                    :disabled="RoleID != 6"
                    v-model="employerdropdownlist[index1]['InspectionDate']"
                    v-on:change="
                      change_date2(
                        employerdropdownlist[index1]['InspectionDate'],
                        index1
                      )
                    "
                  ></date-picker>
                </td>
              </tr>
              <tr>
                <td>Total guard working</td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    :disabled="RoleID != 6"
                    v-model="employerdropdownlist[index1]['GuardsObserved']"
                  />
                </td>
                <td>Guards Not Included in Exemption Application</td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    :disabled="RoleID != 6"
                    v-model="employerdropdownlist[index1]['GuardsExcluded']"
                  />
                </td>
              </tr>
              <tr>
                <td>Observations</td>
                <td colspan="3">
                  <textarea
                    @keyup="
                      change_size(index1);
                      change_font($event, index1);
                    "
                    :class="
                      'form-control resize-ta observation_remark' + index1
                    "
                    :disabled="RoleID != 6"
                    v-model="employerdropdownlist[index1]['Observations']"
                  >
<pre></pre></textarea
                  >
                  <ul
                    v-show="word_suggestions_array[index1]"
                    class="list-group wordlist word_suggestions"
                  >
                    <li
                      style=""
                      v-for="(words, key) in word_suggestions"
                      @click="append_text(words, index1)"
                      :key="key"
                      class="list-group-item"
                    >
                      {{ words }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="hide-print" v-if="RoleID == '6'">
                <td colspan="5"></td>
                <td colspan="8" style="text-align: left">
                  <button
                    class="btn btn-sm btn-primary"
                    @click="save_inspection(option, index1)"
                  >
                    Save
                  </button>
                  <!-- <button class="btn btn-sm btn-primary" @click="save_check_list()">Save</button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      class="table4"
      style="
        border-style: solid;
        border: 1px solid black;
        margin-top: 10px;
        padding: 10px;
        page-break-before: always;
      "
    >
      <h5 class="reg-form-title text-info" style="margin-bottom: 8px">
        Gazette Details

        <!-- <button

          @click.prevent="saveDocumentToFile"
          class="btn btn-primary btn-sm"
        >
          Download
        </button> -->
      </h5>
      <div class="reg-form-body">
        <div class="myclass">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr
                v-if="
                  this.RoleID == 8 &&
                  (this.ApplicationStatus == 26 || this.ApplicationStatus == 19)
                "
              >
                <td>
                  <input type="text" v-model="gr_number" class="form-control" />
                </td>
                <td>
                  <input
                    type="text"
                    @keyup="change_font_gr_number($event)"
                    v-model="gr_number_marathi"
                    class="form-control"
                  />
                  <ul class="list-group">
                    <li
                      style=""
                      v-for="(words, key) in word_suggestions1"
                      @click="append_text1(words)"
                      :key="key"
                      class="list-group-item"
                    >
                      {{ words }}
                    </li>
                  </ul>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="gr_file_number"
                    class="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="gr_file_name"
                    class="form-control"
                  />
                </td>
                <td>
                  <date-picker
                    format="DD-MM-YYYY"
                    type="datetime"
                    width="300px"
                    v-model="gr_date"
                  ></date-picker>
                </td>
                <!-- <td><input type="text" v-model="gr_link" class="form-control"></td> -->
                <td style="width: 100px">
                  <!-- <a v-if="uploade_file_gr_link" target="_blank" class="hide-print" :href="uploade_file_gr_link">View</a> -->
                  <!-- {{uploade_file}} -->
                  <div
                    class="file file--upload"
                    style="align: right !important"
                  >
                    <label for="input-file1">
                      <i class="material-icons">Select file</i>
                    </label>
                    <input
                      id="input-file1"
                      type="file"
                      @change="gr_link_file_change"
                    />
                    <p v-if="gr_link_file[0]">{{ gr_link_file[0].name }}</p>
                    <p v-else>
                      <a
                        v-if="personalDetails.GrFile"
                        target="_blank"
                        class="hide-print"
                        :href="uploade_file_gr_link"
                        >View</a
                      >
                    </p>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="gr_signatory"
                    class="form-control"
                  />
                </td>

                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="save_gr_details"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <tr>
                <th>Notification Number</th>
                <th>Notification Number Marathi</th>
                <th>Notification File Number</th>
                <th>Notification File Name</th>
                <th>Notification Date</th>
                <!-- <th>GR Link</th> -->
                <th>Notification Link</th>
                <th colspan="2">Notification Signatory</th>
              </tr>
              <tr v-if="personalDetails.GrNumber">
                <td>{{ personalDetails.GrNumber }}</td>
                <td>{{ personalDetails.GrMarathiNumber }}</td>
                <td>{{ personalDetails.GrFileNo }}</td>
                <td>{{ personalDetails.GrFileName }}</td>
                <td>{{ convertDateFormat(personalDetails.GrDate) }}</td>
                <!-- <td>{{personalDetails.GrMapLink}}</td> -->
                <td>
                  <a
                    v-if="uploade_file_gr_link"
                    target="_blank"
                    class="hide-print"
                    :href="uploade_file_gr_link"
                    >View</a
                  >
                </td>
                <td colspan="2">{{ personalDetails.GrSignatory }}</td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <!-- approvalstatus is approval dropdown data,rejectreason is reason dropdown
      application status is cureent status and application id is current app. id -->
    <div
      class="py-2"
      style="
        border-style: solid;
        border: 1px solid gray;
        margin-top: 10px;
        padding: 10px;
      "
    >
      <!-- {{check_employer_status1}}------------
    {{check_employer_status}}-----------
    {{check_employer_status4}} -->
      <approvals
        :ApprovalStatus="ApprovalStatus"
        :EmployerApprovalStatus="check_employer_status"
        :GuardApprovalStatus="check_employer_status1"
        :CheckListStatus="check_employer_status4"
        :RejectReasons="RejectReasons"
        :ApplicationID="ApplicationID"
        :ApplicationStatus="ApplicationStatus"
        :remark="formGuard.Remarks"
        :UserID="formGuard.UserID"
        v-if="ApprovalStatus.length > 0"
      ></approvals>
      <approval-log :ApplicationStatus="ApprovalLogList"></approval-log>
    </div>
    <b-row class="mb-3">
      <b-col>
        <center>
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            @click.prevent="viewSanction"
            variant="warning"
            v-if="
              ApplicationStatus == 14 ||
              ApplicationStatus == 15 ||
              ApplicationStatus == 34 ||
              ApplicationStatus == 35 ||
              ApplicationStatus == 30
            "
            >Verification Letters</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            @click.prevent="printBform"
            variant="success"
            v-if="ApplicationStatus == 20 || ApplicationStatus == 21"
            >B-Form Receipt</b-button
          >
          <!-- <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            variant="info"
            @click="print"
            >Inspection Report </b-button
          > -->
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            variant="info"
            @click="print"
            >Print Application</b-button
          >

          <b-button
            class="hide-print mt-3 ml-2"
            @click.prevent="goback"
            variant="danger"
            size="sm"
            >BACK</b-button
          >
          <!-- <b-button
            class="hide-print mt-3 ml-2"
            @click.prevent="unlock"
            variant="secondary"
            size="sm"
            v-if="
              ApplicationStatus <= 3 ||
              ApplicationStatus == 5 ||
              ApplicationStatus == 23
            "
            >UNLOCK PROFILE
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoadingUnlock"
            ></b-spinner>
          </b-button> -->
        </center>
      </b-col>
    </b-row>
    <b-modal
      ref="mdlEmployer"
      size="xl"
      hide-footer
      title="Principal Employer Profile"
    >
      <employer-profile :id="formEmployer.ID"></employer-profile>
    </b-modal>
    <b-modal
      ref="mdlInsReport"
      size="lg"
      hide-footer
      title="Upload Inspection Report"
    >
      <table border="0" class="table_border_none"></table>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from "vue";
import apis from "@/apis";
import vueHtmlToPaper from "vue-html-to-paper";
import DatePicker from "vue2-datepicker";
import Formatter from "@/Formatter.js";
import approvals from "../../components/Approvals.vue";
//import approval from "../../components/Approval.vue";
import EmployerProfile from "./EmployerProfile.vue";
import ApprovalLog from "../../components/ApprovalLog.vue";
import lambda from "@/apis/lambda";
import ApprovalLogVue from "../../components/ApprovalLog.vue";
import * as _ from "lodash";
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  TextRun,
  AlignmentType,
  TabStopType,
  TabStopPosition,
  WidthType,
  Footer,
  Header,
  PageNumberSeparator,
  PageNumberType,
  PageNumber,
} from "docx";
import { saveAs } from "file-saver";

export default {
  components: {
    DatePicker,
    approvals,
    //approval,
    ApprovalLog,
    EmployerProfile,
    ApprovalLogVue,
    Document,
    HeadingLevel,
    ImageRun,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    VerticalAlign,
    TextRun,
    AlignmentType,
    TabStopType,
    TabStopPosition,
    WidthType,
    Footer,
    Header,
    PageNumberSeparator,
    PageNumberType,
    PageNumber,
  },
  props: ["ApplicationID", "ApplicationStatus"],
  data() {
    return {
      visible: true,
      employerdropdownlist: [],
      CreatedBy: "",
      ExemptionEmployerID: "",
      FilePath: "",
      GuardsExcluded: "",
      GuardsObserved: "",
      index1: "",
      InspectionDate: "",
      Observations: "",
      TotalObservedGuards: "",
      showButton: true,
      showApproval: false,
      isReject: false,
      required: false,
      Caste: [],
      CasteID: "",
      PastLOI: false,
      printing: true,
      personalDetails: {},
      docObj: {},
      board1: "",
      docTbl: [],
      test_type: "",
      filter: "",
      employer_data: [],
      employer_data_all: [],
      update_aproval_status: [],
      allemployee: "",
      form: {},
      formEmployer: {
        filter: "",
        BoardID: "",
        ApplicationStatus: this.ApplicationStatus,
        Purpose: 0,
        RejectReason: 0,
        Remarks: "",
        UserID: this.$store.getters.loggedUser.UserID,
        ID: this.ApplicationID,
        ApplicationID: this.ApplicationID,
      },
      formGuard: {
        BoardID: this.$store.getters.getUserBoard,
        ApplicationStatus: this.ApplicationStatus,
        UserID: this.$store.getters.loggedUser.UserID,
        ID: this.ApplicationID,
        ApplicationID: this.ApplicationID,
        OffSet: 0,
        Limit: 500,
      },
      RoleID: 0,
      ApprovalStatus: [],
      approvalStatus1: {
        UserID: "",
        PoliceVerificationStatus: null,
        PSARALicenseStatus: null,
        AffidavitStatus: null,
        PTChalanCoppyStatus: null,
        GSTReturnsStatus: null,
        PFChalanCoppyStatus: null,
        ESICChalanCoppyStatus: null,
        AgreementStatus: null,
        ConcentLetterStatus: null,
        DeclarationFormStatus: null,
        PANCardStatus: null,
        EmployeeWelfareBoardRegistrationStatus: null,
        CompanyIncorporationStatus: null,
        BankAccountProofStatus: null,
      },
      approvalRemarks: [],
      approvalFields: [
        "ApplicationStatus",
        "RejectionReason",
        "Remarks",
        {
          key: "LogDate",
          label: "Approve/Reject Date",
          formatter: "convertDateFormat",
        },
        "ApproverName",
      ],
      RejectReasons: this.$store.getters.getRejectReason,
      alertText: "",
      variant: "success",
      isLoading: false,
      showDismissibleAlert: false,
      showDismissibleAlertUnlock: false,
      isLoadingUnlock: false,
      alertTextUnlock: "",
      variantUnlock: "success",
      ExemptionCheckListID: "00000000-0000-0000-0000-000000000000",
      uForm: {
        ApplicationID: this.ApplicationID,
        UserID: this.$store.getters.loggedUser.UserID,
        Remarks: "",
      },
      checklist: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      checkalllist: "",
      inspection_report: "",
      shree_label: "",
      agency_name: "",
      employer_name: "",
      guardcount1: "",
      guardcount2: "",
      guardcount3: "",
      guardcount4: "",
      observation_date: new Date(),
      observation_remark: "",
      filepath: "",
      grfilepath: "",

      uploade_file: "",
      uploade_file_gr: "",
      file: "",
      change_file_check: "",
      word_suggestions: [],
      word_suggestions_array: [],
      word_suggestions1: "",
      ApprovalLogList: [],
      gr_number: "",
      gr_number_marathi: "",
      gr_file_number: "",
      gr_file_name: "",
      gr_date: "",
      gr_link: "",
      gr_link_file: "",
      gr_signatory: "",
      Remark: "",
      rejectarray: [],
      check_employer_status1: true,
      check_employer_status: true,
      total_employer_status: 0,
      check_employer_status4: true,
      uploade_file_gr_link: "",
      guard_list: [],
      fileindex: "",
      approveOptions: [
        { value: null, text: "Pending" },
        { value: 1, text: "Approved" },
        { value: 0, text: "Rejected" },
      ],
    };
  },

  watch() {
    ApplicationStatus();
    {
      this.ApplicationStatus = this.ApplicationStatus;
    }
    checklist();
    {
      // this.checklist=this.checklist;
    }
  },
  methods: {
    Docket_no_new(item,date)
    {
      let to = new Date(date);
      let year = to.getFullYear();
      var letr =  item.match(/[a-zA-Z]+/g);
      const [first, ...rest] = item.split('MUMX');
      rest[0] = Number(rest[0]).toString();
      return letr[0]+"/"+year+"/"+rest[0];
    },
    change_date2(data, index) {
      this.employerdropdownlist[index]["InspectionDate"] =
        this.convertDateFormatYMD(data);
      //alert(this.employerdropdownlist[index]['InspectionDate']);
    },
    change_date(index) {
      this.employerdropdownlist[index]["InspectionDate"] = null;
    },
    change_index(index) {
      //alert(index);
      // this.fileindex=index;
    },
    status_check2() {
      //   alert(1234)
      //   alert(this.checklist);
      // return;
      if (this.checklist.length > 0) {
        if (this.checklist.length == 19) {
          if (this.RoleID == 5) {
            // alert(this.checklist.length)
            //    return;
            this.check_employer_status4 = true;
            for (var i = 0; i < this.checklist.length; i++) {
              if (i != 9 && i != 11 && i != 15) {
                if (this.checklist[i] == "") {
                  this.check_employer_status4 = false;

                  return;
                }
              }
            }
          } else {
            for (var i = 0; i < this.checklist.length; i++) {
              if (this.checklist[i] == "" || this.checklist[i] === "0") {
                this.check_employer_status4 = false;
              }
            }
          }
        }
      } else {
        this.check_employer_status4 = false;
      }
      // alert(this.check_employer_status4);
    },
    approveDoc9() {
      //alert(111111111);
      var temp = 0;
      for (var i = 0; i < this.employer_data.length; i++) {
        //alert(this.employer_data[i].EmployerApprovalStatus);
        if (this.employer_data[i].EmployerApprovalStatus == 2) {
          temp++;
          //alert(temp);
        }
      }
      if (temp > 0) {
        //alert(1233);

        this.form["ConsentLetter"][0].status = 1;
        this.approveDoc1(7, "ConsentLetter", "Consent Letter");

        this.form["GSTReturns"][0].status = 1;
        this.approveDoc1(16, "GSTReturns", "GST Returns of Last 3 years");
      } else {
        //alert(666);
        this.form["ConsentLetter"][0].status = 0;
        this.approveDoc1(7, "ConsentLetter", "Consent Letter");
        this.form["GSTReturns"][0].status = 0;
        this.approveDoc1(16, "GSTReturns", "GST Returns of Last 3 years");
      }
    },

    approveDoc1(key, type, name) {
      // this.checklist[key]="Yes";
      // alert(JSON.stringify(this.form[type][0]));
      //  this.checklist.splice(0, this.values.length)
      // return;
      //alert(this.form[type][0].status);
      if (this.form[type][0].status == 1) {
        // alert(1)
        this.checklist.splice(key, 1, "Yes");
        const index = this.rejectarray.indexOf(name);
        if (index > -1) {
          this.rejectarray.splice(index, 1);
        }

        //  this.checklist[key]="Yes";
      } else if (this.form[type][0].status === 0) {
        this.checklist.splice(key, 1, "No");
        this.rejectarray.push(name);
        // alert(2)
        // this.checklist[key]="No";
      } else {
        const index = this.rejectarray.indexOf(name);
        if (index > -1) {
          this.rejectarray.splice(index, 1);
        }
        this.checklist.splice(key, 1, "");
      }
      this.checklist = this.checklist;
      this.formGuard.Remarks = this.rejectarray.toString();
      // alert(JSON.stringify(this.checklist));
    },
    update_status() {
      console.log(this.form);
    },
    status_check() {
      //alert(999);
      this.check_employer_status = true;
      for (var i = 0; i < this.employer_data.length; i++) {
        // console.log("+++++++++++++++++++");
        console.log(this.employer_data[i]["EmployerApprovalStatus"]);

        if (
          this.employer_data[i]["EmployerApprovalStatus"] == "1" ||
          this.employer_data[i]["EmployerApprovalStatus"] == "4"
        ) {
          this.check_employer_status = false;

          return;
        }
        if (this.employer_data[i]["EmployerApprovalStatus"] == "2") {
          this.check_employer_status = true;

          return;
        }
      }
    },
    get_guard_list() {
      // console.log(this.personalDetails);
      // console.log(1222222222222222);
      this.formGuard["Limit"] = 500;
      let promise = apis.ReadGuardList(this.formGuard);

      promise
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            this.guard_list = response.body.Table;

            this.status_check1();
            // this.totalRows = response.body.Table1[0].Column1;
          } else {
          }
        })
        .catch((err) => {});
    },
    status_check1() {
      //alert(this.guard_list.length)
      this.check_employer_status1 = true;
      for (var i = 0; i < this.guard_list.length; i++) {
        //alert(1);
        // console.log("+++++++++++++++++++");
        //console.log(this.guard_list[i]['GuardApprovalStatus']);
        // alert(this.guard_list[i]['GuardApprovalStatus'])
        if (
          this.guard_list[i]["GuardApprovalStatus"] == "1" ||
          this.guard_list[i]["GuardApprovalStatus"] == 4
        ) {
          //alert(here);
          this.check_employer_status1 = false;
          return;
        }
      }
    },
    Docket_no(item) {
      //var temp=item.match(/.{1,3}/g);

      const [first, ...rest] = item.split("MUMX");
      rest[0] = Number(rest[0]).toString();
      return rest[0];
    },
    saveDocumentToFile() {
      var guard_list = JSON.parse(this.personalDetails.GuardList);
      console.log(guard_list);
      // return;
      var municipalities = [];
      var employer_address = "";
      for (var i = 0; i < guard_list.length; i++) {
        var address = "-“-";
        if (guard_list[i].EmployerAddress != employer_address) {
          employer_address = guard_list[i].EmployerAddress;
          address = employer_address;
        }
        municipalities.push({
          name: guard_list[i].EntityName,
          category: guard_list[i].Designation,
          agency_address: address,
        });
      }
      // let municipalities= [
      //     {name:'पुजा प्रशांत हरवंदे', category: 'महिला सुरक्षा रक्षक','agency_address':'मे. गोदरेज मेमोरियल हॉस्पीटल फिरोजशहा नगर, विक्रोळी (पूर्व) मुंबई-400 079'},
      //     {name:'सुप्रिया श्रीधर वैद्य', category: 'महिला सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'संतोष वसंत राणे', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'लक्ष्मण रामसागर पांडे', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'संतोष वसंत राणे', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'मोहम्मद  अशफाक अब्दुल  गफुर सय्यद', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'राजेश परशुराम घाग', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'पुजा प्रशांत हरवंदे', category: 'महिला सुरक्षा रक्षक','agency_address':'मे. गोदरेज मेमोरियल हॉस्पीटल फिरोजशहा नगर, विक्रोळी (पूर्व) मुंबई-400 079'},
      //     {name:'सुप्रिया श्रीधर वैद्य', category: 'महिला सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'संतोष वसंत राणे', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'लक्ष्मण रामसागर पांडे', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'संतोष वसंत राणे', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'मोहम्मद  अशफाक अब्दुल  गफुर सय्यद', category: 'सुरक्षा रक्षक','agency_address':'-“-'},
      //     {name:'राजेश परशुराम घाग', category: 'सुरक्षा रक्षक','agency_address':'-“-'}
      //   ];
      const table = new Table({
        style: "MyCustomTableStyle",
        width: {
          size: 9070,
          type: WidthType.DXA,
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "अ.क्र.",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "सुरक्षा रक्षकाचे नांव",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],

                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "वर्ग",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],

                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "मुख्य मालकाचे नांव व पत्ता",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],

                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "(१)",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],

                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "(२)",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],

                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "(३)",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],

                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "(४)",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],

                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
            ],
          }),
        ],
      });
      for (var i = 0; i < municipalities.length; i++) {
        let tableRow = new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: i + 1,
                      bold: false,
                      font: "Mangal",
                      allCaps: false,
                      size: "12pt",
                    }),
                  ],

                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: municipalities[i].name,
                      bold: false,
                      font: "Mangal",
                      allCaps: false,
                      size: "12pt",
                    }),
                  ],

                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: municipalities[i].category,
                      bold: false,
                      font: "Mangal",
                      allCaps: false,
                      size: "12pt",
                    }),
                  ],

                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: municipalities[i].agency_address,
                      bold: false,
                      font: "Mangal",
                      allCaps: false,
                      size: "12pt",
                    }),
                  ],

                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        });
        table.root.push(tableRow);
      }

      const doc = new Document({
        sections: [
          {
            headers: {
              default: new Header({
                children: [
                  // new Paragraph("महाराष्ट्र शासन राजपत्र आसाधारण भाग एक - का, 18 फरवरी, 2020/माघ शके 1941")
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "महाराष्ट्र शासन राजपत्र आसाधारण भाग एक - का, 18 फरवरी, 2020/माघ शके 1941",
                        bold: true,
                        font: "Mangal",
                        allCaps: false,
                        size: "12pt",
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
            },
            footers: {
              default: new Footer({
                children: [],
              }),
            },
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "अधिसूचना",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
                children: [
                  new TextRun({
                    text: "                                                उद्योग, ऊर्जा व कामगार विभाग,",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.left,
              }),
              new Paragraph({
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
                children: [
                  new TextRun({
                    text: "                                                मंत्रालय, मुंबई-400 032.",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "                                                दिनांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "महाराष्ट्र खाजगी  सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) अधिनियम, 1981",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "    क्रमांक : एसजीए - 2019/प्र.क्र. 243/काम-5 :- ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '     ज्याअर्थी ज्यांची नांवे यासोबत जोडलेल्या अनुसूची एक च्या स्तंभ (2) मध्ये नमूद केलेली आहेत अशा विविक्षीत सुरक्षा रक्षकांना, (यात यापुढे ज्यांचा उल्लेख "उक्त सुरक्षा रक्षक" असा करण्यात आला आहे), उक्त अनुसूची एक च्या स्तंभ (4) मध्ये नमूद केलेल्या 1 मुख्य  मालकाकडे   मुंबई  येथे कामावर ठेवलेले आहे.',
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: " अशा",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),

                  new TextRun({
                    text:
                      " मे. " +
                      this.form.AgencyName +
                      ". " +
                      this.form.AgencyAddress,
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: " मालक",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: " " + this.form.OwnerDetails[0].OwnerName + " ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),

                  new TextRun({
                    text: 'यांनी महाराष्ट्र खाजगी सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) अधिनियम, 1981 (1981 चा महाराष्ट्र 58) याच्या कलम 23 अन्वये, उक्त अधिनियमाच्या सर्व तरतूदी आणि महाराष्ट्र खाजगी सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) योजना, 2002 (यात यापुढे ज्याचा उल्लेख "उक्त योजना" असा करण्यात आला आहे) यांच्या अंमलबजावणीतून सूट मिळण्या अर्ज केला आहे;',
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "    आणि ज्याअर्थी, सल्लागार समितीशी विचारविनिमय केल्यानंतर आणि  त्याबाबत संबंधित सुरक्षा रक्षक मंडळाने शिफारस केल्यानुसार उक्त सुरक्षा रक्षकांना मिळत असणारे लाभ हे उक्त अधिनियमाद्वारे व त्या अधिनियमान्वये आणि उक्त योजनेद्वारे व त्याअन्वये तरतूद केलेल्या लाभांपेक्षा एकंदरीत पाहता कमी फायदेशीर नाहीत असे महाराष्ट्र शासनाचे मत झालेले आहे;",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "    त्याअर्थी, आता महाराष्ट्र खाजगी सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) अधिनियम, 1981, याच्या कलम 23 अन्वये प्रदान केलेल्या  अधिकारांचा वापर करुन, महाराष्ट्र शासन याद्वारे उक्त अधिनियमाच्या व उक्त योजनेच्या सर्व तरतूदींच्या अंमलबजावणीतून उक्त खाजगी सुरक्षा रक्षकांना, यासोबत जोडलेल्या अनुसूची दोन मध्ये विनिर्दिष्ट केलेल्या शर्तींच्या अधीन राहून, नियोक्ता अभिकरण एजन्सीने मुख्य मालकासोबत केलेल्या करारपत्राच्या दिनांकापासून कलम 23 अंतर्गत सदरहू अधिसूचना राजपत्रात प्रसिध्द झाल्याचे दिनांकापर्यंत तसेच ही अधिसूचना राजपत्रात  प्रसिध्द केल्याच्या दिनांकापासून  पुढील तीन वर्षांच्या कालावधीसाठी सूट देत आहे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
            ],
          },
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "अनुसूचि-1",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              table,
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "    टिप :-महाराष्ट्र शासन या सुरक्षा रक्षकांबाबत कोणत्याही प्रकारची हमी घेत  नाही.",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "    मुख्य मालक स्वत:च्या जबाबदारीवर सुरक्षा रक्षकांना कामे देऊ शकतात.",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          },
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "अनुसूचि-2",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "मालक एजन्सीने व मुख्य मालकांनी पाळावयाच्या शर्ती",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "1)	पोलीस तपासणी :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "खाजगी सुरक्षा एजन्सी (नियमन) कायदा, 2005 अंतर्गत सुरक्षा रक्षकांच्या तसेच एजन्सीच्या मालकांच्या पूर्वइतिहासाबाबत पोलीस पडताळणी  दाखला तसेच सदरहू परवान्यामधील अटी व शर्तींची पुर्तता करणे आवश्यक असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "2)	प्रशिक्षण :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "खाजगी सुरक्षा एजन्सी (नियमन) कायदा, 2005 अन्वये सुरक्षा रक्षकांना नियुक्त करण्यापुर्वी पुरेसे प्रशिक्षण देणे आवश्यक असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "3)	शैक्षणिक, शारिरीक आणि इतर पात्रता :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "खाजगी सुरक्षा एजन्सी ( नियमन) अधिनियम, 2005 नुसार सुरक्षा रक्षकांची शैक्षणिक व शारिरीक पात्रता पुढील प्रमाणे असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "   किमान शैक्षणिक पात्रता :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "इयत्ता 8वी उत्तीर्ण",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "   शारिरीक पात्रता :(अ) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "   1.    उंची - 162 सें.मी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "               ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "         2.    वजन - 50 किलो",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "               ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "         3.     छाती - न फुगवता - 79 सें.मी. फुगवून - 84 सें.मी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "               ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "         4.    नजर - दृष्टी चष्मा असल्यास नंबर जास्त नसावा.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "                 (ब) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  आदिवासी उमेदवारांना उंचीमध्ये 5 सें.मी. व छातीमध्ये 2 सें.मी. ची सवलत देण्यांत यावी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "4)	लाभ :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "सुरक्षा रक्षकांना पुढील लाभ मिळतील -",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "   अ) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "गणवेष प्रत्येक वर्षाला 2 जोड -",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "   ब) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "चामडी बूट प्रत्येक वर्षात  1 जोड",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "   क) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "पावसाळी व हिवाळी गणवेश - (2 वर्षातून एकदा) रेनकोट, ट्राऊझर, टोपी, वुलन कोट व पँट.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "5)	वेतन व इतर कायदेशीर सवलती  :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "सूट दिलेल्या सुरक्षा रक्षकाने राष्ट्रीयकृत बँकेमध्ये आपले खाते उघडावे व मालक एजन्सीने मुख्य मालकाकडे तैनात केलेल्या सुरक्षा रक्षकांच्या देय वेतनाच्या रक्कमेइतका रेखांकित धनादेश सुरक्षा रक्षकास द्यावा किंवा देय वेतनाची रक्कम सरळ सुरक्षा रक्षकांचे बॅक खात्यात प्रत्येक महिन्याच्या 7 तारखेच्या आत जमा करावी.  सुरक्षा रक्षकास दिलेल्या वेतनाबाबतचे सविस्तर तपशील नमुना “",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "क",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "” मधील  विवरणपत्रामध्ये भरुन सुरक्षा रक्षक मंडळास दर महिन्याच्या 10 तारखेपर्यंत पाठवावे. मालक एजन्सीने खाली दर्शविल्याप्रमाणे लाभ सुरक्षा रक्षकांना द्यावेत -",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "        सानुग्रह अनुदान		:	वेतनाच्या 10 टक्के",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        उपदान			:	वेतनाच्या 4 टक्के",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        भरपगारी रजा		:	वेतनाच्या 6 टक्के",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        भरपगारी सुट्टी		:	वेतनाच्या 1 टक्का",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "        सुरक्षा रक्षकांना लागू असलेल्या भविष्य निर्वाह निधी व कामगार राज्य विमा योजना यांच्या वजाती मालक एजन्सीने परस्पर संबंधित प्राधिकरणाकडे जमा कराव्यात आणि त्यांचे चलन माहितीसाठी मंडळास सादर करावे.  मालक एजन्सीने भरणा केलेल्या भविष्य निर्वाह निधी व कामगार राज्य विमा योजनेच्या वजातीबाबतच्या पावत्या/चलन सुरक्षा रक्षकांना नियमितपणे देऊन त्या संदर्भातील एकत्रित तपशील संबंधित सुरक्षा रक्षक मंडळास प्रत्येक 6 महिन्यांनी सादर करावा, असे न केल्यास मालक एजन्सीला जबाबदार धरुन दिलेली सुट रद्द करण्यांत येईल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "6)	अतिकालिक भत्ता :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "सुरक्षा रक्षकांना मिळणारा अतिकालिक भत्ता हा मंडळाने नोंदित सुरक्षा रक्षकांसाठी निश्चित केलेल्या वेतन दराच्या दुप्पट दरापेक्षा कमी नसावा,  याबाबत संबंधित मुख्य मालकाची अंतिम जबाबदारी राहील. सुरक्षा रक्षकांना देय वेतन व लाभ देणे मुख्य मालकांची जबाबदारी असून मुख्य मालकाने त्यांच्याकडे तैनात करण्यांत आलेल्या सुरक्षा रक्षकांना अधिनियम आणि योजनेतील तरतूदीनुसार वेतन व लाभ मिळत आहेत याची खात्री करुन घेणे बंधनकारक असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "7)	विवरणपत्र सादर करणे : ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "        अ)	त्रैमासिक विवरणपत्र : ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),

                  new TextRun({
                    text: "      मालक एजन्सीजने सुरक्षा रक्षकांच्या नियुक्तीबाबतचे त्रैमासिक विवरणपत्र प्रत्येक त्रैमासिकाच्या (जानेवारी, एप्रिल, जुलै व ऑक्टोबर  महिन्याच्या ) पहिल्या  आठवडयात  सोबत  जोडलेल्या  ",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: " नमुना अ ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: " संबंधित सुरक्षा रक्षक मंडळास सादर करावे. ",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        ब)   सहामाही विवरणपत्र :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "           1)   नियुक्त केलेल्या, नोकरी सोडून गेलेल्या आणि नव्याने भरती केलेल्या सुरक्षा रक्षकांबाबतचे विवरणपत्र दर 6 महिन्यांनी सोबत जोडलेल्या ",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "नमुना ब ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),

                  new TextRun({
                    text: "संबंधित सुरक्षा रक्षक मंडळ यांना एजन्सीने सादर करावे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "           2)   भविष्य निर्वाह निधी व राज्य कामगार विमा योजनेची वर्गणी एजन्सीने नियमित भरुन संबंधित सुरक्षा रक्षकांना त्यासंबंधी वेळोवेळी पावत्या द्याव्यात व दर सहा महिन्यात तसे केल्याबाबतचा अहवाल सुरक्षा रक्षक मंडळास द्यावा.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "           3)   यापूर्वीच्या भविष्य निर्वाह निधीच्या रकमा व राज्य कामगार विमा योजनेची वर्गणी भरल्याबाबतचा पुरावा सदर अधिसूचना निर्गमित झाल्यापासून तीन महिन्यांच्या आंत सादर करावा.  अन्यथा संबंधित सुरक्षा रक्षकांना देण्यांत आलेली सूट रद्द करण्यांत येईल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "           4)   सर्व संबंधित सुरक्षा रक्षक मंडळाचे अध्यक्ष यांनी सर्व विवरणपत्र व प्रपत्रे नियमितपणे व अचूक प्राप्त होत आहेत किंवा नाही याची खात्री करावी जर असे होत नसेल तर अटी नुसार  संबंधितांविरुध्द त्वरीत आवश्यक कारवाई करण्यात यावी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        क) 	वार्षिक विवरणपत्र : ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),

                  new TextRun({
                    text: "   प्रत्येक मालक एजन्सीने, सनदी  लेखापाल यांनी प्रमाणित केलेले   वार्षिक विवरणपत्र सोबत जोडलेल्या ",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "नमुना -ड",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: " मध्ये दरवर्षी 30 जून पर्यंत मंडळास सादर करावे, ज्यात एजन्सीने भरलेला आयकर, सुरक्षा रक्षकांचा जमा केलेला भविष्य निर्वाह निधी व कामगार राज्य विमा याबाबतच्या चलनाच्या प्रती व इतर तपशील असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "8)	एजन्सीची व सुट प्राप्त सुरक्षा रक्षकांची मंडळाकडे नोंदणी :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  अधिसूचनेच्या दिनांकापासून एक महिन्याच्या कालावधीत उक्त मंडळाकडे महाराष्ट्र खाजगी सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) योजना, 2002 च्या खंड 13 (2) व 14 (3)  मधील तरतूदीनुसार एजन्सीजने  स्वत:ची मालक म्हणून आणि त्यांच्याकडील सूट प्राप्त सुरक्षा रक्षकांची  विहीत नमुन्यातील अर्ज  व शुल्क भरुन मंडळात नोंदणी करुन घ्यावी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "9)	एजन्सीच्या मुख्य मालकांची मंडळाकडे नोंदणी :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  सुट प्राप्त सुरक्षा रक्षकांच्या एजन्सीमार्फत सुरक्षा रक्षक नियुक्त करणा-या मुख्य मालकाने अधिसूचनेच्या दिनांकापासून 15 दिवसांचे आत योजनेच्या खंड 13(1) (अ) अन्वये स्वत:ची मंडळात विहीत नमुन्यातील अर्ज व शुल्क भरुन नोंदणी करुन घ्यावी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "10)	नोंदणी शुल्क :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  एजन्सीने तसेच सूट प्राप्त सुरक्षा रक्षकाने मंडळाकडे नोंदणी करतेवेळी महाराष्ट्र खाजगी सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) योजना, 2002 च्या खंड 17 मधील तरतूदीनुसार मंडळाकडे विहित कालावधीत आवश्यक ते नोंदणी शुल्क भरले पाहिजे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "11)	नोंदणीकृत कार्यालय :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  एजन्सीचे नोंदणीकृत कार्यालय असावे आणि त्याबाबतची माहिती एजन्सीने शासन, कामगार आयुक्त व मंडळास द्यावी.  नोंदणीकृत कार्यालयाचा पत्ता बदलल्यास अथवा एजन्सीच्या नावात बदल झाल्यास 15 दिवसांचे आंत बदलाबाबतच्या आवश्यक त्या कागदोपत्री पुराव्यासह शासनास व मंडळास कळवावे, जेणेकरुन शासन सुधारीत अधिसूचना जारी करील.  सुधारीत अधिसूचना जारी  झाल्यानंतर मंडळ झालेल्या बदलांची नोंद घेईल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "12)	सुरक्षा रक्षकांची नियुक्ती :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  उक्त मंडळाकडे ज्या मुख्य मालकांची नोंदणी झाली  आहे आणि/  किंवा जे उक्त  मुख्य मालक मंडळाच्या सुरक्षा रक्षकांच्या सेवेचा लाभ घेत आहेत  अशा मुख्य  मालकांकडे  एजन्सी  त्यांचेकडील  सुरक्षा रक्षक नेमणार नाही.  अशाप्रकारे  सुरक्षा रक्षक नेमल्यास मालक एजन्सीला जबाबदार धरुन दिलेली सुट रद्द करण्यांत  येईल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "13)	ओळखपत्र व हजेरी कार्ड देणे :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  खाजगी सुरक्षा रक्षक एजन्सी त्यांचेकडील सुरक्षा रक्षकांना व अधिकाऱ्यांना नियुक्त केल्यापासून 30 दिवसांच्या आत ओळखपत्र व हजेरीकार्ड देईल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "14)	कायदेशीर देणी अदा करणे :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "  सुरक्षा रक्षकांना मिळणारा अतिकालिक भत्ता हा मंडळाने नोंदित सुरक्षा रक्षकांसाठी निश्चित केलेल्या वेतन दराच्या दुप्पट दरापेक्षा कमी नसावा,  याबाबत संबंधित मुख्य मालकाची अंतिम जबाबदारी राहील. सुरक्षा रक्षकांना देय वेतन व लाभ देणे मुख्य मालकांची जबाबदारी असून मुख्य मालकाने त्यांच्याकडे तैनात करण्यांत आलेल्या सुरक्षा रक्षकांना अधिनियम आणि योजनेतील तरतूदीनुसार वेतन व लाभ मिळत आहेत याची खात्री करुन घेणे बंधनकारक असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "15)	एकावेळी एकाच मुख्य मालकाकडे नोकरी :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "सुरक्षा रक्षक एकावेळी एकापेक्षा अधिक मुख्य मालकाकडे काम करणार नाही. याबाबत प्रत्येक सुरक्षा रक्षक एजन्सीने खात्री करुन घेतली पाहिजे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "16) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        एखाद्या सुरक्षा रक्षकास त्याच्या निवासस्थानापासून 50कि.मी.पेक्षा अधिक अंतरावर काम करण्यासाठी   पाठविल्यास मालक एजन्सीने त्याच्या एकूण वेतनाच्या 20% रक्कम त्याला भत्ता म्हणून द्यावी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "17) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        सुरक्षा रक्षकांच्या फायद्यांसंदर्भात शासनाने किंवा मंडळाने भविष्यकाळात घातलेल्या अटी व शर्तींचे पालन करणे एजन्सीला, तसेच मुख्य मालकाला बंधनकारक राहील.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "18) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        मालक एजन्सीने मुख्य मालकासोबत केलेल्या कराराच्या दिनांकापासून ते कलम 23 अंतर्गत सुट देण्याच्या अधिसूचनेपर्यंत व या सुरक्षा रक्षकांना सूट देण्यासंदर्भातील अधिसूचनेमध्ये विर्निदिष्ट केलेल्या कालावधी पर्यंत सुरक्षा रक्षकांच्या वेतनाच्या 3 टक्के एवढी लेव्ही दरमहा 10 तारखेपर्यंत मंडळास देय राहील. सदर लेव्ही अधिसूचना निर्गमित झाल्याच्या दिनांकापासून 1 महिन्याच्या आत मंडळाकडे जमा करणे अनिवार्य राहील.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        मंडळाने विनिर्दिष्ट केलेल्या कालमर्यादेत लेव्हीची रक्कम भरण्यात जे नियोक्ता अभिकरण कसुर करील ते नियोक्ता अभिकरण मंडळाने भरणा करण्यास निर्धारीत केलेल्या रक्कमेच्या 10% या दराने अधिभार दंडाच्या रुपाने मंडळाकडे भरील.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "19) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        मालक एजन्सीमार्फत सुरक्षा रक्षक नियुक्त करणा-या मुख्य मालकाने करार संपुष्टात आल्यानंतर वा इतर कोणत्याही कारणामुळे सुरक्षा रक्षकांची सेवा घेणे बंद केले असल्यास सेवा खंडीत केल्याच्या दिनांकापासून 7 दिवसांच्या आत अशा मुख्य मालकाची व तेथून कमी केलेल्या सुरक्षा रक्षकांची नांवे व तपशील मालक एजन्सी मंडळास सादर करील.  अशा मुख्य मालकाची अधिसूनेनुसार घेतलेली मंडळातील नोंदणी रद्द होईल.  तसेच मालक एजन्सीकडून नोकरी सोडून गेलेल्या सुरक्षा रक्षकांची नावे व तपशील मालक एजन्सी मंडळास व नजीकच्या पोलीस ठाण्यास 7 दिवसाच्या आंत सादर करील.  अशाप्रकारे नोकरी सोडून गेलेल्या सुरक्षा रक्षकांची नोंदणी मंडळ रद्द करील.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "20) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        मुख्य मालकाकडून सुरक्षा रक्षकांच्या कामाच्या मोबदल्यापोटी एजन्सीकडे जमा होणाऱ्या रक्कमेपैकी, मंडळाने सुरक्षा रक्षकांच्या वेतनापोटी निश्चित केलेली रक्कम तसेच सर्व वैधानिक रकमा जसे भविष्य निर्वाह निधी, कामगार राज्य विमा योजना, बोनस प्रदान, रजा वेतन, राष्ट्रीय सुट्टयांचे वेतन यासाठी विनियमित केले जाईल निदान इतकी रक्कम किंवा मुख्य मालकाने एजन्सीला अदा केलेल्या रकमेच्या 56 टक्के इतकी रक्कम किंवा यापैकी जी अधिक असेल ती सुरक्षा रक्षक एजन्सीनी सुरक्षा रक्षकांना अदा करणे आवश्यक आहे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "21) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        सुरक्षा रक्षकांना साप्ताहीक सुट्टी उपभोगण्याकरीता कार्यमुक्त करणाऱ्या सुरक्षा रक्षकांचे वेतन मुख्य मालक एजन्सीला अदा करील.  हे वेतन यथा प्रमाण पध्दतीवर आधारीत असेल व ही रक्कम मुळ वेतनाच्या 10% अथवा जी अधिक असेल इतकी असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "22) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        सुरक्षा रक्षक मंडळामध्ये जमा करावयाची लेव्ही, सुरक्षा रक्षकांच्या प्रशिक्षणासाठीचा खर्च, देखरेखीवरील खर्च, तसेच एजन्सीचा प्रशासकीय खर्च व नफा या सर्व गोष्टींचा खर्च हा मुख्य मालकाने एजन्सीकडे जमा केलेल्या एकूण रक्कमेच्या 30% रक्कमेपेक्षा जास्त नसावा.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "23) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        उपरोक्त अनिवार्य लादलेल्या खर्चावर नियमानुसार सेवाकर / वस्तु व सेवा कर GST आकारला जाईल व सेवाकर / वस्तु व सेवा कर GST त्या त्या वेळी अंमलात असलेल्या  दरानुसार असेल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "24) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "          या व्यतिरिक्त सुरक्षा रक्षकांना  गणवेश दिला जाईल व त्यासाठी 4% रक्कम दरवर्षी राखीव ठेवण्यात येईल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "25) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        सुरक्षा रक्षकांना त्यांचे वेतन पुढील महिन्याच्या सात तारखेपर्यंत देण्यांत यावे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "26) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        अधिसूचनेतील आणि सोबत जोडलेल्या अनुसूची 2 मधील अटी व शर्तींचे पालन मालक एजन्सी किंवा मुख्य मालकाकडून स्वेच्छेने होत आहे किंवा नाही, याबाबतची खात्री सुरक्षा रक्षक मंडळाने करणे आवश्यक आहे व पालन करण्यास असमर्थ ठरल्यास संबंधित मालक एजन्सी किंवा मुख्य मालकाच्या विरुध्द नियमानुसार योग्य ती कार्यवाही करण्याची जबाबदारी मंडळाची राहील.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "27) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        	प्रस्तुत अधिसूचना ही मालक एजन्सी, मुख्य मालक व संबंधित सुरक्षा रक्षक यांनी सादर केलेली माहिती, कागदपत्रे तसेच संबंधित मंडळाचे अध्यक्ष यांनी केलेल्या शिफारशीवरुन निर्गमित करण्यात येत आहे आणि जर सदरची माहिती, कागदपत्रे खोटी किंवा बनावट असल्याचे दिसून आल्यास संबंधीत मालक एजन्सी , मुख्य मालक, सुरक्षा रक्षक आणि सुरक्षा रक्षक मंडळ हे या अनुषंगाने निर्माण होणा-या कायदेशीर परिणामांस जबाबदार राहील.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "28) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "       	प्रस्तुत अधिसूचना ही संबंधित पोलीस अधिकारी यांनी मालक एजन्सीला खाजगी सुरक्षा एजन्सी 2005 व त्याअंतर्गत नियमातील तरतूदीनुसार देण्यात आलेला परवाना ( पसारा लायसन्स) मधील अटी व शर्तींचे तंतोतंत पालन होण्याच्या अधिन राहून निर्गमित करण्यात येत आहे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "29) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                  new TextRun({
                    text: "        वरीलपैकी कोणत्याही अटी व शर्तींचे मालक एजन्सीने उल्लंघन केल्यास त्यांना सदरहू अधिसूचनेद्वारे देण्यांत आलेली सूट रद्द करण्यांत येईल किंवा काढून टाकण्यात येईल. तसेच त्यानुषंगाने नियमानुसार कारवाई करण्यात येईल.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
            ],
          },
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "नमुना  ‘अ’",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "सुरक्षा रक्षक एजन्सीने सादर करावयाचे त्रैमासिक विवरणपत्र",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "महिन्यांचे त्रैमासिक विवरणपत्र :                                  दिनांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "जानेवारी-मार्च,",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "एप्रिल-जून,",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "जुलै-सप्टेंबर,",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "ऑक्टोबर-डिसेंबर",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),

              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "एजन्सीचे नांव व पत्ता :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "अधिसूचना क्रमांक व दिनांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "एजन्सीचा मंडळातील नोंदणी क्रमांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Table({
                style: "MyCustomTableStyle",
                width: {
                  size: 9070,
                  type: WidthType.DXA,
                },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "अ.क्र.",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "मुख्य मालकाचे नांव व पत्ता",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "सुरक्षा रक्षकांच्या नियुक्तीचे ठिकाण",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "सुरक्षा रक्षकांचे नांव व वर्ग",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(१)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(२)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(३)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(४)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),

                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "प्राधिकृत स्वाक्षरीकर्ता ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "(नांव व हुद्या) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
            ],
          },
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "नमुना  ‘ ब ’",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "सुरक्षा रक्षक एजन्सीने सादर करावयाचे सहामाही विवरणपत्र",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "विवरणपत्राचा कालावधी :	जानेवारी ते जून/जुलै ते डिसेंबर               दिनांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "एजन्सीचे नांव व पत्ता :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "अधिसूचना क्रमांक व दिनांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "एजन्सीचा मंडळातील नोंदणी क्रमांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Table({
                style: "MyCustomTableStyle",
                width: {
                  size: 9070,
                  type: WidthType.DXA,
                },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "अ.क्र.",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "मुख्य मालकाचे नांव व पत्ता",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "नियुक्त केलेल्या सुरक्षा रक्षकांची वर्गनिहाय एकूण संख्या",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "सुरक्षा रक्षक एजन्सी सोडून गेलेल्या सुरक्षा रक्षकांची वर्गनिहाय संख्या",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "नव्याने भरती झालेल्या सुरक्षा रक्षकांची वर्गनिहाय संख्या",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(१)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(२)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(३)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(४)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(5)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),

                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "प्राधिकृत स्वाक्षरीकर्ता ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "(नांव व हुद्या) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
            ],
          },
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "नमुना  ‘ क ’",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "एजन्सीने वेतन प्रदानाबाबत सुरक्षा रक्षक मंडळास सादर करावयाचे विवरणपत्र ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "वेतन प्रदानाचा महिना : ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "मुख्य मालकाचे नांव व पत्ता : ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "बँकेचे नांव (शाखा व पत्ता) :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Table({
                style: "MyCustomTableStyle",
                width: {
                  size: 9070,
                  type: WidthType.DXA,
                },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "अ.क्र.",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "मुख्य मालकाचे नांव व पत्ता",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "नियुक्त केलेल्या सुरक्षा रक्षकांची वर्गनिहाय एकूण संख्या",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "सुरक्षा रक्षक एजन्सी सोडून गेलेल्या सुरक्षा रक्षकांची वर्गनिहाय संख्या",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "नव्याने भरती झालेल्या सुरक्षा रक्षकांची वर्गनिहाय संख्या",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(१)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(२)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(३)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(४)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(5)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),

                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "प्राधिकृत स्वाक्षरीकर्ता ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "(नांव व हुद्या) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
            ],
          },
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "नमुना  ‘ ड ’",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "सुरक्षा रक्षक एजन्सीने  सादर करावयाचे वार्षिक विवरणपत्र",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "वार्षिक विवरणपत्राचे आर्थिक वर्ष :-                           दिनांक ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "एजन्सीचे नांव व पत्ता :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "अधिसूचना क्रमांक व दिनांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "एजन्सीचा मंडळातील नोंदणी क्रमांक :",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),

              new Table({
                style: "MyCustomTableStyle",
                width: {
                  size: 9070,
                  type: WidthType.DXA,
                },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "अ.क्र.",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "महिने (एप्रिल ते मार्च)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "नियुक्त केलेल्या सुरक्षा रक्षकांची संख्या",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "सुरक्षा रक्षकांना अदा केलेले एकूण वेतन",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "भविष्य निर्वाह निधी ज्यावर कपात केली आहे असे वेतन",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "मंडळाकडे जमा केलेली 3% लेव्ही रक्कम",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(१)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(२)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(३)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(४)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(5)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "(6)",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),

                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "  ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "   ",
                                bold: true,
                                font: "Mangal",
                                allCaps: false,
                                size: "12pt",
                              }),
                            ],

                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "प्राधिकृत स्वाक्षरीकर्ता ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "(नांव व हुद्या) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
            ],
          },
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
                children: [
                  new TextRun({
                    text: "                                                ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.left,
              }),
              new Paragraph({
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
                children: [
                  new TextRun({
                    text: "                                                ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "                                                ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        महाराष्ट्र शासनाच्या असाधारण राजपत्र भाग-1-ल, दि.                          मध्ये प्रकाशित करण्यासाठी व्यवस्थापक, शासकीय मध्यवर्ती मुद्रणालय, मुंबई यांच्याकडे रवाना, मुद्रिताचा इंग्रजी अनुवाद सोबत जोडला असून, तो यासह प्रकाशित करावा.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [new TextRun({ text: "  " })],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "        मुद्रिताच्या प्रत्येकी 25 प्रती या विभागास आणि कामगार आयुक्त, मुंबई यांना परस्पर पाठविण्यांत याव्यात.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        सदर अधिसूचनेचे प्रकाशन शासनाच्या असाधारण राजपत्रात प्रसिध्द करण्यास प्रधान सचिव (कामगार), उद्योग, ऊर्जा व कामगार विभाग यांनी मान्यता दिली आहे.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "प्राधिकृत स्वाक्षरीकर्ता ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [new TextRun({ text: "    " })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "(नांव व हुद्या) ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
            ],
          },

          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
                children: [
                  new TextRun({
                    text: "                                                ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.left,
              }),
              new Paragraph({
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
                children: [
                  new TextRun({
                    text: "                                                ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "                                                ",
                    bold: true,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "       1)  कामगार आयुक्त, कामगार आयुक्त यांचे कार्यालय, मुंबई यांना उचीत कार्यवाहीस्तव.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [new TextRun({ text: "  " })],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "        2)  पोलीस आयुक्त,  मुंबई   प्रस्तुत अधिसूचना आपल्या प्राधिकारात मालक एजन्सीला देण्यात आलेल्या खाजगी सुरक्षा एजन्सी (नियमन)अधिनियम, 2005 व त्या अंतर्गत नियमातील तरतुदीनुसार देण्यात आलेल्या परवान्यामधील अटी व शर्तींचे काटेकोर पालन होण्याच्या अधिन राहून निर्गमित करण्यात येत आहे. करीता विनंती की, सदरहू परवाना वितरीत करताना एजन्सीला घालून दिलेल्या सर्व अटी व शर्तींचे पालन होत असल्याबाबत आपल्या यंत्रणेमार्फत खात्री करण्यात यावी.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        3)  ॲडीशनल सेंट्रल प्रोव्हीडंट फंड कमिशनर, मुंबई-2 (ठाणे), वर्धन कमर्शियल कॉम्प्लेक्स, 6 वा मजला, एम.आय.डी.सी.रोड नं.16, वाघळे इस्टेट ( महाराष्ट्र), ठाणे-400604.",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        4)  डायरेक्टर जनरल इएसआयसी,  पंचदीप भवन, 108, एन.एम.जोशी मार्ग, लोअर परेल, मुंबई- 400020",
                    bold: false,
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
                alignment: AlignmentType.JUSTIFIED,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        5)   विक्रीकर आयुक्त, महाराष्ट्र राज्य, मुंबई यांचे कार्यालय, 8 वा मजला, नवीन इमारत, वस्तू व सेवा कर भवन, माझगांव, मुंबई 400010",
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "        6)  अध्यक्ष,  सुरक्षा  रक्षक मंडळ बृहन्मुंबई  आणि ठाणे  यांना सूचित करण्यात येते की त्यांनी सदरहू अधिसूचनेतील सर्व अटी व शर्तींचे काटेकोरपणे पालन होते किंवा कसे याची खात्री करावी आणि अ,ब,क व ड विवरणपत्र नियमितपणे प्राप्त होतील याची खात्री करावी.",
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "        7)  मे. बक्षी सेक्युरिटी अँड पर्सोनेल सर्हिसेस प्रा.लि. 234/235,न्यु  सोनल लिंग औद्योगिक वसाहत, इमारत क्रमांक 2,लिंक रोड, कांचनपाडा  मालाड (पश्चिम), मुंबई 400 064",
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "        8)  निवड नस्ती (कामगार-5)",
                    font: "Mangal",
                    allCaps: false,
                    size: "12pt",
                  }),
                ],
              }),
            ],
          },
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        console.log(blob);
        saveAs(blob, "example.docx");
        console.log("Document created successfully");
      });
    },

    async save_gr_details() {
      // alert(this.gr_link_file[0]);
      if (this.gr_link_file[0]) {
        await this.save_gr_link_file();
      } else {
        this.grfilepath = this.personalDetails.GrFile;
      }
      var data = {
        ExemptionApplicationID: this.formEmployer.ApplicationID, //send ID of the exemption application
        gr_number: this.gr_number,
        gr_number_marathi: this.gr_number_marathi,
        gr_file_number: this.gr_file_number,
        gr_file_name: this.gr_file_name,
        gr_link: this.gr_link,
        gr_date: this.convertDateFormatYMD(this.gr_date),
        gr_signatory: this.gr_signatory,
        grfilepath: this.grfilepath,
      };
      var promise = apis.save_gr_details(data);
      promise.then((response) => {
        if (response.status == 200) {
          this.$dialog.alert("Gr details saved successfully");
          this.get_data();
        } else {
          this.$dialog.alert("Error to save data");
        }
      });
    },
    save_employer_status(data, index) {
      //alert(data.EmployerApprovalStatus);
      if (data.EmployerApprovalStatus == "") {
        this.$dialog.alert("Please select status of application");
        return false;
      }
      if (data.EmployerApprovalStatus == 4) {
        if (data.Remark == "" || data.Remark == null) {
          this.$dialog.alert("Please enter remark");
          return false;
        }
      }

      var userinfo = this.$store.getters.loggedUser;
      var userid = userinfo.UserId;

      var data = {
        ApplicationStatus: data.EmployerApprovalStatus,
        Remarks: data.Remark,
        ApplicationID: data.ExemptionEmployerID,
        ExemptionEmployerID: data.ExemptionEmployerID,
        RejectReason: 0,
        " UserID": userid,
      };
      let promise = apis.UpdateEmployerExemptionStatus(data);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.$dialog.alert("Status has been Updated Succesfully..");
            this.status_check();
            //this.approveDoc9();
          } else {
            this.$dialog.alert(response.body);
          }
        })
        .catch((err) => {
          this.$dialog.alert(err.message);
        });
    },
    change_file() {
      this.change_file_check = "";
    },
    calcHeight(value) {
      let numberOfLineBreaks = (value.match(/\n/g) || []).length;
      // min-height + lines x line-height + padding + border
      let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
      return newHeight;
    },
    change_font(e, index) {
      this.word_suggestions_array = [];
      this.word_suggestions_array[index] = true;

      var KeyID = e.keyCode;
      var sentence = this.employerdropdownlist[index]["Observations"]
        .trim()
        .split(" ");
      var word = sentence[sentence.length - 1];
      var promiseU = apis.get_language(word);
      promiseU.then((response) => {
        if (response.status == 200) {
          this.word_suggestions = response.data[1][0][1];
          if (KeyID == 32) {
            this.append_text(this.word_suggestions[0], index);
          }
        }
      });
    },
    change_font_gr_number(e) {
      var KeyID = e.keyCode;
      var sentence = this.gr_number_marathi.trim().split(" ");
      var word = sentence[sentence.length - 1];
      var promiseU = apis.get_language(word);
      promiseU.then((response) => {
        if (response.status == 200) {
          this.word_suggestions1 = response.data[1][0][1];
          if (KeyID == 32) {
            this.append_text1(this.word_suggestions1[0]);
          }
        }
      });
    },
    convert_font(word, index) {
      // var KeyID = e.keyCode;
      var sentence = this.employerdropdownlist[index]["Observations"]
        .trim()
        .split(" ");
      var word = sentence[sentence.length - 1];
      var promiseU = apis.get_language(word);
      promiseU.then((response) => {
        if (response.status == 200) {
          var word_suggestions = response.data[1][0][1];
          return word_suggestions[0];
        }
      });
    },
    append_text(word, index) {
      var sentence = this.employerdropdownlist[index]["Observations"]
        .trim()
        .split(" ");
      // var word1=sentence[sentence.length-1];
      sentence[sentence.length - 1] = word + " ";
      this.employerdropdownlist[index]["Observations"] = sentence.join(" ");
      this.word_suggestions = [];
      jQuery(".observation_remark" + index)
        .first()
        .focus();
    },
    append_text1(word) {
      var sentence = this.gr_number_marathi.trim().split(" ");
      // var word1=sentence[sentence.length-1];
      sentence[sentence.length - 1] = word + " ";
      this.gr_number_marathi = sentence.join(" ");
      this.word_suggestions1 = [];
      jQuery(".observation_remark").first().focus();
    },
    change_size(index) {
      let textarea = document.querySelector(".resize-ta");
      var height = this.calcHeight(textarea.value);
      // alert(height)
      if (height > 74) {
        textarea.style.height = height + "px";
      }
      // alert(this.calcHeight(textarea.value));
    },
    cancel() {
      this.$refs["mdlInsReport"].hide();
    },
    async save_inspection(option, index1) {
      //alert(this.employerdropdownlist[index1]['Observations']);
      // console.log("--------------------------------------------------");
      // console.log(option);
      // exit;
      if (
        this.employerdropdownlist[index1]["FilePath"] == null ||
        this.employerdropdownlist[index1]["FilePath"] == undefined ||
        this.employerdropdownlist[index1]["FilePath"] == ""
      ) {
        this.$dialog.alert("Please select File");
        return false;
      }
      if (
        this.employerdropdownlist[index1]["InspectionDate"] == null ||
        this.employerdropdownlist[index1]["InspectionDate"] == undefined ||
        this.employerdropdownlist[index1]["InspectionDate"] == ""
      ) {
        this.$dialog.alert("Please select Date");
        return false;
      }
      // this.$dialog.alert(this.employerdropdownlist[index1]['GuardsObserved']);
      // this.$dialog.alert(this.employerdropdownlist[index1]['GuardsExcluded']);
      // this.$dialog.alert(this.employerdropdownlist[index1]['Observations']);
      if (
        this.employerdropdownlist[index1]["GuardsObserved"] == null ||
        this.employerdropdownlist[index1]["GuardsObserved"] == undefined ||
        this.employerdropdownlist[index1]["GuardsObserved"] == ""
      ) {
        this.$dialog.alert("Please Enter Total Guards Working");

        return false;
      }
      if (
        this.employerdropdownlist[index1]["GuardsExcluded"] == null ||
        this.employerdropdownlist[index1]["GuardsExcluded"] == undefined ||
        this.employerdropdownlist[index1]["GuardsExcluded"] == ""
      ) {
        this.$dialog.alert(
          "Please Enter Guards Not Included in Exemption Application"
        );
        return false;
      }
      if (
        this.employerdropdownlist[index1]["Observations"] == null ||
        this.employerdropdownlist[index1]["Observations"] == undefined ||
        this.employerdropdownlist[index1]["Observations"] == ""
      ) {
        this.$dialog.alert("Please Enter Observations");
        return false;
      }

      var count = 19;
      if (this.RoleID == 5) {
        count = 16;
      }

      // alert(this.filepath);
      // return;

      // console.log(this.$store.getters.loggedUser.UserId);
      var data = {
        ExemptionEmployerID:
          this.employerdropdownlist[index1]["ExemptionEmployerID"],
        ExemptionApplicationID: this.formEmployer.ApplicationID, //for new record it is all zero
        FilePath: this.employerdropdownlist[index1]["FilePath"], //send ID of the exemption application
        InspectionDate: this.employerdropdownlist[index1]["InspectionDate"],
        GuardsExcluded: this.employerdropdownlist[index1]["GuardsExcluded"],
        TotalObservedGuards:
          this.employerdropdownlist[index1]["GuardsObserved"],
        Observations: this.employerdropdownlist[index1]["Observations"],
      };
      console.log(data);

      var promiseU = apis.SaveExemtionInspectorReport(data);
      promiseU
        .then((response) => {
          this.$dialog.alert("Inspection Report Has Been Save Successfully");
          // this.employerdropdownlist=response.data;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
    select_emp() {
      //alert(11);

      var data = {
        ExemptionApplicationID: this.formEmployer.ApplicationID,
      };
      console.log(data);

      var promiseU = apis.GetExemtionInspectorReport(data);
      promiseU
        .then((response) => {
          this.employerdropdownlist = response.data;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
    async save_inspection_report() {
      //alert(1);
      if (this.inspection_report == "") {
        this.$dialog.alert("Please select file");
        return;
      } else {
        var userinfo = this.$store.getters.loggedUser;
        var userid = userinfo.UserId;
        var inspection_report = this.inspection_report;
        var filetype = inspection_report[0].type;
        //console.log("Options", options);
        //var filepath = "CourtOrder_." + extn;
        var filepath = inspection_report[0].name;
        // alert(filepath);
        // return;
        let promisefoto = lambda.getSignedURL(
          this.$route.params.ApplicationID,
          filepath,
          filetype
        );

        await promisefoto
          .then((response) => {
            // console.log(response);
            // alert(response.url)
            let signedUrl = response;
            var upldoptions = {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": inspection_report[0].type,
              },
            };
            this.filepath = filepath;

            return Vue.http.put(signedUrl, inspection_report[0], upldoptions);
          })
          .then((res) => {
            if (res.status == 200) {
              options.callback(
                "success",
                inspection_report.map((file) => {
                  return {
                    file: file,
                    //content: data[file.name]
                    content:
                      "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
                      this.formGuard.ID +
                      "/" +
                      filepath,
                  };
                })
              );
            } else {
              this.variant = "warning";
              this.alertText = "Error in uploading Image";
              this.showDismissibleAlert = true;
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.alertText = err.message;
            this.showDismissibleAlert = true;
            this.variant = "danger";

            this.isLoading = false;
          });
        this.$dialog.alert("File has been uploaded successfully");
      }
      return;
    },
    async save_gr_link_file() {
      if (this.gr_link_file == "") {
        this.$dialog.alert("Please select file");
        return;
      } else {
        var userinfo = this.$store.getters.loggedUser;
        var userid = userinfo.UserId;
        var inspection_report = this.gr_link_file;
        var filetype = inspection_report[0].type;
        //console.log("Options", options);
        //var filepath = "CourtOrder_." + extn;
        var filepath = inspection_report[0].name;
        // alert(filepath);
        // return;
        let promisefoto = lambda.getSignedURL(
          this.$route.params.ApplicationID,
          filepath,
          filetype
        );

        await promisefoto
          .then((response) => {
            // console.log(response);
            // alert(response.url)
            let signedUrl = response;
            var upldoptions = {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": inspection_report[0].type,
              },
            };
            this.grfilepath = filepath;

            return Vue.http.put(signedUrl, inspection_report[0], upldoptions);
          })
          .then((res) => {
            if (res.status == 200) {
              options.callback(
                "success",
                inspection_report.map((file) => {
                  return {
                    file: file,
                    //content: data[file.name]
                    content:
                      "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
                      this.formGuard.ID +
                      "/" +
                      filepath,
                  };
                })
              );

              // this.$dialog.alert("Inspection Report has been uploaded successfully")
            } else {
              this.variant = "warning";
              this.alertText = "Error in uploading Image";
              this.showDismissibleAlert = true;
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.alertText = err.message;
            this.showDismissibleAlert = true;
            this.variant = "danger";

            this.isLoading = false;
          });
      }
      return;
    },

    approveDoc(evt, docType) {
      // alert(44);
      if (evt.target.value == 0) {
        if (this.formGuard.Remarks == "") {
          this.formGuard.Remarks = "Error in Documents: " + docType;
          // this.ApplicationStatus=4;
        } else {
          this.formGuard.Remarks += ", " + docType;
          //this.ApplicationStatus=4;   //document rejected
        }
      } else {
        if (this.formGuard.Remarks == "Error in Documents: " + docType) {
          this.this.formGuard.Remarks = "";
          //this.ApplicationStatus=2;
        } else {
          this.formGuard.Remarks = this.formGuard.Remarks.replace(
            ", " + docType,
            ""
          );
          this.formGuard.Remarks = this.formGuard.Remarks.replace(
            ": " + docType + ",",
            ":"
          );
        }
      }

      // if(evt.target.value == 1)
      // {
      //   this.ApplicationStatus=2;
      // }
      // else
      // {
      //   this.ApplicationStatus=1;
      // }

      //this.ApplicationStatus=evt.target.value;

      // console.log(this.Remark);
    },
    async inspection_report_file(evt, index = "") {
      //alert(index);

      const files = evt.target.files;
      this.inspection_report = files;
      var userinfo = this.$store.getters.loggedUser;
      var userid = userinfo.UserId;
      var inspection_report = this.inspection_report;
      var filetype = inspection_report[0].type;
      //console.log("Options", options);
      //var filepath = "CourtOrder_." + extn;
      var filepath = inspection_report[0].name;
      // alert(filepath);
      // return;
      let promisefoto = lambda.getSignedURL(
        this.$route.params.ApplicationID,
        filepath,
        filetype
      );

      await promisefoto
        .then((response) => {
          // console.log(response);
          // alert(response.url)
          let signedUrl = response;
          var upldoptions = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": inspection_report[0].type,
            },
          };

          this.employerdropdownlist[index].FilePath =
            "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
            this.formGuard.ID +
            "/" +
            filepath;
          this.$dialog.alert(
            "Inspection Report has been uploaded successfully"
          );

          return Vue.http.put(signedUrl, inspection_report[0], upldoptions);
        })
        .then((res) => {
          if (res.status == 200) {
            options.callback(
              "success",
              inspection_report.map((file) => {
                return {
                  file: file,
                  //content: data[file.name]
                  content:
                    "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
                    this.formGuard.ID +
                    "/" +
                    filepath,
                };
              })
            );
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";

          this.isLoading = false;
        });
    },

    gr_link_file_change(evt) {
      const files = evt.target.files;
      this.gr_link_file = files;
      // console.log(this.gr_link_file)
    },
    add_inspection_report() {
      this.$refs["mdlInsReport"].show();
    },
    check_all() {
      if (this.checkalllist == "Yes") {
        this.checklist = [
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
          "Yes",
        ];
      }
      if (this.checkalllist == "No") {
        this.checklist = [
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
          "No",
        ];
      }
    },
    view_employer(data) {
      this.formEmployer.ID = data.ExemptionEmployerID;
      this.$refs["mdlEmployer"].show();
    },
    view_all_guard() {
      this.formGuard.ID = this.$route.params.ApplicationID;
      //this.$store.dispatch("setSearchForm", this.formGuard);
      //this.$router.push("GuardList");
      this.$router.push({
        name: "GuardList",
        params: {
          ApplicationID: this.formGuard.ID,
          ApplicationStatus: this.ApplicationStatus,
        },
      });
    },
    view_guard(item) {
      console.log(item);
      this.formGuard.ID = this.$route.params.ApplicationID;
      //this.$store.dispatch("setSearchForm", this.formGuard);
      //this.$router.push("GuardList");
      this.$router.push({
        name: "GuardList",
        params: {
          ApplicationID: this.formGuard.ID,
          ApplicationStatus: this.ApplicationStatus,
          ExemptionEmployerID: item.ExemptionEmployerID,
        },
      });
    },
    async unlock() {
      this.isLoadingUnlock = true;
      try {
        let dialog = await this.$dialog.prompt(
          {
            title: "Unlock Application",
            body: "Reason for Unlocking the application:",
          },
          {
            promptHelp: 'Type in the box below and click "[+:okText]"',
            okText: "Unlock Profile",
            cancelText: "Cancel",
            loader: false,
          }
        );

        if (dialog.data) {
          this.uForm.Remarks = dialog.data;
          let promise = apis.UnlockProfile(uForm);

          promise
            .then((response) => {
              if (response.status == 200) {
                this.$dialog.alert(
                  "Application Unlocked for Changes till tomorrow 9:00 PM"
                );
              } else {
                this.$dialog.alert(response.body);
              }
            })
            .catch((err) => {
              this.alertTextUnlock = err.message;
              this.showDismissibleAlertUnlock = true;
              this.variantUnlock = "danger";
            });
        } else {
          this.$dialog.alert("Please mention Reason.");
          return;
        }
      } catch (err) {
        this.$dialog.alert("Error");
      }
    },
    goback() {
      //alert(777);
      //this.$router.back();
      this.$router.push({
        name: "Exemption",
        params: {
          userDistrictID: this.$store.getters.getUserDistrict,
          userApprovalStatus: this.$store.getters.getAppStatus,
          userLoanType: 1,
        },
      });
    },
    date_format(value) {
      let date = new Date(value);
      let dd = date.getDate();
      let mm = date.getMonth() + 1;
      let yyyy = date.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      let new_date = dd + "-" + mm + "-" + yyyy;

      return new_date;
    },
    convertDateFormat(inDate) {
      if (inDate) {
        return Formatter.convertDateFormat(inDate);
      }
    },
    convertDateFormatYMD(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD(inDate);
      }
    },

    print() {
      window.print();
    },

    getImgUrl(pic) {
      if (pic) {
        return pic;
      }
    },
    status_check3() {
      this.check_employer_status4 = true;
      if (this.RoleID == 5) {
        for (var i = 0; i < this.checklist.length; i++) {
          if (i != 9 && i != 11 && i != 15) {
            if (this.checklist[i] == "No") {
              this.check_employer_status4 = false;
              return;
            }
          }
        }
      } else {
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i] == "No") {
            this.check_employer_status4 = false;
            return;
          }
        }
      }
    },
    async save_check_list() {
      var count = 19;
      if (this.RoleID == 5) {
        count = 16;
      }
      if (this.checklist.length > 0) {
        if (this.checklist.length == 19) {
          if (this.RoleID == 5) {
            for (var i = 0; i < this.checklist.length; i++) {
              if (i != 9 && i != 11 && i != 15) {
                if (!this.checklist[i]) {
                  this.$dialog.alert("Please select all checklist");
                  return;
                }
              }
            }
          } else {
            for (var i = 0; i < this.checklist.length; i++) {
              if (!this.checklist[i]) {
                this.$dialog.alert("Please select all checklist");
                return;
              }
            }
          }

          if (this.inspection_report[0]) {
            await this.save_inspection_report();
          } else {
            this.filepath = this.file;
            this.ExemptionApplicationID = this.ExemptionApplicationID;
          }
          // alert(this.filepath);
          // return;

          // console.log(this.$store.getters.loggedUser.UserId);
          var data = {
            ExemptionCheckListID: this.ExemptionCheckListID, //for new record it is all zero
            ExemptionApplicationID: this.formEmployer.ApplicationID, //send ID of the exemption application
            Annexure1: this.checklist[0],
            ShopActMumbai: this.checklist[1],
            GSTRegistration: this.checklist[2],
            EPFRegistration: this.checklist[3],
            ESICRegistration: this.checklist[4],
            Affidavit: this.checklist[5],
            AgreementWithPE: this.checklist[6],
            ConsentLetter: this.checklist[7],
            AnnexureBofPE: this.checklist[8],
            DBTInformation: this.checklist[9],
            DBTScrollBankDetails: this.checklist[10],
            EmployeeSchemes: this.checklist[11],
            LicenseCopy: this.checklist[12],
            FormD: this.checklist[13],
            PoliceVerifications: this.checklist[14],
            PendingCourCases: this.checklist[15],
            TotalObservedGuards: this.guardcount1,
            GuardsObserved: this.guardcount1,
            GuardsExcluded: this.guardcount2,
            FilePath: this.filepath,
            Observations: this.observation_remark,
            InspectionDate: this.convertDateFormatYMD(this.observation_date),
            CreatedBy: this.$store.getters.loggedUser.UserId, //ID of Logged in User,
            GSTPaymentReceipt: this.checklist[16],
            EmployerUndertaking: this.checklist[17],
            PoliceVerificationOfAgency: this.checklist[18],
            form: JSON.stringify(this.form),
          };
          console.log(data);

          var promiseU = apis.save_application_checklist(data);
          promiseU
            .then((response) => {
              if (response.status == 200) {
                var data = response.body[0];
                this.ExemptionCheckListID = data.ExemptionCheckListID;

                this.$dialog.alert("Checklist has been saved successfully");
                //this.check_employer_status1=true;
                this.get_checklist();
                //this.get_data();

                // this.status_check3();
                //  this.status_check();
              }
            })
            .catch((err) => {
              this.alertText = err.message;
              this.showDismissibleAlert = true;
              this.variant = "danger";
            });
        } else {
          this.$dialog.alert("Please select all checklist");
        }
      } else {
        this.$dialog.alert("Please select checklist");
      }
    },
    clear_list() {
      this.filter = "";
      this.get_employer_list();
    },
    get_employer_list() {
      //alert(99);
      if (this.filter != "") {
        
        let x = this.filter;
        this.employer_data = _.filter(this.employer_data_all, (e) => 
        {
          //alert(e.RegistrationNumber);
          if (e.EntityName.toLowerCase().includes(x) //== x 
              || e.RegistrationNumber.toLowerCase().includes(x))
         {
            return true;
          } else 
          {
            return false;
          }
        });
      } else {
        this.employer_data = this.employer_data_all;
      }

      // this.formEmployer.filter=this.filter;
    },
    get_checklist() {
      let promiseU = "";
      var id = this.$route.params.ApplicationID;

      promiseU = apis.get_application_checklist(id);
      promiseU
        .then((response) => {
          if (response.status == 200) {
            if (response.body.length > 0) {
              var data = response.body[0];
              this.ExemptionCheckListID = data.ExemptionCheckListID;
              this.checklist[0] = data.Annexure1;
              this.checklist[1] = data.ShopActMumbai;
              this.checklist[2] = data.GSTRegistration;
              this.checklist[3] = data.EPFRegistration;
              this.checklist[4] = data.ESICRegistration;
              this.checklist[5] = data.Affidavit;
              this.checklist[6] = data.AgreementWithPE;
              if (data.ConsentLetter == null || !data.ConsentLetter) {
                // alert(777);
                this.checklist[7] = "No";
              } else {
                this.checklist[7] = data.ConsentLetter;
              }

              this.checklist[8] = data.AnnexureBofPE;
              this.checklist[9] = data.DBTInformation;
              this.checklist[10] = data.DBTScrollBankDetails;
              this.checklist[11] = data.EmployeeSchemes;
              this.checklist[12] = data.LicenseCopy;
              this.checklist[13] = data.FormD;
              this.checklist[14] = data.PoliceVerifications;
              this.checklist[15] = data.PendingCourCases;
              if (data.GSTPaymentReceipt == null || !data.GSTPaymentReceipt) {
                //alert(99);
                this.checklist[16] = "No";
              } else {
                this.checklist[16] = data.GSTPaymentReceipt;
              }

              this.checklist[17] = data.EmployerUndertaking;
              this.checklist[18] = data.PoliceVerificationOfAgency;
              this.guardcount1 = data.GuardsObserved;
              this.guardcount2 = data.GuardsExcluded;
              this.observation_remark = data.Observations;
              if (data.FilePath) {
                this.uploade_file =
                  "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
                  this.$route.params.ApplicationID +
                  "/" +
                  data.FilePath;
                this.file = data.FilePath;
                this.change_file_check = 1;
              } else {
                this.uploade_file = "";
              }
              this.status_check3();

              // alert(this.ExemptionCheckListID);
            } else {
              this.ExemptionCheckListID =
                "00000000-0000-0000-0000-000000000000";
              this.checklist = [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
              ];
              this.status_check3();
            }
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
    async get_data() {
      let promiseU = "";
      var id = this.$route.params.ApplicationID;
      var data = {
        ID: id,
      };

      promiseU = apis.readExemptionUserDetail(data);

      promiseU
        .then((response) => {
          if (response.status == 200) {
            this.personalDetails = response.body[0];
            this.application_data = JSON.parse(
              response.body[0].ApplicationDetails
            );
            this.gr_number = this.personalDetails.GrNumber;
            this.gr_number_marathi = this.personalDetails.GrMarathiNumber;
            this.gr_file_number = this.personalDetails.GrFileNo;
            // this.gr_link_file=this.personalDetails.
            this.gr_file_name = this.personalDetails.GrFileName;
            // this.gr_date=this.personalDetails.GrF;
            // alert(this.personalDetails.GrDate)
            if (this.personalDetails.GrDate) {
              this.gr_date = new Date(this.personalDetails.GrDate);
            } else {
              this.gr_date = new Date();
            }
            this.gr_signatory = this.personalDetails.GrSignatory;
            this.gr_link = this.personalDetails.GrMapLink;
            if (this.personalDetails.GrFile) {
              this.uploade_file_gr_link =
                "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
                this.$route.params.ApplicationID +
                "/" +
                this.personalDetails.GrFile;
            }
            this.form = JSON.parse(response.body[0].ApplicationDetails);
            this.ApprovalLogList = JSON.parse(response.body[0].ApplicationLog);

            this.isLoading = false;
            this.$store.getters.getBoards.forEach((item) => {
              if (item.SecurityBoardID == this.personalDetails.BoardID) {
                this.board1 = item.SecurityBoardName;
              }
            });
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
        })

        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
  },

  async mounted() {
    //this.InspectionDate();
    //alert(InspectionDate);

    var isExemption = this.$route.params.isExemption;
    var userinfo = this.$store.getters.loggedUser;
    this.shree_label = this.$store.getters.loggedUser.UserName;
    // console.log(this.shree_label);
    this.RoleID = userinfo.RoleID;

    this.formGuard.ApplicationStatus = this.ApplicationStatus;
    this.showApproval = false;
    //alert(this.ApplicationStatus);
    let promise = apis.ReadExemptAppStatusByRoleStatus({
      Role: userinfo.RoleID,
      Status: this.ApplicationStatus,
    });

    await this.get_data();
    await this.get_checklist();
    this.select_emp();
    let promiseR = apis.ReadRejectReason();

    promiseR
      .then((response) => {
        if (response.status == 200) {
          this.RejectReasons = response.body;
        }
      })
      .catch((err) => {
        this.alertText = err.message;
        this.showDismissibleAlert = true;
        this.variant = "danger";
      });

    var id = this.$route.params.ApplicationID;
    await promise
      .then((response) => {
        if (response.status == 200) {
          this.ApprovalStatus = response.body;
          if (this.ApprovalStatus.length > 0) {
            this.showApproval = true;
          }
        } else {
          this.ApprovalStatus = [];
        }
      })
      .catch((err) => {
        this.ApprovalStatus = [];
        this.alertText = "Error in Loading Application Staus Master";
        this.variant = "danger";
        this.showDismissibleAlert = true;
      });

    // alert(id);
    this.formEmployer.ApplicationID = id;

    let promiseE = apis.ReadEmployerList(this.formEmployer);
    promiseE
      .then((response) => {
        if (response.status == 200) {
          this.employer_data = response.body;
          this.employer_data_all = response.body;

          this.update_aproval_status = response.body;
          this.get_guard_list();
          this.status_check();

          //alert(this.employer_data.length);
        } else {
        }
      })
      .catch((err) => {});
  },
};
</script>

<style scoped>
.radio[disabled] {
  background-color: blue !important;
  color: blue !important  ;
}

@media print {
  .my-table {
    height: 90vh;
  }
}
.table_border_none,
.table_border_none > tr > th,
.table_border_none > tr > td {
  border: none !important;
}
.reg-form-box {
  font-size: 14px !important;
}
table {
  font-family: arial, sans-serif;
  /* border-collapse: collapse; */
  width: 96%;
  margin-left: 2%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
}
.newfont {
  font-size: 0.875rem;
}
.myclass {
  font-size: 13px;
}
.file {
  /* position: relative; */
  /* display: flex; */
  justify-content: right;
  align-items: right;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  font-size: 0.6rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* display: flex; */
  justify-content: right;
  align-items: right;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: hsl(204, 86%, 53%);
  border-color: hsl(204, 86%, 53%);
}

.file--upload > label:hover {
  border-color: hsl(204, 86%, 53%);
  background-color: hsl(204, 86%, 96%);
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}
</style>
