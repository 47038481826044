<template>
  <b-container>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-row>
        <b-col>
          <b-form-group
            id="grp1"
            label-cols="6"
            label="Weight (kg)"
            label-size="sm"
            label-for="ipWt"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipWt"
              required
              v-model="form.Weight"
              min="0"
              max="200"
              step="0.1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="grp1"
            label-cols="6"
            label="Height (cm)"
            label-size="sm"
            label-for="ipHt"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipHt"
              required
              v-model="form.Height"
              min="0"
              max="200"
              step="0.1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="grpCd"
            label-cols="7"
            label="Chest Deflate (cm)"
            label-size="sm"
            label-for="ipCd"
            v-if="gender"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipCd"
              required
              v-model="form.ChestD"
              min="0"
              max="200"
              step="0.1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="grpCi"
            label-cols="6"
            label="Chest Inflate (cm)"
            label-size="sm"
            label-for="ipCi"
            v-if="gender"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipCi"
              required
              v-model="form.ChestI"
              min="0"
              max="200"
              step="0.1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-form-radio-group
            id="grndExmpt"
            v-model="form.Exemption"
            :options="exmptOptions"
            @change="checkAge"
          >
          </b-form-radio-group>
        </b-col>
      </b-row>
      <b-row v-if="form.Exemption == 0">
        <b-col cols="5">
          <b-form-group
            id="grpRt"
            label-cols="6"
            label="Running Time (min)"
            label-size="sm"
            label-for="ipRt"
          >
            <date-picker
              v-model="form.RunningTime"
              type="time"
              value-type="format"
              format="mm.ss"
              id="ipBtDt"
              lang="en"
              size="small"
              :show-hour="showHour"
              required
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="grpCi"
            label-cols="6"
            label="Situps (No.)"
            label-size="sm"
            label-for="ipSu"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipSu"
              required
              v-model="form.Situps"
              min="0"
              max="20"
              step="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="grpCi"
            label-cols="6"
            label="Pullups (No.)"
            label-size="sm"
            label-for="ipPu"
          >
            <b-form-input
              type="number"
              size="sm"
              id="ipPu"
              required
              v-model="form.PullUps"
              min="0"
              max="20"
              step="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-checkbox
            id="chbPublish"
            v-model="form.PublishMarks"
            title="Publish Result?"
            >Publish Result?</b-checkbox
          >
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-checkbox
            v-if="form.PublishMarks"
            id="chbSendMail"
            v-model="form.SendEmail"
            ref="chbSendMail"
            >Send Email for Police Verification &#38; Medical
            Letter?</b-checkbox
          >
        </b-col>
      </b-row> -->
      <b-row>
        <b-col class="mt-1">
          <b-button
            type="submit"
            size="sm"
            :variant="variant"
            class="float-right mx-2"
            >Save Marks
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoading"
            ></b-spinner>
          </b-button>
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: right"
            >Reset
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoadingR"
            ></b-spinner>
          </b-button>
        </b-col>
      </b-row>
      <b-alert
        :show="showDismissibleAlert"
        dismissible
        @dismissed="showDismissibleAlert = false"
        :variant="variant"
      >
        {{ alertText }}
      </b-alert>
    </b-form>
    <b-table
      :items="items"
      :fields="fields"
      size="small"
      style="font-size: 12px"
      class="mt-2"
    ></b-table>
    <b-form @submit="SpecialApprovalSubmit" @reset="SpecialApprovalReset" v-if="showSpecial">
      <b-row>
        <b-col>
          <b-checkbox
            id="chbSplApprove"
            v-model="formSpecial.Approved"
            title="Special Approval for PT case by Chairman"
            >Special Approval for PT case by Chairman</b-checkbox
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="grpRemarkSpl"
            label-cols="1"
            label="Remarks"
            label-size="sm"
            label-for="ipRmkSpl"
            v-if="formSpecial.Approved"
          >
            <b-form-textarea
              max-rows="3"
              size="sm"
              id="ipRmkSpl"
              required
              v-model="formSpecial.Remarks"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="formSpecial.Approved">
        <b-col class="mt-1">
          <b-button
            type="submit"
            size="sm"
            :variant="variant"
            class="float-right mx-2"
            >Save Status
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoadingSpl"
            ></b-spinner>
          </b-button>
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: right"
            >Reset
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoadingRSpl"
            ></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import apis from "../apis";
import ApplicationFilter from "./ApplicationFilter.vue";

export default {
  components: { DatePicker, ApplicationFilter },
  props: ["applicationID", "gender", "age"],
  data() {
    return {
      showHour: false,
      showSpecial: false,
      exmptOptions: [
        { value: 0, text: "Not Exempted" },
        { value: 1, text: "Exempted due to Age" },
        { value: 2, text: "Exempted Temporarily till Ground Test" },
      ],
      form: {
        ApplicationID: "00000000-0000-0000-0000-000000000000",
        Height: 0,
        Weight: 0,
        ChestD: 0,
        ChestI: 0,
        RunningTime: "0.0",
        Pullups: 0,
        Situps: 0,
        Exemption: 0,
        PublishMarks: false,
        SendEmail: false,
        CreatedBy: "00000000-0000-0000-0000-000000000000"
      },
      formSpecial:{
        ApplicationID: "00000000-0000-0000-0000-000000000000",
        Approved:false,
        Remarks:"",
        CreatedBy: "00000000-0000-0000-0000-000000000000",
      },
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingR: false,
      isLoadingSpl: false,
      isLoadingRSpl: false,
      items: [],
      fields: [
        "Weight",
        "Height",
        "ChestDeflated",
        "ChestInflated",
        "RunningTime",
        "RunningMark",
        "SitUps",
        "SitupsMark",
        "PullUps",
        "PullupsMark",
        "EducationalMark",
        "TotalMarks",
        "ResultStatus"
      ],
    };
  },
  methods: {
    checkAge(args) {
      if (args == 1 && this.age < 35) {
        var t = this;
        this.variant = "success";
        this.$dialog
          .confirm(
            "Applicant is LESS THAN 35 yrs OLD. Are you sure you want to give Age Exemption?"
          )
          .then(function (dialog) {
            t.variant = "danger";
          })
          .catch(function () {
            t.form.Exemption = 0;
          });
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.isLoading = true;
      this.form.ApplicationID = this.applicationID;
      this.form.CreatedBy = this.$store.getters.loggedUser.UserId;
      let promiseSave = apis.saveMarks(this.form);      

      promiseSave
        .then((response) => {
          if (response.status == 200) {
            this.$dialog.alert("Marks Saved Successfully");
            this.items = response.body;
          } else {
            this.alertText = "Error !";
            this.variant = "warning";
          }
        })
        .catch((err) => {
          this.$dialog.alert("Error while saving data");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();

      resetForm();
    },
    SpecialApprovalSubmit(evt){
      evt.preventDefault();
      this.isLoadingSpl = true;
      this.formSpecial.ApplicationID = this.applicationID;
      this.formSpecial.CreatedBy = this.$store.getters.loggedUser.UserId;

      let promiseSave = apis.savePTSpecial(this.formSpecial);

      promiseSave
        .then((response) => {
          if (response.status == 200) {
            // this.$dialog.alert("Application Status Updated Successfully");
             this.items = response.body;
             this.$dialog.alert({
          title: "Done",
          body: "Application Status Updated Successfully",
          confirmText: "Continue", // Set the label for the "Continue" button
          }).then(() => {
            this.$root.$emit("bv::hide::modal", "mdlMarks");
          this.$router.push({
                  name: "Applications",
                 
                });
          
        });
          } else {
            this.alertText = "Error !";
            this.variant = "warning";
          }
        })
        .catch((err) => {
          this.$dialog.alert("Error while saving data");
        })
        .finally(() => {
          this.isLoadingSpl = false;
        });
    },
    SpecialApprovalReset(evt){
      this.isLoadingRSpl = true;
      this.formSpecial = {
        ApplicationID: "00000000-0000-0000-0000-000000000000",
        Approved:false,
        Remarks:"",
        CreatedBy: "00000000-0000-0000-0000-000000000000",
      }
      this.isLoadingRSpl = false;
    },
    resetForm() {
      this.isLoadingR = true;
      this.form = {
        ApplicationID: "00000000-0000-0000-0000-000000000000",
        Height: 0,
        Weight: 0,
        ChestD: 0,
        ChestI: 0,
        RunningTime: "0:0",
        Pushups: 0,
        Situps: 0,
        Exemption: 0,
      };
      this.isLoadingR = false;
    },
  },
  mounted() {
    let promise = apis.ReadMarksApplicationwise(this.applicationID);

    promise
      .then((response) => {
        if (response.status == 200) 
        {
          this.items =[];
          this.items = response.body;
          console.log(this.items);

          if(this.items.length>0)
          
          {
            if(this.items[0].EventType == "3" && this.items[0].ResultStatus == "Fail")
            {
            this.showSpecial = true;
            } else 
            {
            this.showSpecial = false;
            }
          }
        } else {
          this.alertText = "Error !";
          this.variant = "warning";
        }
      })
      .catch((err) => {
        this.$dialog.alert("Error while reading data");
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>