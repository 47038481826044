<template>
  <b-container fluid>
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="form-row">
        <b-col class="mt-3" md="6">
          <b-form-group
            id="grp2"
            label-cols="2"
            label="Board"
            label-size="sm"
            label-for="ipBoard"
          >
            <b-select
              id="ipBoard"
              :options="boards"
              value-field="SecurityBoardID"
              text-field="SecurityBoardName"
              
              size="sm"
              :disabled="
                value.BoardID != '00000000-0000-0000-0000-000000000000' 
              "
              v-model="value.BoardID"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col>
          <!-- <b-col md="3" class="mt-3" >
          <b-form-group
            id="grpRegType"
            label-cols="2"
            label="Status"
            label-size="sm"
            label-for="ipRegTyp"
          >
            <b-select
              id="ipRegTyp"
              :options="RegTypes"
              
              size="sm"
              v-model="value.RecordStatus"
              class="form-control"
            ></b-select>
          </b-form-group>
        </b-col> -->
      


      </div>
      <div class="form-row">
        <b-col md="6" class="mt-1">
          <b-form-group
            id="grp1"
            label-cols="2"
            label="Pool Type"
            label-size="sm"
            label-for="ipPoolType"
          >
            <b-select
              id="ipPoolType"
              class="form-control"
              value-field="EventID"
              size="sm"
              text-field="EventTitle"
              :options="events"
              v-model="value.EventID"
            ></b-select>
          </b-form-group>
        </b-col>
          <b-col md="6" class="mt-1" >
           <b-form-group
            id="grpDt"
            label-cols="1"
            label="Date"
            label-size="sm"
            label-for="ipBtDt"
          >
            <date-picker
              v-model="value.entry_date"
              format="DD-MM-YYYY"
              id="ipBtDt"
              lang="en"
              size="small"
              style=""
              range
              
            ></date-picker>
          </b-form-group>
        </b-col>
                    
        </div>
        <div class="form-row">
           <b-col md="6" class="mt-2" >
          <b-form-group
            id="grpRegType"
            label-cols="2"
            label="Applicant Name"
            label-size="sm"
            label-for="ipRegTyp"
          >
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              v-model="value.ApplicantName"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" class="mt-1" >
          <b-form-group
            id="grp4"
            label-cols="2"
            label="Adhar No"
            label-size="sm"
            label-for="ipAppNum"
          >
            <b-form-input
              type="text"
              size="sm"
              id="ipAppNum"
              v-model="value.AdharNo"
            />
          </b-form-group>
        </b-col>
            <b-col class="mt-2" md="3" >
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            style="float: left"
            >Search</b-button
          >
          <b-button
            type="reset"
            size="sm"
            variant="secondary"
            style="float: left"
            class="ml-2"
            >Reset</b-button
          >
        </b-col>
      </div>
        <b-row> 
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect,DatePicker },
  props: ["boards", "events", "approvalStatus", "value", "name"],

  data() {
    return {
      form: this.value || {},
      RegTypes: [
        { value: 1, text: "Pending" },
        { value: 4, text: "Deleted" },
        { value: 3, text: "Login Created" },
        // { value: 3, text: "Compassionate" },
        // { value: 4, text: "Court Order" },
      ],
      fileAppnum: null,
      ExSvcOptions: [
        { Id: 0, Value: "No" },
        { Id: 1, Value: "Yes" },
        { Id: 3, Value: "All" },
      ],
      appDate: null,
      entry_date:"",
      name_status: "",
      name_data: "",
      appStatus: [
        //{ Id: 0, Value: "All" },
        { Id: 1, Value: "Yes" },
        { Id: 0, Value: "No" },
      ],
      appStatusAll: [],

      genderList: this.$store.getters.getGenderList,
    };
  },
  mounted() {
    this.appStatus = this.approvalStatus;
  //console.log(this.boards);
    if (this.events[0].EventTitle == "All") {
      this.events.shift();
    }
    if (this.name[0].name == "Applications") {
      this.name_status = true;
    }
  },

  methods: {
     filter(data){
    const statusesToCheck = ["All"];
    const allStatusesPresent = statusesToCheck.every(ApplicationStatus => data.some(item => item.ApplicationStatus === ApplicationStatus));
    //console.log(allStatusesPresent);
    if (allStatusesPresent) 
    {
      //console.log("All status values are present in the JSON array.");
      this.form.selectValue=[];
      this.form.selectValue=this.appStatus;
      
    } 
   },
    onSubmit(evt) {
      //alert(12);
      this.isloading = true;
      evt.preventDefault();
      this.$emit("submit");
      this.isloading = false;
    },

    onReset(evt) {
      evt.preventDefault();
      this.value.EventID = "";
     this.value.RecordStatus = "";
     this.value.entry_date = [null,null];
     this.value.ApplicantName = "";
     this.value.AdharNo = "";
     this.$emit("reset");
   

    },
  
  },
};
</script>
<style scoped>
.multi-select {
  height: auto;
  max-height: 120px; /* Set your desired maximum height here */
  overflow-y: auto;
}
</style>
