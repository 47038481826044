<template>
  <b-container>
   <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Guard Registration</router-link>
        </li>
        
        <li
          class="breadcrumb-item active"
          style="font-size: 0.875rem"
          aria-current="page"
        >
          View SignUp
        </li>
      </ol>
    </nav>
  
    <div class="body-content">

      <table
        class="table table-bordered"
        style="text-align: left; horizantal-align: top"
      >
        <thead>
          <tr>
            <th style="width: 300px">Pool Type</th>
            <td>{{ RegType }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType <= 2">
            <th>Employer Name</th>
            <td>{{ EventDetails.EmployerName }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType <= 2">
            <th>Employer Registration No</th>
            <td>{{ EventDetails.EmployerRegNo }}</td>
          </tr>
           <tr v-if="EventDetails.PoolType <= 2">
            <th>Status</th>
            <td>{{ RecordDetails.BoardAddProfileStatus }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType == 4">
            <th>Court Case No</th>
            <td>{{ EventDetails.PetitionNo }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType == 4">
            <th>Court Name</th>
            <td>{{ EventDetails.CourtName }}</td>
          </tr>
          <!-- <tr v-if="EventDetails.PoolType == 4">
            <th>Order No</th>
            <td>{{ EventDetails.OrderNo }}</td>
          </tr> -->
          <tr v-if="EventDetails.PoolType == 4">
            <th>Court Order Date</th>
            <td>{{convertDateFormatYMD1( EventDetails.OrderDate)}}</td>
          </tr>
          <tr v-if="EventDetails.PoolType == 4">
            <th>Party Name</th>
            <td>{{ EventDetails.PartyName }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType == 3">
            <th>Deceased Guard RegNo</th>
            <td>{{ EventDetails.DeceasedGuardRegNo }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType == 3">
            <th>Deceased Guard Name</th>
            <td>{{ EventDetails.DeceasedGuardName }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType == 3">
            <th>Applicant Guard Relation</th>
            <td>{{ EventDetails.ApplicantGuardRelation }}</td>
          </tr>
          <tr v-if="EventDetails.PoolType == 3">
            <th>Legal Hier Name</th>
            <td>
              {{ EventDetails.HeirFirstName }}
              {{ EventDetails.HeirMiddleName }} {{ EventDetails.HeirLastName }}
            </td>
          </tr>
        </thead>
      </table>
      
      <h5>
      <b>Applicant Details:</b>
         <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
          @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
      </h5>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Pool Type</th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>DOB</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Aadhar No</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(applicant, index) in EventDetails.ApplicantDetails"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ RegType }}</td>
            <td>{{ applicant.FirstName }}</td>
            <td>{{ applicant.MiddleName }}</td>
            <td>{{ applicant.LastName }}</td>
            <td>{{ convertDateFormatYMD1(applicant.DOB) }}</td>
            <td>{{ applicant.Mobile }}</td>
            <td>{{ applicant.Email }}</td>
            <td>{{ applicant.AadharNo }}</td>
            <td>{{ applicant.Remarks }}</td>
          </tr>
        </tbody>
      </table>
      <b-col v-if="RoleID==99">
        <b-button 
          class="hide-print mt-3 ml-1 float-right"
          size="lg"
          variant="success"
          id="createLoginButton"
          v-if="RecordDetails.RecordStatus == 1"
          @click="CreateLogin"
          >Create Login</b-button
        >

        <b-button
          class="hide-print mt-3 ml-2 float-right"
          size="lg"
          v-if="RecordDetails.RecordStatus == 1"
          @click="Deletedetail"
          variant="danger"
          >Delete</b-button
        >
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          @click.prevent="goback"
          variant="info"
          size="lg"
          >Close</b-button
        >
      </b-col>
      <b-col v-else>
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          @click.prevent="goback"
          variant="info"
          size="lg"
          >Close</b-button
        >
      </b-col>
    </div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Formatter from "@/Formatter.js";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  props: ["RecordDetails","Back_flag"],
  data() {
    return {
      RegisterID: "",
      BoardID: "",
      UserID: "",
      RegType: "",
      EventDetails: {},
      applications:[],
      applicant:[],
    };
  },
  methods: {
    convertToPdf() {
      //console.log (this.EventDetails.PoolType);
      // var Total = this.Total?this.Total.toString():'0';
      var pdf = new jsPDF("l", "pt");
      this.applicant = [];
      var PoolType = this.EventDetails.PoolType;
     for (var i = 0; i < this.EventDetails.ApplicantDetails.length; i++) 
          {
          this.applicant.push({
                              sr_no: i + 1,
                              PoolType:this.RegType,
                              FirstName:this.EventDetails.ApplicantDetails[i].FirstName,
                              MiddleName:this.EventDetails.ApplicantDetails[i].MiddleName,
                              LastName:this.EventDetails.ApplicantDetails[i].LastName,
                              Mobile:this.EventDetails.ApplicantDetails[i].Mobile,
                              DOB:this.convertDateFormatYMD1(this.EventDetails.ApplicantDetails[i].DOB),
                              AadharNo:this.EventDetails.ApplicantDetails[i].AadharNo,
                              Email:this.EventDetails.ApplicantDetails[i].Email,
                              Remarks:this.EventDetails.ApplicantDetails[i].Remarks
                              
          })
    
            
          }
 
      var columns = [
        { dataKey: "sr_no", title: "Sr No" },
        { dataKey: "PoolType", title: "Pool Type" },
        { dataKey: "FirstName", title: "First Name" },
        { dataKey: "MiddleName", title: "Middle Name" },
        { dataKey: "LastName", title: "Last Name" },
        { dataKey: "DOB", title: "DOB" },
        { dataKey: "Mobile", title: "Mobile" },
        { dataKey: "Email", title: "Email" },
        { dataKey: "AadharNo", title: "Aadhar No" },
        { dataKey: "Remarks", title: "Remark",},  
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (item.SecurityBoardID == this.$store.getters.getUserBoard
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      // var event = "";
      // this.$store.getters.getEvents.forEach((e) => {
      //   if (e.EventID == this.$store.getters.getAppSearchForm.EventID) {
      //     event = e.EventTitle;
      //     return;
      //   }
      // });
      var ref = this;
      var pageContent = function (data) {
       pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "View Sign Up List " ,
          pdf.internal.pageSize.getWidth() / 2,
          60,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        // pdf.text("Total applicant :", 610, 70);
        pdf.text(ref.convertDateFormatYMD1(new Date()), 70, 70);
        //  pdf.text(Total,720, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applicant,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            
          },
        }
      );
      pdf.save("SignupList.pdf");
    },
    async downloadExcel() {
      var columns = [
        "Sr No",
        "Pool Type",
        "First Name",
        "Middle Name",
        "Last Name",
        "DOB",
        "Mobile",
        "Email",
        "Aadhar No",
        "Remark",
       
      ];

      let app_data = [];
       for (var i = 0; i < this.EventDetails.ApplicantDetails.length; i++) 
          {
          this.applicant.push({
                              sr_no: i + 1,
                              PoolType:this.RegType,
                              FirstName:this.EventDetails.ApplicantDetails[i].FirstName,
                              MiddleName:this.EventDetails.ApplicantDetails[i].MiddleName,
                              LastName:this.EventDetails.ApplicantDetails[i].LastName,
                              Mobile:this.EventDetails.ApplicantDetails[i].Mobile,
                              DOB:this.convertDateFormatYMD1(this.EventDetails.ApplicantDetails[i].DOB),
                              AadharNo:this.EventDetails.ApplicantDetails[i].AadharNo,
                              Email:this.EventDetails.ApplicantDetails[i].Email,
                              Remarks:this.EventDetails.ApplicantDetails[i].Remarks
                              
          })
    
            
          }
      
      
      await this.applicant.forEach((element) => {
       
        let obj = {
          "Sr No": "",
          "Pool Type": "",
          "First Name": "",
          "Middle Name": "",
          "Last Name": "",
          "DOB":"",
          "Mobile": "",
          "Email": "",
          "Aadhar No": "",
          "Remark": "",
          
        };
        obj["Sr No"] = element.sr_no;
        obj["Pool Type"] = element.PoolType;
        obj["First Name"] = element.FirstName;
        obj["Middle Name"] = element.MiddleName;
        obj["Last Name"] = element.LastName;
        obj["Mobile"] = element.Mobile;
        obj["DOB"] = element.DOB; 
        obj["Email"] = element.Email;
        obj["Aadhar No"] = element.AadharNo;
        obj["Remark"] = element.Remarks;

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
     convertDateFormatYMD1(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD1(inDate);
      }
    },
     RoleID() {
      var nme = this.$store.getters.loggedUser;
      return nme.RoleID;
      //alert(nme.RegistrationType);
    },
     goback() { 
      //this.$router.back();
      
      if (this.Back_flag == 1) {
         this.$router.push({
        name: "ApplicantWiseList",
        params: {
         BoardID: this.RecordDetails.BoardID, 
        },
       
      });
        
      } else {
         this.$router.push({
        name: "RegistrationList",
        params: {
         BoardID: this.RecordDetails.BoardID, 
        },
      });
      }
     
     },
    
    CreateLogin() {
      var button = document.getElementById("createLoginButton");
        button.disabled = true; 
        button.innerHTML = "Creating...";
       var payload = { Id: this.RegisterID, UserID: this.UserID };
      let promise = apis.createlogin(payload);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.$dialog.alert("Login Created Successfully");
              this.$router.push({
                  name: "RegistrationList",
                  params: {
                  BoardID: this.RecordDetails.BoardID, 
                  },
                });
              // this.$router.push("/RegistrationList");
          } else {
            this.$dialog.alert("ERROR" + response.body);
          }
        })
    },
    Deletedetail(item) {
      
      // this.$dialog
      //   .confirm("Please confirm, if you want to Delete")
      //   .then(function (dialog) {
          var payload = { Id: this.RegisterID, UserID: this.UserID };

          let promiseD = apis.deleterecord(payload);
          promiseD
            .then((response) => {
              if (response.status == 200) {
                this.$dialog.alert("Record Deleted Successfully");
                // this.$router.push("/RegistrationList");
                 this.$router.push({
                  name: "RegistrationList",
                  params: {
                  BoardID: this.RecordDetails.BoardID, 
                  },
                });
              }
            })
            .catch((err) => {
              this.$dialog.alert("Error while deleting the record.");
            });
        
      // .catch(function () {
      //   //console.error(err);
      //   console.log("Clicked on cancel");
      // });
    },
  },
  mounted() {
    var userinfo = this.$store.getters.loggedUser;
    this.UserID = userinfo.UserId;
    this.RoleID = userinfo.RoleID;
   
   

    this.BoardID = this.userBoard;
    this.RegisterID = this.RecordDetails.RegisterID;
    this.RegType = this.RecordDetails.RegistrationType;
    this.EventDetails = JSON.parse(this.RecordDetails.EventDetails);
  },
};
</script>

<style scoped>
.container {
  margin-top: 30px;
}
</style>
