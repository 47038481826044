<template>
<b-container>
<b-row class="mb-3">
      <b-col>
        <center>
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            @click.prevent="viewSanction"
            variant="warning"
            v-if="
              ApplicationStatus == 14 ||
              ApplicationStatus == 15 ||
              ApplicationStatus == 34 ||
              ApplicationStatus == 35 ||
              ApplicationStatus == 30
            "
            >Verification Letters</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            @click.prevent="printBform"
            variant="success"
            v-if="ApplicationStatus == 20 || ApplicationStatus == 21"
            >B-Form Receipt</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            size="sm"
            variant="info"
            @click="print"
            >Print Application</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            @click.prevent="goback"
            variant="danger"
            size="sm"
            >BACK</b-button
          >
          <b-button
            class="hide-print mt-3 ml-2"
            @click.prevent="unlock"
            variant="secondary"
            size="sm"
            v-if="
              ApplicationStatus <= 3 ||
              ApplicationStatus == 5 ||
              ApplicationStatus == 23
            "
            >UNLOCK PROFILE
            <b-spinner
              small
              type="grow"
              variant="warning"
              v-if="isLoadingUnlock"
            ></b-spinner>
          </b-button>
        </center>
        <hr />
      </b-col>
       <b-alert
      :show="showDismissibleAlertUnlock"
      dismissible
      @dismissed="showDismissibleAlertUnlock = false"
      :variant="variantUnlock"
      >{{ alertTextUnlock }}
    </b-alert>
    </b-row>
     
      <b-row class="my-2">
        <b-col style="text-align: center">
          <!-- <img
            src="@/assets/images/logo_trans.png"
            class="float-left"
            width="80px"
          /> -->
          <h2>{{ board1 }}</h2>
          <!-- <h4>Application for Exemption U/S 23</h4> -->
        </b-col>
      </b-row>
      <hr />
      <b-row class="my-2">
        <b-col md="1"></b-col>
        <b-col>
          <strong>Application Number:</strong>
          <!-- {{ personalDetails.ExemptionApplicationNo }} -->
        </b-col>
        <b-col></b-col>
        <b-col class="float-right">
          <strong>Date:</strong>
          <!-- {{ convertDateFormat(personalDetails.ExemptionApplicationDate) }} -->
        </b-col>
      </b-row>
    
     <div
        class="table4 mb-2"
        style="border: 1px solid black; border-style: solid; padding: 10px"
      >
  <!-- <body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'> -->



<p class=MsoNormal><b><span style='font-size:12.0pt;line-height:115%;
font-family:DVOT-SurekhMR'>संदर्भ क्रमांक : सुरमं/अध्यक्ष/2021   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;                 हस्तपोच &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;&nbsp;              दिनांक
:</span></b></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%;font-family:
DVOT-SurekhMR'>प्रति,</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>मा. प्रधान सचिव (कामगार),                  </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>उद्योग, ऊर्जा व कामगार विभाग,</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>मंत्रालय, मुंबई 400 032.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'> &nbsp;   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;          <b>विषय :  महाराष्ट्र
खाजगी सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) अधिनियम, 1981</b></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:1.0in;text-indent:.5in;line-height:normal'><b><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'> &nbsp;   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;च्या कलम 23 अंतर्गत </span></b><b><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>प्राप्त झालेल्या सुटबाबतच्या
प्रस्तावासंदर्भात.</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>महोदय,</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:1.0in;text-indent:.5in;line-height:normal'><span style='font-size:
12.0pt;font-family:DVOT-SurekhMR'>     </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify'><span
style='font-size:12.0pt;line-height:115%;font-family:DVOT-SurekhMR'>उपरोक्त विषयाच्या
अनुषंगाने आपणास कळविण्यात येते की, मे. {{this.EntityName}} या एजन्सीने त्यांचेमार्फत
पिक इन्फ्रास्टेक्चर मॅनेजमेंट सर्व्हिसेस प्रा. लि., या आस्थापनेत कार्यरत असलेल्या
02 सुरक्षा रक्षकांना उक्त अधिनियमाच्या कलम 23 अंतर्गत सुट मिळण्याबाबत दि. 11.01.2021
रोजी मंडळास प्रस्ताव सादर केला आहे.   </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify'><span
style='font-size:12.0pt;line-height:115%;font-family:DVOT-SurekhMR'>        मे.
{{this.EntityName}} या खाजगी सुरक्षा एजन्सीने मंडळाकडे 02 परिशिष्ट 'ड' भरुन दिलेले
असून सदरच्या एजन्सीने खाजगी सुरक्षा एजन्सीज (नियमन) अधिनियम 2005 (पसारा) कायद्या
अंतर्गत परवाना  क्र. पीएसए/एल/54/एमएच/2021/एमएआर/3/618, दि. 02.03.2021 रोजी प्राप्त
केला  आहे. सदर एजन्सीचा पसारा परवाना दि. 01.03.2026 पर्यंत अस्तित्वात आहे.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;text-indent:
.5in;line-height:130%'><span style='font-size:12.0pt;line-height:130%;
font-family:DVOT-SurekhMR'>सदर एजन्सीने सादर केलेल्या प्रस्तावातील कागदपत्रांची 
शासन निर्णय दि. 8.9.2017 नुसार तपासणी केली.  तसेच सुरक्षा रक्षक कार्यरत असलेल्या
ठिकाणी विभागीय निरीक्षकांनी पडताळणी केली असता, सदर अर्जात नमुद केलेले  सुरक्षा रक्षक
हे संबंधित मुख्य मालकाकडे  कार्यरत असल्याचे आढळून आले आहे.  त्याबाबतचा तपशील परिशिष्ट-2
मध्ये दर्शविण्यात आला आहे.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;line-height:
130%'><span style='font-size:12.0pt;line-height:130%;font-family:DVOT-SurekhMR'>     शासन
निर्णय दि. 8.9.2017 नुसार कागदपत्रांची तपासणी केली असता, सदर एजन्सी त्यांचे सुरक्षा
रक्षकांना मंडळाच्या नोंदित सुरक्षा रक्षकांना मिळणाऱ्या वेतना इतकेच वेतन, भत्ते व
इतर सोयी-सुविधा अथवा समकक्ष  देत असल्याबाबत एजन्सीने सादर केलेल्या परिशिष्ट-ब व
प्रतिज्ञापत्रावरुन दिसून येते. त्याबाबतची माहिती  परिशिष्ट -3 मध्ये दर्शविण्यात
आलेली आहे.  तसेच परिशिष्ट-3 मध्ये दर्शविण्यात आलेल्या कागदपत्रांची प्रती,  तसेच
इतर सोयी-सुविधांबाबतची माहिती व अभिलेखांच्या प्रती  मुळ प्रस्तावासह सोबत जोडून पाठविण्यात
येत आहे. </span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;text-indent:
.5in;line-height:130%'><span style='font-size:12.0pt;line-height:130%;
font-family:DVOT-SurekhMR'>मे. {{this.EntityName}} या एजन्सीने सादर केलेल्या प्रस्तावातील
नमुद एकूण 02 सुरक्षा रक्षकांचे पोलीस पडताळणी अहवाल प्रस्तावासोबत जोडले आहेत.<b> 
</b>त्याबाबतची माहिती परिशिष्ट-2 मध्ये दर्शविण्यात आलेली आहे.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;text-indent:
.5in;line-height:130%'><span style='font-size:12.0pt;line-height:130%;
font-family:DVOT-SurekhMR'>याद्वारे,  मे. {{this.EntityName}} या एजन्सीने त्यांचेमार्फत
02 कार्यरत असलेल्या सुरक्षा रक्षकांना उक्त अधिनियमाच्या कलम 23 अंतर्गत सुट मिळण्याबाबत
सादर केलेला प्रस्ताव  मान्यतेस्तव सादर करण्यात येत आहे.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;line-height:
130%'><span style='font-size:12.0pt;line-height:130%;font-family:DVOT-SurekhMR'>              </span></p>

<p class=MsoNormal style='margin-left:4.0in;text-align:justify;text-indent:
.5in'><span style='font-size:12.0pt;line-height:115%;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;             
आपला विश्वासू,</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt;
line-height:115%;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
.5in;line-height:normal'><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                     
                 (अशोक ल. डोके)</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
.5in;line-height:normal'><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                               
    <b>   कामगार उप आयुक्त व अध्यक्ष</b> <b></b></span></p>



<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                                       
                                सुरक्षा रक्षक मंडळ </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                    
                                          बृहन्मुंबई आणि ठाणे जिल्ह्याकरिता</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'> </span></b><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>सोबत : वरीलप्रमाणे.<b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;                                                     
        </b></span></p>

<p class=MsoNormal style='text-align:justify'><u><span style='font-size:12.0pt;
line-height:115%;font-family:DVOT-SurekhMR'><span style='text-decoration:none'>&nbsp;</span></span></u></p>

<p class=MsoNormal style='text-align:justify'><u><span style='font-size:12.0pt;
line-height:115%;font-family:DVOT-SurekhMR'>प्रत माहितीसाठी सादर :-</span></u></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>मा. कामगार आयुक्त , </span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>कामगार आयुक्त , यांचे कार्यालय,
मुंबई,</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>कामगार भवन, सी-20, ई ब्लॉक, बांद्रा-
कुर्ला संकुल,</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>बांद्रा (पुर्व), मुंबई- 400 051.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-size:12.0pt;line-height:115%;font-family:DVOT-SurekhMR'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-size:12.0pt;line-height:115%;font-family:DVOT-SurekhMR'>&nbsp;</span></b></p>

</div>
<div class="pagebreak"></div>

 <div
        class="table4 mb-2"
        style="border: 1px solid black; border-style: solid; padding: 10px"
      >

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-size:12.0pt;line-height:115%;font-family:DVOT-SurekhMR'>परिशिष्ट -4</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-size:12.0pt;line-height:115%;font-family:DVOT-SurekhMR'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;line-height:
130%'><span style='font-size:12.0pt;line-height:130%;font-family:DVOT-SurekhMR'>     
    प्रमाणित करण्यात येते की, सुरक्षा रक्षक मंडळ बृहन्मुंबई आणि ठाणे जिल्ह्याकरिता
या मंडळाच्या  अधिकार क्षेत्रातील एका आस्थापनांकडे  नियुक्त केलेल्या मे. एन पी एस
 फॅसिलिटीस् या खाजगी सुरक्षा रक्षक एजन्सीच्या 02  सुरक्षा रक्षकांना महाराष्ट्र खाजगी
 सुरक्षा रक्षक (नोकरीचे नियमन व कल्याण) अधिनियम, 1981 च्या कलम 23 नुसार सुट मिळण्याबाबतच्या
प्रस्तावाची छाननी करण्यात आली.  प्रस्तावात नमुद केलेल्या मुख्य मालकाकडे कार्यरत
असलेल्या सुरक्षा रक्षकांना मंडळाच्या नोंदित सुरक्षा रक्षकांना मिळणाऱ्या वेतना इतकेच
वेतन, भत्ते व  इतर  सोयी सुविधा अथवा समकक्ष  देत असल्याबाबत एजन्सीने सादर केलेल्या
परिशिष्ट-ब व प्रतिज्ञापत्रावरुन दिसून येते. तसेच प्रस्तावातील नमुद सुरक्षा रक्षक
संबंधित आस्थापनेकडे कार्यरत असल्याबाबतची खात्री करण्यात आली आहे. त्यानुसार प्रस्तावातील
02 सुरक्षा रक्षक सुट मिळण्यास पात्र आहेत.  तपासणी सुचीनुसार प्रस्तावातील सर्व कागदपत्रांची
तपासणी केली असून, परिपूर्ण कागदपत्रांसह प्रस्ताव  सादर करीत आहे.</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;line-height:
130%'><span style='font-size:12.0pt;line-height:130%;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;line-height:
140%'><span style='font-size:12.0pt;line-height:140%;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                  
  (अशोक ल. डोके)</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                       
    <b>  कामगार उप आयुक्त तथा अध्यक्ष,</b></span></p>

<!-- <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><b><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'>                                                </span></b><b><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>       </span></b></p> -->

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:4.0in;text-align:justify;text-indent:.5in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;           सुरक्षा रक्षक मंडळ
</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:4.0in;text-align:justify;text-indent:.5in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;बृहन्मुंबई आणि  ठाणे जिल्ह्याकरिता</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:4.0in;text-align:justify;text-indent:.5in;line-height:normal'><span
style='font-size:12.0pt;font-family:DVOT-SurekhMR'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:6.0pt;line-height:110%'><b><span
style='font-size:12.0pt;line-height:110%;font-family:DVOT-SurekhMR'>                                        </span></b></p>
</div>
  

  <!-- </body> -->
   
</b-container>
</template>

<script>
import apis from "@/apis";
export default {
    data(){
        return{
             board1: "",
             EntityName:"",
             approvalFields: [
                        "ApplicationStatus",
                             "RejectionReason",
                         "Remarks",
                     {
                 key: "LogDate",
                 label: "Approve/Reject Date",
                 formatter: "convertDateFormat",
             },
        "ApproverName",
      ],
        }
    },

methods:{
     print() {
      window.print();
    },
     convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
     get_data() {
        // alert(1);
      let promiseU = "";
      var id = this.$route.params.ApplicationID;
      var data = {
        ID: id,
      };

      promiseU = apis.readExemptionUserDetail(data);

      promiseU
        .then((response) => {
          if (response.status == 200) {
            this.personalDetails = response.body[0];
            this.application_data = JSON.parse(
              response.body[0].ApplicationDetails
            );
            this.gr_number = this.personalDetails.GrNumber;
            this.gr_number_marathi = this.personalDetails.GrMarathiNumber;
            this.gr_file_number = this.personalDetails.GrFileNo;
            // this.gr_link_file=this.personalDetails.
            this.gr_file_name = this.personalDetails.GrFileName;
            // this.gr_date=this.personalDetails.GrF;
            this.gr_date = new Date(this.personalDetails.GrDate);
            this.gr_signatory = this.personalDetails.GrSignatory;
            this.gr_link = this.personalDetails.GrMapLink;
            if (this.personalDetails.GrFile) {
              this.uploade_file_gr_link =
                "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
                this.$route.params.ApplicationID +
                "/" +
                this.personalDetails.GrFile;
            }
            this.form = JSON.parse(response.body[0].ApplicationDetails);
            this.ApprovalLogList = JSON.parse(response.body[0].ApplicationLog);

            this.isLoading = false;
            this.$store.getters.getBoards.forEach((item) => {
              if (item.SecurityBoardID == this.personalDetails.BoardID) {
                this.board1 = item.SecurityBoardName;
              }
            });
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
     goback() {
      //this.$router.back();
      this.$router.push({
        name: "Exemption",
        params: {
          userDistrictID: this.$store.getters.getUserDistrict,
          userApprovalStatus: this.$store.getters.getAppStatus,
          userLoanType: 1,
        },
      });
    },
  
},
  created(){
    //   
         this.get_data();
          this.EntityName = this.$route.params.EntityName;
    }
};

</script>

<style>

</style>