<template>
  <div id="app">
    <div style="background-color: #f3f3f3">
      <div class="container">
        <div class="row" style="padding-top: 5px; padding-bottom: 5px">
          <div class="col-md-4">
            <img
              id="image"
              style="float: left"
              src="@/assets/images/top_image1.png"
              alt=""
              height="70"
            />
          </div>
          <div class="col-md-4" align="center" style="text-align: center">
            <a href="index.html"
              ><img
                id="left"
                src="@/assets/images/top_image3.png"
                alt=""
                height="70"
            /></a>
          </div>
          <div class="col-md-4" align="right">
            <img
              id="image"
              src="@/assets/images/top_image2.png"
              alt=""
              height="70"
            />
          </div>
        </div>
      </div>

      <RegistrationNavbar v-if="isLoggedIn"></RegistrationNavbar>
    </div>
    <router-view />
  </div>
</template>

<script>
import RegistrationNavbar from "@/components/RegistrationNavbar";
export default {
  components: { RegistrationNavbar },
  name: "App",
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    login() {
      this.$router.replace("/");
    },
    setLanguage() {
      var temp_lang = document.getElementsByClassName("goog-te-combo")[0].value;
      var flang =
        temp_lang == "hi" ? "हिन्दी" : temp_lang == "mr" ? "मराठी" : "English";

      document.querySelector(".lang-bar a.dropdown-toggle").innerHTML = flang;
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    username() {
      var nme = this.$store.getters.loggedUser;
      return nme.UserName;
    },
    RegType() {
      var nme = this.$store.getters.loggedUser;
      return nme.RegistrationType;
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  background: #e8eff9;
}

ul {
  padding: 0;
  margin: 0;
}
.top-land {
  /*border-bottom : solid 2px #ccc;*/
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar {
  padding: 0 !important;
  background: #5c6bc0 !important;
}
.navbar-brand {
  font-size: 12pt !important;
  color: #ccc !important;
}
.navbar-toggler {
  margin: 5px;
}
.container.sm {
  width: 90% !important;
  background: #fff;
  padding: 20px 30px;
}
/* #google_translate_element,
.skiptranslate {
  display: none;
} */

body {
  top: 0 !important;
}
/*  Md Tofiq (B) 28-2-19 */
@media only screen and (max-width: 767px) {
  #image {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  #left {
    margin-right: 40%;
  }
}
/* Md(E) */
</style>
