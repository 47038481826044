<template>
  <b-container>
    <hr />
    <b-card
      header="Exemption Application Guard Details"
      header-tag="header"
      footer=""
      footer-tag="footer"
      title=""
      style=""
    >
      <b-row class="mb-2" style="margin: 30px">
        <b-col md="3">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Type to Search"
              size="sm"
            />
            <b-input-group-append>
              <b-btn :disabled="!filter" @click="filter = ''" size="sm"
                >Clear</b-btn
              >
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="3">
          <p>Total Applications: {{ totalRows }}</p>
        </b-col>
        <b-col>
          <b-button
            class="float-right mx-3"
            variant="outline-danger"
            size="sm"
            @click="goback"
            style="float: right; font-size: 1em; font-color: red"
          >
            Back
          </b-button>
          <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
          <b-button
            variant="outline-success"
            size="sm"
            @click="downloadExcel"
            style="float: right; font-size: 1em; font-color: green"
          >
            <i class="fa fa-file-excel"></i>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        id="tblApplication"
        show-empty
        select-mode="multi"
        selectable
        head-variant="dark"
        empty-text="No Applications available."
        stacked="md"
        bordered
        striped
        :items="applications"
        :fields="(RoleID==5?fields:otherfields)"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @filtered="onFiltered"
        @row-selected="onRowSelected"
        style="font-size: 12px"
      >
        <template v-slot:cell(selected)="{ rowSelected }">
          <!-- <template slot="selected" slot-scope="row"> -->
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not Selected</span>
          </template>
        </template>
        <template v-slot:cell(Sr)="row">{{
          (currentPage - 1) * perPage + row.index + 1
        }}</template>
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            @click.stop="viewProfile(row.item)"
            class="mr-1 pt-0 pb-0"
            variant="success"
          >
            <i class="fas fa-eye"></i>
          </b-button>
        </template>
        <template v-slot:cell(Gender)="row">
          <!-- <template slot="selected" slot-scope="row"> -->
          <template v-if="row.item.Gender">
            <span>Male</span>
          </template>
          <template v-else>
            <span>Female</span>
          </template>
        </template>
        <template v-slot:cell(ExServiceMan)="row">
          <!-- <template slot="selected" slot-scope="row"> -->
          <template v-if="row.item.ExServiceMan">
            <span>Yes</span>
          </template>
          <template v-else>
            <span></span>
          </template>
        </template>
        <template v-slot:cell(editDoc)="row">
          <b-button
            size="sm"
            @click.stop="editDocument(row.item)"
            class="mr-1 pt-0 pb-0"
            variant="warning"
            v-b-tooltip.hover
            title="Edit Doc"
            v-if="isAdmin"
            >Docs</b-button
          >
        </template>
        <template v-slot:cell(marks)="row">
          <b-button
            size="sm"
            @click.stop="enterMarks(row.item, row.index, $event.target)"
            class="mr-1 pt-0 pb-0"
            variant="warning"
            v-b-tooltip.hover
            title="Edit Doc"
            v-if="row.item.ApplicationStatusID == 6"
            >Marks</b-button
          >
        </template>
        <template v-slot:cell(update_status)="row">
          <b-button
          v-if="ApplicationStatus!=2"
            size="sm"
            @click.stop="update_guard_status(row.item, row.index, $event.target)"
            class="mr-1 pt-0 pb-0"
            variant="warning"
            v-b-tooltip.hover
            title="Update status"
            >Update Status</b-button
          >
        </template>
      </b-table>
      <b-row>
        <b-col md="10" class="my-1">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0"
            size="sm"
            @change="ChangeEvent"
          />
        </b-col>
        <b-col md="2" class="my-1">
          <b-form-group
            horizontal
            label="Per Page"
            class="mb-0"
            label-size="sm"
            :label-cols="6"
          >
            <b-form-select
              :options="pageOptions"
              v-model="perPage"
              size="sm"
              @change="ChangeEventPage"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-modal ref="mdlGuard" size="xl" hide-footer title="Guard Profile">
      <guard-profile :id="selectedID"></guard-profile>
    </b-modal>
    <b-modal id="mdlUpdateStatus" size="lg" :title="title" hide-footer>
        <table style="" class="table table_border_none">
          <tr>
            <td>
              Select Status
            </td>
            <td>
                
                <b-form-group>
                  <b-select
                    id="ipDocType"
                    class="form-control hide-print"
                    value-field="StatusID"
                    size="sm"
                    text-field="Status"
                    :options="ApprovalStatus"
                    v-model="update_aproval_status"
                  ></b-select>
                </b-form-group>
              </td>
              <td>
                Remark
              </td>
              <td>
                <input type="text"
                    id="approvalRemarks"
                    class="form-control hide-print"
                    value-field="approvalRemarks"
                    size="sm"
                    v-model="update_aproval_remark"
                    text-field="Status"
                  ></td>
              <td style="width: 100px">
                <br>
                <b-button class="hide-print" @click="save_status()" size="sm"  variant="info" style="margin: 2px"
                  >Update</b-button
                >
              </td>
              
          </tr>
        </table>
      </b-modal>
  </b-container>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import GuardProfile from './GuardProfile.vue'

export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType","ApplicationStatus"],

  components: { Spinner, GuardProfile},
  data() {
    return {
      ApprovalStatus: [],
      GuardApprovalStatus:"",
      RejectReasons: [],
      showBtn: false,
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      RoleID:"",
      selected: [],
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      approvalStatus: [],
      applications: [],
      appCount: 0,
      appDate: null,
      fields: [
        { key: "selected", label: "Selected" },
        { key: "sr_no", label: "Sr No" },
        { key: "RegistrationNumber", label: "Application No", sortable: true },
         { key: "EntityName", label: "Guard Name", sortable: true },
        {
          key: "EmployerName",
          label: "Employer Name",
          sortable: true,
        },
        {
          key: "ApplicationStatus",
          label: "Status",
          sortable: true,
        },
        {
          key: "CreatedDate",
          label: "Created Date",
          formatter: "convertDateFormat",
          sortable: true,
        },
        { key: "remark", label: "Remark" },
        { key: "update_status", label: "Action",class:"width_row" },
        {
          key: "actions",
          label: "view",
        },
      ],
      otherfields:
      [
        { key: "selected", label: "Selected" },
        { key: "Sr", label: "SrNo" },
        { key: "RegistrationNumber", label: "Application No", sortable: true },
         { key: "EntityName", label: "Guard Name", sortable: true },
        {
          key: "EmployerName",
          label: "Employer Name",
          sortable: true,
        },
        {
          key: "ApplicationStatus",
          label: "Status",
          sortable: true,
        },
        {
          key: "CreatedDate",
          label: "Created Date",
          formatter: "convertDateFormat",
          sortable: true,
        },
        { key: "remark", label: "Remark" },
        
       
        {
          key: "actions",
          label: "view",
        },
      ],
      
      form: {
        BoardID: this.$store.getters.getUserBoard,
        UserID: this.$route.params.ExemptionEmployerID,
        ApplicationID: "",
        OffSet: 0,
        Limit: 10,
        ID: "",
        ApplicationStatus:1,
      },
      ApplicationID: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
      selectedID:"",
      editApproval:'',
      editApprovalIndex:'',
      update_aproval_remark:'',
      update_aproval_status:''
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    get_guard_list()
    {
     
          let promise = apis.ReadGuardList(this.form);

          promise
            .then((response) => {
              if (response.status == 200) {
                console.log(response);
                this.applications = response.body.Table;
               for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
            }
                this.totalRows = response.body.Table1[0].Column1;
              
              } else {
              }
            })
            .catch((err) => {});
    },
    save_status()
    {
     //alert(this.update_aproval_status);
      // console.log(this.editApproval);
      // return;
      if(this.update_aproval_status=='' || this.update_aproval_status==null)
      {
        this.$dialog.alert('Please select status of application');
        return;
        
      }
      else if(this.update_aproval_status==4 && (this.update_aproval_remark=='' || this.update_aproval_remark==null))
      {   
       
        
        this.$dialog.alert('Please enter remark');
        
        return;
      }
      else 
      {
   //alert(55555555);
        var userinfo = this.$store.getters.loggedUser;
        var userid = userinfo.UserId;
       
        var data={
          "ApplicationStatus":this.update_aproval_status,
          "Remarks":this.update_aproval_remark,
          "ApplicationID":this.editApproval.ExemptionGuardID,
          "ExemptionEmployerID":this.editApproval.ExemptionGuardID,
          "RejectReason":0,
          " UserID":userid
        }
        let promise = apis.UpdateGuardExemptionStatus(data);

      promise
        .then((response) => {
          if (response.status == 200) {
          
            this.$dialog.alert("Status has been Updated Succesfully..");
            // this.applications[this.editApprovalIndex].remark=this.update_aproval_remark;
            this.get_guard_list();

            this.$root.$emit("bv::hide::modal", "mdlUpdateStatus");
      
          } else {
            this.$dialog.alert(response.body);
          }
        })
        .catch((err) => {
          this.$dialog.alert(err.message);
        });
      }
    },
    update_guard_status(data,index)
    {
      
      this.$root.$emit("bv::show::modal", "mdlUpdateStatus");
      this.update_aproval_status=data.GuardApprovalStatus;
      // alert(this.update_aproval_status);
      this.update_aproval_remark=data.remark;
      this.editApproval=data;
      this.editApprovalIndex=index;
      
    },
    onRowSelected(items) {
      this.selected = items;
    },
    goback() {
      this.$router.push({
        name: "ExemptionViewProfile",
        params: {
          ApplicationID: this.form.ApplicationID,
          isExemption: 1,
          ApplicationStatus:this.ApplicationStatus
        },
      });
    },
    getdata() {
      this.isLoading = true;
      let promise = apis.getApprovalStatus();
      promise
        .then((response) => {
          this.isLoading = false;
          this.approvalStatus = response.body;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    onReset2(evt) {
      evt.preventDefault();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      this.isLoadingFile = true;

      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1].toLowerCase();
      }

      if (extn != "jpeg" && extn != "png" && extn != "jpg" && extn != "pdf") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.finp.reset();
        this.isLoadingFile = false;
        return;
      }

      let dt = Date.now();
      var filetype = files[0].type;
      var filepath = "bsc_" + dt + "." + extn;
      let promisefoto = lambda.getSignedURL("msobcfdc_bsc", filepath, filetype);

      promisefoto
        .then((response) => {
          let signedUrl = response;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": files[0].type,
            },
          };
          return axios.put(signedUrl, files[0], options);
        })
        .then((res) => {
          if (res.status == 200) {
            this.formBSC.ApprovalDoc = filepath;
            this.showBtn = true;
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoadingFile = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoadingFile = false;
        });
    },
    viewProfile(item) {
      this.selectedID = item.ExemptionGuardID;
      this.$refs["mdlGuard"].show();
    },
    editDocument(item) {
      this.$router.push({
        name: "DocEdit",
        params: {
          UserID: item.UserID,
          ApplicationStatus: this.form.ApplicationStatus,
        },
      });
    },
    enterMarks(item, index, button, n) {
      this.$root.$emit("bv::show::modal", "mdlMarks");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onSubmit() {
      if (this.appDate != null) {
        this.form.StartDate = Formatter.convertDateFormatYMD(this.appDate[0]);
        this.form.EndDate = Formatter.convertDateFormatYMD(this.appDate[1]);
      } else {
        this.form.StartDate = "0001-01-01";
        this.form.EndDate = "0001-01-01";
      }
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      // this.form.Limit = 500;
      this.form.OffSet = 0;

      this.getApplicationsSub();
    },

    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getApplicationsSub();
    },

    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
     // this.getApplicationsSub();
     let promise = apis.ReadGuardList(this.form);

    promise
      .then((response) => {
        if (response.status == 200) {
          console.log(response);
          this.applications = response.body.Table;
          this.totalRows = response.body.Table1[0].Column1;
        } else {
        }
      })
      .catch((err) => {});
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },

    getApplicationsSub() {
      this.isLoading = true;
      this.showDismissibleAlert = false;
      //this.form.AgencyName = "";
      //this.$store.dispatch("setSearchForm", this.form);
      let promise = apis.ReadExemptionApplication(this.form);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.applications = response.body.Table;
            //  this.totalRows = this.applications.length;
            this.totalRows = 100;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: null,
        EndDate: null,
      };
    },

    convertToPdf() {
      // console.log(this.applications)
     var datapdf=this.applications;
     for(var i=0;i<this.applications.length;i++)
     {
       datapdf[i].SrNo=i+1;
       datapdf[i].CreatedDate=this.convertDateFormat(datapdf[i].CreatedDate)
     }
      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "SrNo", title: "Sr No" },
        {
          dataKey: "RegistrationNumber",
          title: "Registration No",
        },
        { dataKey: "EntityName", title: "Guard Name" },
        {dataKey: "EmployerName", title: "Employer Name"},
        { dataKey: "ApplicationStatus", title: "Status" },
        { dataKey: "CreatedDate", title: "Application Date" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchFormExmpt.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Exemption List ",
          pdf.internal.pageSize.getWidth() / 2,
          50,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        datapdf,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            if (data.cell.section != "head") {
              switch (data.column.dataKey) {
                case "CreatedDate":
                  data.cell.text = vc.convertDateFormat(data.cell.text);
                  break;
                case "Gender":
                  if (data.cell.text == "false") {
                    data.cell.text = "Female";
                  } else {
                    data.cell.text = "Male";
                  }
                  break;
                case "ExServiceMan":
                  if (data.cell.text == "false") {
                    data.cell.text = "No";
                  } else {
                    data.cell.text = "Yes";
                  }
                  break;
              }
            }
          },
        }
      );
      pdf.save("Applications.pdf");
    },
    async downloadExcel() {
      var columns = [
       
        "Registation No",
        "Guard Name",
        "Employer Name",
        "Created Date",
        "Remark",
      ];

      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {
        
          "Registation No": null,
          "Guard Name": "",
          "Employer Name": null,
          "Created Date": "",
          Remark: "",
        };
      
        obj["Registation No"] = element.RegistrationNumber;
        obj["Guard Name"] = this.titleCase(element.EntityName);
        obj["Employer Name"] = element.EmployerName;
        obj["Created Date"] = this.convertDateFormat(element.CreatedDate);
        obj["Remark"] = element.Remark;

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },

  },
  mounted() {
//alert(this.$route.params.ExemptionEmployerID);
//alert(this.$route.params.ApplicationStatus);
    var userinfo = this.$store.getters.loggedUser;
    var id = this.$route.params.ApplicationID;
    this.form.ApplicationID = this.$route.params.ApplicationID;
    this.form.ID = id;
    this.ApplicationStatus= this.$route.params.ApplicationStatus;
    this.RoleID=userinfo.RoleID;
    var employerid = this.$route.params.ExemptionEmployerID;
    

    // approval flow

    let promiseA = apis.ReadExemptAppStatusByRoleStatus({
      Role: userinfo.RoleID,
      Status: this.form.ApplicationStatus
    });

    promiseA
      .then((response) => {
        if (response.status == 200) {
          this.ApprovalStatus = response.body;
          if (this.ApprovalStatus.length > 0) {
            this.showApproval = true;
          }

        } else {
          this.ApprovalStatus = [];
        }
      })
      .catch((err) => {
        this.ApprovalStatus = [];
        this.alertText = "Error in Loading Application Staus Master";
        this.variant = "danger";
        this.showDismissibleAlert = true;
      });
    //this.get_data();

    let promiseR = apis.ReadRejectReason();

    promiseR
      .then((response) => {
        if (response.status == 200) {
          this.RejectReasons = response.body;
        }
      })
      .catch((err) => {
        this.alertText = err.message;
        this.showDismissibleAlert = true;
        this.variant = "danger";
      });

    let promise = apis.ReadGuardList(this.form);

    promise
      .then((response) => {
        if (response.status == 200) {
          console.log(response);
          this.applications = response.body.Table;
          this.totalRows = response.body.Table1[0].Column1;
        } else {
        }
      })
      .catch((err) => {});
  },
};
</script>

<style scoped>
.table_border_none,.table_border_none>tr>th,.table_border_none>tr>td{
  border:none!important;
  
}
.width_row{
  width: 20px!important;;
}
</style>
