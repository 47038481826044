<template>
  <b-container class="newfont">
    <div id="printme">
       <b-row class="my-2">
      <!-- <b-row class="my-2">
		    <b-col style="text-align:center">
          <img src="@/assets/images/logo_trans.png" class="float-left" width="80px">
          <h2> Security Guards Board</h2>
          <h4>Registration of Principal Employer</h4>
        </b-col>
			</b-row> -->
      <b-col>
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          @click.prevent="goback"
          variant="danger"
          size="sm"
          >BACK</b-button
        >
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          size="sm"
          variant="info"
          @click="print"
          >Print Profile</b-button
        >
      </b-col>
      </b-row>
       <b-row class="my-2">
		    <b-col style="text-align:center">
          <!-- <img src="@/assets/images/logo_trans.png" class="float-left" width="80px"> -->
          <h2> Security Guards Board</h2>
          <h4>Registration of Principal Employer</h4>
        </b-col>
			</b-row>
			<hr/>
			<b-row class="my-2">
				<b-col md="1"></b-col>
				<b-col><strong>Registration Number:</strong> {{RegistrationNumber}}</b-col>
				<b-col></b-col>
				<b-col class="float-right"><strong>Date:</strong>{{PaymentDate}}</b-col>
			</b-row>
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Board</b-col>
      <b-col md="6">{{BoardName}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Employer Name</b-col>
      <b-col md="6">{{form.EmployerName}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Employer Address</b-col>
      <b-col md="6">{{form.EmployerAddress}}</b-col>
    </b-row>
    
    
	 <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">City</b-col>
      <b-col md="6">{{DistrictName}}</b-col>
    </b-row>
    
    
	 <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Pincode</b-col>
      <b-col md="6">{{form.Pincode}}</b-col>
    </b-row>
    
    

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">TelNo</b-col>
      <b-col md="6">{{form.TelNo}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Email ID</b-col>
      <b-col md="6">{{form.EmailId}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Type of Business</b-col>
      <b-col md="6">{{form.BusinessType}}</b-col>
    </b-row>
    
    

    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">FactoryType</b-col>
      <b-col md="6">{{form.FactoryType}}</b-col>
    </b-row>
    
    
	<b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Establishment Is</b-col>
      <b-col md="6">{{form.WhetherTheEstablishmentIs}}</b-col>
    </b-row>
    
    

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Date Of Incorporation</b-col>
      <b-col md="6">{{form.IncorporationDate}}</b-col>
    </b-row>
    
    

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Registration</b-col>
      <b-col md="6">{{form.RegistrationNo}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Registration Date</b-col>
      <b-col md="6">{{form.RegistrationDate}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Owners/Partners/Directors Details</b-col>
    </b-row>  
    <div>
      <b-row class="my-2">
      <b-col md="1"></b-col><b-col>
      <table>
        <tr>
          <th>Owner Name</th>
          <th>Owner Address</th>
          <th>Owner Mobile</th>
          <th>Owner Email</th>
        </tr>
        <tr v-for="owner in form.OwnerDetails" :key="owner.OwnerName">

          <td>{{owner.OwnerName}}</td>
          <td>{{owner.OwnerAddress}}</td>
          <td>{{owner.OwnerMobile}}</td>
          <td>{{owner.OwnerEmail}}</td>
        </tr>
      </table>
      </b-col>
      </b-row>
      </div>


    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Details of the Guards Employed</b-col>
    </b-row>  
    <div>
      <b-row class="my-2">
      <b-col md="1"></b-col><b-col>
      <table>
        <tr>
          <th>Class</th>
          <th>Male</th>
          <th>Female</th>
          <th>Basic Salary</th>
          <th>DA</th>
          <th>Other Allowance</th>
          <th>total Salary</th>
        </tr>
        <tr>
          <td>SecurityGuards</td>
          <td>{{form.SecurityGuards.TotalMaleGuards}}</td>
          <td>{{form.SecurityGuards.TotalFemaleGuards}}</td>
          <td>{{form.SecurityGuards.BasicSalary}}</td>
          <td>{{form.SecurityGuards.DA}}</td>
          <td>{{form.SecurityGuards.OtherAllowance}}</td>
          <td>{{form.SecurityGuards.TotalSalary}}</td>
        </tr>
        <tr>
          <td>SecuritySupervisor</td>
          <td>{{form.SecuritySupervisor.TotalMaleSupervisor}}</td>
          <td>{{form.SecuritySupervisor.TotalFemaleSupervisor}}</td>
          <td>{{form.SecuritySupervisor.BasicSalary}}</td>
          <td>{{form.SecuritySupervisor.DA}}</td>
          <td>{{form.SecuritySupervisor.OtherAllowance}}</td>
          <td>{{form.SecuritySupervisor.TotalSalary}}</td>
        </tr>
        <tr>
          <td>SecurityOfficer</td>
          <td>{{form.SecurityOfficer.TotalMaleOfficer}}</td>
          <td>{{form.SecurityOfficer.TotalFemaleOfficer}}</td>
          <td>{{form.SecurityOfficer.BasicSalary}}</td>
          <td>{{form.SecurityOfficer.DA}}</td>
          <td>{{form.SecurityOfficer.OtherAllowance}}</td>
          <td>{{form.SecurityOfficer.TotalSalary}}</td>
        </tr>
        <tr>
          <td>HeadSecurityOfficer</td>
          <td>{{form.HeadSecurityOfficer.TotalMaleHeadOfficer}}</td>
          <td>{{form.HeadSecurityOfficer.TotalFemaleHeadOfficer}}</td>
          <td>{{form.HeadSecurityOfficer.BasicSalary}}</td>
          <td>{{form.HeadSecurityOfficer.DA}}</td>
          <td>{{form.HeadSecurityOfficer.OtherAllowance}}</td>
          <td>{{form.HeadSecurityOfficer.TotalSalary}}</td>
        </tr>
      </table>
      </b-col>
      </b-row>
    </div>
    
    

    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Do You Transfer Guards?</b-col>
      <b-col md="6">{{form.Transfer}}</b-col>
    </b-row>
    
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Have You completed Police Verification of Guards?</b-col>
      <b-col md="6">{{form.PoliceVerification}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4"> Reason for No Police Verification:</b-col>
      <b-col md="6">{{form.NonVerificationReason}}</b-col>
    </b-row>
    
    <b-row class="my-2">
      <b-col md="1"></b-col><b-col md="4">Have You Recruited Guards from the Security Guards Board?</b-col>
      <b-col md="6">{{form.BoardsGuardsHistory}}</b-col>
    </b-row>
    </div>
    <b-row class="my-2">
      <!-- <b-col> -->
    <!-- <b-btn variant="success" class="float-right mt-2" size="sm" v-print="'#printMe'">Print Receipt</b-btn> -->
          <!-- <b-button class="float-right mt-2" variant="info" @click="print">Print</b-button>
      </b-col> -->
    </b-row>
  </b-container>
</template>

<script>
import apis from "@/apis";
import vueHtmlToPaper from "vue-html-to-paper";
export default {
  props: ["id"],
  data() {
    return {
      show: true,
      form: {},
      RegistrationID:""
    };
  },
  methods: {
    print() {
      window.print();
    },
     goback() {
      //this.$router.back();
      this.$router.push({
        name: "PrivateRegistration",
        params: {
          userDistrictID: this.$store.getters.getUserDistrict,
          userApprovalStatus: this.$store.getters.getAppStatus,
          userLoanType: 1,
        },
      });
    },
    readProfile() {
      let promise = apis.ReadRegistrationDetails(this.id, 11);

      promise
        .then(response => {
          //console.log('Reponse' ,response);
          if (
            response.status == 200 &&
            response.body != "Error in Registration"
          ) {
            this.$store.dispatch("setRegID", response.body.RegistrationID);
            this.form = JSON.parse(response.body.FormDetails);

            this.BoardName = response.body.BoardName;
            this.PaymentDate = response.body.PaymentDate;
            this.RegistrationNumber = response.body.RegistrationNumber;
            this.DistrictName = response.body.DistrictName;
            this.StateName = response.body.StateName;
          } else {
            this.form = {};
          }
        })
        .catch(err => {
          this.$dialog.alert("Error In Loading previous Details.");
        });
    }
  },
  mounted() {
    this.readProfile();
  }
};
</script>
		<style scoped>
table {
  font-family: arial, sans-serif;
  /* border-collapse: collapse; */
  width: 80%;
  margin-left: 10%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
}
.newfont {
  font-size: 0.875rem;
}
</style>
		
		